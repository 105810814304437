// import store from '@/store/index'

const accType = localStorage.getItem('type')

// Lazy load route
function route (name, path, component, options = {}) {
  return {
    name,
    path,
    component: () => import(`@/pages/${component}.vue`),
    ...options
  }
}

export default [
  { path: '/', redirect: { name: accType && accType === 'god' ? 'Templates' : accType && accType !== 'god' ? 'Dashboard' : 'Login' } },
  route('AppWrapper', '/', 'AppWrapper', {
    meta: {
      requiresAuth: true
    },
    children: [
      route('Dashboard', '/dashboard', 'Dashboard/Dashboard', {
        meta: {
          requiresAccountType: ['company', 'individual', 'lawyer']
        }
      }),
      route('Cases', '/cases', 'Cases/Cases', {
        meta: {
          requiresAccountType: ['company', 'individual', 'lawyer']
        }
      }),
      route('CaseView', '/cases/:_id', 'Cases/CaseView', {
        children: [
          route('CasesViewWorkbench', '/cases/:_id/:workbenchId', 'Cases/WorkbenchView', {
            children: [
              route('CasesViewTemplate', '/cases/:_id/:workbenchId/:templateId', 'Cases/TemplateView', {
                meta: {
                  requiresAccountType: ['company', 'individual', 'lawyer']
                },
                props: true
              })
            ],
            meta: {
              requiresAccountType: ['company', 'individual', 'lawyer']
            }
          })
        ],
        meta: {
          requiresAccountType: ['company', 'individual', 'lawyer']
        }
      }),
      route('Members', '/members', 'Members/Members', {
        meta: {
          requiresAccountType: ['lawyer']
        }
      }),
      route('Clients', '/clients', 'Clients/Clients', {
        meta: {
          requiresAccountType: ['lawyer']
        }
      }),
      route('Client', '/clients/:_id', 'Clients/Client', {
        meta: {
          requiresAccountType: ['lawyer']
        }
      }),
      route('Professionals', '/professionals', 'Professionals/Professionals', {
        meta: {
          requiresAccountType: ['company', 'individual']
        }
      }),
      route('ProfessionalDetails', '/professional/:_id', 'Professionals/ProfessionalDetails', {
        meta: {
          requiresAccountType: ['company', 'individual']
        }
      }),
      route('CaseFormBuilder', '/models', 'CaseFormBuilder/CaseFormBuilder', {
        meta: {
          requiresAccountType: ['lawyer', 'god']
        }
      }),
      route('ViewCaseForm', '/models/:_id', 'CaseFormBuilder/ViewCaseForm', {
        children: [
          route('WorkbenchViewCaseModels', '/models/:_id/:workbenchId', 'CaseFormBuilder/WorkbenchViewCaseModels', {
            children: [
              route('TemplateViewCaseModels', '/models/:_id/:workbenchId/:templateId', 'CaseFormBuilder/TemplateViewCaseModels', {
                meta: {
                  requiresAccountType: ['lawyer', 'god']
                }
              })
            ],
            meta: {
              requiresAccountType: ['lawyer', 'god']
            }
          })
        ],
        meta: {
          requiresAccountType: ['lawyer', 'god']
        }
      }),
      route('AddCaseForm', '/add-case-form', 'CaseFormBuilder/AddCaseForm', {
        meta: {
          requiresAccountType: ['lawyer', 'god']
        }
      }),
      // route('ViewCaseForm', '/case-form-:_id', 'CaseFormBuilder/ViewCaseForm', {
      //   meta: {
      //     requiresAccountType: ['lawyer', 'god']
      //   }
      // }),
      route('Templates', '/templates', 'TemplateEditor/Templates', {
        meta: {
          requiresAccountType: ['lawyer', 'god']
        }
      }),
      route('Documents', '/documents', 'Documents/Documents', {
        meta: {
          requiresAccountType: ['company', 'individual', 'lawyer', 'god']
        }
      }),
      route('Folders', '/documents/folders/:_id', 'Documents/Documents', {
        meta: {
          requiresAccountType: ['company', 'individual', 'lawyer', 'god']
        }
      }),
      route('TemplateEditor', '/templates/:_id', 'TemplateEditor/TemplateEditorView', {
        meta: {
          requiresAccountType: ['lawyer', 'god']
        }
      }),
      route('Profile', '/profile', 'Profile/Profile', {
        meta: {
          requiresAccountType: ['company', 'individual', 'lawyer']
        }
      }),
      route('Notifications', '/notifications', 'Notifications/Notifications', {
        meta: {
          requiresAccountType: ['company', 'individual', 'lawyer']
        }
      }),
      route('BuyAccount', '/activate-account', 'Buy/BuyAccount', {
        meta: {
          requiresAccountType: ['lawyer']
        }
      }),
      route('CreateCompany', '/create-company', 'Members/CreateCompany', {
        meta: {
          requiresAccountType: ['lawyer']
        }
      }),
      route('BuyMemberSeats', '/buy-member-seats', 'Buy/BuyMemberSeats', {
        meta: {
          requiresAccountType: ['lawyer']
        }
      }),
      route('BuyStorage', '/buy-storage', 'Buy/BuyStorage', {
        meta: {
          requiresAccountType: ['lawyer']
        }
      }),
      route('BuyCompanyStorage', '/buy-company-storage', 'Buy/BuyCompanyStorage', {
        meta: {
          requiresAccountType: ['lawyer']
        }
      }),
      route('DeleteMemberSeats', '/delete-member-seats', 'Buy/DeleteMemberSeats', {
        meta: {
          requiresAccountType: ['lawyer']
        }
      }),
      route('CreateCompanySuccessPage', '/company-success', 'Members/CreateCompanySuccessPage', {
        meta: {
          requiresAccountType: ['lawyer']
        }
      }),
      route('AccountActivationSuccessPage', '/account-success', 'Profile/AccountActivationSuccessPage', {
        meta: {
          requiresAccountType: ['lawyer']
        }
      }),
      route('Settings', '/settings', 'Settings/Settings', {
        meta: {
          requiresAccountType: ['god']
        }
      }),

      // MOBILE ROUTES
      route('Search', '/search', 'Mobile/Search/Search', {
        meta: {
          requiresAccountType: ['company', 'individual', 'lawyer']
        }
      }),
      route('AddNotesMobile', '/add-note', 'Mobile/Notes/AddNoteMobile', {
        meta: {
          requiresAccountType: ['company', 'individual', 'lawyer']
        }
      }),
      route('FullNoteMobile', '/edit-note', 'Mobile/Notes/FullNoteMobile', {
        meta: {
          requiresAccountType: ['company', 'individual', 'lawyer']
        }
      }),
      route('CompanyClientProfile', '/company-client-profile/:_id', 'Mobile/Clients/CompanyClientProfile', {
        meta: {
          requiresAccountType: ['lawyer']
        }
      }),
      route('IndividualClientProfile', '/individual-client-profile/:_id', 'Mobile/Clients/IndividualClientProfile', {
        meta: {
          requiresAccountType: ['lawyer']
        }
      }),
      route('ExportDocumentsMobile', '/export-documents', 'Mobile/Documents/ExportDocumentsMobile', {
        meta: {
          requiresAccountType: ['company', 'individual', 'lawyer']
        }
      }),
      route('AddDocumentsMobile', '/upload-documents', 'Mobile/Documents/AddDocumentsMobile', {
        meta: {
          requiresAccountType: ['company', 'individual', 'lawyer']
        }
      }),
      route('AddClientMobile', '/add-client', 'Mobile/Clients/AddClientMobile', {
        meta: {
          requiresAccountType: ['lawyer']
        }
      }),
      route('CaseViewMobile', '/mobile-case/:_id', 'Mobile/Cases/CaseViewMobile', {
        meta: {
          requiresAccountType: ['company', 'individual', 'lawyer']
        }
      }),
      route('WorkbenchViewMobile', '/mobile-case/:_id/:workbenchId', 'Mobile/Cases/WorkbenchViewMobile', {
        meta: {
          requiresAccountType: ['company', 'individual', 'lawyer']
        }
      }),
      route('TemplateViewMobile', '/mobile-case/:_id/:workbenchId/:templateId', 'Mobile/Cases/TemplateViewMobile', {
        meta: {
          requiresAccountType: ['company', 'individual', 'lawyer']
        }
      }),
      route('AddCaseModelMobile', '/add-case-model', 'Mobile/Cases/AddCaseModelMobile', {
        meta: {
          requiresAccountType: ['lawyer']
        }
      }),
      route('CaseTimelineMobile', '/case-timeline/:_id', 'Mobile/Cases/CaseTimelineMobile', {
        meta: {
          requiresAccountType: ['lawyer']
        }
      }),
      route('TemplatesGeneralOverviewMobile', '/templates-mobile', 'Mobile/Templates/TemplatesGeneralOverviewMobile', {
        meta: {
          requiresAccountType: ['lawyer', 'god']
        }
      }),
      route('TemplateEditorViewMobile', '/templates-mobile/:_id', 'Mobile/Templates/TemplateEditorViewMobile', {
        meta: {
          requiresAccountType: ['lawyer', 'god']
        }
      }),
      route('FileViewMobile', '/file-view-mobile/:_id', 'Mobile/Components/FileViewMobile', {
        meta: {
          requiresAccountType: ['company', 'individual', 'lawyer']
        }
      })
    ]
  }),
  route('SharableClient', '/client-:_id', 'Clients/SharableClient', {
    meta: {
      viewOnly: true
    }
  }),
  route('Login', '/login', 'Login', {
    meta: {
      viewOnly: true
    }
  }),
  route('Register', '/register', 'Register', {
    meta: {
      viewOnly: true
    }
  }),
  route('ForgotPassword', '/forgotpassword', 'ForgotPassword', {
    meta: {
      viewOnly: true
    }
  }),
  route('ClientAccountConfirmation', '/confirmation/:email/:_id/:token', 'ClientAccountConfirmation', {
    meta: {
      viewOnly: true
    }
  }),
  route('MainAccountConfirmation', '/verification/:email', 'MainAccountConfirmation', {
    meta: {
      viewOnly: true
    }
  })
]
