import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import store from '@/store/index'
import { EventBus } from '@/utils/EventBus'
// import { loadLanguageAsync } from '@/i18n/index'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.auth.token) {
      next()
    } else {
      await store.dispatch('auth/refreshToken')
      if (store.state.auth.token) next()
      else next('/login')
    }
  } else {
    next()
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAccountType)) {
    if (to.meta.htmlFullHeight) {
      document.querySelector('html').classList.add('html-overflow-hidden')
    } else {
      document.querySelector('html').classList.remove('html-overflow-hidden')
    }
    if (!store.state.account.account) {
      const message = 'ref_token/setAccount'
      EventBus.$emit('app-loading', message)
      await store.dispatch('auth/refreshToken')
      await store.dispatch('account/getAndSetAccount')
      EventBus.$emit('stop-app-loading')
    }
    if (to.name === 'CreateCompany') {
      if (!store.state.company.company) {
        await store.dispatch('company/setCompany')
        if (!store.state.company.company) next()
        else next('/dashboard')
      }
    }
    if (to.name === 'CreateCompanySuccessPage') {
      console.log(store.state.company.company, 'store.state.company.company')
      if (!store.state.company.company) {
        await store.dispatch('company/setCompany')
        console.log(store.state.company.company, 'store.state.company.company2')
        if (store.state.company.company) next()
        else next('/dashboard')
      }
    }
    if (store.state.account.account && to.meta.requiresAccountType.includes(store.state.account.account.accountType)) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

// router.beforeEach((to, from, next) => {
//   const lang = to.params.lang
//   loadLanguageAsync(lang).then(() => next())
// })

export default router
