export default {
  badge: 'Значка',
  close: 'Затвори',
  dataIterator: {
    noResultsText: 'Няма намерени съответстващи записи',
    loadingText: 'Зареждане на елементи...'
  },
  dataTable: {
    itemsPerPageText: 'Редове на страница:',
    ariaLabel: {
      sortDescending: 'Низходящо сортиране.',
      sortAscending: 'Сортиране по възходящ ред.',
      sortNone: 'Без подредба.',
      activateNone: 'Активирайте, за да премахнете сортирането.',
      activateDescending: 'Активирайте, за да сортирате низходящо.',
      activateAscending: 'Активирайте, за да сортирате възходящо.'
    },
    sortBy: 'Сортирайте по'
  },
  dataFooter: {
    itemsPerPageText: 'Елементи на страница:',
    itemsPerPageAll: 'Всички',
    nextPage: 'Следваща страница',
    prevPage: 'Предишна страница',
    firstPage: 'Първа страница',
    lastPage: 'Последна страница',
    pageText: '{0}-{1} от {2}'
  },
  datePicker: {
    itemsSelected: '{0} избрани',
    nextMonthAriaLabel: 'Следващият месец',
    nextYearAriaLabel: 'Следващата година',
    prevMonthAriaLabel: 'Предишен месец',
    prevYearAriaLabel: 'Предишна година'
  },
  noDataText: 'Няма налични данни',
  carousel: {
    prev: 'Предишна визуализация',
    next: 'Следваща визуализация',
    ariaLabel: {
      delimiter: 'Carousel slide {0} от {1}'
    }
  },
  calendar: {
    moreEvents: '{0} още'
  },
  fileInput: {
    counter: '{0} файла',
    counterSize: '{0} файла ({1} общо)'
  },
  timePicker: {
    am: 'AM',
    pm: 'PM'
  },
  pagination: {
    ariaLabel: {
      wrapper: 'Странициране',
      next: 'Следваща страница',
      previous: 'Предишна страница',
      page: 'Отидете на страница {0}',
      currentPage: 'Текуща страница, Страница {0}'
    }
  }
}
