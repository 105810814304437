<template>
  <v-list-item
    v-ripple
    @click="$emit('open-client', client)"
  >
    <template v-slot:default="{ active }">
      <v-list-item-action v-if="hasSelection">
        <v-checkbox :input-value="active" />
      </v-list-item-action>
      <v-list-item-action v-if="hasActions">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              link
              :to="`/clients/${client._id}`"
              v-on="on"
              @click="closeSearchDialoginParent"
            >
              <v-icon
                color="info"
                dark
              >
                mdi-account-group
              </v-icon>
            </v-btn>
          </template>
        </v-menu>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title v-html="client.clientName" />
      </v-list-item-content>
    </template>
  </v-list-item>
</template>

<script>
import { mdiCog } from '@mdi/js'
import { EventBus } from '@/utils/EventBus'

export default {
  props: {
    client: {
      type: Object,
      default: null
    },
    hasSelection: {
      type: Boolean,
      default: false
    },
    hasActions: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      icons: {
        mdiCog
      }
    }
  },
  methods: {
    closeSearchDialoginParent () {
      EventBus.$emit('close-search-dialog')
    }
  }
}
</script>
