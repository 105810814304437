<template>
  <div>
    <v-menu
      v-if="!view"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      min-width="290px"
      :disabled="readonly"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="model"
          :label="label"
          :hint="hint"
          outlined
          dense
          readonly
          v-bind="attrs"
          persistent-hint
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="model"
        :hint="hint"
        @input="menu = false"
      />
    </v-menu>
    <v-sheet
      v-else
      color="white"
      elevation="3"
      class="relative pa-1"
    >
      <slot />
      <div>Field Name: {{ label }}</div>
      <div>Field Type: File Input</div>
      <div v-if="!value">
        Pending
      </div>
    </v-sheet>
  </div>
</template>

<script>
import InputMixin from './InputMixin'

export default {
  mixins: [InputMixin],
  props: {
    value: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      menu: false
    }
  }
}
</script>
