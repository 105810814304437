<template>
  <v-container>
    <v-card
      class="rounded-b-0 lightBackground"
      style=""
    >
      <v-btn
        v-if="comment.resolved"
        icon
        @click.stop="unresolveComment(comment)"
      >
        <v-icon
          color="primary"
        >
          {{ icon }}
        </v-icon>
      </v-btn>
      <v-btn
        v-if="comment.resolved && collabsOnResolved && comment.replies.arr && comment.replies.arr.length"
        icon
        @click.stop="showReplies()"
      >
        <v-icon
          color="primary"
        >
          mdi-menu-down
        </v-icon>
      </v-btn>
      <v-btn
        v-if="comment.resolved && !collabsOnResolved"
        icon
        @click.stop="hideReplies()"
      >
        <v-icon
          color="primary"
        >
          mdi-menu-up
        </v-icon>
      </v-btn>
    </v-card>
    <v-card
      v-click-outside="hide"
      class="pa-2"
      :class="comment.resolved ? 'rounded-t-0 custom-card' : ''"
      :style="comment.resolved ? `border-top: none;` : ``"
      :elevation="elevationEffect && commentId === comment.id ? 8 : 2"
      :disabled="comment.resolved"
      color="white"
      :ripple="false"
      @click.stop="openReplyField"
    >
      <v-btn
        color="primary"
        icon
        style="position: absolute; top: 5px; right: 35px;"
        @click.stop="resolveComment(comment)"
      >
        <v-icon>mdi-check</v-icon>
      </v-btn>

      <v-btn
        icon
        style="position: absolute; top: 5px; right: 10px;"
        @click="deleteComment(comment)"
      >
        <v-icon>
          mdi-delete-outline
        </v-icon>
      </v-btn>

      <v-card-text
        class="black--text"
      >
        <v-row class="mb-1 mt-1">
          <v-col
            cols="2"
          >
            <v-avatar
              size="26"
              color="primary"
            >
              <img
                v-if="comment.avatar"
                :src="comment.avatar"
              >
              <v-icon
                v-else
                size="20"
                dark
              >
                mdi-account-circle
              </v-icon>
            </v-avatar>
          </v-col>
          <v-col
            cols="10"
          >
            <span
              v-if="comment.createdBy"
              class="font-weight-medium"
            >
              {{ comment.createdBy }}
            </span>
          </v-col>
        </v-row>
        <div
          v-if="!commentTextToBeUpdated"
          style="white-space: normal;"
        >
          {{ comment.commentText }}
          <v-icon
            v-if="checkIfPermitted(comment.createdBy) && !commentTextToBeUpdated"
            small
            color="primary"
            class="ml-3"
            @click="commentTextToBeUpdated = true"
          >
            mdi-pencil
          </v-icon>
        </div>
        <v-text-field
          v-if="commentTextToBeUpdated"
          v-model="comment.commentText"
          v-click-outside="onClickOutside"
          dense
          outlined
          append-icon="mdi-send"
          @click:append="updateCommentsText(comment); commentTextToBeUpdated = false"
        />
        <v-divider
          v-if="comment.replies.arr && comment.replies.arr.length"
          class="my-3"
        />
        <div
          v-for="rep in comment.replies.arr"
          :key="rep.id"
          class="mt-3"
        >
          <div v-if="!collabsOnResolved">
            <v-row
              class="mb-1"
            >
              <v-col
                cols="2"
              >
                <v-avatar
                  size="26"
                  color="primary"
                >
                  <img
                    v-if="rep.avatar"
                    :src="rep.avatar"
                  >
                  <v-icon
                    v-else
                    size="20"
                    dark
                  >
                    mdi-account-circle
                  </v-icon>
                </v-avatar>
              </v-col>
              <v-col
                cols="10"
              >
                <span
                  class="font-weight-medium ml-3"
                >
                  {{ rep.author }}
                </span>
                <v-btn
                  v-if="checkIfPermitted(rep.author)"
                  icon
                  style="position: absolute; right: 10px;"
                  @click="deleteReply(rep, comment)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <div
              v-if="!replyTextToBeUpdated"
              style="white-space: normal;"
            >
              {{ rep.replyText }}
              <v-icon
                v-if="checkIfPermitted(rep.author)"
                small
                color="primary"
                class="ml-1"
                @click="replyTextToBeUpdated = true"
              >
                mdi-pencil
              </v-icon>
            </div>
            <v-text-field
              v-if="replyTextToBeUpdated"
              v-model="rep.replyText"
              dense
              outlined
              append-icon="mdi-send"
              @click:append="updateReplyText(comment, rep); replyTextToBeUpdated = false"
            />
            <v-btn
              v-if="rep.replyText.length>=88 && !showMore"
              x-small
              text
              color="primary"
              @click.stop="showMore = true"
            >
              {{ $t('actions|show_more') }}
            </v-btn>
            <v-btn
              v-if="rep.replyText.length >= 88 && showMore"
              x-small
              text
              color="primary"
              @click.stop="showMore = false"
            >
              {{ $t('actions|show_less') }}
            </v-btn>
            <v-divider class="my-3" />
          </div>
        </div>
        <div
          v-if="openValue"
        >
          <v-text-field
            v-model="replyText"
            dense
            outlined
            placeholder="Reply"
            class="mt-4 mb-n6"
          />
          <span
            style="font-size: 14px"
          >
            {{ $t('expressions|viewers_can_see_comments_and_suggestions') }}
          </span>
        </div>
      </v-card-text>
      <v-card-actions v-if="openValue">
        <v-btn
          color="primary"
          x-small
          :disabled="!replyText"
          @click="reply(comment)"
        >
          {{ $t('actions|reply') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mdiCommentArrowLeftOutline } from '@mdi/js'
import { EventBus } from '@/utils/EventBus'

export default {
  props: {
    comment: {
      type: Object,
      default: null
    },
    template: {
      type: Object,
      default: null
    },
    workbench: {
      type: Object,
      default: null
    },
    caseData: {
      type: Object,
      default: null
    },
    elevationEffect: {
      type: Boolean,
      default: false
    },
    commentId: {
      type: String,
      default: undefined
    }
  },
  data (instance) {
    return {
      replyText: '',
      commentTextToBeUpdated: false,
      replyTextToBeUpdated: false,
      openValue: false,
      showMore: false,
      icon: mdiCommentArrowLeftOutline,
      collabsOnResolved: instance._props.comment.resolved
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      actualCommentsEditorStorage: state => state.editorStore.actualCommentsEditorStorage
    }),
    actualComment () {
      return this.actualCommentsEditorStorage.find(comm => comm.attrs.id === this.comment.id)
    }
  },
  methods: {
    ...mapActions({
      deleteMyComment: 'cases/deleteMyComment',
      replyToComment: 'cases/updateCaseWorkbenchTemplate',
      updateMyComment: 'cases/updateCaseWorkbenchTemplate'
    }),
    onClickOutside () {
      this.commentTextToBeUpdated = false
    },
    openReplyField () {
      this.openValue = true
    },
    hide () {
      this.openValue = false
    },
    showReplies () {
      this.collabsOnResolved = false
    },
    hideReplies () {
      this.collabsOnResolved = true
    },
    getName (accountData) {
      let _name
      if (accountData.firstName && accountData.lastName) _name = accountData.firstName + ' ' + accountData.lastName
      else if (accountData.firstName && !accountData.lastName) _name = accountData.firstName
      else if (!accountData.firstName && accountData.lastName) _name = accountData.lastName
      else _name = accountData.email
      return _name
    },
    async resolveComment (comment) {
      this.loading = true
      EventBus.$emit('resolve-comment', comment)
      this.loading = false
    },
    async unresolveComment (comment) {
      this.loading = true
      EventBus.$emit('unresolve-comment', comment)
      this.loading = false
    },
    async updateCommentsText (comment) {
      this.loading = true
      const res = comment.commentText
      await this.$emit('comment-text-update', comment, res)
      this.loading = false
    },
    async deleteComment (comment) {
      EventBus.$emit('comment-deleted', comment)
    },
    async reply (comment) {
      const avatar = this.account.avatar ? `${process.env.VUE_APP_BACKEND_PATH}downloadAvatar/${this.account._id}/${this.account.avatar.raw.filename}` : ''
      EventBus.$emit('comment-reply', comment, this.replyText, this.account.accountData, avatar)
      this.replyText = ''
    },
    async updateReplyText (comment, rep) {
      this.loading = true
      const res = rep.replyText
      await this.$emit('comment-reply-text-update', comment, rep, res)
      this.loading = false
    },
    async deleteReply (reply, comment) {
      this.loading = true
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('common|are_you_sure_you_want_to_delete_this_resource')
      })
      if (res) {
        EventBus.$emit('delete-reply', reply, comment)
      }
    },
    checkIfPermitted (createdBy) {
      if (createdBy === this.account.accountData.firstName + ' ' + this.account.accountData.lastName) return true
      else if (createdBy === this.account.accountData.email) return true
      else return false
    }
  }
}
</script>

<style scoped>
.custom-card {
  border-top: none; /* Remove top border */
  box-shadow: none; /* Remove shadow */
}
</style>
