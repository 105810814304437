// import Mention from '@tiptap/extension-mention'
// import { Plugin } from 'tiptap'
// import { nodeEqualsType } from 'tiptap-utils'
import HashTagComponent from './HashTag.vue'
import Suggestion from '@tiptap/suggestion'
import store from '@/store/index.js'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import { mergeAttributes, Node } from '@tiptap/core'
import { PluginKey } from 'prosemirror-state'

var updateMentionValues

const MentionPluginKey = new PluginKey('mention')

const Mention = Node.create({
  name: 'mention',

  addOptions () {
    return {
      HTMLAttributes: {},
      renderLabel ({ options, node }) {
        return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`
      },
      suggestion: {
        char: '@',
        pluginKey: MentionPluginKey,
        command: ({ editor, range, props }) => {
          // increase range.to by one when the next node is of type "text"
          // and starts with a space character
          const nodeAfter = editor.view.state.selection.$to.nodeAfter
          const overrideSpace = nodeAfter?.text?.startsWith(' ')

          if (overrideSpace) {
            range.to += 1
          }

          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: this.name,
                attrs: props
              },
              {
                type: 'text',
                text: ' '
              }
            ])
            .run()
        },
        allow: ({ state, range }) => {
          const $from = state.doc.resolve(range.from)
          const type = state.schema.nodes[this.name]
          const allow = !!$from.parent.type.contentMatch.matchType(type)

          return allow
        }
      }
    }
  },

  group: 'inline',

  inline: true,

  selectable: false,

  atom: false,

  addAttributes () {
    return {
      id: {
        default: null,
        parseHTML: element => element.getAttribute('data-id'),
        renderHTML: attributes => {
          if (!attributes.id) {
            return {}
          }

          return {
            'data-id': attributes.id
          }
        }
      },

      label: {
        default: null,
        parseHTML: element => element.getAttribute('data-label'),
        renderHTML: attributes => {
          if (!attributes.label) {
            return {}
          }

          return {
            'data-label': attributes.label
          }
        }
      }
    }
  },

  parseHTML () {
    return [
      {
        tag: `span[data-type="${this.name}"]`
      }
    ]
  },

  renderHTML ({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes({ 'data-type': this.name }, this.options.HTMLAttributes, HTMLAttributes),
      this.options.renderLabel({
        options: this.options,
        node
      })
    ]
  },

  renderText ({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node
    })
  },

  addKeyboardShortcuts () {
    return {
      Backspace: () => this.editor.commands.command(({ tr, state }) => {
        let isMention = false
        const { selection } = state
        const { empty, anchor } = selection

        if (!empty) {
          return false
        }

        state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
          if (node.type.name === this.name) {
            isMention = true
            tr.insertText(this.options.suggestion.char || '', pos, pos + node.nodeSize)

            return false
          }
        })

        return isMention
      })
    }
  },

  addProseMirrorPlugins () {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion
      })
    ]
  }
})

const HashTag = Mention.extend({
  addNodeView () {
    return VueNodeViewRenderer(HashTagComponent)
  },
  addAttributes () {
    return {
      ...this.parent(),
      id: {
        parseHTML: element => {
          return {
            id: element.getAttribute('data-id')
          }
        },
        renderHTML: attributes => {
          return {
            'data-id': attributes.id
          }
        }
      },
      label: {
        parseHTML: element => {
          return {
            label: element.getAttribute('data-label')
          }
        },
        renderHTML: attributes => {
          return {
            'data-label': attributes.label
          }
        }
      },
      // key: {
      //   parseHTML: element => {
      //     return {
      //       key: element.getAttribute('data-key')
      //     }
      //   },
      //   renderHTML: attributes => {
      //     return {
      //       'data-key': attributes.key
      //     }
      //   }
      // },
      fieldType: {
        parseHTML: element => {
          return {
            fieldType: element.getAttribute('data-fieldType')
          }
        },
        renderHTML: attributes => {
          return {
            'data-fieldType': attributes.fieldType
          }
        }
      },
      fieldKey: {
        parseHTML: element => {
          return {
            fieldKey: element.getAttribute('data-fieldKey')
          }
        },
        renderHTML: attributes => {
          return {
            'data-fieldKey': attributes.fieldKey
          }
        }
      },
      fieldValue: {
        parseHTML: element => {
          return {
            fieldValue: element.getAttribute('data-fieldValue')
          }
        },
        renderHTML: attributes => {
          return {
            'data-fieldValue': attributes.fieldValue
          }
        }
      },
      fieldState: {
        parseHTML: element => {
          return {
            fieldValue: element.getAttribute('data-fieldState')
          }
        },
        renderHTML: attributes => {
          return {
            'data-fieldState': attributes.fieldState
          }
        }
      },
      customHashtag: {
        parseHTML: element => {
          return {
            fieldValue: element.getAttribute('data-customHashtag')
          }
        },
        renderHTML: attributes => {
          return {
            'data-customHashtag': attributes.customHashtag
          }
        }
      },
      customLabel: {
        parseHTML: element => {
          return {
            fieldValue: element.getAttribute('data-customLabel')
          }
        },
        renderHTML: attributes => {
          return {
            'data-customLabel': attributes.customLabel
          }
        }
      },
      language: {
        parseHTML: element => {
          return {
            fieldValue: element.getAttribute('data-language')
          }
        },
        renderHTML: attributes => {
          return {
            'data-language': attributes.language
          }
        }
      },
      fieldClass: {
        parseHTML: element => {
          return {
            fieldValue: element.getAttribute('data-fieldClass')
          }
        },
        renderHTML: attributes => {
          return {
            'data-fieldClass': attributes.fieldClass
          }
        }
      },
      fontSize: {
        parseHTML: element => {
          return {
            fieldValue: element.getAttribute('data-fontSize')
          }
        },
        renderHTML: attributes => {
          return {
            'data-fontSize': attributes.fontSize
          }
        }
      }
    }
  },

  addStorage () {
    return {
      actualHashTags: []
    }
  },

  async onCreate () {
    const setMentions = async () => {
      updateMentionValues = setTimeout(() => {
        this.storage.actualHashTags = []
        this.editor.view.state.doc.descendants((node, pos) => {
          if (node.type.name === 'mention') {
            Object.assign(node, { pos: pos })
            this.storage.actualHashTags.push(node)
          }
        })
        store.dispatch('editorStore/setSuggestions', this.storage.actualHashTags)
      },
      1000)
    }
    await setMentions()
  },

  onTransaction ({ editor, transaction }) {
    if (!transaction.steps.length) return
    if (transaction.steps[0] && !transaction.steps[0].slice) return
    if (transaction.steps[0].slice.content.content.length) return
    const updateMentions = async () => {
      updateMentionValues = setTimeout(async () => {
        this.storage.actualHashTags = []
        this.editor.view.state.doc.descendants((node, pos) => {
          if (node.type.name === 'mention') {
            Object.assign(node, { pos: pos })
            this.storage.actualHashTags.push(node)
          }
        })
        store.dispatch('editorStore/setSuggestions', this.storage.actualHashTags)
        this.options.ydoc.transact(() => {
          this.options.ymap.set('suggestions', 'onTransactionUpdate')
        })
      },
      700)
    }
    const stopTimeout = () => {
      clearTimeout(updateMentionValues)
    }
    stopTimeout()
    updateMentions()
  },
  onDestroy () {
    store.dispatch('editorStore/setSuggestions', [])
  }
})

export default HashTag
