import axios from '@/plugins/axios'
import Vue from 'vue'
import store from '../index'
import autobahn from 'autobahn'

const INIT = 'INIT'
const SET_TEMPLATES = 'SET_TEMPLATES'
const SET_TEMPLATE = 'SET_TEMPLATE'
const SET_LAVVIRA_TEMPLATES = 'SET_LAVVIRA_TEMPLATES'
const ADD_TEMPLATE = 'ADD_TEMPLATE'
const ADD_COMMENT = 'ADD_COMMENT'
const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE'
const UPDATE_IF_GOD_ACC_UNSHARED_TEMP = 'UPDATE_IF_GOD_ACC_UNSHARED_TEMP'
const DELETE_TEMPLATE = 'DELETE_TEMPLATE'
const DELETE_COMMENT = 'DELETE_COMMENT'
const SET_TEMPLATE_ID = 'SET_TEMPLATE_ID'
const SET_LOADING = 'SET_LOADING'
const SET_TEMPLATES_TAB_STATE = 'SET_TEMPLATES_TAB_STATE'
const SET_SCELETON_CARD = 'SET_SCELETON_CARD'
const REMOVE_SCELETON_CARD = 'REMOVE_SCELETON_CARD'
const RESET_TEMPLATES = 'RESET_TEMPLATES'

const state = () => {
  return {
    init: false,
    templateId: null,
    templates: [],
    lavviraTemplates: [],
    loading: false,
    actualTabTemplates: {}
    // actualTabTemplates: JSON.parse(localStorage.getItem('actualTabTemplates')) || {}
  }
}

const mutations = {
  [INIT] (state) {
    state.init = true
  },
  [SET_TEMPLATES] (state, payload) {
    state.templates = payload
  },
  [SET_LAVVIRA_TEMPLATES] (state, payload) {
    state.lavviraTemplates = payload
  },
  [SET_TEMPLATE] (state, payload) {
    const index = state.templates.findIndex(t => t._id === payload._id)
    Vue.set(state.templates, index, payload)
  },
  [ADD_TEMPLATE] (state, payload) {
    state.templates.push(payload)
  },
  [ADD_COMMENT] (state, payload) {
    const index = state.templates.findIndex(t => t._id === payload._id)
    Vue.set(state.templates, index, payload)
  },
  [UPDATE_TEMPLATE] (state, payload) {
    if (payload.shared) {
      // here the god account has shared the template with all users
      const index = state.lavviraTemplates.findIndex(t => t._id === payload._id)
      Vue.set(state.lavviraTemplates, index, payload)
      if (index === -1) {
        state.lavviraTemplates.push(payload)
      }
      const templatesIndex = state.templates.findIndex(t => t._id === payload._id)
      Vue.set(state.templates, templatesIndex, payload)
    } else {
      const index = state.templates.findIndex(t => t._id === payload._id)
      Vue.set(state.templates, index, payload)
    }
  },
  [UPDATE_IF_GOD_ACC_UNSHARED_TEMP] (state, payload) {
    const index = state.lavviraTemplates.findIndex(t => t._id === payload._id)
    Vue.set(state.lavviraTemplates, index, payload)
    const templatesIndex = state.templates.findIndex(t => t._id === payload._id)
    Vue.set(state.templates, templatesIndex, payload)
  },
  [DELETE_TEMPLATE] (state, payload) {
    const index = state.templates.findIndex(t => t._id === payload._id)
    state.templates.splice(index, 1)
  },
  [DELETE_COMMENT] (state, payload) {
    const index = state.templates.findIndex(t => t._id === payload._id)
    Vue.set(state.templates, index, payload)
  },
  [SET_TEMPLATE_ID] (state, id) {
    state.templateId = id
  },
  [SET_LOADING] (state, value) {
    state.loading = value
  },
  [SET_TEMPLATES_TAB_STATE] (state, value) {
    // localStorage.setItem('actualTabTemplates', JSON.stringify(value))
    state.actualTabTemplates = value
  },
  [SET_SCELETON_CARD] (state, sceleton) {
    state.templates.push(sceleton)
  },
  [REMOVE_SCELETON_CARD] (state) {
    const index = state.templates.findIndex(t => t.name === 'loading')
    state.templates.splice(index, 1)
  },
  [RESET_TEMPLATES] (state) {
    state.templates = []
    state.actualTabTemplates = {}
  }
}

const actions = {
  fetchTemplates ({ commit, state }, shouldInit = false) {
    if (shouldInit) {
      commit(INIT)
    } else {
      if (state.init) return
    }
    return new Promise(async (resolve) => {
      try {
        const { data } = await axios.get('/getTemplates')
        commit(SET_TEMPLATES, data.data)
      } catch (e) {
        console.error(e, 'fetchTemplates ERROR')
      } finally {
        resolve()
      }
    })
  },
  fetchLavviraTemplates ({ commit, state }, shouldInit = false) {
    if (shouldInit) {
      commit(INIT)
    } else {
      if (state.init) return
    }
    return new Promise(async (resolve) => {
      try {
        const { data } = await axios.get('/getLavviraTemplates')
        commit(SET_LAVVIRA_TEMPLATES, data.data)
      } catch (e) {
        console.error(e, 'fetchTemplates ERROR')
      } finally {
        resolve()
      }
    })
  },
  fetchTemplate ({ commit }, { _id }) {
    return new Promise(async (resolve) => {
      try {
        const { data } = await axios.get(`/getTemplate/${_id}`)
        commit(SET_TEMPLATE, data.data)
      } catch (e) {
        console.error(e, 'fetchTemplates ERROR')
      } finally {
        resolve()
      }
    })
  },
  addTemplate ({ commit, rootState }, payload = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const routeName = rootState.account.account.accountType === 'god' ? 'createSharedTemplate' : 'createTemplate'
        const { data } = await axios.post(`/${routeName}`, payload)
        if (data.success) {
          commit(ADD_TEMPLATE, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  saveAsTemplate ({ commit }, { _id, payload }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/copyTemplate/${_id}`, payload)
        if (data.success) {
          commit(ADD_TEMPLATE, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (e) {
        console.error(e, 'Copy Template ERROR')
        reject(e)
      }
    })
  },
  updateTemplate ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateTemplate/${_id}`, payload)
        if (data.removeFromShared === store.state.account.account._id) {
          commit(DELETE_TEMPLATE, data.data)
          resolve()
        } else if (data.unShareLavviraTemplate) {
          commit(UPDATE_IF_GOD_ACC_UNSHARED_TEMP, data.data)
        } else {
          commit(UPDATE_TEMPLATE, data.data)
          resolve()
        }
      } catch (e) {
        console.error(e, 'updateTemplate ERROR')
        reject(e)
      }
    })
  },
  deleteTemplate ({ commit }, payload = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/deleteTemplate/${payload._id}`)
        commit(DELETE_TEMPLATE, data.data)
        resolve()
      } catch (e) {
        console.error(e, 'deleteTemplate ERROR')
        reject(e)
      }
    })
  },
  deleteTemplateCategoryInTemplate ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateTemplate/${_id}/deleteTemplateCategory`, payload)
        if (data.success) {
          commit(UPDATE_TEMPLATE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  setTemplateId ({ commit }, _id) {
    commit(SET_TEMPLATE_ID, _id)
  },
  updateActualTabTemplates ({ commit }, tab) {
    commit(SET_TEMPLATES_TAB_STATE, tab)
  },
  setSceletonCard ({ commit }, { sceleton }) {
    commit(SET_SCELETON_CARD, sceleton)
  },
  deleteSceleton ({ commit }) {
    commit(REMOVE_SCELETON_CARD)
  },
  resetTemplates ({ commit }) {
    commit(RESET_TEMPLATES)
  },
  templatesUpdatesSubscription ({ commit }, { _id }) {
    try {
      const connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })

      connection.onopen = function (session) {
        // call a remote procedure
        session.subscribe(`lavvira.updates.${_id}`, async (...args) => {
          const templateTopic = args[0].includes('Template')
          const createTask = args[0].includes('create')
          const updateTask = args[0].includes('update')
          const deleteTask = args[0].includes('delete')
          const removeTask = args[0].includes('remove')
          if (templateTopic && updateTask) {
            const template = store.state.templates.templates.find(t => t._id === args[0][0]._id)
            if (template) {
              commit(UPDATE_TEMPLATE, args[0][0])
            } else {
              // implemet 'share task'!!! to be clear
              commit(ADD_TEMPLATE, args[0][0])
            }
          } else if (templateTopic && createTask) {
            commit(ADD_TEMPLATE, args[0][0])
          } else if (templateTopic && deleteTask) {
            commit(DELETE_TEMPLATE, args[0][0])
          } else if (templateTopic && removeTask) {
            commit(DELETE_TEMPLATE, args[0][0])
          }
        })
      }

      connection.onclose = function (reason, details) {
      // handle connection lost
        console.error(reason, details, 'connection.onclose')
      }
      connection.open()
    } catch (e) {
      console.error(e, 'ERROR in autobahn connection')
    }
  }
}

const getters = {
  getCurrentTemplate: (state) => {
    if (state.templateId) {
      return state.templates.find(c => c._id === state.templateId)
    }
    return null
  }
}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions,
  getters
}

// DEPRECATED

// addMyComment ({ commit }, { _id, payload } = {}) {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const { data } = await axios.post(`/addComment/${_id}`, payload)
//       commit(ADD_COMMENT, data.data)
//       resolve()
//     } catch (e) {
//       console.error(e, 'updateTemplate ERROR')
//       reject(e)
//     }
//   })
// },
// replyToComment ({ commit }, { _id, payload } = {}) {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const { data } = await axios.post(`/replyComment/${_id}`, payload)
//       commit(ADD_COMMENT, data.data)
//       resolve()
//     } catch (e) {
//       console.error(e, 'updateTemplate ERROR')
//       reject(e)
//     }
//   })
// },
// updateMyComment ({ commit }, { _id, payload } = {}) {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const { data } = await axios.post(`/updateComment/${_id}`, payload)
//       commit(ADD_COMMENT, data.data)
//       resolve()
//     } catch (e) {
//       console.error(e, 'updateComment ERROR')
//       reject(e)
//     }
//   })
// },
// deleteMyComment ({ commit }, { _id, payload } = {}) {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const { data } = await axios.post(`/deleteComment/${_id}`, payload)
//       commit(DELETE_COMMENT, data.data)
//       resolve()
//     } catch (e) {
//       console.error(e, 'deleteComment ERROR')
//       reject(e)
//     }
//   })
// },
