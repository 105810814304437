import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

// import locale packages from https://github.com/vuetifyjs/vuetify/tree/master/packages/vuetify/src/locale
import en from './vuetifyLocales/en'
import bg from './vuetifyLocales/bg'
import fr from './vuetifyLocales/fr'
import de from './vuetifyLocales/de'
import es from './vuetifyLocales/es'
import it from './vuetifyLocales/it'
import ro from './vuetifyLocales/ro'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { en, bg, fr, de, es, it, ro },
    current: localStorage.getItem('preferedLanguage') || 'en'
  },
  icons: {
    iconfont: 'mdiSvg' // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        primary: '#234e79',
        accent: '#ecb652',
        accent2: '#2b5f94',
        accent3: '#ffffff',
        accent4: '#e99e14',
        secondary: '#3e8bbc',
        success: '#a5d64c',
        info: '#ff53d0',
        warning: '#ff8e00',
        error: '#ed3f43',
        background: '#d7d7d7',
        lightBackground: '#F6F4F4'
      }
    }
  }
  // breakpoint: {
  //   thresholds: {
  //     xs: 0,
  //     sm: 100,
  //     md: 1600,
  //     lg: 2000
  //   }
  // }
})
