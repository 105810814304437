// Packages
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VuetifyDialog from 'vuetify-dialog'
import VueEllipseProgress from 'vue-ellipse-progress'
import PortalVue from 'portal-vue'
import Vue2TouchEvents from 'vue2-touch-events'
import 'vuetify-dialog/dist/vuetify-dialog.min.css'

// App
import App from '@/App'
import router from '@/router'
import store from '@/store'
import '@/plugins/globalComponents'

// Plugins
import { i18n } from '@/i18n/index'
import vuetify from '@/plugins/vuetify'
import '@/plugins/axios'
import '@/plugins/drag-drop'
import '@/filters/index'

Vue.use(Vuelidate)
Vue.use(VueEllipseProgress)
Vue.use(PortalVue)
Vue.use(Vue2TouchEvents)
Vue.use(VuetifyDialog, {
  context: {
    vuetify
  },
  confirm: {
    actions: {
      false: i18n.t('actions|cancel'),
      true: {
        text: i18n.t('actions|confirm'),
        color: 'primary'
      }
    },
    icon: 'mdi-alert-octagon-outline',
    color: 'warning'
  }
})

new Vue({
  router,
  store,
  vuetify,
  VueEllipseProgress,
  i18n,
  render: h => h(App)
}).$mount('#app')
