
<template>
  <v-row
    v-if="$vuetify && !$vuetify.breakpoint.mobile"
  >
    <v-col cols="6">
      <iframe
        v-if="src && (lavviraPreview || (pdfPreviewOnly && !previewImage))"
        :src="src"
        style="width: 100%; height: 100%; border:none;"
      />
      <div
        v-if="previewImage"
        :style="`height: ${$vuetify.breakpoint.height - 250}px; width: 70%; border: 1px solid grey; overflow-y: auto;`"
        class="rounded-lg mx-auto center-item"
      >
        <img
          :src="src"
        >
      </div>
    </v-col>
    <v-col
      cols="6"
    >
      <Editor
        v-if="content"
        ref="editorRef"
        :case-data="caseData"
        :options="{
          inCase: inCase
        }"
        :read-only-option="false"
        :file-view-drawer="false"
        :actual-document="actualDocument"
        :deactivate-pop-up-menu="true"
      />
      <div
        v-else
      >
        <v-sheet
          color="grey"
          class="pa-3"
        >
          <v-snackbar
            v-model="show"
            :timeout="3000"
            centered
            color="warning"
            elevation="28"
          >
            <v-icon>
              mdi-alert-octagon-outline
            </v-icon>
            {{ this.$t('error|document_processing_error') }}
          </v-snackbar>
          <v-skeleton-loader
            class="mx-auto mt-7"
            tile
            max-width="300"
            min-height="500"
            type="article@3"
          />
        </v-sheet>
      </div>
    </v-col>
  </v-row>
  <v-row
    v-else
    class="background"
    :style="`height: ${$vuetify.breakpoint.height}px; overflow-y: auto;`"
  >
    <div
      style="width: 100%; height: 50%;"
      class="mb-4"
    >
      <iframe
        v-if="src && (lavviraPreview || (pdfPreviewOnly && !previewImage))"
        :src="src"
        style="width: 100%; height: 100%; border:none;"
        class="mx-3 mt-3"
      />
      <div
        v-if="previewImage"
        :style="`height: ${$vuetify.breakpoint.height - 250}px; width: 70%; border: 1px solid grey; overflow-y: auto;`"
        class="rounded-lg mx-auto center-item"
      >
        <img
          :src="src"
        >
      </div>
    </div>

    <div
      class="background pl-5"
    >
      <EditorMobileVue
        v-if="content"
        ref="editorRef"
        class="white"
        :case-data="caseData"
        :options="{
          inCase: inCase
        }"
        :read-only-option="false"
        :file-view-drawer="false"
        :actual-document="actualDocument"
        :deactivate-pop-up-menu="true"
      />

      <div
        v-else
      >
        <v-sheet
          color="grey"
          class="pa-3"
        >
          <v-snackbar
            v-model="show"
            :timeout="3000"
            centered
            color="warning"
            elevation="28"
          >
            <v-icon>
              mdi-alert-octagon-outline
            </v-icon>
            {{ this.$t('error|document_processing_error') }}
          </v-snackbar>
          <v-skeleton-loader
            class="mx-auto mt-7"
            tile
            max-width="300"
            min-height="500"
            type="article@3"
          />
        </v-sheet>
      </div>
    </div>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import Editor from '@/pages/TemplateEditor/Editor.vue'
import EditorMobileVue from '../../pages/Mobile/Editor/EditorMobile.vue'

export default {
  components: {
    Editor,
    EditorMobileVue
  },
  props: {
    content: {
      type: Object,
      default: null
    },
    src: {
      type: String,
      default: null
    },
    caseData: {
      type: Object,
      default: null
    },
    inCase: {
      type: Boolean,
      default: false
    },
    actualDocument: {
      type: Object,
      default: null
    },
    lavviraPreview: {
      type: Boolean,
      default: false
    },
    pdfPreviewOnly: {
      type: Boolean,
      default: false
    },
    previewImage: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
      account: state => state.account.account,
      documents: state => state.documents.documents,
      companyDocuments: state => state.companyDocuments.companyDocuments,
      groupDocuments: state => state.groupDocuments.groupDocuments,
      sharedWithMeDocuments: state => state.documents.sharedWithMeDocuments,
      actualLanguage: state => state.account.actualLanguage
    })
  },
  mounted () {
    this.getRef()
  },
  methods: {
    getRef () {
      if (this.content) this.$refs['editorRef'].setContent(this.content)
    }
  }
}
</script>

<style scoped>
.topright {
  position: absolute;
  top: 8px;
  right: 16px;
  font-size: 18px;
}
.page {
  background: white;
  display: block;
  margin: 0 auto;
  width: 18cm;
  height: 20.7cm;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.2cm rgba(0,0,0,0.2);
}
.static-footer {
  background-color: white;
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  bottom: 0em;
  z-index: 2;
}
.center-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
