import { i18n } from '@/i18n/index'
import store from '@/store'

const uniqBy = (a, key) => {
  const seen = {}
  return a.filter(function (item) {
    const k = item[key]
    return seen.hasOwnProperty(k) ? false : (seen[k] = true)
  })
}

const translateNotification = (notification) => {
  if (typeof notification.content === 'string') {
    notification.content = {
      translation: notification.content
    }
    return notification
  } else {
    let newString = ''
    const splitedString = notification.content.string ? notification.content.string.split(' ') : notification.content
    let j = 0
    for (let i = 0; i < splitedString.length; i++) {
      let element = splitedString[i]
      if (element === '###PLACEHOLDER###') {
        element = notification.content.content_placeholders[j]
        j++
      } else {
        element = i18n.t(`${element}`)
      }
      newString = newString + ' ' + element
    }
    notification.content.translation = newString
    return notification
  }
}

const userHasAccess = (account, company, group, tab, section) => {
  let companySufficientStorage = null

  const accountSufficientStorage = availableStorage(account.availableStorage, account.usedStorage)
  if (company) companySufficientStorage = availableStorage(company.availableStorage, company.usedStorage)

  // Only Account Access
  if (account && section && section === 'onlyAccountAccess') {
    if (!accountSufficientStorage) return false
    else return true
  }

  // Covers CreateCaseFromCaseModel && AddCaseModel && QuickStart sections
  if (company && section && section === 'availableSections') {
    if (!companySufficientStorage) return false
    else return true
  } else if (account && !company && section && section === 'availableSections') {
    if (!accountSufficientStorage) return false
    else return true
  } else if (company && section && section === 'QuickStart') {
    if (!companySufficientStorage) return false
    else return true
  } else if (!company && section && section === 'QuickStart') {
    if (!accountSufficientStorage || account.subscriptionStatus === 'unpaid') return false
    else return true
  }

  if (group && tab.isGroupTab) {
    company = store.state.company.company
  }

  if (tab && tab.isMyTab && account && !account.companyId) {
    if (account.subscriptionStatus === 'unpaid') return false
    else if (!accountSufficientStorage) return false
    else return true
  } else if (account && account.companyId && tab && tab.isMyTab) {
    if (!accountSufficientStorage) return false
    else return true
  } else if (company && tab && !tab.isMyTab) {
    const companySufficientStorage = availableStorage(company.availableStorage, company.usedStorage)
    if (!companySufficientStorage) return false
    else return true
  }
  // if (process.env.NODE_ENV === 'production') {}
}

const availableStorage = (availableStorage, usedStorage) => {
  // availableStorage in GB
  // usedStorage in GB

  let a = Number(availableStorage * Math.pow(1024, 3)).toFixed(0)
  let b = Number(usedStorage * Math.pow(1024, 3)).toFixed(0)
  let c = ((a - b) / a) * 100
  const res = Math.round(c)
  if (res > 0) return true
  else return false
}

export {
  uniqBy,
  translateNotification,
  userHasAccess,
  availableStorage
}
