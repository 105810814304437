// import moment from 'moment'
import Vue from 'vue'
import axios from '@/plugins/axios'
import store from '../index'
import autobahn from 'autobahn'

const SET_LOADING = 'SET_LOADING'
const SET_ERROR = 'SET_ERROR'
const SET_CASES = 'SET_CASES'
const SET_CASE_ID = 'SET_CASE_ID'
const ADD_COMPANY_CASE = 'ADD_COMPANY_CASE'
const UPDATE_COMPANY_CASE = 'UPDATE_COMPANY_CASE'
const DELETE_CASE_WORKBENCH = 'DELETE_CASE_WORKBENCH'
const DELETE_COMMENT = 'DELETE_COMMENT'
const UPDATE_CASE_FILE = 'UPDATE_CASE_FILE'
const DELETE_COMPANY_CASE = 'DELETE_COMPANY_CASE'
const RESET_COMPANY_CASES = 'RESET_COMPANY_CASES'

// const dateFormat = date => moment(date).format('DD/MM/YYYY')

const state = () => {
  return {
    casesError: null,
    companyCases: [],
    companyCasesSubscriptions: [],
    caseId: null,
    caseError: null,
    loading: false
  }
}

const mutations = {
  [SET_LOADING] (state, value) {
    state.loading = value
  },
  [SET_ERROR] (state, { key, error } = {}) {
    state[key] = error
  },
  [SET_CASES] (state, payload) {
    state.companyCases = payload
  },
  [SET_CASE_ID] (state, _id) {
    state.caseId = _id
  },
  [ADD_COMPANY_CASE] (state, payload) {
    const index = state.companyCases.findIndex(c => c._id === payload._id)
    if (index === -1) state.companyCases.push(payload)
    else Vue.set(state.companyCases, index, payload)
  },
  [UPDATE_COMPANY_CASE] (state, payload) {
    const index = state.companyCases.findIndex(c => c._id === payload._id)
    Vue.set(state.companyCases, index, payload)
  },
  [DELETE_CASE_WORKBENCH] (state, { _id, workbenchId }) {
    const index = state.companyCases.findIndex(c => c._id === _id)
    const workbenchIndex = state.companyCases[index].workbenches.findIndex(f => f._id === workbenchId)
    Vue.delete(state.companyCases[index].workbenches, workbenchIndex)
  },
  [DELETE_COMMENT] (state, payload) {
    const index = state.companyCases.findIndex(c => c._id === payload._id)
    Vue.set(state.companyCases, index, payload)
  },
  [UPDATE_CASE_FILE] (state, { _id, fileId, payload }) {
    const index = state.companyCases.findIndex(c => c._id === _id)
    const fileIndex = state.companyCases[index].caseFiles.findIndex(f => f._id === fileId)
    Vue.set(state.companyCases[index].caseFiles, fileIndex, payload)
  },
  [DELETE_COMPANY_CASE] (state, _id) {
    const index = state.companyCases.findIndex(c => c._id === _id)
    if (index !== -1) state.companyCases.splice(index, 1)
  },
  [RESET_COMPANY_CASES] (state) {
    state.companyCases = []
    state.companyCasesSubscriptions = []
  }
}

const actions = {
  fetchCompanyCases ({ commit }, { _id }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getSharedCases/${_id}`)
        if (data.success) {
          commit(SET_CASES, data.data)
          commit(SET_ERROR, { key: 'casesError', error: null })
          resolve()
        } else {
          commit(SET_ERROR, { key: 'casesError', error: data })
          reject(data)
        }
      } catch (e) {
        commit(SET_ERROR, { key: 'casesError', error: e })
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  fetchCompanyCase ({ commit }, _id) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getCase/${_id}`)
        if (data.success) {
          commit(ADD_COMPANY_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  setCaseId ({ commit }, _id) {
    commit(SET_CASE_ID, _id)
  },
  addCompanyCase ({ commit }, payload) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/createCase`, payload)
        if (data.success) {
          commit(ADD_COMPANY_CASE, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  addCaseFromCaseModel ({ commit }, payload) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/createCaseFromCaseForm`, payload)
        if (data.success) {
          commit(ADD_COMPANY_CASE, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  // addMyComment ({ commit }, { _id, workbenchId, templateId, payload } = {}) {
  //   commit(SET_LOADING, true)
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const { data } = await axios.post(`/updateCase/${_id}/${workbenchId}/${templateId}/addComment`, payload)
  //       if (data.success) {
  //         commit(UPDATE_CASE, data.data)
  //         resolve()
  //       } else {
  //         reject(data)
  //       }
  //     } catch (e) {
  //       reject(e)
  //     } finally {
  //       commit(SET_LOADING, false)
  //     }
  //   })
  // },
  updateCompanyCase ({ commit }, { _id, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}`, payload)
        if (data.success && data.updatedDoc) {
          commit(UPDATE_COMPANY_CASE, data.data)
          store.dispatch('companyDocuments/getCompanyDocument', { _id: data.updatedDoc })
          resolve()
        }
        if (data.success && data.newAssignedDocs) {
          commit(UPDATE_COMPANY_CASE, data.data)
          data.newAssignedDocs.forEach(doc => {
            store.dispatch('companyDocuments/getCompanyDocument', { _id: doc })
          })
          resolve()
        }
        if (data.success) {
          commit(UPDATE_COMPANY_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteCompanyCase ({ commit }, { _id } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        await axios.post(`/deleteCase/${_id}`)
        commit(DELETE_COMPANY_CASE, _id)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  addCompanyCaseWorkbench ({ commit }, { _id, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/addWorkbench`, payload)
        if (data.success) {
          commit(UPDATE_COMPANY_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateCompanyCaseWorkbench ({ commit }, { _id, workbenchId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/${workbenchId}/`, payload)
        if (data.success) {
          commit(UPDATE_COMPANY_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteCompanyCaseWorkbenchDocument ({ commit }, { _id, workbenchId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/remove/${workbenchId}/`, payload)
        if (data.success) {
          commit(UPDATE_COMPANY_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteCompanyCaseWorkbench ({ commit }, { _id, workbenchId } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/delete/${workbenchId}`)
        if (data.success) {
          commit(DELETE_CASE_WORKBENCH, { _id, workbenchId })
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  addCompanyCaseWorkbenchTemplate ({ commit }, { _id, workbenchId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/${workbenchId}/addTemplate`, payload)
        if (data.success) {
          commit(UPDATE_COMPANY_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateCaseWorkbenchTemplate ({ commit }, { _id, workbenchId, templateId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/${workbenchId}/${templateId}`, payload)
        if (data.success) {
          commit(UPDATE_COMPANY_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteMyComment ({ commit }, { _id, workbenchId, templateId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/${workbenchId}/${templateId}/deleteComment`, payload)
        if (data.success) {
          commit(UPDATE_COMPANY_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteCaseWorkbenchTemplate ({ commit }, { _id, workbenchId, templateId } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/${workbenchId}/delete/${templateId}`)
        if (data.success) {
          commit(UPDATE_COMPANY_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  resetCompanyCases ({ commit }) {
    commit(RESET_COMPANY_CASES)
  },
  companyCasesUpdatesSubscription ({ commit }, { _id }) {
    try {
      const connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })

      connection.onopen = function (session) {
        // call a remote procedure
        session.subscribe(`lavvira.updates.${_id}`, async (...args) => {
          const caseTopic = args[0].includes('Case')
          const createTask = args[0].includes('create')
          const updateTask = args[0].includes('update')
          const deleteTask = args[0].includes('delete')
          const removeTask = args[0].includes('remove')
          if (caseTopic && createTask) {
            commit(ADD_COMPANY_CASE, args[0][0])
          } else if (caseTopic && updateTask) {
            const _case = store.state.companyCases.companyCases.find(c => c._id === args[0][0]._id)
            if (_case) {
              commit(UPDATE_COMPANY_CASE, args[0][0])
            } else {
              // implemet 'share task'!!! to be clear
              commit(UPDATE_COMPANY_CASE, args[0][0])
            }
          } else if (caseTopic && deleteTask) {
            commit(DELETE_COMPANY_CASE, args[0][0]._id)
          } else if (caseTopic && removeTask) {
            if (args[0][4] && args[0][4].removeCaseFromStore && args[0][4].company && args[0][4].company === store.state.company.company._id) {
              commit(DELETE_COMPANY_CASE, args[0][0]._id)
            } else {
              commit(UPDATE_COMPANY_CASE, args[0][0])
            }
            // const isShared = args[0][0].sharedWith.company.find(c => c._id === store.state.company.company._id)
            // if (isShared) {
            //   commit(UPDATE_COMPANY_CASE, args[0][0])
            // } else {
            //   commit(DELETE_COMPANY_CASE, args[0][0])
            // }
          }
        }).then(
          function (subscription) {
            store.state.companyCases.companyCasesSubscriptions.push(subscription)
          }
        )
      }

      connection.onclose = function (reason, details) {
        // handle connection lost
        console.log(reason, details, 'connection.onclose')
      }
      connection.open()
    } catch (e) {
      console.error(e, 'ERROR in autobahn connection')
    }
  },
  unsubscribeCompanyCasesUpdate ({ commit }, sub) {
    sub.unsubscribe().then(
      function (gone) {
        if (gone) commit(RESET_COMPANY_CASES)
        // successfully unsubscribed
      },
      function (error) {
        // unsubscribe failed
        console.error(error, 'error unsubscribe')
      }
    )
  }
}

const getters = {
  menuCases: (state) => {
    const temp = {}
    state.companyCases.forEach((c) => {
      if (c.createdBy._id in temp) {
        temp[c.createdBy._id].companyCases.push(c)
      } else {
        temp[c.createdBy._id] = {
          createdBy: c.createdBy,
          companyCases: [c]
        }
      }
    })
    return temp
  },
  getCurrentCase: (state) => {
    if (state.caseId) {
      return state.companyCases.find(c => c._id === state.caseId)
    }
    return null
  }
}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions,
  getters
}
