import { v4 as uuidv4 } from 'uuid'

const ADD_TOAST = 'ADD_TOAST'
const DELETE_TOAST = 'DELETE_TOAST'

const state = () => {
  return {
    toasts: []
  }
}

const mutations = {
  [ADD_TOAST] (state, toast) {
    state.toasts.push({
      id: uuidv4(),
      expire: 6000,
      ...toast
    })
  },
  [DELETE_TOAST] (state, toast) {
    const index = state.toasts.findIndex((t) => t.id === toast.id)
    state.toasts.splice(index, 1)
  }
}

const actions = {
  addToast ({ commit }, toast) {
    commit(ADD_TOAST, toast)
  },
  deleteToast ({ commit }, toast) {
    commit(DELETE_TOAST, toast)
  }
}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions
}
