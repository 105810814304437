export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    view: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: null
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
