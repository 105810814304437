import axios from '@/plugins/axios'
import Vue from 'vue'

const SET_LOADING = 'SET_LOADING'
const SET_CASE_FORMS = 'SET_CASE_FORMS'
const SET_LAVVIRA_CASE_FORMS = 'SET_LAVVIRA_CASE_FORMS'
const ADD_CASE_FORM = 'ADD_CASE_FORM'
const UPDATE_CASE_FORM = 'UPDATE_CASE_FORM'
const UPDATE_IF_GOD_ACC_UNSHARED_CASE_FORM = 'UPDATE_IF_GOD_ACC_UNSHARED_CASE_FORM'
const DELETE_CASE_FORM = 'DELETE_CASE_FORM'
const SET_CASE_FORMS_TAB_STATE = 'SET_CASE_FORMS_TAB_STATE'
const RESET_CASE_FORMS = 'RESET_CASE_FORMS'

const state = () => {
  return {
    loading: false,
    caseForms: [],
    lavviraCaseForms: [],
    actualTabCaseForms: {}
  }
}

const mutations = {
  [SET_LOADING] (state, value) {
    state.loading = value
  },
  [SET_CASE_FORMS] (state, payload) {
    state.caseForms = payload
  },
  [SET_LAVVIRA_CASE_FORMS] (state, payload) {
    state.lavviraCaseForms = payload
  },
  [ADD_CASE_FORM] (state, payload) {
    state.caseForms.push(payload)
  },
  [UPDATE_CASE_FORM] (state, payload) {
    if (payload.shared) {
      // here the god account has shared the case model with all users
      const index = state.lavviraCaseForms.findIndex(cf => cf._id === payload._id)
      Vue.set(state.lavviraCaseForms, index, payload)
      if (index === -1) {
        state.lavviraCaseForms.push(payload)
      }
      const caseFormsIndex = state.caseForms.findIndex(cf => cf._id === payload._id)
      Vue.set(state.caseForms, caseFormsIndex, payload)
    } else {
      const index = state.caseForms.findIndex(cf => cf._id === payload._id)
      Vue.set(state.caseForms, index, payload)
    }
  },
  [UPDATE_IF_GOD_ACC_UNSHARED_CASE_FORM] (state, payload) {
    const index = state.lavviraCaseForms.findIndex(cf => cf._id === payload._id)
    Vue.set(state.lavviraCaseForms, index, payload)
    const caseFormsIndex = state.caseForms.findIndex(t => t._id === payload._id)
    Vue.set(state.caseForms, caseFormsIndex, payload)
  },
  [DELETE_CASE_FORM] (state, payload) {
    const index = state.caseForms.findIndex(c => c._id === payload._id)
    if (index !== -1) {
      state.caseForms.splice(index, 1)
    }
  },
  [SET_CASE_FORMS_TAB_STATE] (state, value) {
    state.actualTabCaseForms = value
  },
  [RESET_CASE_FORMS] (state) {
    state.caseForms = []
  }
}

const actions = {
  fetchCaseForms ({ commit }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getCaseForms`)
        commit(SET_CASE_FORMS, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  fetchLavviraCaseForms ({ commit }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve) => {
      try {
        const { data } = await axios.get('/getLavviraCaseForms')
        commit(SET_LAVVIRA_CASE_FORMS, data.data)
      } catch (e) {
        console.error(e, 'fetchTemplates ERROR')
      } finally {
        resolve()
        commit(SET_LOADING, false)
      }
    })
  },
  addCaseForm ({ commit }, payload) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/createCaseForm`, payload)
        if (data.success) {
          commit(ADD_CASE_FORM, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateCaseForm ({ commit }, { _id, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCaseForm/${_id}`, payload)
        if (data.unshareLavviraCaseModel) {
          commit(UPDATE_IF_GOD_ACC_UNSHARED_CASE_FORM, data.data)
          resolve()
        }
        if (data.success) {
          commit(UPDATE_CASE_FORM, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteCaseForm ({ commit }, payload = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        await axios.post(`/deleteCaseForm/${payload._id}`)
        commit(DELETE_CASE_FORM, payload)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  addCaseFormWorkbench ({ commit }, { _id, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCaseForm/${_id}/addWorkbench`, payload)
        if (data.success) {
          commit(UPDATE_CASE_FORM, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  addCaseFormWorkbenchTemplate ({ commit }, { _id, workbenchId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCaseForm/${_id}/${workbenchId}/addCaseFormTemplate`, payload)
        if (data.success) {
          commit(UPDATE_CASE_FORM, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  saveAsTemplateInCaseForm ({ commit }, { _id, workbenchId, templateId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/copyTemplateInCaseForm/${_id}/${workbenchId}/${templateId}`, payload)
        if (data.success) {
          commit(UPDATE_CASE_FORM, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateCaseFormWorkbenchTemplate ({ commit }, { _id, workbenchId, templateId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCaseForm/${_id}/${workbenchId}/${templateId}/updateCaseFormTemplate`, payload)
        if (data.success) {
          commit(UPDATE_CASE_FORM, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteCaseFormWorkbenchTemplate ({ commit }, { _id, workbenchId, templateId } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCaseForm/${_id}/${workbenchId}/delete/${templateId}`)
        if (data.success) {
          commit(UPDATE_CASE_FORM, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  addCaseRequiredDocuments ({ commit }, { _id, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCaseForm/${_id}/addCaseFormRequiredDocs`, payload)
        if (data.success) {
          commit(UPDATE_CASE_FORM, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  addCaseFormWorkbenchRequiredDocuments ({ commit }, { _id, workbenchId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCaseForm/${_id}/${workbenchId}/addCaseFormRequiredDocsToWorkbench`, payload)
        if (data.success) {
          commit(UPDATE_CASE_FORM, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteCaseModelWbRequiredDocument ({ commit }, { _id, workbenchId, payload } = {}) {
    // delete from workbench
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCaseForm/${_id}/${workbenchId}/deleteCaseFormWbRequiredDoc`, payload)
        if (data.success) {
          commit(UPDATE_CASE_FORM, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteRequiredDocFromCaseModel ({ commit }, { _id, payload } = {}) {
    // delete from case model => documents array
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCaseForm/${_id}/deleteCaseModelDoc`, payload)
        if (data.success) {
          commit(UPDATE_CASE_FORM, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateWorkbench ({ commit }, { _id, workbenchId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCaseForm/${_id}/${workbenchId}/updateCaseFormWorkbench`, payload)
        if (data.success) {
          commit(UPDATE_CASE_FORM, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteWorkbench ({ commit }, { _id, workbenchId } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCaseForm/${_id}/${workbenchId}/deleteCaseFormWorkbench`)
        if (data.success) {
          commit(UPDATE_CASE_FORM, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteCaseModelCategoryInCaseModel ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCaseForm/${_id}/deleteCaseFormCategory`, payload)
        if (data.success) {
          commit(SET_CASE_FORMS, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateActualTabCaseForms ({ commit }, tab) {
    commit(SET_CASE_FORMS_TAB_STATE, tab)
  },
  resetCaseForms ({ commit }) {
    commit(RESET_CASE_FORMS)
  }
}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions
}
