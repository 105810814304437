import Table from '@tiptap/extension-table'
import { Plugin } from 'prosemirror-state'
const columnResizingPluginKey = require('prosemirror-tables').columnResizingPluginKey

const CustomTable = Table.extend({
  // Add a `width` attribute
  addAttributes () {
    return {
      ...this.parent?.(),
      width: {
        default: null
      }
    }
  },
  onUpdate () {
    this.editor.view.state.doc.descendants((node, pos) => {
      if (node.type.name === 'table') {
        const width = this.editor.view.nodeDOM(pos)?.offsetWidth
        const tr = this.editor.state.tr
        tr.setNodeMarkup(pos, undefined, {
          ...node.attrs,
          width
        })
      }
    })
  },
  // Add a ProseMirror plugin to listen for colwidth changes
  // and update the `width` attribute
  addProseMirrorPlugins () {
    return [
      ...this.parent?.(),
      new Plugin({
        appendTransaction: (transactions, oldState, state) => {
          const transaction = transactions[0]
          const resizingState = columnResizingPluginKey.getState(state)

          // Check if it’s a resize transaction
          if (!resizingState.activeHandle || !resizingState.dragging) {
            return
          }

          // Loop only through the range that has been modified
          const { doc, before } = transaction
          const from = before.content.findDiffStart(doc.content)
          const to = before.content.findDiffEnd(doc.content)

          if (!from || !to || from === to.b) {
            return
          }

          // Create a new transaction first
          const tr = state.tr

          state.doc.nodesBetween(from, to.b, (node, pos) => {
            if (node.type.name !== 'table') {
              return
            }
            // const percentage = (partialValue, totalValue) => {
            //   return ((100 * partialValue) / totalValue).toFixed(2)
            // }

            // Get the table width from the DOM
            // const allAvailableWidth = this.editor.view.nodeDOM(pos)?.offsetWidth
            const width = this.editor.view.nodeDOM(pos)?.firstChild.clientWidth
            // const result = percentage(width, allAvailableWidth) + '%'

            // Update the `width` attribute
            tr.setNodeMarkup(pos, undefined, {
              ...node.attrs,
              width
            })
          })

          if (!tr.steps.length) {
            return
          }

          return tr
        }
      })
    ]
  }
}).configure({
  resizable: true
  // lastColumnResizable: true
  // cellMinWidth: 100
})

export default CustomTable
