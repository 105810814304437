<template>
  <v-container
    fluid
    style="width: 100%; position: fixed; top: 88px; z-index: 1;"
    class="pa-0"
  >
    <v-card
      flat
      color="lightBackground"
      class="pa-0 ma-0"
    >
      <v-card-text
        class="pa-0"
        style="white-space: normal;"
      >
        <v-row no-gutters>
          <v-col cols="12">
            <!-- BACK BUTTON -->
            <v-btn
              icon
              class="ml-2"
              @click="$emit('close')"
            >
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>

            <!-- CONNECTION STATUS -->
            <span
              v-if="connectionStatus === $t('mixed|you_are_connected') || connectionStatus === 'You are synced!'"
              class="ml-2 mt-1"
            >
              <v-icon
                color="primary"
              >
                {{ loadingSavingData ? 'mdi-sync' : 'mdi-cloud-check' }}
              </v-icon>
            </span>

            <!-- CONNECTION STATUS -->
            <span
              class="ml-2 mt-1"
            >
              <v-progress-circular
                v-if="connectionStatus === 'Connecting...'"
                indeterminate
                color="primary"
              />
              <v-icon
                v-if="connectionStatus === $t('mixed|you_are_connected') || connectionStatus === 'You are synced!'"
                color="primary"
              >
                mdi-access-point-network
              </v-icon>
              <v-icon
                v-if="connectionStatus === $t('mixed|you_are_not_connected')"
                color="error"
              >
                mdi-access-point-network-off
              </v-icon>
            </span>

            <!-- HEADINGS -->
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  class="ml-2"
                  v-on="on"
                >
                  <v-icon>
                    mdi-format-header-1
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in headings"
                  :key="index"
                  @click="$emit('select-heading', item)"
                >
                  <h1
                    v-if="item === 'Heading 1'"
                  >
                    {{ $t('editor|heading') }} 1
                  </h1>
                  <h2
                    v-if="item === 'Heading 2'"
                  >
                    {{ $t('editor|heading') }} 2
                  </h2>
                  <h3
                    v-if="item === 'Heading 3'"
                  >
                    {{ $t('editor|heading') }} 3
                  </h3>
                  <h4
                    v-if="item === 'Heading 4'"
                  >
                    {{ $t('editor|heading') }} 4
                  </h4>
                  <h5
                    v-if="item === 'Heading 5'"
                  >
                    {{ $t('editor|heading') }} 5
                  </h5>
                  <h6
                    v-if="item === 'Heading 6'"
                  >
                    {{ $t('editor|heading') }} 6
                  </h6>
                  <v-list-item-title
                    v-if="item === 'Normal text'"
                    style="font-size: 12px;"
                  >
                    {{ $t('editor|normal_text') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- FONT TYPE -->
            <v-menu
              offset-y
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  class="ml-2"
                  v-on="on"
                >
                  <v-icon>
                    mdi-format-font
                  </v-icon>
                </v-btn>
              </template>
              <v-list
                style="max-height: 500px; overflow-y: auto;"
              >
                <v-list-item
                  v-for="(font, index) in fonts"
                  :key="index"
                  class="px-8"
                  @click="$emit('font-family', font)"
                >
                  <v-list-item-title
                    :style="`font-family: ${font.label}`"
                  >
                    {{ font.label }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- FONT SIZE -->
            <v-menu
              offset-y
              bottom
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-format-size
                  </v-icon>
                </v-btn>
              </template>
              <v-list
                style="max-height: 400px; min-width: 80px; overflow-y: auto;"
                class="text-center"
              >
                <v-list-item
                  v-for="(num, i) in numbers"
                  :key="i"
                  @click="$emit('text-size', num); textSize = num"
                >
                  <v-list-item-title>{{ num }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- ADD MENU -->
            <v-menu
              offset-y
              @input="addMenuInput"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <v-card
                class="mx-auto"
                tile
              >
                <v-list
                  dense
                >
                  <v-list-item-group
                    v-model="selectedItemAddMenu"
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in addMenuItems"
                      :key="i"
                      :disabled="item.disabled"
                      @click="$emit(item.action)"
                    >
                      <v-list-item-icon>
                        <v-icon
                          :disabled="item.disabled"
                        >
                          {{ item.icon }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          style="white-space: normal;"
                        >
                          {{ item.text }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-menu>

            <!-- MAIN MENU -->
            <v-menu
              offset-y
              bottom
              :close-on-content-click="selectedItemFileMenu === 8 || selectedItemFileMenu === 9 ? false : true"
              @input="mainMenuInput"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>
              <v-card
                v-if="!searchAndReplace && !changeSuggestionLanguageList"
                class="mx-auto"
                tile
                :max-height="$vuetify.breakpoint.height - 200"
                :style="'overflow-y: auto;'"
              >
                <v-list
                  dense
                >
                  <v-list-item-group
                    v-model="selectedItemFileMenu"
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in fileMenuItems"
                      :key="i"
                      :disabled="item.disabled"
                      @click="item.action === 'search-and-replace' ?
                        searchAndReplace = true : item.action == 'change-suggestion-language' ?
                          changeSuggestionLanguageList = true :
                          $emit(item.action)"
                    >
                      <v-list-item-icon>
                        <v-icon>
                          {{ item.icon }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          style="white-space: normal;"
                        >
                          {{ item.text }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>

                  <v-divider
                    class="my-2"
                  />

                  <v-list-item-group
                    v-model="selectedItemEditMenu"
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in viewMenuItems"
                      :key="i"
                      :disabled="item.disabled"
                      @click="$emit(item.action)"
                    >
                      <v-list-item-icon>
                        <v-icon>
                          {{ item.icon }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          style="white-space: normal;"
                        >
                          {{ item.text }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>

              <!-- SEARCH AND REPLACE MENU -->
              <v-card
                v-else-if="searchAndReplace"
                flat
                max-width="300"
                color="lightBackground"
                class="pa-0 ma-0"
              >
                <v-card-text>
                  <v-row
                    no-gutters
                    justify="center"
                  >
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="searchInput"
                        dense
                        hide-details
                        :label="$t('common|search') + '...'"
                        class="mb-2"
                        append-icon="mdi-magnify"
                        @click:append="$emit('find-in-text', searchInput)"
                      />

                      <v-text-field
                        v-model="replaceWith"
                        dense
                        hide-details
                        class="mt-6"
                        :label="$t('common|replace') + '...'"
                        append-icon="mdi-find-replace"
                        @click:append="$emit('replace-with', replaceWith, searchInput)"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    x-small
                    outlined
                    dense
                    @click="$emit('replace-all-with', replaceWith)"
                  >
                    {{ $t('common|replace_all') }}
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>

              <!-- CHANGE SUGGESTION LANG MENU -->
              <v-card
                v-else-if="changeSuggestionLanguageList"
                width="100"
                class="pa-0 ma-0"
              >
                <v-card-text class="pa-0 text-center">
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in suggestionLanguages"
                      :key="index"
                      @click="$emit('change-suggestion-language', item)"
                    >
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mdiTableHeadersEye, mdiTableHeadersEyeOff, mdiTextBoxOutline } from '@mdi/js'
// import CustomSwatches from './CustomSwatches.vue'
import { EventBus } from '@/utils/EventBus'
// import { debounce } from 'lodash'
import cfg from '@/config'

export default {
//   components: { CustomSwatches },
  props: {
    editor: {
      type: Object,
      default: null
    },
    template: {
      type: Object,
      default: null
    },
    account: {
      type: Object,
      default: null
    },
    isActiveTable: {
      type: Boolean,
      default: false
    },
    actualFontSize: {
      type: String,
      default: null
    },
    disableCommentInTemplate: {
      type: Boolean,
      default: false
    },
    inTemplateOnly: {
      type: Boolean,
      default: false
    },
    loadingSavingData: {
      type: Boolean,
      default: false
    },
    connectionStatus: {
      type: String,
      default: null
    }
  },
  data: (instance) => ({
    changeSuggestionLanguageList: false,
    searchAndReplace: false,
    searchDialogModel: false,
    flatFields: [],
    hasToolbarEllipsis: false,
    ellipsesToolbars: Object.keys({}).reverse(),
    headerEye: mdiTableHeadersEye,
    headerEyeOff: mdiTableHeadersEyeOff,
    selectedItemFileMenu: null,
    selectedItemEditMenu: null,
    selectedItemAddMenu: null,
    searchInput: '',
    replaceWith: '',
    searchMenu: false,
    tableMenu: false,
    alignMenu: false,
    font: {
      name: 'Sofia-Sans',
      value: 'Sofia-Sans',
      label: 'Sofia-Sans',
      style: {
        fontFamily: 'Sofia-Sans'
      }
    },
    suggestionLanguages: cfg.usedLanguages,
    availableSizes: ['1', '1.15', '1.75', '2', 'etc...'],
    textSize: '11',
    numbers: [6, 8, 9, 10, 11, 12, 13, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    lineSpacingItems: [
      {
        text: '0.85'
      },
      {
        text: '1'
      },
      {
        text: 'normal'
      },
      {
        text: '1.5'
      },
      {
        text: '2'
      },
      {
        text: '3'
      },
      {
        text: '4'
      },
      {
        text: '5'
      }
    ],
    headings: ['Heading 1', 'Heading 2', 'Heading 3', 'Heading 4', 'Heading 5', 'Heading 6', 'Normal text'],
    selectedTextColor: '',
    selectedColor: '',
    selectedTableColor: '',
    selectedHeading: '',
    swatches: [
      ['#FFFFFF', '#FF0000', '#550000'],
      ['#FFFF00', '#AAAA00', '#555500'],
      ['#00FF00', '#00AA00', '#005500'],
      ['#00FFFF', '#00AAAA', '#005555'],
      ['#0000FF', '#0000AA', '#000000']
    ],
    fonts: [
      {
        name: 'Arial',
        value: 'Arial',
        label: 'Arial',
        style: {
          // optional style to apply to the item
          fontFamily: 'Arial'
        }
      },
      {
        name: 'Cambria',
        label: 'Cambria',
        value: 'Cambria',
        style: {
          // optional style to apply to the item
          fontFamily: 'Cambria'
        }
      },
      {
        name: 'Arial Black',
        label: 'Arial Black',
        value: 'Arial Black',
        style: {
          // optional style to apply to the item
          fontFamily: 'Arial Black'
        }
      },
      {
        name: 'Courier',
        label: 'Courier',
        value: 'Courier',
        style: {
          // optional style to apply to the item
          fontFamily: 'Courier'
        }
      },
      {
        name: 'Courier New',
        label: 'Courier New',
        value: 'Courier New',
        style: {
          // optional style to apply to the item
          fontFamily: 'Courier New'
        }
      },
      {
        name: 'Sofia Sans',
        label: 'Sofia Sans',
        value: 'Sofia Sans',
        style: {
          // optional style to apply to the item
          fontFamily: 'Sofia Sans'
        }
      },
      {
        name: 'Sans-Serif',
        label: 'Sans-Serif',
        value: 'Sans-Serif',
        style: {
          // optional style to apply to the item
          fontFamily: 'Sans-Serif'
        }
      },
      {
        name: 'Serif',
        label: 'Serif',
        value: 'Serif',
        style: {
          // optional style to apply to the item
          fontFamily: 'Serif'
        }
      },
      {
        name: 'Times New Roman',
        label: 'Times New Roman',
        value: 'Times New Roman',
        style: {
          // optional style to apply to the item
          fontFamily: 'Times New Roman'
        }
      },
      {
        name: 'Verdana',
        label: 'Verdana',
        value: 'Verdana',
        style: {
          // optional style to apply to the item
          fontFamily: 'Verdana'
        }
      },
      {
        name: 'Helvetica',
        label: 'Helvetica',
        value: 'Helvetica',
        style: {
          // optional style to apply to the item
          fontFamily: 'Helvetica'
        }
      },
      {
        name: 'Calibri',
        label: 'Calibri',
        value: 'Calibri',
        style: {
          // optional style to apply to the item
          fontFamily: 'Calibri'
        }
      },
      {
        name: 'Impact',
        label: 'Impact',
        value: 'Impact',
        style: {
          // optional style to apply to the item
          fontFamily: 'Impact'
        }
      }
    ],
    // hiddenIcons: [],
    window: {
      width: 0,
      height: 0
    },
    searchInputGeneralSearch: ''
  }),
  computed: {
    panelIconsIds () {
      return [
        {
          domItem: 'share-icon',
          action: undefined
        },
        {
          domItem: 'print-icon',
          action: undefined
        },
        {
          domItem: 'divider-5',
          action: undefined
        },
        {
          domItem: 'format-page',
          action: undefined
        },
        {
          domItem: 'page-layout-footer',
          action: undefined
        },
        {
          domItem: 'page-layout-header',
          action: undefined
        },
        {
          domItem: 'divider-4',
          action: undefined
        },
        {
          domItem: 'magnify',
          action: 'magnify'
        },
        {
          domItem: 'comment-outline',
          action: 'add-comment'
        },
        {
          domItem: 'add-suggestion',
          action: 'add-suggestion'
        },
        {
          domItem: 'divider-3',
          action: 'add-suggestion'
        },
        {
          domItem: 'insert-table',
          action: 'insert-table'
        },
        {
          domItem: 'list-checkbox',
          action: undefined
        },
        {
          domItem: 'list-bulleted',
          action: 'bulleted-list'
        },
        {
          domItem: 'list-numbered',
          action: 'numbered-list'
        },
        {
          domItem: 'align-left',
          action: 'align-left'
        },
        {
          domItem: 'divider-2',
          action: undefined
        },
        {
          domItem: 'color-highlight',
          action: undefined
        },
        {
          domItem: 'format-color-text',
          action: undefined
        },
        {
          domItem: 'format-underline',
          action: undefined
        },
        {
          domItem: 'format-italic',
          action: undefined
        },
        {
          domItem: 'format-bold',
          action: undefined
        }
      ]
    },
    addMenuItems () {
      return [
        { text: this.$t('common|table'), icon: 'mdi-table', action: 'insert-table' },
        { text: this.$t('editor|tooltip_add_suggestion'), icon: 'mdi-at', action: 'add-suggestion' },
        { text: this.$t('editor|add_comment'), icon: 'mdi-comment-outline', action: 'add-comment', disabled: this.inTemplateOnly }
      ]
    },
    fileMenuItems () {
      let itemsArr
      let disableShareBtn = false
      if (!this.inTemplateOnly) disableShareBtn = true
      if (this.account && !this.account.companyId) disableShareBtn = true
      if (this.account && this.account.accountType === 'lawyer') {
        itemsArr = [
          { text: this.$t('editor|undo'), icon: 'mdi-undo', action: 'undo', disabled: false },
          { text: this.$t('editor|redo'), icon: 'mdi-redo', action: 'redo', disabled: false },
          { text: this.$t('actions|save'), icon: 'mdi-content-save', action: 'save', disabled: false },
          { text: this.$t('actions|save_as'), icon: 'mdi-content-save-outline', action: 'save-as', disabled: false },
          { text: this.$t('templates|new_template'), icon: mdiTextBoxOutline, action: 'new-template', disabled: false },
          { text: this.$t('editor|export_as_word'), icon: 'mdi-export', action: 'export-template', disabled: false },
          { text: this.$t('editor|export_as_pdf'), icon: 'mdi-export', action: 'export-template-pdf', disabled: false },
          { text: this.$t('actions|share'), icon: 'mdi-share-variant', action: 'share-template', disabled: disableShareBtn },
          { text: this.$t('editor|tooltip_search_replace'), icon: 'mdi-magnify', action: 'search-and-replace', disabled: false },
          { text: this.$t('editor|change_suggestion_language'), icon: 'mdi-web', action: 'change-suggestion-language', disabled: false },
          { text: this.$t('editor|version_history'), icon: 'mdi-history', action: 'open-version-history', disabled: true }
        ]
      }
      if (this.account && this.account.accountType !== 'lawyer') {
        itemsArr = [
          { text: this.$t('editor|export_as_word'), icon: 'mdi-export', action: 'export-template', disabled: false },
          { text: this.$t('editor|export_as_pdf'), icon: 'mdi-export', action: 'export-template-pdf', disabled: false }
        ]
      }
      return itemsArr
    },
    viewMenuItems () {
      return [
        { text: this.$t('templates|template_suggestions'), icon: 'mdi-at', action: 'show-template-suggestions' },
        { text: this.$t('common|comments'), icon: 'mdi-comment-text-outline', action: 'show-template-comments', disabled: this.inTemplateOnly },
        { text: this.$t('documents|required_documents'), icon: 'mdi-file-outline', action: 'show-workbench-required-documents' },

        { text: this.$t('editor|seggeustion_view_text'), icon: 'mdi-eye', action: 'change-suggestion-view-text' },
        { text: this.$t('editor|seggeustion_view_editor'), icon: 'mdi-eye-off', action: 'change-suggestion-view-editor' }
      ]
    },
    editMenuItems () {
      return [
        { text: this.$t('editor|undo'), icon: 'mdi-undo', action: 'undo', shortCut: 'Ctrl-Z', disabled: false },
        { text: this.$t('editor|redo'), icon: 'mdi-redo', action: 'redo', shortCut: 'Ctrl-Z', disabled: false },
        { text: this.$t('editor|cut'), icon: 'mdi-content-cut', action: 'cut', shortCut: 'Ctrl-X', disabled: false },
        { text: this.$t('editor|copy'), icon: 'mdi-content-copy', action: 'copy', shortCut: 'Ctrl-C', disabled: false },
        { text: this.$t('editor|paste'), icon: 'mdi-content-paste', action: 'paste', shortCut: 'Ctrl-V', disabled: false }
      ]
    },
    position () {
      const doc = this.editor.state.doc
      return doc
    },
    getActiveHeading () {
      if (this.editor.isActive('heading', { level: 1 })) return this.$t('editor|heading') + ' 1'
      if (this.editor.isActive('heading', { level: 2 })) return this.$t('editor|heading') + ' 2'
      if (this.editor.isActive('heading', { level: 3 })) return this.$t('editor|heading') + ' 3'
      if (this.editor.isActive('heading', { level: 4 })) return this.$t('editor|heading') + ' 4'
      if (this.editor.isActive('heading', { level: 5 })) return this.$t('editor|heading') + ' 5'
      if (this.editor.isActive('heading', { level: 6 })) return this.$t('editor|heading') + ' 6'
      else return this.$t('editor|normal_text')
    },
    getActiveFont () {
      return this.font.label
    },
    activeTable () {
      return this.isActiveTable
    }
  },
  watch: {
    activeTable (value) {
      if (value) this.tableMenu = true
      else this.tableMenu = false
    },
    searchInputGeneralSearch (value) {
      if (value) {
        this.searchDialogModel = true
      } else {
        this.searchDialogModel = false
      }
    },
    selectedTextColor (value) {
      if (value) {
        this.$emit('text-color', value)
      } else {
        value = this.editor.getAttributes('textStyle').color || '#000000'
      }
    },
    searchInput (value) {
      if (!value) this.$emit('find-in-text', '')
    },
    searchMenu (value) {
      if (!value) this.$emit('find-in-text', '')
    },
    // 'window.width': {
    //   handler (newVal, oldVal) {
    //     if (newVal < oldVal) {
    //       if (oldVal === 0) return
    //       for (let i = 0; i < this.panelIconsIds.length; i++) {
    //         const panel = document.getElementById('panel')
    //         const panelBounding = panel.getBoundingClientRect()
    //         const icon = this.panelIconsIds[i]
    //         const domElement = document.getElementById(icon.domItem)
    //         if (domElement) {
    //           const iconBounding = domElement.getBoundingClientRect()
    //           const isAllreadyInArrayIndex = this.hiddenIcons.findIndex(item => item === domElement.id)
    //           if ((panelBounding.right - 26) <= iconBounding.left && isAllreadyInArrayIndex === -1) {
    //             domElement.style = 'display: none;'
    //             this.hiddenIcons.push(domElement.id)
    //           }
    //         }
    //       }
    //     }
    //     if (newVal > oldVal) {
    //       if (oldVal === 0) return
    //       const panelIconsArrayReverse = this.panelIconsIds.reverse()
    //       for (let i = 0; i < panelIconsArrayReverse.length; i++) {
    //         const panel = document.getElementById('panel')
    //         const panelBounding = panel.getBoundingClientRect()
    //         const icon = panelIconsArrayReverse[i]
    //         const domElement = document.getElementById(icon.domItem)
    //         if (domElement && window.getComputedStyle(domElement).display === 'none') {
    //           domElement.style = 'display: inherit'
    //         }
    //         if (domElement) {
    //           const iconBounding = domElement.getBoundingClientRect()
    //           const isAllreadyInArrayIndex = this.hiddenIcons.findIndex(item => item === domElement.id)
    //           if (panelBounding.right > iconBounding.left && isAllreadyInArrayIndex !== -1) {
    //             this.hiddenIcons.splice(isAllreadyInArrayIndex, 1)
    //           }
    //         }
    //       }
    //     }
    //   },
    //   deep: true
    // },
    position: {
      handler () {
        if (this.editor.isActive('textStyle', { fontFamily: 'Sofia Sans' })) {
          this.font = {
            name: 'Sofia Sans',
            value: 'Sofia Sans',
            label: 'Sofia Sans',
            style: {
            // optional style to apply to the item
              fontFamily: 'Sofia Sans'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Arial' })) {
          this.font = {
            name: 'Arial',
            value: 'Arial',
            label: 'Arial',
            style: {
            // optional style to apply to the item
              fontFamily: 'Arial'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Cambria' })) {
          this.font = {
            name: 'Cambria',
            value: 'Cambria',
            label: 'Cambria',
            style: {
            // optional style to apply to the item
              fontFamily: 'Cambria'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Arial Black' })) {
          this.font = {
            name: 'Arial Black',
            value: 'Arial Black',
            label: 'Arial Black',
            style: {
            // optional style to apply to the item
              fontFamily: 'Arial Black'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Courier' })) {
          this.font = {
            name: 'Courier',
            value: 'Courier',
            label: 'Courier',
            style: {
            // optional style to apply to the item
              fontFamily: 'Courier'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Courier New' })) {
          this.font = {
            name: 'Courier New',
            value: 'Courier New',
            label: 'Courier New',
            style: {
            // optional style to apply to the item
              fontFamily: 'Courier New'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Sans-Serif' })) {
          this.font = {
            name: 'Sans-Serif',
            value: 'Sans-Serif',
            label: 'Sans-Serif',
            style: {
            // optional style to apply to the item
              fontFamily: 'Sans-Serif'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Serif' })) {
          this.font = {
            name: 'Serif',
            value: 'Serif',
            label: 'Serif',
            style: {
            // optional style to apply to the item
              fontFamily: 'Serif'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Times New Roman' })) {
          this.font = {
            name: 'Times New Roman',
            value: 'Times New Roman',
            label: 'Times New Roman',
            style: {
            // optional style to apply to the item
              fontFamily: 'Times New Roman'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Verdana' })) {
          this.font = {
            name: 'Verdana',
            value: 'Verdana',
            label: 'Verdana',
            style: {
            // optional style to apply to the item
              fontFamily: 'Verdana'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Helvetica' })) {
          this.font = {
            name: 'Helvetica',
            value: 'Helvetica',
            label: 'Helvetica',
            style: {
            // optional style to apply to the item
              fontFamily: 'Helvetica'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Calibri' })) {
          this.font = {
            name: 'Calibri',
            value: 'Calibri',
            label: 'Calibri',
            style: {
            // optional style to apply to the item
              fontFamily: 'Calibri'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Impact' })) {
          this.font = {
            name: 'Impact',
            value: 'Impact',
            label: 'Impact',
            style: {
            // optional style to apply to the item
              fontFamily: 'Impact'
            }
          }
        }
      }
    },
    actualFontSize (value) {
      if (value) {
        let newVal = null
        const p = value.indexOf('p')
        const x = value.indexOf('x')
        newVal = value.slice(0, p)
        value.slice(0, x)
        this.textSize = newVal
      } else {
        this.textSize = null
      }
    }
  },
  mounted () {
    this.handleIconMenu()
    // setTimeout(() => {
    //   this.calculateToolbarGroups()
    // }, '1000')
  },
  created () {
    // window.addEventListener('resize', this.handleIconMenu)
    // window.addEventListener('resize', this.callDebounceCalculate)
  },
  destroyed () {
    // window.removeEventListener('resize', this.handleIconMenu)
    // window.removeEventListener('resize', this.callDebounceCalculate)
  },
  methods: {
    // callDebounceCalculate: debounce(function () {
    //   this.calculateToolbarGroups()
    // }, 1300),
    // async calculateToolbarGroups () {
    //   this.ellipsesToolbars = {}
    //   this.hasToolbarEllipsis = false
    //   await this.$nextTick()
    //   const container = this.$refs.toolbarGroupContainer
    //   // const containerRect = container.getBoundingClientRect()
    //   const toolbarGroups = [...container.querySelectorAll('.toolbar-group')]
    //   toolbarGroups.forEach((groupEl) => {
    //     // const groupElRect = groupEl.getBoundingClientRect()
    //     // if => containerRect.width <= groupElRect.left + groupElRect.width - 280
    //     if (groupEl.offsetTop > 80) {
    //       const toolbarIndex = groupEl.dataset.toolbar
    //       this.ellipsesToolbars[toolbarIndex] = true
    //       this.hasToolbarEllipsis = true
    //     }
    //   })
    // },
    mainMenuInput (input) {
      console.log(input, 'menu')
      if (!input) {
        this.selectedItemFileMenu = null
        this.selectedItemEditMenu = null
        this.searchAndReplace = false
        this.$emit('find-in-text', '')
        this.searchInput = ''
        this.replaceWith = ''
        this.changeSuggestionLanguageList = false
      }
    },
    addMenuInput (input) {
      if (input === false) this.selectedItemAddMenu = null
    },
    updateCategory (category) {
      EventBus.$emit('updateTempCat', category)
    },
    newSelectedFontColor (color) {
      this.$emit('text-color', color)
    },
    newSelectedBackgroundColor (color) {
      this.$emit('highlight', color)
    },
    checkActive (input) {
      const result = this.editor.isActive('textStyle', { fontFamily: input })
      return result
    },
    handleIconMenu () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    // isHidden (id) {
    //   const foundItem = this.hiddenIcons.find(item => item === id)
    //   if (foundItem) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    closeReset () {
      this.searchInputGeneralSearch = ''
      this.searchDialogModel = false
    },
    clearInput () {
      this.searchInputGeneralSearch = ''
    },
    translateCategoryIfNeeded (category) {
      if (category === 'none') return this.$t('common|uncategorized')
      else return category
    }
  }
}
</script>
