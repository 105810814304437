<template>
  <v-card
    class="pa-2"
  >
    <v-row
      v-for="(colors, i) in swatches"
      :key="i"
      no-gutters
    >
      <v-col
        v-for="(color, index) in colors"
        :key="index"
        class="pa-1"
      >
        <v-sheet
          rounded
          :color="color"
          elevation="1"
          height="20"
          width="60"
          @click="$emit('new-selected-color', color)"
        >
          <v-icon
            v-if="isActualColor(color)"
            size="13"
            :color="(color === '#550000' || color === '#000000' || color === '#0000AA') ? 'white' : 'dark-grey'"
            class="pl-6 pb-2"
          >
            mdi-check-circle-outline
          </v-icon>
        </v-sheet>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

export default {
  props: {
    editor: {
      type: Object,
      default: null
    },
    colorText: {
      type: Boolean,
      default: false
    },
    colorHighlight: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    swatches: [
      ['#FFFFFF', '#FF0000', '#FF9800', '#FFFF00', '#AAAA00'],
      ['#555500', '#00FF00', '#00AA00', '#005500', '#00FFFF'],
      ['#00AAAA', '#005555', '#0000FF', '#0000AA', '#000000']
    ]
  }),
  methods: {
    isActualColor (color) {
      if (this.colorText) {
        const actualColor = this.editor.getAttributes('textStyle').color
        if (color === actualColor) {
          return true
        } else {
          return false
        }
      }
      if (this.colorHighlight) {
        const actualColor = this.editor.getAttributes('textStyle').backgroundColor
        if (color === actualColor) {
          return true
        } else {
          return false
        }
      }
    }
  }
}
</script>
