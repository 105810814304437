import { Extension } from '@tiptap/core'
import '@tiptap/extension-text-style'

export const Color = Extension.create({
  name: 'color',

  addOptions: {
    types: ['textStyle']
  },

  addGlobalAttributes () {
    return [
      {
        types: this.options.types,
        attributes: {
          color: {
            default: null,
            renderHTML: (attributes) => {
              if (!attributes.color) {
                return {}
              }

              return {
                style: `color: ${attributes.color}`
              }
            },
            parseHTML: (element) => ({
              color: element.style.color.replace(/['"]+/g, '')
            })
          },
          backgroundColor: {
            default: null,
            renderHTML: (attributes) => {
              if (!attributes.backgroundColor) {
                return {}
              }

              return {
                style: `background-color: ${attributes.backgroundColor}`
              }
            },
            parseHTML: (element) => ({
              color: element.style.backgroundColor.replace(/['"]+/g, '')
            })
          }
        }
      }
    ]
  },

  addCommands () {
    return {
      setColor:
        (color) =>
          ({ chain }) => {
            return chain().setMark('textStyle', { color }).run()
          },
      setBackgroundColor:
        (backgroundColor) =>
          ({ chain }) => {
            return chain().setMark('textStyle', { backgroundColor }).run()
          },
      unsetColor:
        () =>
          ({ chain }) => {
            return chain()
              .setMark('textStyle', { color: null })
              .removeEmptyTextStyle()
              .run()
          }
    }
  }
})
