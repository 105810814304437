<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        outlined
        dark
        small
        class="ml-2 pl-2 pr-2"
        v-bind="attrs"
        v-on="on"
      >
        View
        <v-icon>
          {{ icons.arrowDown }}
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item-group
        v-model="selectedView"
        mandatory
      >
        <v-list-item
          v-for="(view, i) in views"
          :key="i"
          color="red"
          @select="selectedView"
          @click="isSelected"
        >
          <v-list-item-content>
            <v-list-item-title v-text="view.text" />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiChevronDown } from '@mdi/js'
// import { EventBus } from '@/utils/EventBus'

export default {
  props: {
    value: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    views: [
      {
        text: 'Tiles',
        active: false
      },
      {
        text: 'List',
        active: false
      }
    ],
    selectedView: {},
    icons: {
      arrowDown: mdiChevronDown
    }
  }),
  methods: {
    isSelected (event) {
      this.$emit('viewIsSelected', this.selectedView)
    }
  }
}
</script>
