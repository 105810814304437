import Vue from 'vue'
import axios from '@/plugins/axios'
import store from '../index'
import autobahn from 'autobahn'

const SET_LOADING = 'SET_LOADING'
const SET_COMPANY_CLIENTS = 'SET_COMPANY_CLIENTS'
const SET_CLIENT_AS_CLIENT = 'SET_CLIENT_AS_CLIENT'
const ADD_COMPANY_CLIENT = 'ADD_COMPANY_CLIENT'
const UPDATE_COMPANY_CLIENT = 'UPDATE_COMPANY_CLIENT'
const UPDATE_COMPANY_CLIENT_CASES = 'UPDATE_COMPANY_CLIENT_CASES'
const DELETE_COMPANY_CLIENT = 'DELETE_COMPANY_CLIENT'
const RESET_COMPANY_CLIENTS = 'RESET_COMPANY_CLIENTS'

const state = () => {
  return {
    loading: false,
    companyClients: [],
    companyClientsSubscriptions: []
  }
}

const mutations = {
  [SET_LOADING] (state, value) {
    state.loading = value
  },
  [SET_COMPANY_CLIENTS] (state, payload) {
    state.companyClients = payload
  },
  [SET_CLIENT_AS_CLIENT] (state, payload) {
    state.meAsClient.push(payload)
  },
  [ADD_COMPANY_CLIENT] (state, payload) {
    state.companyClients.push(payload)
  },
  [UPDATE_COMPANY_CLIENT] (state, payload) {
    const index = state.companyClients.findIndex(c => c._id === payload._id)
    if (index === -1) state.companyClients.push(payload)
    else Vue.set(state.companyClients, index, payload)
  },
  [UPDATE_COMPANY_CLIENT_CASES] (state, payload) {
    let casesToBeRefreshed = []
    store.state.companyCases.companyCases.forEach(_case => {
      if (_case.client._id === payload._id) casesToBeRefreshed.push({ caseId: _case._id, type: 'company' })
    })
    store.state.groupCases.groupCases.forEach(_case => {
      if (_case.client._id === payload._id) casesToBeRefreshed.push({ caseId: _case._id, type: 'group' })
    })
    store.state.cases.cases.forEach(_case => {
      if (_case.client._id === payload._id) casesToBeRefreshed.push({ caseId: _case._id, type: 'acc' })
    })
    if (casesToBeRefreshed.length) {
      casesToBeRefreshed.forEach(obj => {
        if (obj.type === 'company') store.dispatch('companyCases/fetchCompanyCase', obj.caseId)
        if (obj.type === 'group') store.dispatch('groupCases/fetchGroupCase', obj.caseId)
        if (obj.type === 'acc') store.dispatch('cases/fetchCase', obj.caseId)
      })
    }
  },
  [DELETE_COMPANY_CLIENT] (state, _id) {
    const index = state.companyClients.findIndex(c => c._id === _id)
    if (index !== -1) {
      state.companyClients.splice(index, 1)
    }
  },
  [RESET_COMPANY_CLIENTS] (state) {
    state.companyClients = []
    state.companyClientsSubscriptions = []
  }
}

const actions = {
  fetchCompanyClients ({ commit }, { _id }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getSharedClients/${_id}`)
        commit(SET_COMPANY_CLIENTS, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  resetCompanyClients ({ commit }) {
    commit(RESET_COMPANY_CLIENTS)
  },
  companyClientsUpdatesSubscription ({ commit }, { _id }) {
    try {
      const connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })

      connection.onopen = function (session) {
        // call a remote procedure
        session.subscribe(`lavvira.updates.${_id}`, async (...args) => {
          const clientsTopic = args[0].includes('Client')
          const createTask = args[0].includes('create')
          const updateTask = args[0].includes('update')
          const deleteTask = args[0].includes('delete')
          if (args[0][4] && args[0][4].updateType === 'clientProfileUpdate') {
            commit(UPDATE_COMPANY_CLIENT_CASES, args[0][0])
          }
          if (clientsTopic && createTask) {
            commit(ADD_COMPANY_CLIENT, args[0][0])
          } else if (clientsTopic && updateTask) {
            const client = store.state.companyClients.companyClients.find(c => c._id === args[0][0]._id)
            if (client) {
              commit(UPDATE_COMPANY_CLIENT, args[0][0])
            } else {
              // implemet 'share task'!!! to be clear
              commit(UPDATE_COMPANY_CLIENT, args[0][0])
            }
          } else if (clientsTopic && deleteTask) {
            commit(DELETE_COMPANY_CLIENT, args[0][0]._id)
          }
        }).then(
          function (subscription) {
            store.state.companyClients.companyClientsSubscriptions.push(subscription)
          }
        )
      }

      connection.onclose = function (reason, details) {
        // handle connection lost
        console.log(reason, details, 'connection.onclose')
      }
      connection.open()
    } catch (e) {
      console.loerror(e, 'ERROR in autobahn connection')
    }
  },
  unsubscribeCompanyClientsUpdate ({ commit }, sub) {
    sub.unsubscribe().then(
      function (gone) {
        if (gone) commit(RESET_COMPANY_CLIENTS)
        // successfully unsubscribed
      },
      function (error) {
        // unsubscribe failed
        console.error(error, 'error unsubscribe')
      }
    )
  }
}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions
}
