const SET_COMMENTS = 'SET_COMMENTS'
const RESET_COMMENTS = 'RESET_COMMENTS'
const SET_SUGGESTIONS = 'SET_SUGGESTIONS'

const state = () => {
  return {
    actualCommentsEditorStorage: [],
    actualSuggestionsEditorStorage: []
  }
}

const mutations = {
  [SET_COMMENTS] (state, value) {
    state.actualCommentsEditorStorage = value
  },
  [RESET_COMMENTS] (state) {
    state.actualCommentsEditorStorage = []
  },
  [SET_SUGGESTIONS] (state, value) {
    state.actualSuggestionsEditorStorage = value
  }
}

const actions = {
  setComments ({ commit }, comments) {
    commit(SET_COMMENTS, comments)
  },
  resetComments ({ commit }) {
    commit(RESET_COMMENTS)
  },
  setSuggestions ({ commit }, suggestions) {
    commit(SET_SUGGESTIONS, suggestions)
  }
}

const getters = {}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions,
  getters
}
