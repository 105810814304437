'use strict'

import Vue from 'vue'
import axios from 'axios'
import router from '@/router/index'
// import store from '@/store/index'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// const getRefToken = async (_id, originalConfig) => {
//   _axios.defaults.withCredentials = true
//   const { data } = await _axios.post(`/refreshToken/${_id}`, { withCredentials: true })
//   if (data) {
//     _axios.defaults.headers.common['authorization'] = 'Bearer ' + data.token
//     await store.dispatch('auth/setToken', data)
//     return _axios(originalConfig)
//   } else {
//     localStorage.removeItem('access_token')
//     router.push('/login')
//   }
// }

let config = {
  baseURL: process.env.VUE_APP_BACKEND_PATH
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response
  },
  async (error) => {
    if (error && error.response && error.response.data) {
      if (error.response.data.code === 1 || error.response.data.code === 401) {
        localStorage.setItem('access', false)
        router.push('/login')
        // TOKEN VALIDATION ERROR | EXPIRED TOKEN
        // const originalConfig = error.config
        // if (error.response.data.code === 1 && !originalConfig._retry) {
        //   originalConfig._retry = true
        //   try {
        //     const _id = localStorage.getItem('#$a!C%(C&!#i[D]#')
        //     getRefToken(_id, originalConfig)
        //   } catch (_error) {
        //     if (_error.response && _error.response.data) {
        //       return Promise.reject(_error.response.data)
        //     }
        //     return Promise.reject(_error)
        //   }
        // }
      }
      if (error.msg === 'No token provided.' || error.msg === 'Failed to authenticate token.') {
        localStorage.setItem('access', false)
        router.push('/login')
      }
    }
    // Do something with response error
    return Promise.reject(error)
  }
)

Plugin.install = function (Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get () {
        return _axios
      }
    },
    $axios: {
      get () {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default _axios
