<template>
  <div>
    <div
      v-if="label"
      class="mb-2"
    >
      {{ label }}
    </div>
    <file-upload-button
      class="mt-2"
      :mobile="$vuetify.breakpoint.mobile"
      :multiple="multiple"
      :in-case="inCase"
      :in-template="inTemplate"
      :show-upload-area="showUploadArea"
      :disabled-btn="disabledBtn"
      @file="onFilesChange"
    />
    <div
      v-for="(file, index) in innerFiles"
      :key="file.filename || file.name"
      :class="$vuetify.breakpoint.mobile ? 'mt-16' : 'd-flex mt-4 justify-between align-center'"
    >
      <template v-if="file._id">
        <FileBox
          :file-name="file[fileKey].originalname"
          :success="true"
          @close="removeFile(file)"
          @preview="openDocument(file, 'file-input-preview')"
        />
      </template>
      <template v-else>
        <v-container>
          <v-row>
            <v-col cols="8">
              <FileBox
                :file-name="file.file.name"
                :file-type="file.file.type"
                :loading="file.loading"
                :has-upload-button="true"
                :has-delete-button="false"
                class="mt-2"
                @close="removeFile(file)"
                @preview="openDocument(file, 'file-input-preview')"
                @select="file.language"
              />
            </v-col>
            <v-col cols="4">
              <div class="row my-1">
                <v-select
                  v-model="file.language"
                  :items="settings.activeLanguages"
                  style="max-width: 70%;"
                  :label="$t('common|language')"
                  multiple
                  dense
                  hide-details
                  :menu-props="{ bottom: true, offsetY: true }"
                  @input="limiter"
                />
                <v-btn
                  icon
                  color="grey"
                  class="mt-1 ml-2"
                  @click="removeFile(file)"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row
            v-if="index !== innerFiles.length - 1"
          >
            <v-divider />
          </v-row>
        </v-container>
      </template>
    </div>
    <v-snackbar
      v-model="show"
      :timeout="timeout"
      top
      color="warning"
      elevation="28"
    >
      <v-icon>
        mdi-alert-octagon-outline
      </v-icon>
      {{ snackBarText }}
    </v-snackbar>
    <div>
      {{ hint }}
    </div>
    <FileView
      ref="fileView"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import InputMixin from '../InputMixin'
import generalMixin from '@/utils/generalMixin.js'
import FileBox from './FileBox.vue'
import FileView from '../../dialogs/FileView.vue'

export default {
  components: {
    FileBox,
    FileView
  },
  mixins: [InputMixin, generalMixin],
  props: {
    value: {
      type: Array,
      default: null
    },
    fileKey: {
      type: String,
      default: null
    },
    multiple: {
      type: Boolean,
      default: true
    },
    hint: {
      type: String,
      default: null
    },
    showUploadArea: {
      type: Boolean,
      default: true
    },
    inCase: {
      type: Boolean,
      default: false
    },
    inTemplate: {
      type: Boolean,
      default: false
    },
    disabledBtn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      innerFiles: [],
      filesToUpload: [],
      uploadedFilesVisibility: true,
      show: false,
      snackbar: false,
      snackBarText: this.$t('warning|select_maximum_two_languages'),
      timeout: 2000
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings
    })
  },
  watch: {
    value: {
      handler (files) {
        this.innerFiles = files
      },
      deep: true
    }
  },
  created () {
    if (this.value && this.value.length) this.innerFiles = [...this.value]
  },
  methods: {
    onFilesChange (files) {
      const filesToUpload = files.map((f) => {
        return {
          file: f,
          language: '',
          loading: false
        }
      })
      this.innerFiles = [...this.innerFiles, ...filesToUpload]
      this.$emit('input', this.innerFiles)
    },
    async removeFile (file) {
      if (file._id) {
        const res = await this.$dialog.confirm({
          title: this.$t('common|warning'),
          text: this.$t('actions|delete_file')
        })
        if (!res) return
        this.$emit('delete', file)
      } else {
        const fileToRemove = this.innerFiles.findIndex((f) => {
          if (!f.file) return false
          return f.file.name === file.file.name
        })
        if (fileToRemove !== -1) {
          this.innerFiles.splice(fileToRemove, 1)
        }
      }
    },
    // limit for language selector
    limiter (input) {
      if (input) this.$emit('languageChange', this.innerFiles)
      if (input.length > 2) {
        input.pop()
        this.show = true
      }
    }
  }
}
</script>
