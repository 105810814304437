import Vue from 'vue'
import axios from '@/plugins/axios'
import store from '../index'
import autobahn from 'autobahn'
import { i18n } from '@/i18n/index'

const SET_LOADING = 'SET_LOADING'
const SET_DOCUMENTS = 'SET_DOCUMENTS'
const SET_SHARED_WITH_ME_DOCUMENTS = 'SET_SHARED_WITH_ME_DOCUMENTS'
const ADD_SHARED_WITH_ME_DOCUMENT = 'ADD_SHARED_WITH_ME_DOCUMENT'
const UPDATE_SHARED_WITH_ME_DOCUMENT = 'UPDATE_SHARED_WITH_ME_DOCUMENT'
const ADD_DOCUMENT = 'ADD_DOCUMENT'
const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT'
const DELETE_DOCUMENT = 'DELETE_DOCUMENT'
const RESET_DOCUMENTS = 'RESET_DOCUMENTS'
const SET_FOLDERS = 'SET_FOLDERS'
const SET_SHARED_WITH_ME_FOLDERS = 'SET_SHARED_WITH_ME_FOLDERS'
const ADD_FOLDER = 'ADD_FOLDER'
const ADD_DOCUMENT_TO_FOLDER = 'ADD_DOCUMENT_TO_FOLDER'
const UPDATE_FOLDERS = 'UPDATE_FOLDERS'
const UPDATE_FOLDER = 'UPDATE_FOLDER'
const DELETE_FOLDER = 'DELETE_FOLDER'

const state = () => {
  return {
    loading: false,
    loadingSingleDocument: [],
    documents: [],
    folders: [],
    sharedWithMeDocuments: [],
    sharedWithMeFolders: []
  }
}

const mutations = {
  [SET_LOADING] (state, value) {
    state.loading = value
  },
  [SET_DOCUMENTS] (state, payload) {
    state.documents = payload
  },
  [SET_SHARED_WITH_ME_DOCUMENTS] (state, payload) {
    state.sharedWithMeDocuments = payload
  },
  [ADD_DOCUMENT] (state, payload) {
    const index = state.documents.findIndex(doc => doc._id === payload._id)
    if (index === -1) {
      state.documents.push(payload)
    }
  },
  [ADD_SHARED_WITH_ME_DOCUMENT] (state, payload) {
    const index = state.sharedWithMeDocuments.findIndex(doc => doc._id === payload._id)
    if (index === -1) {
      state.sharedWithMeDocuments.push(payload)
    }
  },
  [UPDATE_DOCUMENT] (state, payload) {
    // Тhe check is performed to verify if the document was removed during the update from shared with.
    // If the company ID does not exist in sharedWith after the update,
    // the document was obviously removed
    // for this it must be removed from the state because it is now unshared

    // Documents in the lavvira platform are only removed from sharedWith,
    // unless there is only one ID in sharedWith left, then they are physically deleted
    const index = state.documents.findIndex(doc => doc._id === payload._id)
    if (index !== -1) {
      const actualDoc = state.documents[index]
      const isShared = actualDoc.sharedWith.accounts.includes(store.state.account.account._id)
      if (isShared) {
        const idIndex = payload.sharedWith.accounts.findIndex(id => id === store.state.account.account._id)
        if (idIndex === -1) {
          state.documents.splice(index, 1)
        } else {
          Vue.set(state.documents, index, payload)
        }
      } else {
        Vue.set(state.documents, index, payload)
      }
    }
    if (payload.setLoadingToFalse) {
      setTimeout(() => {
        state.loading = false
        const loadingIndex = state.loadingSingleDocument.findIndex(id => id === payload._id)
        if (loadingIndex !== -1) state.loadingSingleDocument.splice(loadingIndex, 1)
      }, 1500)
    }
  },
  [UPDATE_SHARED_WITH_ME_DOCUMENT] (state, payload) {
    const index = state.sharedWithMeDocuments.findIndex(doc => doc._id === payload._id)
    if (index !== -1) {
      const actualDoc = state.sharedWithMeDocuments[index]
      const isShared = actualDoc.sharedWith.accounts.includes(store.state.account.account._id)
      if (isShared) {
        const idIndex = payload.sharedWith.accounts.findIndex(id => id === store.state.account.account._id)
        if (idIndex === -1) {
          state.sharedWithMeDocuments.splice(index, 1)
        } else {
          Vue.set(state.sharedWithMeDocuments, index, payload)
        }
      } else {
        Vue.set(state.sharedWithMeDocuments, index, payload)
      }
    }
    if (payload.setLoadingToFalse) {
      setTimeout(() => {
        state.loading = false
        const loadingIndex = state.loadingSingleDocument.findIndex(id => id === payload._id)
        if (loadingIndex !== -1) state.loadingSingleDocument.splice(loadingIndex, 1)
      }, 1500)
    }
  },
  [DELETE_DOCUMENT] (state, payload) {
    const index = state.documents.findIndex(doc => doc._id === payload._id)
    const shredDocIndex = state.sharedWithMeDocuments.findIndex(doc => doc._id === payload._id)
    // the index must be checked, because the friction update comes from two places:
    // crossbar and express and if there is no check,
    // an error occurs (all documents from the store temporarily disappear until refresh)
    if (index !== -1) state.documents.splice(index, 1)
    if (shredDocIndex !== -1) state.sharedWithMeDocuments.splice(shredDocIndex, 1)
  },
  [RESET_DOCUMENTS] (state) {
    state.documents = []
    state.sharedWithMeDocuments = []
    state.folders = []
  },
  [SET_FOLDERS] (state, payload) {
    state.folders = payload
  },
  [SET_SHARED_WITH_ME_FOLDERS] (state, payload) {
    state.sharedWithMeFolders = payload
  },
  [ADD_FOLDER] (state, payload) {
    const folderIndex = state.folders.findIndex(f => f._id === payload._id)
    if (folderIndex === -1) state.folders.push(payload)
  },
  [ADD_DOCUMENT_TO_FOLDER] (state, payload) {
    const folderIndex = state.folders.findIndex(f => f._id === payload.folder._id)
    if (folderIndex !== -1) state.folders[folderIndex] = payload.folder
    const documentIndex = state.documents.findIndex(d => d._id === payload.docId)
    if (documentIndex !== -1) state.documents.splice(documentIndex, 1)
  },
  [UPDATE_FOLDERS] (state, payload) {
    if (payload && payload.folders) state.folders = payload.folders
    else {
      state.folders = payload
    }
  },
  [UPDATE_FOLDER] (state, payload) {
    const idx = state.folders.findIndex(f => f._id === payload._id)
    if (idx !== -1) Vue.set(state.folders, idx, payload)
  },
  [DELETE_FOLDER] (state, payload) {
    const idx = state.folders.findIndex(f => f._id === payload._id)
    if (idx !== -1) Vue.delete(state.folders, idx)
  },
  setLoading (state, value) {
    state.loading = value
  },
  setSingleDocumentLoading (state, _id) {
    state.loadingSingleDocument.push(_id)
  }
}

const actions = {
  getDocuments ({ commit }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getDocuments`)
        commit(SET_DOCUMENTS, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  getSharedWithMeDocuments ({ commit }, { _id }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getSharedDocuments/${_id}`)
        commit(SET_SHARED_WITH_ME_DOCUMENTS, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  getDocument ({ commit }, payload = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getDocument/${payload._id}`)
        commit(UPDATE_DOCUMENT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  uploadDocument ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const formData = new FormData()
        Object.keys(payload).forEach((key) => {
          formData.append(key, payload[key])
        })
        const { data } = await axios.post(`/uploadDocument/${_id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        if (data.success) {
          commit(ADD_DOCUMENT, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateDocument ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateDocument/${_id}`, {
          payload: payload
        })
        if (!data.success && data.data.msg === 'account not found') {
          this.addToast({
            title: i18n.t('error|lavvira_account_not_found'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
        if (data.success && data.onNewShareId && store.state.company.company && data.onNewShareId !== store.state.company.company._id) {
          const index = store.state.groupDocuments.groupDocuments.findIndex(doc => doc._id === data.data._id)
          if (index !== -1) {
            store.state.groupDocuments.groupDocuments.splice(index, 1, data.data)
          } else {
            store.state.groupDocuments.groupDocuments.push(data.data)
          }
          resolve(data)
        }
        if (data.success && data.onNewShareId && store.state.company.company && data.onNewShareId === store.state.company.company._id) {
          store.state.companyDocuments.companyDocuments.push(data.data)
          resolve(data)
        }
        if (data.success && !data.onNewShareId) {
          commit(UPDATE_DOCUMENT, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (e) {
        if (e.response.data && e.response.data === 'Acc Not Found') {
          store.dispatch('toasts/addToast', {
            title: i18n.t('error|lavvira_account_not_found'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
        if (e.response.data && e.response.data === 'Already shared') {
          store.dispatch('toasts/addToast', {
            title: i18n.t('error|already_shared'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
        reject(e)
      } finally {
        if (!payload.info) commit(SET_LOADING, false)
      }
    })
  },
  removeSharedDocument ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/removeDocument/${_id}`, {
          payload: payload
        })
        if (data.success) {
          commit(DELETE_DOCUMENT, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteDocument ({ commit }, { document } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        await axios.post(`/deleteDocument/${document._id}`)
        const _id = document._id
        commit(DELETE_DOCUMENT, { _id })
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  createNewFolder ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/createNewFolder/${_id}`, {
          payload: payload
        })
        commit(ADD_FOLDER, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  dragDocumentInToFolder ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/dragDocumentInToFolder/${_id}`, {
          payload: payload
        })
        commit(UPDATE_FOLDER, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  changeDocumentFolder ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/changeDocumentFolder/${_id}`, { payload })
        commit(UPDATE_FOLDER, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  renameFolder ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/renameFolder/${_id}`, { payload })
        commit(UPDATE_FOLDER, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateFolder ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateFolder/${_id}`, { payload })
        commit(UPDATE_FOLDER, data.data)
        resolve()
      } catch (e) {
        if (e.response.data && e.response.data.errorType && e.response.data.errorType === 'intentional') {
          store.dispatch('toasts/addToast', {
            title: e.response.data.msg,
            color: 'white',
            snackbarColor: 'error'
          })
        }
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteFolder ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/deleteFolder/${_id}`, { payload })
        commit(DELETE_FOLDER, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  getDocumentFolders ({ commit }, { _id }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getFolders/${_id}`)
        commit(SET_FOLDERS, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  getSharedWithMeFolders ({ commit }, { _id }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getSharedWithMeFolders/${_id}`)
        commit(SET_SHARED_WITH_ME_FOLDERS, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  resetDocuments ({ commit }) {
    commit(RESET_DOCUMENTS)
  },
  documentsUpdatesSubscription ({ commit }, { _id }) {
    try {
      const connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })

      connection.onopen = function (session) {
        session.subscribe(`lavvira.updates.${_id}`, async (...args) => {
          const documentTopic = args[0].includes('Documents')
          const folderTopic = args[0].includes('Folders')
          const createTask = args[0].includes('create')
          const updateTask = args[0].includes('update')
          const deleteTask = args[0].includes('delete')
          const removeTask = args[0].includes('remove')
          const documentTypeUpdate = args[0].includes('document_type_update')
          if (args[0][4] && args[0][4].sharedDocument && args[0][4] && args[0][4].sharedWithType === 'account' &&
          args[0][4].accountId === store.state.account.account._id) {
            commit(ADD_SHARED_WITH_ME_DOCUMENT, args[0][0])
          }
          if (documentTopic && createTask) {
            commit(ADD_DOCUMENT, args[0][0])
          } else if (documentTopic && updateTask) {
            const doc = store.state.documents.documents.find(d => d._id === args[0][0]._id) ||
            store.state.documents.sharedWithMeDocuments.find(d => d._id === args[0][0]._id)
            if (doc) {
              if (documentTypeUpdate) {
                args[0][0].setLoadingToFalse = true
              }
              commit(UPDATE_DOCUMENT, args[0][0])
              commit(UPDATE_SHARED_WITH_ME_DOCUMENT, args[0][0])
            } else {
              // implement 'share task'!!! to be clear
              commit(ADD_DOCUMENT, args[0][0])
            }
          } else if (documentTopic && deleteTask) {
            commit(DELETE_DOCUMENT, args[0][0])
            if (args[0][4] && args[0][4].foldersUpdate) {
              commit(UPDATE_FOLDERS, args[0][4].folders)
            }
          } else if (documentTopic && removeTask) {
            commit(UPDATE_DOCUMENT, args[0][0])
            commit(UPDATE_SHARED_WITH_ME_DOCUMENT, args[0][0])
            // implement remove option
          }
          if (folderTopic && createTask) {
            commit(ADD_FOLDER, args[0][0])
          } else if (folderTopic && updateTask) {
            if (args[0][4] && args[0][4].newSubfolderAdded) {
              commit(ADD_FOLDER, args[0][4].newSubfolder)
            }
            const folder = store.state.documents.folders.find(d => d._id === args[0][0]._id) ||
            store.state.documents.sharedWithMeFolders.find(d => d._id === args[0][0]._id)
            if (folder) {
              commit(UPDATE_FOLDER, args[0][0])
              // commit(UPDATE_SHARED_WITH_ME_FOLDER, args[0][0])
            }
          } else if (folderTopic && deleteTask) {
            commit(DELETE_FOLDER, args[0][0])
          } else if (folderTopic && removeTask) {
            if (
              args[0][4] &&
              args[0][4].removedFolder &&
              args[0][4].userType &&
              args[0][4].userType === 'accounts' &&
              args[0][4].userId === _id
            ) {
              commit(DELETE_FOLDER, args[0][0].returnFolder)
            }
          }
        })
      }

      connection.onclose = function (reason, details) {
        // handle connection lost
        console.log(reason, details, 'connection.onclose')
      }
      connection.open()
    } catch (e) {
      console.error(e, 'ERROR in autobahn connection')
    }
  }
}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions
}
