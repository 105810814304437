import Vue from 'vue'
import axios from '@/plugins/axios'
import store from '../index'
import autobahn from 'autobahn'
import { uniqBy } from '@/utils/utils'

const SET_LOADING = 'SET_LOADING'
const SET_GROUP_CASE_FORMS = 'SET_COMPANY_CASE_FORMS'
const ADD_GROUP_CASE_FORM = 'ADD_GROUP_CASE_FORM'
const UPDATE_GROUP_CASE_FORM = 'UPDATE_GROUP_CASE_FORM'
const DELETE_GROUP_CASE_FORM = 'DELETE_GROUP_CASE_FORM'
const RESET_GROUP_CASE_FORMS = 'RESET_GROUP_CASE_FORMS'

const state = () => {
  return {
    loading: false,
    groupCaseForms: [],
    groupCaseFormsSubscriptions: []
  }
}

const mutations = {
  [SET_LOADING] (state, value) {
    state.loading = value
  },
  [SET_GROUP_CASE_FORMS] (state, payload) {
    state.groupCaseForms.push(payload)
    // state group case forms array must be filtered for unique identification ids
    // because the appwrapper calls for each group the cases shared with it.
    // As cases can of course be repeated, they must be filtered by _id
    state.groupCaseForms = uniqBy(state.groupCaseForms, '_id')
  },
  [ADD_GROUP_CASE_FORM] (state, payload) {
    const index = state.groupCaseForms.findIndex(cf => cf._id === payload._id)
    if (index === -1) state.groupCaseForms.push(payload)
    else Vue.set(state.groupCaseForms, index, payload)
    store.dispatch('groupCaseForms/groupCaseFormsUpdatesSubscription', payload._id)
  },
  [UPDATE_GROUP_CASE_FORM] (state, payload) {
    const index = state.groupCaseForms.findIndex(cf => cf._id === payload._id)
    Vue.set(state.groupCaseForms, index, payload)
  },
  [DELETE_GROUP_CASE_FORM] (state, payload) {
    const index = state.groupCaseForms.findIndex(cf => cf._id === payload._id)
    if (index !== -1) {
      state.groupCaseForms.splice(index, 1)
    }
  },
  [RESET_GROUP_CASE_FORMS] (state) {
    state.groupCaseForms = []
    state.groupCaseFormsSubscriptions = []
  }
}

const actions = {
  fetchGroupCaseForms ({ commit }, { _id }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getSharedCaseForms/${_id}`)
        if (data.success) {
          for (let i = 0; i < data.data.length; i++) {
            const cf = data.data[i]
            commit(SET_GROUP_CASE_FORMS, cf)
          }
          resolve()
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  resetGroupCaseForms ({ commit }) {
    commit(RESET_GROUP_CASE_FORMS)
  },
  groupCaseFormsUpdatesSubscription ({ commit }, { _id }) {
    try {
      const connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })

      connection.onopen = function (session) {
        // call a remote procedure
        session.subscribe(`lavvira.updates.${_id}`, async (...args) => {
          const caseFormTopic = args[0].includes('CaseForm')
          const createTask = args[0].includes('create')
          const updateTask = args[0].includes('update')
          const deleteTask = args[0].includes('delete')
          const removeTask = args[0].includes('remove')
          if (caseFormTopic && createTask) {
            commit(ADD_GROUP_CASE_FORM, args[0][0])
          } else if (caseFormTopic && updateTask) {
            const _caseForm = store.state.groupCaseForms.groupCaseForms.find(c => c._id === args[0][0]._id)
            if (_caseForm) {
              commit(UPDATE_GROUP_CASE_FORM, args[0][0])
            } else {
              // implemet 'share task'!!! to be clear
              commit(UPDATE_GROUP_CASE_FORM, args[0][0])
            }
          } else if (caseFormTopic && deleteTask) {
            commit(DELETE_GROUP_CASE_FORM, args[0][0])
          } else if (caseFormTopic && removeTask) {
            // the removе task is used when a group is removed from sharedWith.groups
            // since all group documents are in one array
            // it is necessary to check with which group the document is still shared
            let groupIds = []
            let sharedArray = []
            for (let i = 0; i < store.state.company.company.groups.length; i++) {
              const group = store.state.company.company.groups[i]
              groupIds.push(group._id)
            }
            groupIds.forEach(groupId => {
              const stillShared = args[0][0].sharedWith.groups.findIndex(g => g._id === groupId)
              sharedArray.push(stillShared)
            })
            if (sharedArray.some((index) => index >= 0)) {
              commit(UPDATE_GROUP_CASE_FORM, args[0][0])
            } else {
              commit(UPDATE_GROUP_CASE_FORM, args[0][0])
            }
          }
        })
      }

      connection.onclose = function (reason, details) {
        // handle connection lost
        console.log(reason, details, 'connection.onclose')
      }
      connection.open()
    } catch (e) {
      console.error(e, 'ERROR in autobahn connection')
    }
  },
  usnsubscribeGroupCaseFormsUpdates ({ commit }, sub) {
    sub.unsubscribe().then(
      function (gone) {
        if (gone) commit(RESET_GROUP_CASE_FORMS)
        // successfully unsubscribed sub1
      },
      function (error) {
        // unsubscribe failed
        console.error(error)
      }
    )
  }
}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions
}
