export default {
  namespaced: true,
  state: {
    drawer: false,
    mini: localStorage.getItem('mini') === 'true' || false,
    toolbar: false,
    loadingData: true,
    focus: false
  },
  mutations: {
    toggleDrawer (state, payload) {
      if (payload === undefined) {
        state.drawer = !state.drawer
      } else {
        localStorage.setItem('drawer', payload)
        state.drawer = payload
      }
    },
    toggleMini (state, payload) {
      localStorage.setItem('mini', payload)
      state.mini = payload
    },
    toggleToolbar (state, payload) {
      state.toolbar = payload
    },
    toggleLoadingData (state, payload) {
      state.loadingData = payload
    },
    toggleFocus (state, payload) {
      state.focus = payload
    }
  }
}
