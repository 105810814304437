<template>
  <v-list-item
    v-ripple
    @click="$emit('open-template', template)"
  >
    <template v-slot:default="{ active }">
      <v-list-item-action v-if="hasSelection">
        <v-checkbox :input-value="active" />
      </v-list-item-action>
      <v-list-item-action v-if="hasActions">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              link
              :to="`/templates/${template._id}`"
              v-on="on"
              @click="$emit('close-menu')"
            >
              <v-icon
                color="info"
                dark
              >
                mdi-file-hidden
              </v-icon>
            </v-btn>
          </template>
          <!-- <v-list>
            <v-list-item @click="$emit('this.dialog=false')">
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
          </v-list> -->
        </v-menu>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title v-html="template.name" />

        <!-- <v-list-item-subtitle
          class="text--primary"
          v-text="item.headline"
        ></v-list-item-subtitle> -->

        <!-- <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle> -->
      </v-list-item-content>

      <v-list-item-action>
        <!-- <v-list-item-action-text v-text="item.action"></v-list-item-action-text> -->

        <!-- <v-icon
          color="info"
        >
          mdi-file-hidden
        </v-icon> -->
      </v-list-item-action>
    </template>
  </v-list-item>
</template>

<script>
import { mdiCog } from '@mdi/js'
// import { EventBus } from '@/utils/EventBus'

export default {
  props: {
    template: {
      type: Object,
      default: null
    },
    hasSelection: {
      type: Boolean,
      default: false
    },
    hasActions: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      icons: {
        mdiCog
      }
    }
  }
  // methods: {
  //   closeSearchDialoginParent () {
  //     EventBus.$emit('close-search-dialog', {})
  //   }
  // }
}
</script>
