// Core Components
import Vue from 'vue'
import Toolbar from '@/components/core/Toolbar.vue'
import Menu from '@/components/core/Menu.vue'
import Content from '@/components/core/Content.vue'
import Footer from '@/components/core/Footer.vue'

// Input Components
import TextInput from '@/components/InputFields/TextInput.vue'
import CurrencyInput from '@/components/InputFields/CurrencyInput.vue'
import DateInput from '@/components/InputFields/DateInput.vue'
import TagsInput from '@/components/InputFields/TagsInput.vue'
import FileInput from '@/components/InputFields/FileInput/FileInput.vue'
import BooleanInput from '@/components/InputFields/BooleanInput.vue'

// Generall
import FileView from '@/components/dialogs/FileView.vue'
import SearchDialog from '@/components/dialogs/SearchDialog.vue'
import FileUploadButton from '@/components/InputFields/FileInput/FileUploadButton.vue'
import ThemeChangerMenu from '@/components/ThemeChanger.vue'
import ChangeViewFilter from '@/components/viewSelection/ChangeViewFilter.vue'
import DialogBase from '@/components/dialogs/DialogBase.vue'

// Material Design Components
import Card from '@/components/cards/Card.vue'
import NotificationCard from '@/components/cards/NotificationCard.vue'
import StatsCard from '@/components/cards/StatsCard.vue'
import ProgressCard from '@/components/cards/ProgressCard.vue'
import Offset from '@/components/helper/Offset.vue'

// Partials // List Items
import ClientItem from '@/components/partials/ClientItem.vue'
import CaseItem from '@/components/partials/CaseItem.vue'
import CaseModelsItem from '@/components/partials/CaseModelsItem.vue'
import TemplateItem from '@/components/partials/TemplateItem.vue'
import DocumentItem from '@/components/partials/DocumentItem.vue'
import MemberItem from '@/components/partials/MemberItem.vue'
import DocumentList from '@/components/partials/DocumentList.vue'

Vue.component('core-toolbar', Toolbar)
Vue.component('core-menu', Menu)
Vue.component('core-content', Content)
Vue.component('core-footer', Footer)

Vue.component('text-input', TextInput)
Vue.component('currency-input', CurrencyInput)
Vue.component('date-input', DateInput)
Vue.component('file-input', FileInput)
Vue.component('boolean-input', BooleanInput)
Vue.component('tags-input', TagsInput)

Vue.component('file-view', FileView)
Vue.component('search-dialog', SearchDialog)
Vue.component('file-upload-button', FileUploadButton)
Vue.component('theme-changer-menu', ThemeChangerMenu)
Vue.component('change-view-filter', ChangeViewFilter)
Vue.component('dialog-base', DialogBase)

Vue.component('material-card', Card)
Vue.component('material-notification', NotificationCard)
Vue.component('stats-card', StatsCard)
Vue.component('progress-card', ProgressCard)

Vue.component('helper-offset', Offset)

Vue.component('client-item', ClientItem)
Vue.component('case-item', CaseItem)
Vue.component('case-models-item', CaseModelsItem)
Vue.component('template-item', TemplateItem)
Vue.component('document-item', DocumentItem)
Vue.component('member-item', MemberItem)
Vue.component('document-list', DocumentList)
