<template>
  <v-combobox
    v-model="model"
    chips
    clearable
    :label="label"
    multiple
    solo
    :readonly="readonly"
  >
    <template v-slot:selection="{ attrs, item, select, selected }">
      <v-chip
        v-bind="attrs"
        :input-value="selected"
        close
        @click="select"
        @click:close="remove(item)"
      >
        <strong>{{ item }}</strong>&nbsp;
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
import InputMixin from './InputMixin'

export default {
  mixins: [InputMixin],
  props: {
    value: {
      type: Array,
      default: () => ([])
    }
  },
  methods: {
    remove (item) {
      this.value.splice(this.value.indexOf(item), 1)
      this.$emit('input', this.value)
    }
  }
}
</script>
