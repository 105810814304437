import axios from '@/plugins/axios'
import store from '../index'
import autobahn from 'autobahn'
import router from '../../router/index'
import { EventBus } from '@/utils/EventBus'
import { i18n } from '@/i18n/index'

const SET_LOADING = 'SET_LOADING'
const SET_COMPANY = 'SET_COMPANY'
const ADD_COMPANY = 'ADD_COMPANY'
const ADD_MEMBER = 'ADD_MEMBER'
const UPDATE_COMPANY = 'UPDATE_COMPANY'
const MODIFY_CASE_MODEL_CATEGORIES_COMPANY = 'ADD_CASE_MODEL_CATEGORIES_COMPANY'
const MODIFY_CASE_MODEL_CATEGORIES_GROUP = 'ADD_CASE_MODEL_CATEGORIES_GROUP'
const DELETE_COMPANY = 'DELETE_COMPANY'
const DELETE_GROUP = 'DELETE_GROUP'
const RESET_COMPANY = 'RESET_COMPANY'
const NAVIGATE_TO_DASHBOARD = 'NAVIGATE_TO_DASHBOARD'

const printError = function (data) {
  store.dispatch('toasts/addToast', {
    title: data.msg,
    color: 'white',
    snackbarColor: 'error'
  })
}

const state = () => {
  return {
    loading: false,
    company: null,
    companySubscriptions: []
  }
}

const mutations = {
  [SET_LOADING] (state, value) {
    state.loading = value
  },
  [SET_COMPANY] (state, payload) {
    state.company = payload
  },
  [ADD_COMPANY] (state, payload) {
    state.company = payload
  },
  [ADD_MEMBER] (state, payload) {
    state.company = payload
  },
  [UPDATE_COMPANY] (state, payload) {
    state.company = payload
    EventBus.$emit('refresh-case-models')
  },
  [MODIFY_CASE_MODEL_CATEGORIES_COMPANY] (state, payload) {
    state.company = payload
    // When creating, deleting or updating a category in the company/group,
    // all case models need to receive the updated company/group in shared with,
    // because they no longer contain actual version of the company/group in the sharedWith.company/group array in the store.
    // The backend populates sharedWith.company/group and the case models are respectively outdated.
    store.state.companyCaseForms.companyCaseForms.forEach(cf => {
      const index = cf.sharedWith.company.findIndex(c => c._id === payload._id)
      if (index !== -1) {
        cf.sharedWith.company[index] = payload
      }
    })
  },
  [MODIFY_CASE_MODEL_CATEGORIES_GROUP] (state, payload) {
    state.company = payload
    // When creating, deleting or updating a category in the company/group,
    // all case models need to receive the updated company/group in shared with,
    // because they no longer contain actual version of the company/group in the sharedWith.company/group array in the store.
    // The backend populates sharedWith.company/group and the case models are respectively outdated.
    store.state.groupCaseForms.groupCaseForms.forEach(cf => {
      payload.groups.forEach(group => {
        const index = cf.sharedWith.groups.findIndex(g => g._id === group._id)
        if (index !== -1) {
          cf.sharedWith.groups[index] = group
        }
      })
    })
  },
  [DELETE_COMPANY] (state, payload) {
    state.company = null
  },
  [DELETE_GROUP] (state, _id) {
    const index = state.company.groups.findIndex(g => g._id === _id)
    state.company.groups.splice(index, 1)
  },
  [RESET_COMPANY] (state) {
    state.company = null
    state.companySubscriptions = []
  },
  [NAVIGATE_TO_DASHBOARD] (state) {
    if (router && router.history.current.path === '/members') router.push('/dashboard')
  }
}

const actions = {
  setCompany ({ commit }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getCompany`)
        commit(SET_COMPANY, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  addCompany ({ commit }, payload) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/createCompany`, payload)
        if (data.success) {
          commit(SET_COMPANY, data.data)
          store.dispatch('auth/removeToken')
          store.dispatch('auth/setToken', data)
          // let account = JSON.parse(localStorage.getItem('account'))
          let account = store.state.account.account
          const _id = account._id
          store.dispatch('account/fetchAccount', { _id })
          resolve(data)
        } else {
          reject(data)
        }
        if (data.msg === 'Successfully created new company.') {
          store.dispatch('toasts/addToast', {
            title: i18n.t('message|company_created_success'),
            color: 'white',
            snackbarColor: 'success'
          })
        }
      } catch (error) {
        printError(error.response.data)
        reject(error)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateCompany ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCompany/${_id}`, payload)
        if (data.success) {
          commit(UPDATE_COMPANY, data.data)
          resolve(data)
          if (data.msg === 'message|new_role_created_success') {
            store.dispatch('toasts/addToast', {
              title: i18n.t(data.msg),
              color: 'white',
              snackbarColor: 'success'
            })
          } else {
            store.dispatch('toasts/addToast', {
              title: i18n.t('message|company_profile_update_success'),
              color: 'white',
              snackbarColor: 'success'
            })
          }
        } else {
          reject(data)
        }
        commit(UPDATE_COMPANY, data.data)
        resolve(data)
      } catch (e) {
        reject(e)
      }
    })
  },
  updateCompanyMemberSeats ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCompanyMemberSeats/${_id}`, payload)
        if (data.success) {
          commit(SET_COMPANY, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      }
    })
  },
  deleteTheCompany ({ commit }, { _id, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        await axios.post(`/deleteCompany/${_id}`, payload)
        commit(DELETE_COMPANY, _id)
        setTimeout(() => {
          if (router && router.history.current.path === '/members') commit(NAVIGATE_TO_DASHBOARD)
        }, 1000)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  fetchMembers ({ commit }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getMembers`)
        commit(SET_COMPANY, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  addMember ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/addMember/${_id}`, payload)
        commit(ADD_MEMBER, data.data)
        resolve(data)
      } catch (e) {
        printError(e.response.data)
        reject(e)
      }
    })
  },
  deleteMember ({ commit }, { _id, companyId } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/removeMember/${_id}/${companyId}/`)
        commit(UPDATE_COMPANY, data.data)
        resolve(data)
      } catch (e) {
        printError(e.response.data)
        reject(e)
      }
    })
  },
  createGroup ({ commit }, { _id, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/createNewGroup/${_id}`, payload)
        if (data.success) {
          commit(UPDATE_COMPANY, data.data)
          resolve(data)
        } else {
          reject(data)
        }
        if (data.msg && data.msg === 'message|new_group_created_success') {
          store.dispatch('toasts/addToast', {
            title: i18n.t(data.msg),
            color: 'white',
            snackbarColor: 'success'
          })
        }
      } catch (error) {
        if (error.response.data.msg.type && error.response.data.msg.type === 'intended error') {
          store.dispatch('toasts/addToast', {
            title: i18n.t(error.response.data.msg.i18text),
            color: 'white',
            snackbarColor: 'error'
          })
        } else {
          printError(error.response.data)
        }
        reject(error)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  addMemberToGroup ({ commit }, { _id, groupId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/addNewMemberToGroup/${_id}/${groupId}`, payload)
        if (data.success) {
          commit(UPDATE_COMPANY, data.data)
          resolve(data)
        } else {
          reject(data)
        }
        if (data.msg === 'message|added_new_group_members_success') {
          store.dispatch('toasts/addToast', {
            title: i18n.t(data.msg),
            color: 'white',
            snackbarColor: 'success'
          })
        }
      } catch (error) {
        printError(error.response.data)
        reject(error)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteGroupMember ({ commit }, { _id, groupId } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/deleteMemberFromGroup/${_id}/${groupId}`)
        commit(UPDATE_COMPANY, data.data)
        resolve(data)
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateGroupName ({ commit }, { _id, groupId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateGroupName/${_id}/${groupId}`, payload)
        if (data.success) {
          commit(UPDATE_COMPANY, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (error) {
        reject(error)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateGroupEmail ({ commit }, { _id, groupId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateGroupEmail/${_id}/${groupId}`, payload)
        if (data.success) {
          commit(UPDATE_COMPANY, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (error) {
        reject(error)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteActualGroup ({ commit }, { _id } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        await axios.post(`/deleteGroup/${_id}`)
        commit(DELETE_GROUP, _id)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  addCompanyTemplateCategories ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/setCompanyTemplateCategories/${_id}`, payload)
        commit(UPDATE_COMPANY, data.data)
        resolve(data)
      } catch (e) {
        reject(e)
      }
    })
  },
  addGroupTemplateCategories ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/setGroupTemplateCategories/${_id}`, payload)
        commit(UPDATE_COMPANY, data.data)
        resolve(data)
      } catch (e) {
        reject(e)
      }
    })
  },
  updateCompanyTemplateCategories ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/modifyCompanyTemplateCategories/${_id}`, payload)
        commit(UPDATE_COMPANY, data.data)
        resolve(data)
      } catch (e) {
        reject(e)
      }
    })
  },
  updateGroupTemplateCategories ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/modifyGroupTemplateCategories/${_id}`, payload)
        commit(UPDATE_COMPANY, data.data)
        resolve(data)
      } catch (e) {
        reject(e)
      }
    })
  },
  removeCompanyTemplateCategories ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/deleteCompanyTemplateCategories/${_id}`, payload)
        commit(UPDATE_COMPANY, data.data)
        resolve(data)
      } catch (e) {
        reject(e)
      }
    })
  },
  removeGroupTemplateCategories ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/deleteGroupTemplateCategories/${_id}`, payload)
        commit(UPDATE_COMPANY, data.data)
        resolve(data)
      } catch (e) {
        reject(e)
      }
    })
  },
  addCompanyCaseModelsCategories ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/setCompanyCaseModelsCategories/${_id}`, payload)
        commit(MODIFY_CASE_MODEL_CATEGORIES_COMPANY, data.data)
        resolve(data)
      } catch (e) {
        reject(e)
      } finally {
        EventBus.$emit('refresh-case-models')
      }
    })
  },
  addGroupCaseModelsCategories ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/setGroupCaseModelsCategories/${_id}`, payload)
        commit(MODIFY_CASE_MODEL_CATEGORIES_GROUP, data.data)
        resolve(data)
      } catch (e) {
        reject(e)
      } finally {
        EventBus.$emit('refresh-case-models')
      }
    })
  },
  updateCompanyCaseModelsCategories ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/modifyCompanyCaseModelCategories/${_id}`, payload)
        commit(MODIFY_CASE_MODEL_CATEGORIES_COMPANY, data.data)
        resolve(data)
      } catch (e) {
        reject(e)
      } finally {
        EventBus.$emit('refresh-case-models')
      }
    })
  },
  updateGroupCaseModelsCategories ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/modifyGroupCaseModelsCategories/${_id}`, payload)
        commit(MODIFY_CASE_MODEL_CATEGORIES_GROUP, data.data)
        resolve(data)
      } catch (e) {
        reject(e)
      } finally {
        EventBus.$emit('refresh-case-models')
      }
    })
  },
  removeCompanyCaseModelCategories ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/deleteCompanyCaseModelCategories/${_id}`, payload)
        commit(MODIFY_CASE_MODEL_CATEGORIES_COMPANY, data.data)
        resolve(data)
      } catch (e) {
        reject(e)
      } finally {
        EventBus.$emit('refresh-case-models')
      }
    })
  },
  removeGroupCaseModelsCategories ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/deleteGroupCaseModelsCategories/${_id}`, payload)
        commit(UPDATE_COMPANY, data.data)
        resolve(data)
      } catch (e) {
        reject(e)
      } finally {
        EventBus.$emit('refresh-case-models')
      }
    })
  },
  removeCompanyOrGroupInvitation ({ commit }, { _id, payload }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/removeCompanyOrGroupInvitation/${_id}`, payload)
        commit(UPDATE_COMPANY, data.data)
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  },
  rejectCompanyOrGroupInvitation ({ commit }, { _id, payload }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/declineCompanyOrGroupInvitation/${_id}`, payload)
        commit(UPDATE_COMPANY, data.data)
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  },
  cancelCompanyMemberInvitation ({ commit }, { _id, payload }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/cancelCompanyMemberInvitation/${_id}`, payload)
        commit(UPDATE_COMPANY, data.data)
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  },
  resetCompany ({ commit }) {
    commit(RESET_COMPANY)
  },
  companyUpdatesSubscription ({ commit }, { _id }) {
    try {
      const connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })

      connection.onopen = function (session) {
        // call a remote procedure
        session.subscribe(`lavvira.updates.${_id}`, async (...args) => {
          const companyTopic = args[0].includes('Company')
          const createTask = args[0].includes('create')
          const updateTask = args[0].includes('update')
          const deleteTask = args[0].includes('delete')
          // const removeTask = args[0].includes('remove')
          if (companyTopic && createTask) {
            commit(ADD_COMPANY, args[0][0])
          } else if (companyTopic && updateTask) {
            let _company
            if (store.state.company.company && store.state.company.company._id === args[0][0]._id) {
              _company = store.state.company.company
            }
            if (_company) {
              commit(UPDATE_COMPANY, args[0][0])
            } else {
              // implemet 'share task'!!! to be clear
              commit(UPDATE_COMPANY, args[0][0])
            }
          } else if (companyTopic && deleteTask) {
            commit(DELETE_COMPANY, args[0][0])
            if (router && router.history.current.path === '/members') router.push('/dashboard')
          }
          if (args[0][4] && Object.keys(args[0][4]).length && args[0][4].newGroup) {
            EventBus.$emit('new-group-subscription', args[0][4].newGroupId)
          }
          if (args[0][4] && Object.keys(args[0][4]).length && args[0][4].newMembersAddedToGroup) {
            const index = args[0][4].accountsAddedToGroup.findIndex(id => id === store.state.account.account._id)
            if (index !== -1) {
              EventBus.$emit('new-group-subscription', args[0][4].groupId)
            }
          }
          if (args[0][4] && Object.keys(args[0][4]).length && args[0][4].updateType === 'setCompanyCaseModelsCategories') {
            commit(MODIFY_CASE_MODEL_CATEGORIES_COMPANY, args[0][0])
          }
          if (args[0][4] && Object.keys(args[0][4]).length && args[0][4].updateType === 'addGroupCaseModelCategories') {
            commit(MODIFY_CASE_MODEL_CATEGORIES_GROUP, args[0][0])
          }
          if (args[0][4] && Object.keys(args[0][4]).length && args[0][4].updateType === 'deleteCompanyCaseModelCategories') {
            commit(MODIFY_CASE_MODEL_CATEGORIES_COMPANY, args[0][0])
          }
          if (args[0][4] && Object.keys(args[0][4]).length && args[0][4].updateType === 'deleteGroupCaseModelsCategories') {
            commit(MODIFY_CASE_MODEL_CATEGORIES_GROUP, args[0][0])
          }
          if (args[0][4] && Object.keys(args[0][4]).length && args[0][4].updateType === 'modifyCompanyCaseModelsCategories') {
            commit(MODIFY_CASE_MODEL_CATEGORIES_COMPANY, args[0][0])
          }
          if (args[0][4] && Object.keys(args[0][4]).length && args[0][4].updateType === 'modifyGroupCaseModelsCategories') {
            commit(MODIFY_CASE_MODEL_CATEGORIES_GROUP, args[0][0])
          }
        }).then(
          function (subscription) {
            store.state.company.companySubscriptions.push(subscription)
          }
        )
      }

      connection.onclose = function (reason, details) {
        // handle connection lost
        console.log(reason, details, 'connection.onclose')
      }
      connection.open()
    } catch (e) {
      console.error(e, 'ERROR in autobahn connection')
    }
  },
  unsubscribeCompanyUpdate ({ commit }, sub) {
    sub.unsubscribe().then(
      function (gone) {
        if (gone) commit(RESET_COMPANY)
        // successfully unsubscribed
      },
      function (error) {
        // unsubscribe failed
        console.error(error, 'error unsubscribe')
      }
    )
  }
}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions
}
