import Vue from 'vue'
import Vuex from 'vuex'

import settings from './modules/settings.js'
import app from './modules/app.js'
import auth from './modules/auth.js'
import editorStore from './modules/editorStore.js'
import account from './modules/account.js'
import clients from './modules/clients.js'
import companyClients from './modules/companyClients.js'
import groupClients from './modules/groupClients.js'
import cases from './modules/cases.js'
import companyCases from './modules/companyCases.js'
import groupCases from './modules/groupCases.js'
import templates from './modules/templates.js'
import companyTemplates from './modules/companyTemplates.js'
import groupTemplates from './modules/groupTemplates.js'
import toasts from './modules/toasts.js'
import questionnaire from './modules/questionnaire'
import caseForms from './modules/caseForms.js'
import companyCaseForms from './modules/companyCaseForms.js'
import groupCaseForms from './modules/groupCaseForms.js'
import documents from './modules/documents.js'
import companyDocuments from './modules/companyDocuments.js'
import groupDocuments from './modules/groupDocuments.js'
import company from './modules/company.js'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    settings,
    app,
    auth,
    editorStore,
    account,
    clients,
    companyClients,
    groupClients,
    cases,
    companyCases,
    groupCases,
    templates,
    companyTemplates,
    groupTemplates,
    caseForms,
    companyCaseForms,
    groupCaseForms,
    documents,
    companyDocuments,
    groupDocuments,
    company,
    toasts,
    questionnaire
  }
})

export default store
