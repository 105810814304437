<template>
  <v-snackbar
    v-model="isVisible"
    :color="data.snackbarColor"
    top
  >
    <span
      :style="`color: ${data.color}`"
      :class="`${data.color}--text`"
      class="d-flex align-center"
    >
      <v-icon
        v-if="data.snackbarColor === 'success'"
        color="white"
        class="mr-3"
      >
        mdi-check-circle-outline
      </v-icon>
      <v-icon
        v-if="data.snackbarColor === 'warning'"
        color="white"
        class="mr-3"
      >
        mdi-alert-octagon-outline
      </v-icon>
      <v-icon
        v-if="data.snackbarColor === 'error'"
        color="white"
        class="mr-3"
      >
        mdi-alert-circle-outline
      </v-icon>
      {{ data.title }}
      <v-btn
        icon
        x-small
        right
        class="ml-6"
        @click="close"
      >
        <v-icon
          :color="data.color"
        >
          mdi-close
        </v-icon>
      </v-btn>
    </span>
  </v-snackbar>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      isVisible: false,
      timer: null
    }
  },
  mounted () {
    this.isVisible = true
    const expire = this.data.expire
    this.timer = setTimeout(() => {
      this.isVisible = false
      this.deleteToast(this.data)
    }, expire)
  },
  methods: {
    ...mapActions({
      deleteToast: 'toasts/deleteToast'
    }),
    close () {
      clearTimeout(this.timer)
      this.isVisible = false
      this.deleteToast(this.data)
    }
  }
}
</script>
