<template>
  <v-container
    class="pa-0"
  >
    <v-card
      id="panel"
      outlined
      elevation="1"
    >
      <v-card-text
        style="height:100%"
      >
        <v-container
          fill-height
          class="pa-0"
        >
          <v-row
            align="center"
          >
            <v-icon
              color="primary"
            >
              mdi-file-outline
            </v-icon>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-hover
                  v-slot="{ hover }"
                >
                  <div
                    v-bind="attrs"
                    v-on="on"
                  >
                    <input
                      v-if="template"
                      v-model="template.name"
                      class="ml-3 pl-2 rounded input"
                      :style="hover && !flatFields.includes(template.name) ? 'background-color: #eee;' : flatFields.includes(template.name) ? 'background-color: #CFD8DC' : ''"
                      @focus="flatFields.push(template.name)"
                      @blur="$emit('update-template-name', template.name); flatFields.splice(0, 1)"
                    >
                  </div>
                </v-hover>
              </template>
              <span>{{ $t('actions|change_template_name') }}</span>
            </v-tooltip>

            <div
              v-if="connectionStatus === $t('mixed|you_are_connected') || connectionStatus === 'You are synced!'"
              class="ml-3"
            >
              <div v-if="loadingSavingData">
                <v-icon
                  color="primary"
                  small
                >
                  mdi-sync
                </v-icon>
                <span
                  class="ml-1"
                  style="font-size: 14px;"
                >
                  {{ $t('editor|saving') }}...
                </span>
              </div>
              <div v-if="!loadingSavingData">
                <v-icon
                  color="primary"
                  small
                >
                  mdi-cloud-check
                </v-icon>
                <span
                  class="ml-1"
                  style="font-size: 14px;"
                >
                  {{ $t('editor|saved') }}
                </span>
              </div>
            </div>

            <div
              class="ml-3"
              style="font-size: 14px;"
            >
              <v-progress-circular
                v-if="connectionStatus === 'Connecting...'"
                indeterminate
                color="primary"
              />
              <v-icon
                v-if="connectionStatus === $t('mixed|you_are_connected') || connectionStatus === 'You are synced!'"
                color="primary"
                small
              >
                mdi-access-point-network
              </v-icon>
              <v-icon
                v-if="connectionStatus === $t('mixed|you_are_not_connected')"
                color="error"
                small
              >
                mdi-access-point-network-off
              </v-icon>
              {{ connectionStatus === 'You are synced!' ? $t('mixed|you_are_connected') : connectionStatus }}
            </div>

            <!-- Additional info template section only -->
            <v-menu
              v-if="inTemplateOnly"
              offset-y
              bottom
              dense
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="account.accountType === 'lawyer'"
                  v-bind="attrs"
                  style="position: absolute; right: 10px;"
                  v-on="on"
                >
                  mdi-information-variant
                </v-icon>
              </template>
              <v-card
                class="mx-auto"
                min-width="300"
                max-width="350"
                tile
              >
                <div>
                  <v-col>
                    <div>
                      <div
                        class="font-weight-light subtitle-2"
                      >
                        {{ $t('common|category') }}: {{ translateCategoryIfNeeded(template.category) }}
                        <v-icon
                          class="ml-2"
                          size="17"
                          @click="updateCategory(template.category)"
                        >
                          mdi-pencil
                        </v-icon>
                      </div>
                      <div
                        class="font-weight-light subtitle-2"
                      >
                        {{ $t('common|description') }}: {{ template.description }}
                        <v-icon
                          class="ml-2"
                          size="17"
                          @click="$emit('change-template-description')"
                        >
                          mdi-pencil
                        </v-icon>
                      </div>
                      <div
                        class="font-weight-light subtitle-2"
                      >
                        {{ $t('common|date_created') }}: {{ template.createdAt | date }}
                      </div>
                      <div
                        class="font-weight-light subtitle-2"
                      >
                        {{ $t('common|date_updated') }}: {{ template.updatedAt | date }}
                      </div>
                    </div>
                  </v-col>
                </div>
              </v-card>
            </v-menu>
          </v-row>
          <v-row
            align="center"
          >
            <!-- Menu section => File/Edit/General Search input field -->
            <v-menu
              offset-y
              bottom
              dense
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="btn"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t('file|file') }}
                </v-btn>
              </template>
              <v-card
                class="mx-auto"
                min-width="300"
                max-width="350"
                tile
              >
                <v-list
                  dense
                >
                  <v-list-item-group
                    v-model="selectedItemFileMenu"
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in fileMenuItems"
                      :key="i"
                      :disabled="item.disabled"
                      @click="$emit(item.action)"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon" />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-menu>

            <v-menu
              offset-y
              bottom
              dense
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="btn"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t('actions|view') }}
                </v-btn>
              </template>
              <v-card
                class="mx-auto"
                min-width="300"
                max-width="350"
                tile
              >
                <v-list
                  dense
                >
                  <v-list-item-group
                    v-model="selectedItemEditMenu"
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in viewMenuItems"
                      :key="i"
                      @click="$emit(item.action)"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon" />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-menu>

            <v-menu
              offset-y
              bottom
              dense
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  small
                  class="btn"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t('actions|edit') }}
                </v-btn>
              </template>
              <v-card
                class="mx-auto"
                min-width="300"
                max-width="350"
                tile
              >
                <v-list
                  dense
                >
                  <v-list-item-group
                    v-model="selectedItemEditMenu"
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in editMenuItems"
                      :key="i"
                      @click="$emit(item.action)"
                    >
                      <v-list-item-icon>
                        <v-icon
                          small
                          v-text="item.icon"
                        />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text" />
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text v-text="item.shortCut" />
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-menu>

            <!-- General search -->
            <v-spacer />
            <v-menu
              v-model="searchDialogModel"
              transition="scale-transition"
              scrollable
              bottom
              rounded
              outlined
              :open-on-click="false"
              :offset-y="true"
              :close-on-content-click="false"
              min-width="600px"
              color="background"
              content-class="vdialognew hide-scrollbar-chrome hide-scrollbar-firefox outlined"
              @keydown.esc="closeDialogEscapeKey()"
            >
              <template v-slot:activator="{ on }">
                <div class="text-left mb-1">
                  <v-text-field
                    v-model="searchInputGeneralSearch"
                    dense
                    hide-details
                    rounded="rounded"
                    light
                    background-color="background"
                    style="font-size: 0.8em"
                    v-on="on"
                    @keydown.esc="closeReset"
                  >
                    <v-input
                      slot="label"
                      style="font-size: 13px;"
                    >
                      {{ $t('mixed|general_search') }}:
                    </v-input>
                    <v-icon
                      slot="prepend-inner"
                      size="18"
                      class="ml-n3 mt-1"
                    >
                      mdi-magnify
                    </v-icon>
                    <v-icon
                      v-if="searchInputGeneralSearch"
                      slot="append"
                      size="18"
                      class="mt-1"
                      @click="clearInput()"
                    >
                      mdi-close
                    </v-icon>
                  </v-text-field>
                </div>
              </template>
              <search-dialog
                :search-input="searchInputGeneralSearch"
                :from-ribbon-panel="true"
                @closeReset="closeReset()"
              />
            </v-menu>
          </v-row>

          <v-row>
            <v-divider
              class="mb-2"
            />
          </v-row>
          <!-- 3 row/tools -->
          <v-row
            ref="toolbarGroupContainer"
            class="toolbar-group-container pl-3"
            align="center"
          >
            <div
              class="toolbar-group"
            >
              <v-tooltip
                top
                color="black"
                open-delay="700"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    size="15"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('undo')"
                  >
                    mdi-undo
                  </v-icon>
                </template>
                <span>{{ $t('editor|tooltip_undo') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="black"
                open-delay="700"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    size="15"
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('redo')"
                  >
                    mdi-redo
                  </v-icon>
                </template>
                <span>{{ $t('editor|tooltip_redo') }}</span>
              </v-tooltip>
            </div>

            <div class="toolbar-group btn-row">
              <v-divider
                vertical
                class="mx-2"
              />

              <v-menu offset-y>
                <template #activator="{ on: onMenu }">
                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template #activator="{ on: onTooltip }">
                      <v-btn
                        small
                        text
                        plain
                        class="px-1 btn"
                        v-on="{ ...onMenu, ...onTooltip }"
                      >
                        {{ getActiveHeading }}
                        <v-icon>
                          mdi-menu-down
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('editor|tooltip_set_paragraph_style') }}</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in headings"
                    :key="index"
                    @click="$emit('select-heading', item)"
                  >
                    <h1
                      v-if="item === 'Heading 1'"
                    >
                      {{ $t('editor|heading') }} 1
                    </h1>
                    <h2
                      v-if="item === 'Heading 2'"
                    >
                      {{ $t('editor|heading') }} 2
                    </h2>
                    <h3
                      v-if="item === 'Heading 3'"
                    >
                      {{ $t('editor|heading') }} 3
                    </h3>
                    <h4
                      v-if="item === 'Heading 4'"
                    >
                      {{ $t('editor|heading') }} 4
                    </h4>
                    <h5
                      v-if="item === 'Heading 5'"
                    >
                      {{ $t('editor|heading') }} 5
                    </h5>
                    <h6
                      v-if="item === 'Heading 6'"
                    >
                      {{ $t('editor|heading') }} 6
                    </h6>
                    <v-list-item-title
                      v-if="item === 'Normal text'"
                      style="font-size: 12px;"
                    >
                      {{ $t('editor|normal_text') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-divider
                vertical
                class="mx-2"
              />

              <v-menu offset-y>
                <template #activator="{ on: onMenu }">
                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template #activator="{ on: onTooltip }">
                      <v-btn
                        small
                        text
                        plain
                        class="px-1 btn"
                        :style="`font-family: ${getActiveFont}`"
                        v-on="{ ...onMenu, ...onTooltip }"
                      >
                        {{ getActiveFont }}
                        <v-icon>
                          mdi-menu-down
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('editor|tooltip_font_name') }}</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(font, index) in fonts"
                    :key="index"
                    class="px-8"
                    @click="$emit('font-family', font)"
                  >
                    <v-list-item-title
                      :style="`font-family: ${font.label}`"
                    >
                      {{ font.label }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-divider
                vertical
                class="mx-2"
              />
            </div>
            <v-tooltip
              top
              color="black"
              open-delay="700"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="toolbar-group btn-row"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    small
                    class="text-size-box-icon ml-1"
                    @click="textSize > 6 ? textSize-- : textSize = 6; $emit('text-size', textSize)"
                  >
                    mdi-minus
                  </v-icon>
                  <v-menu
                    offset-y
                    dense
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        :class="textSize > 9 ? 'text-size-box' : 'text-size-box-small-number'"
                        v-on="on"
                      >
                        <span
                          style="position: relative; left: 38%; transform: translateX(-38%);"
                        >
                          {{ textSize }}
                        </span>
                      </div>
                    </template>
                    <v-list class="scroll-num-list">
                      <v-list-item
                        v-for="(num, i) in numbers"
                        :key="i"
                        @click="$emit('text-size', num); textSize = num"
                      >
                        <v-list-item-title>{{ num }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-icon
                    small
                    class="text-size-box-icon mr-1"
                    @click="textSize < 96 ? textSize++ : textSize = textSize; $emit('text-size', textSize)"
                  >
                    mdi-plus
                  </v-icon>
                </div>
              </template>
              <span>{{ $t('editor|tooltip_font_size') }}</span>
            </v-tooltip>
            <portal
              to="editor-toolbar-ellipses"
              class="toolbar-group btn-row"
              data-toolbar="1"
              :disabled="!ellipsesToolbars[1]"
            >
              <v-divider
                class="mx-2"
                vertical
              />

              <v-tooltip
                top
                color="black"
                open-delay="700"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    id="format-bold"
                    size="22"
                    :class="{ 'blue lighten-5': editor.isActive('bold') }"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('bold')"
                  >
                    mdi-format-bold
                  </v-icon>
                </template>
                <span>{{ $t('editor|tooltip_bold') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="black"
                open-delay="700"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    id="format-italic"
                    class="ml-1"
                    size="22"
                    :class="{ 'blue lighten-5': editor.isActive('italic') }"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('italic')"
                  >
                    mdi-format-italic
                  </v-icon>
                </template>
                <span>{{ $t('editor|tooltip_italic') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="black"
                open-delay="700"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    id="format-underline"
                    class="ml-1"
                    size="19"
                    :class="{ 'blue lighten-5': editor.isActive('underline') }"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('underline')"
                  >
                    mdi-format-underline
                  </v-icon>
                </template>
                <span>{{ $t('editor|tooltip_underline') }}</span>
              </v-tooltip>

              <v-menu
                bottom
                dense
                transition="scale-transition"
                offset-y
              >
                <template #activator="{ on: onMenu }">
                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template #activator="{ on: onTooltip }">
                      <v-icon
                        id="format-color-text"
                        class="ml-1 mt-1"
                        size="23"
                        :color="editor.getAttributes('textStyle').color"
                        v-on="{...onMenu, ...onTooltip}"
                      >
                        mdi-format-color-text
                      </v-icon>
                    </template>
                    <span>{{ $t('editor|tooltip_font_color') }}</span>
                  </v-tooltip>
                </template>
                <CustomSwatches
                  :editor="editor"
                  :color-text="true"
                  @new-selected-color="newSelectedFontColor"
                />
              </v-menu>

              <v-menu
                bottom
                dense
                transition="scale-transition"
                offset-y
              >
                <template #activator="{ on: onMenu }">
                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template #activator="{ on: onTooltip }">
                      <v-icon
                        id="color-highlight"
                        class="mt-1"
                        :color="editor.getAttributes('textStyle').backgroundColor"
                        size="23"
                        v-on="{...onMenu, ...onTooltip}"
                      >
                        mdi-format-color-highlight
                      </v-icon>
                    </template>
                    <span>{{ $t('editor|tooltip_background_color') }}</span>
                  </v-tooltip>
                </template>
                <CustomSwatches
                  :editor="editor"
                  :color-highlight="true"
                  @new-selected-color="newSelectedBackgroundColor"
                />
              </v-menu>
            </portal>

            <portal
              to="editor-toolbar-ellipses"
              class="toolbar-group btn-row"
              data-toolbar="2"
              :disabled="!ellipsesToolbars[2]"
            >
              <v-divider
                class="mx-2"
                vertical
              />

              <v-menu
                v-model="alignMenu"
                bottom
                open-on-hover
                offset-y
                dense
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    id="align-left"
                    class="ml-2"
                    size="20"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-format-align-left
                  </v-icon>
                </template>
                <v-sheet
                  color="white"
                  class="pa-2"
                >
                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mx-1"
                        :class="{ 'blue lighten-5': editor.isActive('paragraph', { textAlign: 'left' }) }"
                        v-bind="attrs"
                        v-on="on"
                        @click="$emit('align-left')"
                      >
                        mdi-format-align-left
                      </v-icon>
                    </template>
                    <span>{{ $t('editor|tooltip_align_left') }}</span>
                  </v-tooltip>

                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mx-1"
                        :class="{ 'blue lighten-5': editor.isActive('paragraph', { textAlign: 'center' }) }"
                        v-bind="attrs"
                        v-on="on"
                        @click="$emit('align-center')"
                      >
                        mdi-format-align-center
                      </v-icon>
                    </template>
                    <span>{{ $t('editor|tooltip_align_center') }}</span>
                  </v-tooltip>

                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mx-1"
                        :class="{ 'blue lighten-5': editor.isActive('paragraph', { textAlign: 'justify' }) }"
                        v-bind="attrs"
                        v-on="on"
                        @click="$emit('align-justify')"
                      >
                        mdi-format-align-justify
                      </v-icon>
                    </template>
                    <span>{{ $t('editor|tooltip_justified') }}</span>
                  </v-tooltip>

                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mx-1"
                        :class="{ 'blue lighten-5': editor.isActive('paragraph', { textAlign: 'right' }) }"
                        v-bind="attrs"
                        v-on="on"
                        @click="$emit('align-right')"
                      >
                        mdi-format-align-right
                      </v-icon>
                    </template>
                    <span>{{ $t('editor|tooltip_align_right') }}</span>
                  </v-tooltip>
                </v-sheet>
              </v-menu>

              <v-menu
                bottom
                offset-y
                dense
                transition="scale-transition"
              >
                <template #activator="{ on: onMenu }">
                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template #activator="{ on: onTooltip }">
                      <v-icon
                        id="line-spacing"
                        class="ml-2"
                        v-on="{ ...onMenu, ...onTooltip }"
                      >
                        mdi-format-line-spacing
                      </v-icon>
                    </template>
                    <span>{{ $t('editor|tooltip_set_line_spacing') }}</span>
                  </v-tooltip>
                </template>
                <v-list
                  class="scroll-num-list text-center"
                  min-width="150"
                >
                  <v-list-item
                    v-for="(item, index) in lineSpacingItems"
                    :key="index"
                    @click="$emit('line-spacing', item.text)"
                  >
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-tooltip
                top
                color="black"
                open-delay="700"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    id="list-numbered"
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('numbered-list')"
                  >
                    mdi-format-list-numbered
                  </v-icon>
                </template>
                <span>{{ $t('editor|tooltip_numbered_list') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="black"
                open-delay="700"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    id="list-bulleted"
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('bulleted-list')"
                  >
                    mdi-format-list-bulleted
                  </v-icon>
                </template>
                <span>{{ $t('editor|tooltip_bulleted_list') }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="!isActiveTable"
                top
                color="black"
                open-delay="700"
              >
                <template v-slot:activator="{ on: onTooltip }">
                  <v-icon
                    v-if="!isActiveTable"
                    id="insert-table"
                    class="ml-2"
                    v-on="{ ...onTooltip }"
                    @click="$emit('insert-table')"
                  >
                    mdi-table
                  </v-icon>
                </template>
                <span>{{ $t('common|table') }}</span>
              </v-tooltip>

              <v-menu
                v-if="isActiveTable"
                v-model="tableMenu"
                bottom
                :close-on-content-click="false"
                :close-on-click="false"
                offset-y
                dense
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    id="insert-table"
                    v-bind="attrs"
                    class="ml-2"
                    v-on="on"
                    @click="$emit('insert-table')"
                  >
                    mdi-table
                  </v-icon>
                </template>

                <v-sheet
                  v-if="editor.isActive('table')"
                  color="white"
                  class="pa-2"
                >
                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        class="mx-1"
                        v-on="on"
                        @click="$emit('add-row-after')"
                      >
                        mdi-table-row-plus-after
                      </v-icon>
                    </template>
                    <span>{{ $t('table|add_row_after') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        class="mx-1"
                        v-on="on"
                        @click="$emit('add-row-before')"
                      >
                        mdi-table-row-plus-before
                      </v-icon>
                    </template>
                    <span>{{ $t('table|add_row_before') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        class="mx-1"
                        v-on="on"
                        @click="$emit('remove-row')"
                      >
                        mdi-table-row-remove
                      </v-icon>
                    </template>
                    <span>{{ $t('table|remove_row') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        class="mx-1"
                        v-on="on"
                        @click="$emit('add-col-before')"
                      >
                        mdi-table-column-plus-before
                      </v-icon>
                    </template>
                    <span>{{ $t('table|add_column_before') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        class="mx-1"
                        v-on="on"
                        @click="$emit('add-col-after')"
                      >
                        mdi-table-column-plus-after
                      </v-icon>
                    </template>
                    <span>{{ $t('table|add_column_after') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        class="mx-1"
                        v-on="on"
                        @click="$emit('remove-col')"
                      >
                        mdi-table-column-remove
                      </v-icon>
                    </template>
                    <span>{{ $t('table|remove_column') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        class="mx-1"
                        v-on="on"
                        @click="$emit('split-cell')"
                      >
                        mdi-table-split-cell
                      </v-icon>
                    </template>
                    <span>{{ $t('table|split_cell') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        class="mx-1"
                        v-on="on"
                        @click="$emit('merge-cells')"
                      >
                        mdi-table-merge-cells
                      </v-icon>
                    </template>
                    <span>{{ $t('table|merge_cells') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        class="mx-1"
                        v-on="on"
                        @click="$emit('toggle-header-row')"
                      >
                        mdi-table-border
                      </v-icon>
                    </template>
                    <span>{{ $t('table|toggle_header_row') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        class="mx-1"
                        v-on="on"
                        @click="$emit('toggle-header-col')"
                      >
                        {{ headerEye }}
                      </v-icon>
                    </template>
                    <span>{{ $t('table|toggle_header_column') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        class="mx-1"
                        v-on="on"
                        @click="$emit('toggle-header-cell')"
                      >
                        mdi-rectangle
                      </v-icon>
                    </template>
                    <span>{{ $t('table|toggle_header_cell') }}</span>
                  </v-tooltip>

                  <v-menu
                    bottom
                    transition="scale-transition"
                  >
                    <template #activator="{ on: onMenu }">
                      <v-tooltip
                        top
                        color="black"
                        open-delay="700"
                      >
                        <template #activator="{ on: onTooltip }">
                          <v-icon
                            class="ml-1 mt-2"
                            :color="selectedTableColor === '#FFFFFFFF' || selectedTableColor === '#00000000' ? 'f8f8f8' : selectedTableColor"
                            size="23"
                            v-on="{ ...onMenu, ...onTooltip }"
                          >
                            mdi-format-color-fill
                          </v-icon>
                        </template>
                        <span>{{ $t('editor|tooltip_table_cell_color') }}</span>
                      </v-tooltip>
                    </template>
                    <v-color-picker
                      v-model="selectedTableColor"
                      dot-size="28"
                      hide-canvas
                      hide-inputs
                      hide-sliders
                      mode="rgba"
                      show-swatches
                      swatches-max-height="200"
                      @input="$emit('set-table-cell-color', selectedTableColor); selectedTableColor = ''"
                    />
                  </v-menu>

                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        class="mx-1"
                        v-on="on"
                        @click="$emit('delete-table')"
                      >
                        mdi-table-remove
                      </v-icon>
                    </template>
                    <span>{{ $t('table|delete_table') }}</span>
                  </v-tooltip>
                </v-sheet>
              </v-menu>
            </portal>

            <portal
              to="editor-toolbar-ellipses"
              class="toolbar-group btn-row"
              data-toolbar="3"
              :disabled="!ellipsesToolbars[3]"
            >
              <v-divider
                class="mx-2"
                vertical
              />

              <v-tooltip
                top
                color="black"
                open-delay="700"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-1"
                    size="20"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('drop-cap')"
                  >
                    mdi-caps-lock
                  </v-icon>
                </template>
                <span>{{ $t('editor|tooltip_drop_cap') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="black"
                open-delay="700"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-1"
                    size="18"
                    :class="{ 'blue lighten-5': editor.isActive('strike') }"
                    v-bind="attrs"
                    v-on="on"
                    @click="editor.isActive('strike') ? $emit('unstrike-through') : $emit('strike-through')"
                  >
                    mdi-format-strikethrough-variant
                  </v-icon>
                </template>
                <span>{{ $t('editor|tooltip_strikethrough') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="black"
                open-delay="700"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-1"
                    size="20"
                    :class="{ 'blue lighten-5': editor.isActive('superscript') }"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('toggle-superscript')"
                  >
                    mdi-format-superscript
                  </v-icon>
                </template>
                <span>{{ $t('editor|tooltip_superscript') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="black"
                open-delay="700"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-1"
                    size="20"
                    :class="{ 'blue lighten-5': editor.isActive('subscript') }"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('toggle-subscript')"
                  >
                    mdi-format-subscript
                  </v-icon>
                </template>
                <span>{{ $t('editor|tooltip_subscript') }}</span>
              </v-tooltip>

              <v-divider
                vertical
                class="mx-2"
              />

              <v-tooltip
                top
                color="black"
                open-delay="700"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    id="add-suggestion"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('add-suggestion')"
                  >
                    mdi-at
                  </v-icon>
                </template>
                <span>{{ $t('editor|tooltip_add_suggestion') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="black"
                open-delay="700"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          id="suggestion-language"
                          class="ml-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-web
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in suggestionLanguages"
                          :key="index"
                          @click="$emit('change-suggestion-language', item)"
                        >
                          <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </template>
                <span>{{ $t('editor|change_suggestion_language') }}</span>
              </v-tooltip>
            </portal>

            <portal
              to="editor-toolbar-ellipses"
              class="toolbar-group btn-row"
              data-toolbar="4"
              :disabled="!ellipsesToolbars[4]"
            >
              <v-divider
                v-if="!ellipsesToolbars[4]"
                class="mx-2"
                vertical
              />

              <v-tooltip
                top
                color="black"
                open-delay="700"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      id="comment-outline"
                      class="ml-2"
                      :disabled="disableCommentInTemplate"
                      @click="$emit('add-comment')"
                    >
                      mdi-comment-outline
                    </v-icon>
                  </div>
                </template>
                <span v-if="disableCommentInTemplate">{{ $t('editor|comment_only_in_case') }}</span>
                <span v-else>{{ $t('editor|add_comment') }}</span>
              </v-tooltip>

              <v-menu
                v-model="searchMenu"
                bottom
                dense
                :close-on-content-click="false"
                :nudge-width="400"
                offset-y
                transition="scale-transition"
              >
                <template #activator="{ on: onMenu }">
                  <v-tooltip
                    top
                    color="black"
                    open-delay="700"
                  >
                    <template #activator="{ on: onTooltip }">
                      <v-icon
                        id="magnify"
                        class="ml-2"
                        v-on="{ ...onMenu, ...onTooltip }"
                      >
                        mdi-magnify
                      </v-icon>
                    </template>
                    <span>{{ $t('editor|tooltip_search_replace') }}</span>
                  </v-tooltip>
                </template>
                <v-card
                  class="mx-auto py-2"
                  width="100%"
                  outlined
                >
                  <v-row
                    no-gutters
                    justify="center"
                    align="center"
                  >
                    <v-col
                      cols="8"
                    >
                      <div
                        class="text-left my-2 mx-2"
                        style="width: 85%"
                      >
                        <v-text-field
                          v-model="searchInput"
                          dense
                          hide-details
                          rounded
                          outlined
                          height="20"
                          style="font-size: 0.6em; pa-0"
                          light
                        >
                          <v-input
                            slot="label"
                            style="font-size: 12px;"
                          >
                            {{ $t('common|search') }}...
                          </v-input>
                        </v-text-field>
                      </div>
                      <div
                        class="text-left mt-4 mb-2 mx-2"
                        style="width: 85%"
                      >
                        <v-text-field
                          v-model="replaceWith"
                          dense
                          hide-details
                          rounded
                          outlined
                          height="20"
                          style="font-size: 0.6em; pa-0"
                          light
                        >
                          <v-input
                            slot="label"
                            style="font-size: 12px;"
                          >
                            {{ $t('common|replace') }}...
                          </v-input>
                        </v-text-field>
                      </div>
                    </v-col>
                    <v-col
                      cols="4"
                    >
                      <v-btn
                        x-small
                        outlined
                        dense
                        class="btn"
                        @click="$emit('find-in-text', searchInput)"
                      >
                        {{ $t('common|find') }}
                      </v-btn>
                      <v-btn
                        x-small
                        outlined
                        dense
                        class="btn"
                        @click="$emit('replace-with', replaceWith, searchInput)"
                      >
                        {{ $t('common|replace') }}
                      </v-btn>
                      <v-btn
                        x-small
                        outlined
                        dense
                        class="btn"
                        @click="$emit('replace-all-with', replaceWith)"
                      >
                        {{ $t('common|replace_all') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-menu>

              <v-tooltip
                top
                color="black"
                open-delay="700"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    id="print-icon"
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('print-document')"
                  >
                    mdi-printer
                  </v-icon>
                </template>
                <span>{{ $t('editor|tooltip_print') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="black"
                open-delay="700"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    id="share-icon"
                    class="ml-2"
                    :disabled="!inTemplateOnly || !account.companyId"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('share-template')"
                  >
                    mdi-share-variant
                  </v-icon>
                </template>
                <span>{{ $t('actions|share') }}</span>
              </v-tooltip>
            </portal>

            <v-menu
              v-if="hasToolbarEllipsis"
              offset-y
              transition="scale-transition"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-dots-horizontal
                  </v-icon>
                </v-btn>
              </template>
              <v-card
                class="mx-auto"
                tile
              >
                <v-card-text
                  class="pa-2 mx-auto"
                >
                  <portal-target
                    class="portal-menu"
                    name="editor-toolbar-ellipses"
                    multiple
                  />
                </v-card-text>
              </v-card>
            </v-menu>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mdiTableHeadersEye, mdiTableHeadersEyeOff, mdiTextBoxOutline } from '@mdi/js'
import CustomSwatches from './CustomSwatches.vue'
import { EventBus } from '@/utils/EventBus'
import { debounce } from 'lodash'
import cfg from '@/config'

export default {
  components: { CustomSwatches },
  props: {
    editor: {
      type: Object,
      default: null
    },
    template: {
      type: Object,
      default: null
    },
    account: {
      type: Object,
      default: null
    },
    isActiveTable: {
      type: Boolean,
      default: false
    },
    actualFontSize: {
      type: String,
      default: null
    },
    disableCommentInTemplate: {
      type: Boolean,
      default: false
    },
    inTemplateOnly: {
      type: Boolean,
      default: false
    },
    loadingSavingData: {
      type: Boolean,
      default: false
    },
    connectionStatus: {
      type: String,
      default: null
    }
  },
  data: (instance) => ({
    searchDialogModel: false,
    flatFields: [],
    hasToolbarEllipsis: false,
    ellipsesToolbars: Object.keys({}).reverse(),
    headerEye: mdiTableHeadersEye,
    headerEyeOff: mdiTableHeadersEyeOff,
    selectedItemFileMenu: null,
    selectedItemEditMenu: null,
    searchInput: '',
    replaceWith: '',
    searchMenu: false,
    tableMenu: false,
    alignMenu: false,
    font: {
      name: 'Sofia-Sans',
      value: 'Sofia-Sans',
      label: 'Sofia-Sans',
      style: {
        fontFamily: 'Sofia-Sans'
      }
    },
    suggestionLanguages: cfg.usedLanguages,
    availableSizes: ['1', '1.15', '1.75', '2', 'etc...'],
    textSize: '11',
    numbers: [6, 8, 9, 10, 11, 12, 13, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    lineSpacingItems: [
      {
        text: '0.85'
      },
      {
        text: '1'
      },
      {
        text: 'normal'
      },
      {
        text: '1.5'
      },
      {
        text: '2'
      },
      {
        text: '3'
      },
      {
        text: '4'
      },
      {
        text: '5'
      }
    ],
    headings: ['Heading 1', 'Heading 2', 'Heading 3', 'Heading 4', 'Heading 5', 'Heading 6', 'Normal text'],
    selectedTextColor: '',
    selectedColor: '',
    selectedTableColor: '',
    selectedHeading: '',
    swatches: [
      ['#FFFFFF', '#FF0000', '#550000'],
      ['#FFFF00', '#AAAA00', '#555500'],
      ['#00FF00', '#00AA00', '#005500'],
      ['#00FFFF', '#00AAAA', '#005555'],
      ['#0000FF', '#0000AA', '#000000']
    ],
    fonts: [
      {
        name: 'Arial',
        value: 'Arial',
        label: 'Arial',
        style: {
          // optional style to apply to the item
          fontFamily: 'Arial'
        }
      },
      {
        name: 'Cambria',
        label: 'Cambria',
        value: 'Cambria',
        style: {
          // optional style to apply to the item
          fontFamily: 'Cambria'
        }
      },
      {
        name: 'Arial Black',
        label: 'Arial Black',
        value: 'Arial Black',
        style: {
          // optional style to apply to the item
          fontFamily: 'Arial Black'
        }
      },
      {
        name: 'Courier',
        label: 'Courier',
        value: 'Courier',
        style: {
          // optional style to apply to the item
          fontFamily: 'Courier'
        }
      },
      {
        name: 'Courier New',
        label: 'Courier New',
        value: 'Courier New',
        style: {
          // optional style to apply to the item
          fontFamily: 'Courier New'
        }
      },
      {
        name: 'Sofia Sans',
        label: 'Sofia Sans',
        value: 'Sofia Sans',
        style: {
          // optional style to apply to the item
          fontFamily: 'Sofia Sans'
        }
      },
      {
        name: 'Sans-Serif',
        label: 'Sans-Serif',
        value: 'Sans-Serif',
        style: {
          // optional style to apply to the item
          fontFamily: 'Sans-Serif'
        }
      },
      {
        name: 'Serif',
        label: 'Serif',
        value: 'Serif',
        style: {
          // optional style to apply to the item
          fontFamily: 'Serif'
        }
      },
      {
        name: 'Times New Roman',
        label: 'Times New Roman',
        value: 'Times New Roman',
        style: {
          // optional style to apply to the item
          fontFamily: 'Times New Roman'
        }
      },
      {
        name: 'Verdana',
        label: 'Verdana',
        value: 'Verdana',
        style: {
          // optional style to apply to the item
          fontFamily: 'Verdana'
        }
      },
      {
        name: 'Helvetica',
        label: 'Helvetica',
        value: 'Helvetica',
        style: {
          // optional style to apply to the item
          fontFamily: 'Helvetica'
        }
      },
      {
        name: 'Calibri',
        label: 'Calibri',
        value: 'Calibri',
        style: {
          // optional style to apply to the item
          fontFamily: 'Calibri'
        }
      },
      {
        name: 'Impact',
        label: 'Impact',
        value: 'Impact',
        style: {
          // optional style to apply to the item
          fontFamily: 'Impact'
        }
      }
    ],
    // hiddenIcons: [],
    window: {
      width: 0,
      height: 0
    },
    searchInputGeneralSearch: ''
  }),
  computed: {
    panelIconsIds () {
      return [
        {
          domItem: 'share-icon',
          action: undefined
        },
        {
          domItem: 'print-icon',
          action: undefined
        },
        {
          domItem: 'divider-5',
          action: undefined
        },
        {
          domItem: 'format-page',
          action: undefined
        },
        {
          domItem: 'page-layout-footer',
          action: undefined
        },
        {
          domItem: 'page-layout-header',
          action: undefined
        },
        {
          domItem: 'divider-4',
          action: undefined
        },
        {
          domItem: 'magnify',
          action: 'magnify'
        },
        {
          domItem: 'comment-outline',
          action: 'add-comment'
        },
        {
          domItem: 'add-suggestion',
          action: 'add-suggestion'
        },
        {
          domItem: 'divider-3',
          action: 'add-suggestion'
        },
        {
          domItem: 'insert-table',
          action: 'insert-table'
        },
        {
          domItem: 'list-checkbox',
          action: undefined
        },
        {
          domItem: 'list-bulleted',
          action: 'bulleted-list'
        },
        {
          domItem: 'list-numbered',
          action: 'numbered-list'
        },
        {
          domItem: 'align-left',
          action: 'align-left'
        },
        {
          domItem: 'divider-2',
          action: undefined
        },
        {
          domItem: 'color-highlight',
          action: undefined
        },
        {
          domItem: 'format-color-text',
          action: undefined
        },
        {
          domItem: 'format-underline',
          action: undefined
        },
        {
          domItem: 'format-italic',
          action: undefined
        },
        {
          domItem: 'format-bold',
          action: undefined
        }
      ]
    },
    fileMenuItems () {
      let itemsArr
      let disableShareBtn = false
      if (!this.inTemplateOnly) disableShareBtn = true
      if (this.account && !this.account.companyId) disableShareBtn = true
      if (this.account && this.account.accountType === 'lawyer') {
        itemsArr = [
          { text: this.$t('actions|save'), icon: 'mdi-content-save', action: 'save', disabled: false },
          { text: this.$t('actions|save_as'), icon: 'mdi-content-save-outline', action: 'save-as', disabled: false },
          { text: this.$t('templates|new_template'), icon: mdiTextBoxOutline, action: 'new-template', disabled: false },
          { text: this.$t('editor|export_as_word'), icon: 'mdi-export', action: 'export-template', disabled: false },
          { text: this.$t('editor|export_as_pdf'), icon: 'mdi-export', action: 'export-template-pdf', disabled: false },
          { text: this.$t('actions|share'), icon: 'mdi-share-variant', action: 'share-template', disabled: disableShareBtn },
          { text: this.$t('editor|version_history'), icon: 'mdi-history', action: 'open-version-history', disabled: true }
        ]
      }
      if (this.account && this.account.accountType !== 'lawyer') {
        itemsArr = [
          { text: this.$t('editor|export_as_word'), icon: 'mdi-export', action: 'export-template', disabled: false },
          { text: this.$t('editor|export_as_pdf'), icon: 'mdi-export', action: 'export-template-pdf', disabled: false }
        ]
      }
      return itemsArr
    },
    viewMenuItems () {
      return [
        { text: this.$t('editor|seggeustion_view_text'), icon: 'mdi-eye', action: 'change-suggestion-view-text' },
        { text: this.$t('editor|seggeustion_view_editor'), icon: 'mdi-eye-off', action: 'change-suggestion-view-editor' }
      ]
    },
    editMenuItems () {
      return [
        { text: this.$t('editor|undo'), icon: 'mdi-undo', action: 'undo', shortCut: 'Ctrl-Z', disabled: false },
        { text: this.$t('editor|redo'), icon: 'mdi-redo', action: 'redo', shortCut: 'Ctrl-Z', disabled: false },
        { text: this.$t('editor|cut'), icon: 'mdi-content-cut', action: 'cut', shortCut: 'Ctrl-X', disabled: false },
        { text: this.$t('editor|copy'), icon: 'mdi-content-copy', action: 'copy', shortCut: 'Ctrl-C', disabled: false },
        { text: this.$t('editor|paste'), icon: 'mdi-content-paste', action: 'paste', shortCut: 'Ctrl-V', disabled: false }
      ]
    },
    position () {
      const doc = this.editor.state.doc
      return doc
    },
    getActiveHeading () {
      if (this.editor.isActive('heading', { level: 1 })) return this.$t('editor|heading') + ' 1'
      if (this.editor.isActive('heading', { level: 2 })) return this.$t('editor|heading') + ' 2'
      if (this.editor.isActive('heading', { level: 3 })) return this.$t('editor|heading') + ' 3'
      if (this.editor.isActive('heading', { level: 4 })) return this.$t('editor|heading') + ' 4'
      if (this.editor.isActive('heading', { level: 5 })) return this.$t('editor|heading') + ' 5'
      if (this.editor.isActive('heading', { level: 6 })) return this.$t('editor|heading') + ' 6'
      else return this.$t('editor|normal_text')
    },
    getActiveFont () {
      return this.font.label
    },
    activeTable () {
      return this.isActiveTable
    }
  },
  watch: {
    activeTable (value) {
      if (value) this.tableMenu = true
      else this.tableMenu = false
    },
    searchInputGeneralSearch (value) {
      if (value) {
        this.searchDialogModel = true
      } else {
        this.searchDialogModel = false
      }
    },
    selectedTextColor (value) {
      if (value) {
        this.$emit('text-color', value)
      } else {
        value = this.editor.getAttributes('textStyle').color || '#000000'
      }
    },
    searchInput (value) {
      if (!value) this.$emit('find-in-text', '')
    },
    searchMenu (value) {
      if (!value) this.$emit('find-in-text', '')
    },
    // 'window.width': {
    //   handler (newVal, oldVal) {
    //     if (newVal < oldVal) {
    //       if (oldVal === 0) return
    //       for (let i = 0; i < this.panelIconsIds.length; i++) {
    //         const panel = document.getElementById('panel')
    //         const panelBounding = panel.getBoundingClientRect()
    //         const icon = this.panelIconsIds[i]
    //         const domElement = document.getElementById(icon.domItem)
    //         if (domElement) {
    //           const iconBounding = domElement.getBoundingClientRect()
    //           const isAllreadyInArrayIndex = this.hiddenIcons.findIndex(item => item === domElement.id)
    //           if ((panelBounding.right - 26) <= iconBounding.left && isAllreadyInArrayIndex === -1) {
    //             domElement.style = 'display: none;'
    //             this.hiddenIcons.push(domElement.id)
    //           }
    //         }
    //       }
    //     }
    //     if (newVal > oldVal) {
    //       if (oldVal === 0) return
    //       const panelIconsArrayReverse = this.panelIconsIds.reverse()
    //       for (let i = 0; i < panelIconsArrayReverse.length; i++) {
    //         const panel = document.getElementById('panel')
    //         const panelBounding = panel.getBoundingClientRect()
    //         const icon = panelIconsArrayReverse[i]
    //         const domElement = document.getElementById(icon.domItem)
    //         if (domElement && window.getComputedStyle(domElement).display === 'none') {
    //           domElement.style = 'display: inherit'
    //         }
    //         if (domElement) {
    //           const iconBounding = domElement.getBoundingClientRect()
    //           const isAllreadyInArrayIndex = this.hiddenIcons.findIndex(item => item === domElement.id)
    //           if (panelBounding.right > iconBounding.left && isAllreadyInArrayIndex !== -1) {
    //             this.hiddenIcons.splice(isAllreadyInArrayIndex, 1)
    //           }
    //         }
    //       }
    //     }
    //   },
    //   deep: true
    // },
    position: {
      handler () {
        if (this.editor.isActive('textStyle', { fontFamily: 'Sofia Sans' })) {
          this.font = {
            name: 'Sofia Sans',
            value: 'Sofia Sans',
            label: 'Sofia Sans',
            style: {
            // optional style to apply to the item
              fontFamily: 'Sofia Sans'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Arial' })) {
          this.font = {
            name: 'Arial',
            value: 'Arial',
            label: 'Arial',
            style: {
            // optional style to apply to the item
              fontFamily: 'Arial'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Cambria' })) {
          this.font = {
            name: 'Cambria',
            value: 'Cambria',
            label: 'Cambria',
            style: {
            // optional style to apply to the item
              fontFamily: 'Cambria'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Arial Black' })) {
          this.font = {
            name: 'Arial Black',
            value: 'Arial Black',
            label: 'Arial Black',
            style: {
            // optional style to apply to the item
              fontFamily: 'Arial Black'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Courier' })) {
          this.font = {
            name: 'Courier',
            value: 'Courier',
            label: 'Courier',
            style: {
            // optional style to apply to the item
              fontFamily: 'Courier'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Courier New' })) {
          this.font = {
            name: 'Courier New',
            value: 'Courier New',
            label: 'Courier New',
            style: {
            // optional style to apply to the item
              fontFamily: 'Courier New'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Sans-Serif' })) {
          this.font = {
            name: 'Sans-Serif',
            value: 'Sans-Serif',
            label: 'Sans-Serif',
            style: {
            // optional style to apply to the item
              fontFamily: 'Sans-Serif'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Serif' })) {
          this.font = {
            name: 'Serif',
            value: 'Serif',
            label: 'Serif',
            style: {
            // optional style to apply to the item
              fontFamily: 'Serif'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Times New Roman' })) {
          this.font = {
            name: 'Times New Roman',
            value: 'Times New Roman',
            label: 'Times New Roman',
            style: {
            // optional style to apply to the item
              fontFamily: 'Times New Roman'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Verdana' })) {
          this.font = {
            name: 'Verdana',
            value: 'Verdana',
            label: 'Verdana',
            style: {
            // optional style to apply to the item
              fontFamily: 'Verdana'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Helvetica' })) {
          this.font = {
            name: 'Helvetica',
            value: 'Helvetica',
            label: 'Helvetica',
            style: {
            // optional style to apply to the item
              fontFamily: 'Helvetica'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Calibri' })) {
          this.font = {
            name: 'Calibri',
            value: 'Calibri',
            label: 'Calibri',
            style: {
            // optional style to apply to the item
              fontFamily: 'Calibri'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Impact' })) {
          this.font = {
            name: 'Impact',
            value: 'Impact',
            label: 'Impact',
            style: {
            // optional style to apply to the item
              fontFamily: 'Impact'
            }
          }
        }
      }
    },
    actualFontSize (value) {
      if (value) {
        let newVal = null
        const p = value.indexOf('p')
        const x = value.indexOf('x')
        newVal = value.slice(0, p)
        value.slice(0, x)
        this.textSize = newVal
      } else {
        this.textSize = null
      }
    }
  },
  mounted () {
    this.handleIconMenu()
    setTimeout(() => {
      this.calculateToolbarGroups()
    }, '1000')
  },
  created () {
    // window.addEventListener('resize', this.handleIconMenu)
    window.addEventListener('resize', this.callDebounceCalculate)
  },
  destroyed () {
    // window.removeEventListener('resize', this.handleIconMenu)
    window.removeEventListener('resize', this.callDebounceCalculate)
  },
  methods: {
    callDebounceCalculate: debounce(function () {
      this.calculateToolbarGroups()
    }, 1300),
    async calculateToolbarGroups () {
      this.ellipsesToolbars = {}
      this.hasToolbarEllipsis = false
      await this.$nextTick()
      const container = this.$refs.toolbarGroupContainer
      // const containerRect = container.getBoundingClientRect()
      const toolbarGroups = [...container.querySelectorAll('.toolbar-group')]
      toolbarGroups.forEach((groupEl) => {
        // const groupElRect = groupEl.getBoundingClientRect()
        // if => containerRect.width <= groupElRect.left + groupElRect.width - 280
        if (groupEl.offsetTop > 80) {
          const toolbarIndex = groupEl.dataset.toolbar
          this.ellipsesToolbars[toolbarIndex] = true
          this.hasToolbarEllipsis = true
        }
      })
    },
    updateCategory (category) {
      EventBus.$emit('updateTempCat', category)
    },
    newSelectedFontColor (color) {
      this.$emit('text-color', color)
    },
    newSelectedBackgroundColor (color) {
      this.$emit('highlight', color)
    },
    checkActive (input) {
      const result = this.editor.isActive('textStyle', { fontFamily: input })
      return result
    },
    handleIconMenu () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    // isHidden (id) {
    //   const foundItem = this.hiddenIcons.find(item => item === id)
    //   if (foundItem) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    closeReset () {
      this.searchInputGeneralSearch = ''
      this.searchDialogModel = false
    },
    clearInput () {
      this.searchInputGeneralSearch = ''
    },
    translateCategoryIfNeeded (category) {
      if (category === 'none') return this.$t('common|uncategorized')
      else return category
    }
  }
}
</script>

<style lang="scss" scoped>
.input:focus {
  outline: none !important;
  border:1px solid #234e79;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 5px;
  z-index: 1;
}
.btn {
  text-transform: none !important;
}
.text-size-box {
  display: flex;
  align-items: center;
  padding-left: 7px;
  width: 50px;
  height: 20px;
  border: 1px solid rgb(175, 175, 175);
  font-size: 12px;
}
.text-size-box-small-number {
  display: flex;
  align-items: center;
  padding-left: 7px;
  width: 50px;
  height: 20px;
  border: 1px solid rgb(175, 175, 175);
  font-size: 12px;
}
.text-size-box-icon {
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid rgb(175, 175, 175);
}
.scroll-num-list {
  height:300px;
  width: 70px;
  overflow-y:auto
}
.field {
  max-width: 130px;
  height: 20px;
  font-size: 12px;
}
.field-width {
  width: 200px;
}
.search-field {
  position: relative;
  left: 77%;
  transform: translateX(-77%);
  width: 20%;
}
.vdialognew {
    height: 600px;
    background-color: #FAFAFA;
    bottom: 50px;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .hide-scrollbar-chrome::-webkit-scrollbar {
      display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar-firefox {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .outlined {
    outline: 1px dashed #234e79;
  }
  .btn-row {
    display: flex;
    align-items: center;
  }
  .portal-menu {
    display: flex;
    flex-direction: row-reverse;
    align-content: center;
  }
</style>
