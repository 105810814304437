import store from '@/store/index'

const generalSearch = (searchitem) => {
  return new Promise(async (resolve, reject) => {
    try {
      // for Node.js
      var autobahn = require('autobahn')
      // for browsers (where AutobahnJS is available globally)
      var connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })
      // const token = localStorage.getItem('access_token')
      const token = store.state.auth.token
      connection.onopen = function (session) {
        // call a remote procedure
        session.call('lavvira.watchman_light.search', [searchitem, token]).then((res) => {
          try {
            resolve(JSON.parse(res))
          } catch (e) {
            reject(e)
          }
        }).catch((e) => {
          reject(e)
        })
      }
      connection.open()
    } catch (e) {
      reject(e)
    }
  })
}

const clientSearch = (searchitem) => {
  return new Promise(async (resolve, reject) => {
    try {
      // for Node.js
      var autobahn = require('autobahn')
      // for browsers (where AutobahnJS is available globally)
      var connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })
      const token = localStorage.getItem('access_token')
      connection.onopen = function (session) {
        // call a remote procedure
        const data = session.call('lavvira.watchman_light.client_search', [searchitem, token])
        resolve(data)
      }
      connection.open()
    } catch (e) {
      reject(e)
    }
  })
}

const caseSearch = (searchitem) => {
  return new Promise(async (resolve, reject) => {
    try {
      // for Node.js
      var autobahn = require('autobahn')
      // for browsers (where AutobahnJS is available globally)
      var connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })
      const token = localStorage.getItem('access_token')
      connection.onopen = function (session) {
        // call a remote procedure
        const data = session.call('lavvira.watchman_light.case_search', [searchitem, token])
        resolve(data)
      }
      connection.open()
    } catch (e) {
      reject(e)
    }
  })
}

const caseModelSearch = (searchitem) => {
  return new Promise(async (resolve, reject) => {
    try {
      // for Node.js
      var autobahn = require('autobahn')
      // for browsers (where AutobahnJS is available globally)
      var connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })
      const token = localStorage.getItem('access_token')
      connection.onopen = function (session) {
        // call a remote procedure
        const data = session.call('lavvira.watchman_light.case_model_search', [searchitem, token])
        resolve(data)
      }
      connection.open()
    } catch (e) {
      reject(e)
    }
  })
}

const templateSearch = (searchitem) => {
  return new Promise(async (resolve, reject) => {
    try {
      // for Node.js
      var autobahn = require('autobahn')
      // for browsers (where AutobahnJS is available globally)
      var connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })
      const token = localStorage.getItem('access_token')
      connection.onopen = function (session) {
        // call a remote procedure
        const data = session.call('lavvira.watchman_light.template_search', [searchitem, token])
        resolve(data)
      }
      connection.open()
    } catch (e) {
      reject(e)
    }
  })
}

const documentsSearch = (searchitem) => {
  return new Promise(async (resolve, reject) => {
    try {
      // for Node.js
      var autobahn = require('autobahn')
      // for browsers (where AutobahnJS is available globally)
      var connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })
      const token = localStorage.getItem('access_token')
      connection.onopen = function (session) {
        // call a remote procedure
        const data = session.call('lavvira.watchman_light.document_search', [searchitem, token])
        resolve(data)
      }
      connection.open()
    } catch (e) {
      reject(e)
    }
  })
}

const memberSearch = (searchitem) => {
  return new Promise(async (resolve, reject) => {
    try {
      // for Node.js
      var autobahn = require('autobahn')
      // for browsers (where AutobahnJS is available globally)
      var connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })
      const token = localStorage.getItem('access_token')
      connection.onopen = function (session) {
        // call a remote procedure
        const data = session.call('lavvira.watchman_light.member_search', [searchitem, token])
        resolve(data)
      }
      connection.open()
    } catch (e) {
      reject(e)
    }
  })
}

const searchGestion = (searchgestion, docArray) => {
  return new Promise(async (resolve, reject) => {
    try {
      // for Node.js
      var autobahn = require('autobahn')
      // for browsers (where AutobahnJS is available globally)
      var connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })
      const token = localStorage.getItem('access_token')
      connection.onopen = function (session) {
        // call a remote procedure
        session.call('lavvira.watchman_light.search', [searchgestion, docArray, token]).then((res) => {
          try {
            resolve(JSON.parse(res))
          } catch (e) {
            reject(e)
          }
        }).catch((e) => {
          reject(e)
        })
      }
      connection.open()
    } catch (e) {
      reject(e)
    }
  })
}

export { generalSearch, clientSearch, caseSearch, caseModelSearch, templateSearch, documentsSearch, memberSearch, searchGestion }
