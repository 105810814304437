<template>
  <v-dialog
    :value="value"
    max-width="600"
    @input="$event => $emit('input', $event)"
  >
    <v-card
      :key="componentKey"
    >
      <v-toolbar
        v-if="toolbarActive"
        :style="styleHeader"
        flat
      >
        <v-card-title>
          <slot name="header">
            {{ title }}
          </slot>
          <v-spacer />
          <v-btn
            icon
            style="position: absolute; right: 10px;"
            @click="$emit('input', false)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
      </v-toolbar>
      <div
        v-if="toolbarActive"
        :style="styleTextField"
      >
        <v-text-field
          v-model="text"
          solo-inverted
          rounded
          dense
          clearable
          class="mx-4 mt-n1"
          prepend-inner-icon="mdi-magnify"
          color="white"
          @input="$emit('searching-field', text)"
        />
      </div>
      <v-card-title
        v-if="!toolbarActive"
      >
        <slot name="header">
          {{ title }}
        </slot>
        <v-spacer />
        <v-btn
          icon
          @click="$emit('input', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <slot />
      </v-card-text>
      <v-card-actions
        v-if="!toolbarActive"
      >
        <slot name="actions" />
      </v-card-actions>
      <v-card-actions
        v-if="toolbarActive"
        :style="actionsStyle"
      >
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    styleHeader: {
      type: String,
      default: null
    },
    styleTextField: {
      type: String,
      default: null
    },
    actionsStyle: {
      type: String,
      default: null
    },
    toolbarActive: {
      type: Boolean,
      default: false
    },
    componentKey: {
      type: Number,
      default: 1
    }
  },
  data: () => {
    return {
      text: null
    }
  },
  watch: {
    value (val) {
      if (!val && this.toolbarActive) {
        this.text = null
        setTimeout(() => {
          this.$emit('dialog-closed', this.text)
        }, '1000')
      }
    }
  }
}
</script>
