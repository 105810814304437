<template>
  <div
    :style="disabledBtn ? 'pointer-events: none; opacity: 0.33;' : ''"
  >
    <div
      v-if="mobile"
      class="text-center"
      :class="mobile && !inCase && !inTemplate ? 'plain-mobile ml-n6 pb-4' : 'ml-n4'"
    >
      <v-btn
        fab
        large
        color="primary"
        :loading="isSelecting"
        @click="onClick"
      >
        <v-icon large>
          mdi-file-upload-outline
        </v-icon>
      </v-btn>
    </div>

    <div
      v-if="showUploadArea && !mobile"
      class="sheet"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <div
        class="container4"
      >
        <p>
          <span style="font-size: 15px;">{{ $t('actions|drop') }}</span>
          <v-btn
            small
            class="ml-2"
            color="primary"
            :loading="isSelecting"
            @click="onClick"
          >
            {{ $t('actions|select') }}
          </v-btn>
          <v-menu
            v-model="menu"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="indigo"
                dark
                v-bind="attrs"
                class="ml-2"
                v-on="on"
              >
                mdi-information-variant
              </v-icon>
            </template>

            <v-card
              max-width="350"
            >
              <v-card-title>
                {{ $t('files|allowed_file_types') }}
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <div>{{ $t('common|images') }}:</div>
                    .jpg
                    .jpeg
                    .png
                    .bmp
                    .pdf
                  </v-col>

                  <v-col cols="12">
                    <div>MS Office:</div>
                    .doc
                    .docx
                    .rtf
                    .ppt
                    .pptx
                    .xls
                    .xlsx
                  </v-col>

                  <v-col cols="12">
                    <div>Open Office / Libre Office:</div>
                    .odt
                    .ott
                    .fodt
                    .uot
                    .ods
                    .odp
                  </v-col>

                  <v-col cols="12">
                    <div>Apple:</div>
                    .pages
                    .numbers
                    .key
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-menu>
        </p>
      </div>
    </div>
    <input
      ref="uploader"
      style="display: none;"
      type="file"
      :multiple="multiple"
      accept=".jpg,.jpeg,.png,.bmp,.pdf,.doc,.docx,.rtf,.ppt,.pptx,.xls,.xlsx,.pages,.numbers,.key,.odt,.ott,.fodt,.uot,.ods,.odp"
      capture="camera"
      @change="onFileChanged"
    >
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Upload'
    },
    multiple: {
      type: Boolean,
      default: true
    },
    showUploadArea: {
      type: Boolean,
      default: true
    },
    mobile: {
      type: Boolean,
      default: false
    },
    inCase: {
      type: Boolean,
      default: false
    },
    inTemplate: {
      type: Boolean,
      default: false
    },
    disabledBtn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menu: false,
      isSelecting: false
    }
  },
  methods: {
    onClick () {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })

      this.$refs.uploader.click()
    },
    onFileChanged (e) {
      this.$emit('file', [...e.target.files])
    },
    dragover (event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100')
        event.currentTarget.classList.add('bg-green-300')
      }
    },
    dragleave (event) {
      // Clean up
      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
    },
    drop (event) {
      event.preventDefault()
      let files = [...event.dataTransfer.files]
      if (!this.multiple) {
        files = [files[0]]
      }
      this.$emit('file', files)
      // Clean up
      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
    }
  }
}
</script>

<style scoped>
.sheet {
  background-color: #f4f8fd;
  min-height: 100px;
  min-width: 100%;
  border: 2px dashed #234e79;
  border-radius: 0.5rem;
}
div.container4 {
  height: 10em;
  position: relative
}
div.container4 p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}
.sticky-fab {
  position: sticky;
  top: 200px;
  z-index: 1;
}
.plain-mobile {
  position: fixed;
  top: 100px;
  background-color: white;
  width: 100%;
  z-index: 1;
}
</style>
