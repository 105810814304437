import { Mark, mergeAttributes } from '@tiptap/core'

export const DropCap = Mark.create({
  name: 'dropcap',
  addAttributes () {
    return {
      fontWeight: {
        default: 900,
        parseHTML: element => element.getAttribute('font-weight') || element.style.fontWeight,
        renderHTML: attributes => {
          if (!attributes.fontWeight) {
            return {}
          }

          return {
            'font-weight': attributes.fontWeight,
            style: `font-weight: ${attributes.fontWeight}`
          }
        }
      },
      fontSize: {
        default: '56px',
        parseHTML: element => ({
          fontSize: element.style.fontSize.replace(/['"]+/g, '')
        }),
        renderHTML: attributes => {
          if (!attributes.fontSize) {
            return {}
          }

          return {
            style: `font-size: ${attributes.fontSize}`
          }
        }
      }
    }
  },
  parseHTML () {
    return [
      {
        tag: 'p',
        getAttrs: (node) => node.className === 'drop-cap'
      }
    ]
  },

  renderHTML () {
    return ['p', mergeAttributes({ class: 'drop-cap' })]
  },

  addCommands () {
    return {
      toggleDropCap: () => ({ commands }) => commands.toggleMark('dropcap')
    }
  }
})
