import {
  TextRun,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  WidthType,
  AlignmentType,
  ShadingType
} from 'docx'
import { DocxSerializer, defaultNodes, defaultMarks } from 'prosemirror-docx'
import { saveAs } from 'file-saver'
import store from '@/store/index'
import axios from '@/plugins/axios'
import { EventBus } from '@/utils/EventBus'
const docx = require('docx')

const MAX_IMAGE_WIDTH = 600

const exportTemplate = async (doc, templateName, type, caseExport) => {
  const nodeSerializer = {
    ...defaultNodes,
    mention (state, node) {
      if (node.attrs.fieldState === 'tag' && !node.attrs.fieldValue) state.text('@' + node.attrs.label)
      if (node.attrs.fieldState === 'tag' && node.attrs.fieldValue) state.text('@' + node.attrs.label + ':' + ' ' + node.attrs.fieldValue)
      if (node.attrs.fieldState === 'view') state.text(node.attrs.fieldValue)
    },
    comment (state, node) {
      if (node.attrs.label) state.text(node.attrs.label)
    },
    hardBreak: defaultNodes.hard_break,
    codeBlock: defaultNodes.code_block,
    orderedList: defaultNodes.ordered_list,
    listItem: defaultNodes.list_item,
    bulletList: defaultNodes.bullet_list,
    horizontalRule: defaultNodes.horizontal_rule,
    image (state, node) {
    // no image
      state.renderInline(node)
      state.closeBlock(node)
    },
    heading (state, node) {
      state.renderInline(node)
      const heading = [
        docx.HeadingLevel.HEADING_1,
        docx.HeadingLevel.HEADING_2,
        docx.HeadingLevel.HEADING_3,
        docx.HeadingLevel.HEADING_4,
        docx.HeadingLevel.HEADING_5,
        docx.HeadingLevel.HEADING_6
      ][node.attrs.level - 1]
      const alignment = node.attrs.textAlign
      state.closeBlock(node, { heading, alignment })
    },
    paragraph (state, node) {
      let spacing
      state.renderInline(node)
      function getAlignment (node) {
        switch (node.attrs.textAlign) {
        case 'center':
          return AlignmentType.CENTER
        case 'justify':
          return AlignmentType.JUSTIFIED
        case 'left':
          return AlignmentType.LEFT
        case 'right':
          return AlignmentType.RIGHT
        default:
          return AlignmentType.LEFT
        }
      }
      const alignment = getAlignment(node)
      if (node.attrs.lineHeight) {
        spacing = {
          line: node.attrs.lineHeight * 230
        }
        state.closeBlock(node, { alignment, spacing })
      } else {
        state.closeBlock(node, { alignment })
      }
    },
    async table (state, node) {
      let rows = []
      let paragraphs = []
      let columnWidths = []
      for (let i = 0; i < node.content.content.length; i++) {
        const rowContent = node.content.content[i]
        let cells = []
        // Check if all cells are headers in this row
        let tableHeader = true
        if (i === 0) {
          rowContent.content.forEach(cell => {
            columnWidths.push(cell.attrs.colwidth ? cell.attrs.colwidth[0] : 0)
          })
        }
        rowContent.content.forEach((cell) => {
          if (cell.type.name !== 'tableHeader') {
            tableHeader = false
          }
        })
        // This scales images inside of tables
        this.maxImageWidth = MAX_IMAGE_WIDTH / rowContent.childCount
        for (let index = 0; index < rowContent.content.content.length; index++) {
          const cell = rowContent.content.content[index]
          var _a, _b
          for (let index = 0; index < cell.content.content.length; index++) {
            const node = cell.content.content[index]
            if (node.type.name === 'paragraph') {
              let text
              if (!node.content.content.length) text = ''
              if (node.content.content.length && node.content.content[0].type.isText) {
              }
              if (node.content.content.length && node.content.content[0].type.isText && !node.content.content[0].marks.length) {
                text = new TextRun({
                  text: node.content.content[0].text
                })
              }
              if (node.content.content.length && node.content.content[0].type.isText && node.content.content[0].marks.length) {
                let boldMark
                let italicMark
                let underlineMark
                let markColor
                node.content.content[0].marks.forEach(mark => {
                  const markName = mark.type.name
                  if (markName === 'bold') boldMark = true
                  if (markName === 'italic') italicMark = true
                  if (markName === 'underline') underlineMark = {}
                  if (markName === 'textStyle' && mark.attrs.color !== null) markColor = mark.attrs.color.slice(0, -2)
                })
                text = new TextRun({
                  text: node.content.content[0].text,
                  bold: boldMark,
                  italics: italicMark,
                  underline: underlineMark,
                  color: markColor || '#000000'
                })
              }
              const child = new Paragraph({
                children: [text]
              })
              paragraphs.push(child)
            }
            // eslint-disable-next-line no-inner-declarations
            async function createNestedTable (_state, _node) {
              if (_node.type.name === 'table') {
                let nestedRows = []
                let nestedParagraphs = []
                let nestedColumnWidths = []
                let nestedCells = []
                _node.content.forEach(({ content: _rowContent }, index) => {
                  // Check if all cells are headers in this row
                  let nestedTableHeader = true
                  if (index === 0) {
                    _rowContent.content.forEach(_cell => {
                      nestedColumnWidths.push(_cell.attrs.colwidth ? _cell.attrs.colwidth[0] : 0)
                    })
                  }
                  rowContent.content.forEach((_cell) => {
                    if (_cell.type.name !== 'tableHeader') {
                      nestedTableHeader = false
                    }
                  })
                  // This scales images inside of tables
                  // this.maxImageWidth = MAX_IMAGE_WIDTH / rowContent.childCount
                  _rowContent.content.forEach((_cell) => {
                    var _x, _y
                    _cell.content.content.forEach(subNode => {
                      if (subNode.type.name === 'paragraph') {
                        let subText
                        if (!subNode.content.content.length) subText = ''
                        if (subNode.content.content.length && subNode.content.content[0].type.isText && !subNode.content.content[0].marks.length) {
                          subText = new TextRun({
                            text: subNode.content.content[0].text
                          })
                        }
                        if (subNode.content.content.length && subNode.content.content[0].type.isText && subNode.content.content[0].marks.length) {
                          let subBoldMark
                          let subItalicMark
                          let subUnderlineMark
                          let deepColorMark
                          subNode.content.content[0].marks.forEach(subMark => {
                            const markName = subMark.type.name
                            if (markName === 'bold') subBoldMark = true
                            if (markName === 'italic') subItalicMark = true
                            if (markName === 'underline') subUnderlineMark = {}
                            if (markName === 'textStyle') deepColorMark = subMark.attrs.color ? subMark.attrs.color.slice(0, -2) : null
                          })
                          subText = new TextRun({
                            text: subNode.content.content[0].text,
                            bold: subBoldMark,
                            italics: subItalicMark,
                            underline: subUnderlineMark,
                            color: deepColorMark || '#000000'
                          })
                        }
                        const child = new Paragraph({
                          children: [subText]
                        })
                        nestedParagraphs.push(child)
                      }
                      if (subNode.type.name === 'table') {
                        // eslint-disable-next-line no-inner-declarations
                        async function createNextNestedTable (_state, _nextNode) {
                          if (_nextNode.type.name === 'table') {
                            let deepNestedRows = []
                            let deepNestedParagraphs = []
                            let deepNestedColumnWidths = []
                            _nextNode.content.forEach(({ content: _deepRowContent }, index) => {
                              let deepNestedCells = []
                              // Check if all cells are headers in this row
                              let tableHeader = true
                              if (index === 0) {
                                _deepRowContent.content.forEach(_deepCell => {
                                  deepNestedColumnWidths.push(_deepCell.attrs.colwidth ? _deepCell.attrs.colwidth[0] : 0)
                                })
                              }
                              rowContent.content.forEach((_deepCell) => {
                                if (_deepCell.type.name !== 'tableHeader') {
                                  tableHeader = false
                                }
                              })
                              // This scales images inside of tables
                              // this.maxImageWidth = MAX_IMAGE_WIDTH / rowContent.childCount
                              _deepRowContent.content.forEach((_deepCell) => {
                                var _x, _y
                                _deepCell.content.content.forEach(deepSubNode => {
                                  if (deepSubNode.type.name === 'paragraph') {
                                    let deepSubText
                                    if (!deepSubNode.content.content.length) deepSubText = ''
                                    if (deepSubNode.content.content.length && deepSubNode.content.content[0].type.isText && !deepSubNode.content.content[0].marks.length) {
                                      deepSubText = new TextRun({
                                        text: deepSubNode.content.content[0].text
                                      })
                                    }
                                    if (deepSubNode.content.content.length && deepSubNode.content.content[0].type.isText && deepSubNode.content.content[0].marks.length) {
                                      let deepSubBoldMark
                                      let deepSubItalicMark
                                      let deepSubUnderlineMark
                                      deepSubNode.content.content[0].marks.forEach(deepSubMark => {
                                        const markName = deepSubMark.type.name
                                        if (markName === 'bold') deepSubBoldMark = true
                                        if (markName === 'italic') deepSubItalicMark = true
                                        if (markName === 'underline') deepSubUnderlineMark = {}
                                      })
                                      deepSubText = new TextRun({
                                        text: deepSubNode.content.content[0].text,
                                        bold: deepSubBoldMark,
                                        italics: deepSubItalicMark,
                                        underline: deepSubUnderlineMark
                                      })
                                    }
                                    const child = new Paragraph({
                                      children: [deepSubText]
                                    })
                                    deepNestedParagraphs.push(child)
                                  }
                                  // if (subNode.type.name === 'table') {
                                  //   createNestedTable(_state, subNode)
                                  // }
                                })
                                const _deepTableCellOpts = {}
                                const _deepColspan = (_x = _deepCell.attrs.colspan) !== null && _x !== void 0 ? _x : 1
                                const _deepRowspan = (_y = _deepCell.attrs.rowspan) !== null && _y !== void 0 ? _y : 1
                                if (_deepColspan > 1) { _deepTableCellOpts.columnSpan = _colspan }
                                if (_deepRowspan > 1) { _deepTableCellOpts.rowSpan = _rowspan }
                                let deepNestedCellColor
                                if (_deepCell.attrs.backgroundColor) {
                                  if (_deepCell.attrs.backgroundColor.length > 7) {
                                    deepNestedCellColor = _deepCell.attrs.backgroundColor.slice(0, -2)
                                  } else {
                                    deepNestedCellColor = _deepCell.attrs.backgroundColor
                                  }
                                }
                                const newCell = new TableCell({
                                  children: deepNestedParagraphs,
                                  _deepTableCellOpts,
                                  shading: {
                                    fill: tableHeader ? '#f1f3f5' : deepNestedCellColor || '#FFFFFF',
                                    type: ShadingType.SOLID,
                                    color: tableHeader ? '#f1f3f5' : deepNestedCellColor
                                  }
                                })
                                deepNestedCells.push(newCell)
                                deepNestedParagraphs = []
                              })
                              const newRow = new TableRow({ children: deepNestedCells, tableHeader })
                              deepNestedCells = []
                              deepNestedRows.push(newRow)
                            })
                            const deepTable = new Table({
                              rows: deepNestedRows,
                              width: {
                                size: node.attrs.width * 5,
                                type: WidthType.DXA
                              },
                              columnWidths: deepNestedColumnWidths
                            })
                            nestedParagraphs.push(deepTable)
                          }
                        }
                        createNextNestedTable(_state, subNode)
                      }
                    })
                    const _tableCellOpts = {}
                    const _colspan = (_x = _cell.attrs.colspan) !== null && _x !== void 0 ? _x : 1
                    const _rowspan = (_y = _cell.attrs.rowspan) !== null && _y !== void 0 ? _y : 1
                    if (_colspan > 1) { _tableCellOpts.columnSpan = _colspan }
                    if (_rowspan > 1) { _tableCellOpts.rowSpan = _rowspan }
                    let nestedCellColor
                    if (_cell.attrs.backgroundColor) {
                      if (_cell.attrs.backgroundColor.length > 7) {
                        nestedCellColor = _cell.attrs.backgroundColor.slice(0, -2)
                      } else {
                        nestedCellColor = _cell.attrs.backgroundColor
                      }
                    }
                    const newCell = new TableCell({
                      children: nestedParagraphs,
                      _tableCellOpts,
                      shading: {
                        fill: tableHeader ? '#f1f3f5' : nestedCellColor || '#FFFFFF',
                        type: ShadingType.SOLID,
                        color: tableHeader ? '#f1f3f5' : nestedCellColor
                      }
                    })
                    nestedCells.push(newCell)
                    nestedParagraphs = []
                  })
                  const newRow = new TableRow({ children: nestedCells, nestedTableHeader })
                  nestedCells = []
                  nestedRows.push(newRow)
                })
                const table = new Table({
                  rows: nestedRows,
                  width: {
                    size: node.attrs.width * 10,
                    type: WidthType.DXA
                  },
                  columnWidths: nestedColumnWidths
                })
                paragraphs.push(table)
              }
            }
            createNestedTable(state, node)
          }
          const tableCellOpts = {}
          const colspan = (_a = cell.attrs.colspan) !== null && _a !== void 0 ? _a : 1
          const rowspan = (_b = cell.attrs.rowspan) !== null && _b !== void 0 ? _b : 1
          if (colspan > 1) { tableCellOpts.columnSpan = colspan }
          if (rowspan > 1) { tableCellOpts.rowSpan = rowspan }
          let cellColor
          if (cell.attrs.backgroundColor) {
            if (cell.attrs.backgroundColor.length > 7) {
              cellColor = cell.attrs.backgroundColor.slice(0, -2)
            } else {
              cellColor = cell.attrs.backgroundColor
            }
          }
          const newCell = new TableCell({
            children: paragraphs,
            tableCellOpts,
            shading: {
              fill: tableHeader ? '#f1f3f5' : cellColor || '#FFFFFF',
              type: ShadingType.SOLID,
              color: tableHeader ? '#f1f3f5' : cellColor
            }
          })
          cells.push(newCell)
          paragraphs = []
        }
        rows.push(new TableRow({ children: cells, tableHeader }))
      }
      this.maxImageWidth = MAX_IMAGE_WIDTH
      // const points = (node.attrs.width * 72) / 96
      const table = new Table({ rows,
        width: {
          size: node.attrs.width * 10,
          type: WidthType.DXA
        },
        columnWidths: columnWidths
      })

      // If there are multiple tables, this seperates them
      // actualChildren.push(new docx.Paragraph(''))

      state.children.push(table)
    }
  }

  const changedMarks = {
    ...defaultMarks,
    italic () {
      return { italics: true }
    },
    bold () {
      return { bold: true }
    },
    strike () {
      return { strike: true }
    },
    superscript () {
      return { superScript: true }
    },
    subscript () {
      return { subScript: true }
    },
    dropcap () {
      return { size: '50pt' }
    },
    textStyle (state, node) {
      let fontColor = '#000000'
      let fontFam
      let fontSize
      let highlightColor
      // Available colors in MS Word
      // {Colors.Transparent, "none"},
      // {Colors.Black, "black"},
      // {Colors.Blue, "blue"},
      // {Colors.Cyan, "cyan"},
      // {Color.FromArgb(255, 0, 0, 128), "darkBlue"},
      // {Color.FromArgb(255, 0, 128, 128), "darkCyan"},
      // {Color.FromArgb(255, 128, 128, 128), "darkGray"},
      // {Color.FromArgb(255, 0, 128, 0), "darkGreen"},
      // {Color.FromArgb(255, 128, 0, 128), "darkMagenta"},
      // {Color.FromArgb(255, 128, 0, 0), "darkRed"},
      // {Color.FromArgb(255, 128, 128, 0), "darkYellow"},
      // {Color.FromArgb(255, 0, 255, 0), "green"},
      // {Color.FromArgb(255, 192, 192, 192), "lightGray"},
      // {Color.FromArgb(255, 255, 0, 255), "magenta"},
      // {Colors.Red, "red"},
      // {Colors.White, "white"},
      // {Colors.Yellow, "yellow"}
      function getColorName (actualColor) {
        switch (actualColor) {
        case '':
          return ''
        case '#FFFFFF':
          return 'white'
        case '#FF0000':
          return 'red'
        case '#FFFF00':
          return 'yellow'
        case '#00FF00':
          return 'green'
        case '#00FFFF':
          return 'cyan'
        case '#0000FF':
          return 'blue'
        case '#AAAA00':
          return 'darkYellow'
        case '#00AA00':
          return 'green'
        case '#00AAAA':
          return 'grey'
        case '#0000AA':
          return 'darkBlue'
        case '#550000':
          return 'brown'
        case '#555500':
          return 'darkGreen'
        case '#005500':
          return 'darkGreen'
        case '#005555':
          return 'darkGray'
        case '#000000':
          return 'black'
        default:
          return 'yellow'
        }
      }
      node.marks.forEach(mark => {
        if (mark.type.name === 'textStyle' && mark.attrs.color) {
          if (mark.attrs.color === null) fontColor = ''
          fontColor = mark.attrs.color
        } else {
          fontColor = '#000000'
        }
        if (mark.type.name === 'textStyle' && mark.attrs.fontFamily) {
          fontFam = mark.attrs.fontFamily
        }
        if (mark.type.name === 'textStyle' && mark.attrs.fontSize && typeof mark.attrs.fontSize !== 'object') {
          fontSize = ((Number(mark.attrs.fontSize.slice(0, -2)) * 3) / 4).toString() + 'pt'
        }
        if (mark.type.name === 'textStyle' && mark.attrs.backgroundColor) {
          if (mark.attrs.backgroundColor === null) highlightColor = ''
          if (mark.attrs.backgroundColor.length === 7) {
            highlightColor = getColorName(mark.attrs.backgroundColor)
          } else if (mark.attrs.backgroundColor.length > 7) {
            highlightColor = getColorName(mark.attrs.backgroundColor.slice(0, -2))
          }
        }
        if (mark.type.name === 'textStyle' && mark.attrs.backgroundColor === null) highlightColor = ''
        if (mark.type.name === 'textStyle' && mark.attrs.color) fontColor = ''
      })
      return { color: fontColor, font: fontFam, size: fontSize, highlight: highlightColor }
    }
  }
  const myDocxSerializer = new DocxSerializer(nodeSerializer, changedMarks)
  const wordDocument = myDocxSerializer.serialize(doc)
  if (caseExport) {
    return Packer.toBlob(wordDocument).then(async blob => {
      return blob
    })
  } else {
    return Packer.toBlob(wordDocument).then(async blob => {
      if (type === 'docx') {
        saveAs(blob, templateName)
      }
      if (type === 'pdf') {
        EventBus.$emit('pdf-loading')
        const payload = {
          file: blob
        }
        const _id = store.state.account.account._id
        return new Promise(async (resolve, reject) => {
          let pathForDeleting
          try {
            const formData = new FormData()
            Object.keys(payload).forEach((key) => {
              formData.append(key, payload[key])
            })
            const { data } = await axios.post(`/convertDocxToPDF/${_id}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            if (data.success) {
              pathForDeleting = data.data
              const splitted = data.data.split('/')
              const fileName = splitted[3]
              const path = `/downloadDocument/${splitted[2]}/${fileName}`
              const result = await downloadDocument(path)
              saveAs(result, 'DOWNLOAD')
              const deletePDF = await deletePdf(pathForDeleting)
              resolve(deletePDF)
            } else {
              reject(data)
            }
          } catch (e) {
            reject(e)
          } finally {
            EventBus.$emit('pdf-done')
          }
        })
      }
      if (type === 'print') {
        EventBus.$emit('pdf-loading')
        const payload = {
          file: blob
        }
        const _id = store.state.account.account._id
        return new Promise(async (resolve, reject) => {
          let pathForDeleting
          try {
            const formData = new FormData()
            Object.keys(payload).forEach((key) => {
              formData.append(key, payload[key])
            })
            const { data } = await axios.post(`/convertDocxToPDF/${_id}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            if (data.success) {
              pathForDeleting = data.data
              const splitted = data.data.split('/')
              const fileName = splitted[3]
              const path = `/downloadDocument/${splitted[2]}/${fileName}`
              const result = await downloadDocument(path)
              const iframe = document.createElement('iframe') // load content in an iframe to print later
              document.body.appendChild(iframe)
              var blobURL = URL.createObjectURL(result)

              iframe.style.display = 'none'
              iframe.src = blobURL
              EventBus.$emit('pdf-done')
              iframe.onload = function () {
                setTimeout(function () {
                  iframe.focus()
                  iframe.contentWindow.print()
                }, 1)
              }
              const deletePDF = await deletePdf(pathForDeleting)
              resolve(deletePDF)
            } else {
              reject(data)
            }
          } catch (e) {
            reject(e)
          } finally {
            EventBus.$emit('pdf-done')
          }
        })
      }
    })
  }
}

const downloadDocument = async (path) => {
  const { data } = await axios({
    url: path,
    method: 'GET',
    responseType: 'blob' // important
  })
  return data
}

const deletePdf = async (path) => {
  const payload = {
    path: path
  }
  const { data } = await axios.post(`/deleteExportedPdf`, payload)
  return data
}

export default exportTemplate
