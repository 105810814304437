<template>
  <div
    v-if="loadingSingleDocument.includes(document._id)"
    class="d-flex align-center justify-center pa-0 my-3 mx-2"
    :style="`min-height: 60px; border-radius: 10px; border: 1px solid ${$vuetify.theme.themes.light.background};`"
  >
    <v-progress-circular
      indeterminate
      color="primary"
    />
  </div>
  <v-card
    v-else
    flat
    outlined
    class="pa-0 my-3 mx-2"
    :style="`border-radius: 10px; border: 1px solid ${$vuetify.theme.themes.light.background};`"
  >
    <v-row
      no-gutters
    >
      <v-col
        cols="1"
      >
        <v-card
          flat
          color="lightBackground"
          height="100%"
          :style="`border-top-left-radius: 10px; border-bottom-left-radius: 10px; border: 1px solid ${$vuetify.theme.themes.light.background};`"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="account.accountType === 'lawyer'"
                color="primary"
                class="handle-icon centered-icon"
                style="cursor: grab;"
                size="30"
                v-bind="attrs"
                v-on="on"
              >
                {{ icons.drag }}
              </v-icon>
              <v-icon
                v-else
                :color="iconType.color"
                class="ml-1 centered-icon"
              >
                {{ iconType.icon }}
              </v-icon>
            </template>
            <span>{{ $t('actions|drag_document_to_workbench') }}</span>
          </v-tooltip>
        </v-card>
      </v-col>
      <v-col
        cols="1"
        fill-height
        style="display: flex; align-items: center; justify-content: center;"
      >
        <v-icon
          v-if="account.accountType === 'lawyer'"
          :color="iconType.color"
          class="ml-3"
        >
          {{ iconType.icon }}
        </v-icon>
      </v-col>
      <v-col cols="9">
        <v-card
          flat
          class="pa-0 ma-0"
        >
          <v-card-text
            class="ml-2 mt-n3"
            style="white-space: normal; overflow: hidden; text-overflow: ellipsis;"
            v-text="document.raw.originalname"
          />
          <div
            class="mt-n4 ml-4 mb-1"
            :style="`width: 85%;`"
          >
            <CustomAutocomplete
              v-if="inCase"
              :item="document"
              :item-text="getFieldValue(document.info.document_type, selectedLanguage)"
              :index="index"
              :fields="_fields"
              :used-in-documents-table="false"
              :component-is-used-in="inCaseWorkbench ? 'inCaseWorkbench' : inCase ? 'inCase' : 'default'"
              :input-width="'100%'"
              :input-outline-style="'none'"
              :input-outline-style-on-focus="'none'"
              :input-font-size="'15px'"
              @set-input-value="setInputValue"
              @click.native.stop.prevent
            />
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="1"
      >
        <v-card
          height="100%"
          flat
          outlined
          color="lightBackground"
          :style="`border-top-right-radius: 10px; border-bottom-right-radius: 10px; border: 1px solid ${$vuetify.theme.themes.light.background};`"
        >
          <v-row
            align="center"
            no-gutters
          >
            <v-col
              cols="12"
            >
              <div class="centered-icon">
                <div
                  v-if="document.status === 'pending'"
                  class="ml-1"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-progress-circular
                        v-if="document.status === 'pending'"
                        indeterminate
                        size="20"
                        :width="3"
                        color="accent"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <span>{{ $t('documents|processing_document') }}</span>
                  </v-tooltip>
                </div>
                <div
                  v-if="document.status === 'error'"
                  class="ml-1"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="error"
                        size="20"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-alert
                      </v-icon>
                    </template>
                    <span>{{ $t('documents|processing_error') }}</span>
                  </v-tooltip>
                </div>
                <div
                  v-if="document.status === 'processed'"
                  class="ml-1"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="document.status === 'processed'"
                        color="success"
                        size="20"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-check-circle
                      </v-icon>
                    </template>
                    <span>{{ $t('documents|processed_successfully') }}</span>
                  </v-tooltip>
                </div>
                <v-menu
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        size="20"
                      >
                        {{ icons.mdiCog }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-if="inCase || inCaseWorkbench"
                      @click="$emit('open-doc')"
                    >
                      <v-icon
                        color="primary"
                      >
                        mdi-arrow-top-right
                      </v-icon>
                      <v-list-item-title>{{ $t('actions|open') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('deleteAction')">
                      <v-icon
                        color="primary"
                        class="mr-2"
                      >
                        mdi-link-off
                      </v-icon>
                      <v-list-item-title>{{ $t('actions|unassign') }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mdiFileImage, mdiFilePdfBox, mdiFileWord, mdiMicrosoftExcel, mdiCog, mdiCheckCircle, mdiChevronDown, mdiDrag } from '@mdi/js'
import CustomAutocomplete from '../InputFields/CustomAutocomplete.vue'
import generalMixin from '@/utils/generalMixin'

export default {
  components: {
    // FileView,
    CustomAutocomplete
  },
  mixins: [
    generalMixin
  ],
  props: {
    document: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: null
    },
    hasSelection: {
      type: Boolean,
      default: false
    },
    hasActions: {
      type: Boolean,
      default: true
    },
    hasSearchActions: {
      type: Boolean,
      default: false
    },
    hasDocIcons: {
      type: Boolean,
      default: false
    },
    inCase: {
      type: Boolean,
      default: false
    },
    inCaseWorkbench: {
      type: Boolean,
      default: false
    },
    inDialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      icons: {
        mdiCog,
        mdiCheckCircle,
        mdiChevronDown,
        drag: mdiDrag
      },
      selectedLanguage: localStorage.getItem('preferedLanguage') || 'en',
      selectedDocumentType: null,
      customField: false,
      customFieldInput: null,
      menu: false
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      settings: state => state.settings.settings,
      loadingSingleDocument: state => state.documents.loadingSingleDocument
    }),
    documentSuggestions () {
      const suggestions = Object.keys(this.settings.fields).reduce((acc, key) => {
        if (this.settings.fields[key].type === 'file-input') {
          acc[key] = this.settings.fields[key]
        }
        return acc
      }, {})
      return suggestions
    },
    _fields () {
      let _filtered
      const suggestions = Object.keys(this.documentSuggestions).map((key) => {
        let name = this.documentSuggestions[key].label[this.selectedLanguage] ?? undefined
        if (Array.isArray(this.documentSuggestions[key].label[this.selectedLanguage])) {
          name = this.documentSuggestions[key].label[this.selectedLanguage].join(' / ')
        }
        return {
          name,
          fieldKey: key,
          language: this.selectedLanguage
        }
      })
      // remove suggestions with no label in this actual language
      const removeUndefined = (obj) => {
        for (var prop in obj) {
          if (obj.hasOwnProperty(prop) && obj[prop] === undefined) {
            return
          }
        }
        return obj
      }
      _filtered = suggestions.filter(removeUndefined)
      if (!_filtered.length) _filtered.push({ name: this.$t('common|no_results_found') })
      return _filtered
    },
    iconType () {
      return this.getIconType(this.document.raw.mimetype)
    }
  },
  mounted () {
    window.addEventListener('pref-language-changed', (event) => {
      this.selectedLanguage = event.detail.lang
    })
  },
  methods: {
    ...mapActions({
      updateDocument: 'documents/updateDocument'
    }),
    getIconType (fileType) {
      switch (fileType) {
      case 'application/pdf':
        return {
          icon: mdiFilePdfBox,
          color: '#c11e07'
        }
      case 'image/jpeg':
        return {
          icon: mdiFileImage,
          color: '#ffbd2e'
        }
      case 'image/png':
        return {
          icon: mdiFileImage,
          color: 'blue'
        }
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/msword':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/rtf':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/vnd.ms-word.document.macroenabled.12':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/msword-template':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/vnd.oasis.opendocument.text-template':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/vnd.oasis.opendocument.text-flat-xml':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/vnd.oasis.opendocument.text':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/vnd.ms-excel':
        return {
          icon: mdiMicrosoftExcel,
          color: 'green darken-3'
        }
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return {
          icon: mdiMicrosoftExcel,
          color: 'green darken-3'
        }
      case 'application/vnd.oasis.opendocument.spreadsheet':
        return {
          icon: mdiMicrosoftExcel,
          color: 'green darken-3'
        }
      case 'application/octet-stream':
        return {
          icon: 'mdi-file',
          color: 'grey lighten-2'
        }
      default:
        return {
          icon: 'mdi-file',
          color: 'grey'
        }
      }
    },
    getSvg (fileType, docName) {
      if (docName && docName.includes('.pages')) return require(`@/assets/pages.svg`)
      if (docName && docName.includes('.numbers')) return require(`@/assets/numbers.png`)
      if (fileType && fileType === 'application/vnd.apple.pages') return require(`@/assets/pages.svg`)
      if (fileType && fileType === 'application/vnd.apple.numbers') return require(`@/assets/numbers.png`)
    },
    getOctetValue (docName) {
      if (docName && docName.includes('.pages')) return require(`@/assets/pages.svg`)
      if (docName && docName.includes('.numbers')) return require(`@/assets/numbers.png`)
    },
    isIcon (fileType, docName) {
      let rule = true
      if (fileType === 'application/vnd.apple.pages' || fileType === 'application/vnd.apple.numbers') {
        rule = false
      } else if (fileType === 'application/octet-stream') {
        if (docName && docName.includes('.pages')) rule = false
        if (docName && docName.includes('.numbers')) rule = false
      }
      return rule
    }
  }
}
</script>

<style scoped>
  .centered-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
