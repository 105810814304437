<template>
  <v-main>
    <v-container
      fluid
      fill-height
      class="pa-0"
    >
      <router-view />
    </v-container>
  </v-main>
</template>

<script>
export default {

}
</script>

<style lang="sass" scoped>

</style>
