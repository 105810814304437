<template>
  <v-container>
    <v-card
      flat
      color="transparent"
    >
      <v-card-text class="text-center">
        <vue-ellipse-progress
          :progress="relDiff"
          :angle="-90"
          color="#3e8bbc"
          empty-color="#d9d9d9"
          :size="125"
          :thickness="7"
          empty-thickness="7%"
          line-mode="normal"
          line="butt"
          :legend="true"
          :legend-value="relDiff"
          :legend-formatter="({currentValue}) => new Intl.NumberFormat('de-DE').format(currentValue)"
          legend-class="legend-custom-style"
          animation="reverse 700 400"
          :no-data="false"
          :loading="false"
          font-color="white"
          :half="false"
          :gap="10"
          font-size="0.6rem"
        >
          <span>{{ storageSize }}</span>
          <v-divider />
          <span slot="legend-value">{{ $t('common|percent_used') }}</span>
          <!-- <p slot="legend-caption">
            used
          </p> -->
        </vue-ellipse-progress>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn
          v-if="storageButtonRule"
          class="white--text pa-3"
          color="accent4"
          x-small
          link
          :to="storageType && storageType === 'personal' ? `/buy-storage` : `/buy-company-storage`"
          @mouseover="hoverMoreStorage = true"
          @mouseleave="hoverMoreStorage = false"
        >
          <v-icon
            v-if="hoverMoreStorage"
            small
            class="pr-2"
          >
            mdi-plus-circle-outline
          </v-icon>
          <v-icon
            v-else
            small
            class="pr-2"
          >
            mdi-database
          </v-icon>
          {{ $t('common|get_more') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    storageType: {
      type: String,
      default: 'personal'
    }
    // documentsType: {
    //   type: String,
    //   default: ''
    // }
  },
  data () {
    return {
      hoverMoreStorage: false,
      hoverMoreMembers: false,
      hoverMoreTranslations: false
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      documents: state => state.documents.documents,
      companyDocuments: state => state.companyDocuments.companyDocuments,
      groupDocuments: state => state.groupDocuments.groupDocuments
    }),
    storageButtonRule () {
      let rule = true
      if (this.storageType && this.storageType === 'company' && this.company.subscriptionStatus === 'beta') rule = false
      if (this.storageType && this.storageType === 'personal' && this.account.subscriptionStatus === 'beta') rule = false
      return rule
    },
    relDiff () {
      //  Percentage of decrease example = |5000 - 8|/5000 = 4992/5000 = 0.9984 = 99.84%
      if (this.storageType && this.storageType === 'company') {
        let a = Number(this.company.availableStorage * Math.pow(1024, 3)).toFixed(0)
        let b = Number(this.company.usedStorage * Math.pow(1024, 3)).toFixed(0)
        let c = a - b
        let d = c / a
        let e = d * 100
        let f = 100 - e
        let res = Math.round(f)
        if (res < 0) res = 100
        if (res > 100) res = 100
        return Number(res)
      } else {
        let a = Number(this.account.availableStorage * Math.pow(1024, 3))
        let b = Number(this.account.usedStorage * Math.pow(1024, 3))
        let c = a - b
        let d = c / a
        let e = d * 100
        let f = 100 - e
        let res = Math.round(f)
        if (res < 0) res = 100
        if (res > 100) res = 100
        return Number(res)
      }
    },
    storageSize () {
      const formatBytes = (bytes, decimals = 2) => {
        if (!+bytes) return '0 Bytes'

        const k = 1000
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        let i = Math.floor(Math.log(bytes) / Math.log(k))
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
      }
      if (this.storageType && this.storageType === 'company') {
        const sizeInBytes = this.company.usedStorage * Math.pow(1000, 3)
        const size = formatBytes(sizeInBytes)
        let availableStorage = this.company.availableStorage
        if (availableStorage < 1000) {
          availableStorage = availableStorage + ' ' + 'GB'
        } else {
          availableStorage = (availableStorage / 1000).toFixed(0) + ' ' + 'TB'
        }
        return (size + ' / ' + availableStorage)
      } else {
        const sizeInBytes = this.account.usedStorage * Math.pow(1000, 3)
        const size = formatBytes(sizeInBytes)
        let availableStorage = this.account.availableStorage
        if (availableStorage < 1000) {
          availableStorage = availableStorage + ' ' + 'GB'
        } else {
          availableStorage = (availableStorage / 1000).toFixed(0) + ' ' + 'TB'
        }
        return (size + ' / ' + availableStorage)
      }
    }
    // storageSize () {
    //   if (this.storageType && this.storageType === 'company') {
    //     const size = this.company.usedStorage
    //     let availableStorage = this.company.availableStorage
    //     if (availableStorage < 1000) {
    //       availableStorage = availableStorage + ' ' + 'GB'
    //     } else {
    //       availableStorage = (availableStorage / 1000).toFixed(0) + ' ' + 'TB'
    //     }
    //     const gbSize = size
    //     const mbSize = size * 1024
    //     const kbSize = size * (1024 * 1024)

    //     console.log(gbSize, 'company gbSize')
    //     console.log(mbSize, 'company mbSize')
    //     console.log(kbSize, 'company kbSize')
    //     if (gbSize > 1) {
    //       return (gbSize.toFixed(2) + ' GB' + ' / ' + availableStorage)
    //     } else if (mbSize > 1) {
    //       return (mbSize.toFixed(2) + ' MB' + ' / ' + availableStorage)
    //     } else {
    //       return (kbSize.toFixed(2) + ' KB' + ' / ' + availableStorage)
    //     }
    //   } else {
    //     const size = this.account.usedStorage
    //     let availableStorage = this.account.availableStorage
    //     if (availableStorage < 1000) {
    //       availableStorage = availableStorage + ' ' + 'GB'
    //     } else {
    //       availableStorage = (availableStorage / 1000).toFixed(0) + ' ' + 'TB'
    //     }
    //     const gbSize = size
    //     const mbSize = size * 1024
    //     const kbSize = size * (1024 * 1024)

    //     console.log(gbSize, 'gbSize')
    //     console.log(mbSize, 'mbSize')
    //     console.log(kbSize, 'kbSize')
    //     if (gbSize > 1) {
    //       return (gbSize.toFixed(2) + ' GB' + ' / ' + availableStorage)
    //     } else if (mbSize > 1) {
    //       return (mbSize.toFixed(2) + ' MB' + ' / ' + availableStorage)
    //     } else {
    //       return (kbSize.toFixed(2) + ' KB' + ' / ' + availableStorage)
    //     }
    //   }
    // }
    // actualMembers () {
    //   let membersAmount
    //   if (this.company && this.company.members) {
    //     membersAmount = this.company.members.length
    //   }
    //   return membersAmount
    // },
    // subscriptionSize () {
    //   let availableSeats
    //   if (this.company && this.company.subscriptionType === 'lavvira-group-s') {
    //     availableSeats = 3
    //   }
    //   if (this.company && this.company.subscriptionType === 'lavvira-group-l') {
    //     availableSeats = 7
    //   }
    //   return availableSeats
    // }
    // unfilteredDocuments () {
    //   let document
    //   if (this.account._id === this.id) {
    //     return this.documents.map((doc) => {
    //       return {
    //         doc: document,
    //         fileType: doc.raw.mimetype,
    //         documentType: doc.info.document_type,
    //         docName: doc.raw.originalname,
    //         docSize: doc.raw.size
    //       }
    //     })
    //   }
    //   return document
    // },
    // filteredDocuments () {
    //   let docs = []
    //   let companyDocs = []
    //   let groupDocs = []
    //   let finalDocsArr = []
    //   if (this.documentsType.length && this.documentsType === 'personal') {
    //     this.documents.filter(doc => {
    //       if (doc.createdBy && doc.createdBy._id === this.account._id) {
    //         docs.push(doc)
    //       }
    //     })
    //   }
    //   if (this.documentsType.length && this.documentsType === 'company') {
    //     this.companyDocuments.filter(doc => {
    //       if (doc.createdBy && doc.createdBy._id === this.company._id) {
    //         companyDocs.push(doc)
    //       }
    //     })
    //     this.company.groups.forEach(group => {
    //       this.groupDocuments.filter(function (doc) {
    //         if (doc.createdBy && doc.createdBy._id === group._id) {
    //           groupDocs.push(doc)
    //         }
    //       })
    //     })
    //   }
    //   if (docs.length) finalDocsArr = docs
    //   if (!docs.length && (companyDocs.length || groupDocs.length)) {
    //     finalDocsArr = [...companyDocs, ...groupDocs]
    //   }
    //   return finalDocsArr
    // },
    // rawStorageSize () {
    //   let size = this.filteredDocuments.reduce((acc, doc) => {
    //     return (acc += doc.raw.size)
    //   }, 0)
    //   return size
    // },
    // relDiffMembers () {
    //   //  Percentage of decrease example = |5000 - 8|/5000 = 4992/5000 = 0.9984 = 99.84%
    //   let a = Number(this.subscriptionSize)
    //   let b = Number(this.actualMembers)
    //   let c = a - b
    //   return Number((100 - (100 * (c / a))).toFixed(2))
    // },
    // amountDocuments () {
    //   let array = this.filteredDocuments
    //   let sum = 0
    //   for (var i = 0; i < array.length; i++) {
    //     sum += array[i]
    //     sum = sum.length
    //   }
    //   return sum
    // },
    // amountAccounts () {
    //   let array = this.account
    //   let sum = 0
    //   for (var i = 0; i < array.length; i++) {
    //     sum += array[i]
    //     sum = sum.length
    //   }
    //   // alert(sum)
    //   return sum
    // },
    // companySize () {
    //   let allAcc = this.amountAccounts
    //   if (allAcc >= 5) {
    //     return ('No seats available')
    //   }
    //   alert(allAcc)
    //   return (allAcc)
    // }
  }
}
</script>
