<template>
  <div
    class="file-box text-center caption"
    :class="cardStyles"
  >
    <div class="file-box-icon-container">
      <div>
        <v-btn
          :color="success ? 'success' : 'primary'"
          icon
          small
          @click="previewFile()"
        >
          <v-icon>
            {{ icons.file }}
          </v-icon>
        </v-btn>
      </div>
    </div>
    <div class="file-text-container">
      <div
        class="primary--text"
        :style="getCursorType()"
        @click="previewFile()"
      >
        {{ fileName }}
      </div>
    </div>
    <v-btn
      v-if="hasDeleteButton"
      class="file-box-file-close"
      color="gray"
      fab
      x-small
      @click="$emit('close')"
    >
      <v-icon small>
        mdi-close
      </v-icon>
    </v-btn>
    <v-btn
      v-if="hasUploadButton"
      class="file-box-file-preview"
      color="gray"
      fab
      x-small
      dark
      @click="$emit('upload')"
    >
      <v-icon small>
        {{ icons.upload }}
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mdiFile, mdiUpload } from '@mdi/js'

export default {
  props: {
    fileName: {
      type: String,
      default: 'File'
    },
    fileType: {
      type: String,
      default: null
    },
    hasDeleteButton: {
      type: Boolean,
      default: true
    },
    hasUploadButton: {
      type: Boolean,
      default: false
    },
    pending: {
      type: Boolean,
      default: true
    },
    success: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    card: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      icons: {
        file: mdiFile,
        upload: mdiUpload
      }
    }
  },
  computed: {
    progressColor () {
      if (this.success) return 'success'
      if (this.error) return 'error'
      return 'primary'
    },
    progressValue () {
      if (this.success) return 100
      return 0
    },
    cardStyles () {
      if (this.card) {
        return [
          'file-box-card',
          'elevation-2',
          'pa-1'
        ]
      } else {
        return []
      }
    }
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast'
    }),
    previewFile () {
      if (this.fileType && (this.fileType === 'application/pdf' || this.fileType.indexOf('image') !== -1)) this.$emit('preview')
      else {
        this.addToast({
          title: this.$t('expressions|preview_only_image_and_pdf_before_upload'),
          snackbarColor: 'info',
          color: 'black'
        })
      }
    },
    getCursorType () {
      if (this.fileType && (this.fileType === 'application/pdf' || this.fileType.indexOf('image') !== -1)) return 'cursor: pointer;'
    }
  }
}
</script>

<style lang="scss" scoped>
  .file-box {
    position: relative;
    display: flex;
    align-items: center;
    .file-box-file-close, .file-box-file-preview {
      position: absolute;
      top: 50%;
      display: none;
      transform: translateY(-50%);
    }
    .file-box-file-close {
      right: 2px;
    }
    &:hover {
      .file-box-file-close {
        display: block;
      }
    }
  }
  .file-box-icon-container {
    position: relative;
    height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
    margin-right: 6px;
  }
  .file-box {
    &.file-box-card {
      flex-direction: row;
      justify-content: flex-start;
      .file-text-container {
        flex: 1;
        > div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
        }
      }
    }
  }
  .file-text-container {
    min-width: 0px;
    max-width: 100%;
    font-weight: bold;
    font-size: 15px;
  }
</style>
