<template>
  <v-container
    fluid
    style="width: 100%; position: fixed; bottom: 0px; z-index: 1;"
    class="pa-0"
  >
    <v-card
      flat
      color="lightBackground"
      class="pa-0 ma-0"
    >
      <v-card-text
        class="pa-0"
        style="white-space: normal;"
      >
        <v-row
          no-gutters
        >
          <v-col
            cols="12"
            class="pa-0"
          >
            <!-- BOLD -->
            <v-btn
              icon
              class="ml-1"
              :class="{ 'blue lighten-3': editor.isActive('bold') }"
              @click="$emit('bold')"
            >
              <v-icon>
                mdi-format-bold
              </v-icon>
            </v-btn>

            <!-- ITALIC -->
            <v-btn
              icon
              :class="{ 'blue lighten-3': editor.isActive('italic') }"
              @click="$emit('italic')"
            >
              <v-icon>
                mdi-format-italic
              </v-icon>
            </v-btn>

            <!-- UNDERLINE -->
            <v-btn
              icon
              :class="{ 'blue lighten-3': editor.isActive('underline') }"
              @click="$emit('underline')"
            >
              <v-icon>
                mdi-format-underline
              </v-icon>
            </v-btn>

            <!-- COLOR TEXT -->
            <v-menu
              offset-y
              top
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  :color="editor.getAttributes('textStyle').color"
                  v-on="on"
                >
                  <v-icon>
                    mdi-format-color-text
                  </v-icon>
                </v-btn>
              </template>
              <CustomSwatches
                :editor="editor"
                :color-text="true"
                @new-selected-color="newSelectedFontColor"
              />
            </v-menu>

            <!-- COLOR HIGHLIGHT -->
            <v-menu
              top
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  :color="editor.getAttributes('textStyle').backgroundColor"
                  v-on="on"
                >
                  <v-icon>
                    mdi-format-color-highlight
                  </v-icon>
                </v-btn>
              </template>
              <CustomSwatches
                :editor="editor"
                :color-highlight="true"
                @new-selected-color="newSelectedBackgroundColor"
              />
            </v-menu>

            <!-- ALIGN MENU -->
            <v-menu
              v-model="alignMenu"
              top
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-format-align-left
                  </v-icon>
                </v-btn>
              </template>
              <v-sheet
                color="white"
                class="pa-2"
              >
                <v-icon
                  class="mx-1"
                  :class="{ 'blue lighten-3': editor.isActive('paragraph', { textAlign: 'left' }) }"
                  @click="$emit('align-left')"
                >
                  mdi-format-align-left
                </v-icon>

                <v-icon
                  class="mx-1"
                  :class="{ 'blue lighten-3': editor.isActive('paragraph', { textAlign: 'center' }) }"
                  @click="$emit('align-center')"
                >
                  mdi-format-align-center
                </v-icon>

                <v-icon
                  class="mx-1"
                  :class="{ 'blue lighten-3': editor.isActive('paragraph', { textAlign: 'justify' }) }"
                  @click="$emit('align-justify')"
                >
                  mdi-format-align-justify
                </v-icon>

                <v-icon
                  class="mx-1"
                  :class="{ 'blue lighten-3': editor.isActive('paragraph', { textAlign: 'right' }) }"
                  @click="$emit('align-right')"
                >
                  mdi-format-align-right
                </v-icon>
              </v-sheet>
            </v-menu>

            <!-- LINE SPACING -->
            <v-menu
              offset-y
              top
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>
                    mdi-format-line-spacing
                  </v-icon>
                </v-btn>
              </template>
              <v-list
                class="scroll-num-list text-center"
                min-width="150"
              >
                <v-list-item
                  v-for="(item, index) in lineSpacingItems"
                  :key="index"
                  @click="$emit('line-spacing', item.text)"
                >
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- NUMBERED LIST -->
            <v-btn
              icon
              @click="$emit('numbered-list')"
            >
              <v-icon>
                mdi-format-list-numbered
              </v-icon>
            </v-btn>

            <!-- BULET LIST -->
            <v-btn
              icon
              @click="$emit('bulleted-list')"
            >
              <v-icon>
                mdi-format-list-bulleted
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mdiTableHeadersEye, mdiTableHeadersEyeOff, mdiTextBoxOutline } from '@mdi/js'
import CustomSwatches from '../../TemplateEditor/CustomSwatches.vue'
import { EventBus } from '@/utils/EventBus'
// import { debounce } from 'lodash'
import cfg from '@/config'

export default {
  components: { CustomSwatches },
  props: {
    editor: {
      type: Object,
      default: null
    },
    template: {
      type: Object,
      default: null
    },
    account: {
      type: Object,
      default: null
    },
    isActiveTable: {
      type: Boolean,
      default: false
    },
    actualFontSize: {
      type: String,
      default: null
    },
    disableCommentInTemplate: {
      type: Boolean,
      default: false
    },
    inTemplateOnly: {
      type: Boolean,
      default: false
    },
    loadingSavingData: {
      type: Boolean,
      default: false
    },
    connectionStatus: {
      type: String,
      default: null
    }
  },
  data: (instance) => ({
    searchDialogModel: false,
    flatFields: [],
    hasToolbarEllipsis: false,
    ellipsesToolbars: Object.keys({}).reverse(),
    headerEye: mdiTableHeadersEye,
    headerEyeOff: mdiTableHeadersEyeOff,
    selectedItemFileMenu: null,
    selectedItemEditMenu: null,
    searchInput: '',
    replaceWith: '',
    searchMenu: false,
    tableMenu: false,
    alignMenu: false,
    font: {
      name: 'Sofia-Sans',
      value: 'Sofia-Sans',
      label: 'Sofia-Sans',
      style: {
        fontFamily: 'Sofia-Sans'
      }
    },
    suggestionLanguages: cfg.usedLanguages,
    availableSizes: ['1', '1.15', '1.75', '2', 'etc...'],
    textSize: '11',
    numbers: [6, 8, 9, 10, 11, 12, 13, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    lineSpacingItems: [
      {
        text: '0.85'
      },
      {
        text: '1'
      },
      {
        text: 'normal'
      },
      {
        text: '1.5'
      },
      {
        text: '2'
      },
      {
        text: '3'
      },
      {
        text: '4'
      },
      {
        text: '5'
      }
    ],
    headings: ['Heading 1', 'Heading 2', 'Heading 3', 'Heading 4', 'Heading 5', 'Heading 6', 'Normal text'],
    selectedTextColor: '',
    selectedColor: '',
    selectedTableColor: '',
    selectedHeading: '',
    swatches: [
      ['#FFFFFF', '#FF0000', '#550000'],
      ['#FFFF00', '#AAAA00', '#555500'],
      ['#00FF00', '#00AA00', '#005500'],
      ['#00FFFF', '#00AAAA', '#005555'],
      ['#0000FF', '#0000AA', '#000000']
    ],
    fonts: [
      {
        name: 'Arial',
        value: 'Arial',
        label: 'Arial',
        style: {
          // optional style to apply to the item
          fontFamily: 'Arial'
        }
      },
      {
        name: 'Cambria',
        label: 'Cambria',
        value: 'Cambria',
        style: {
          // optional style to apply to the item
          fontFamily: 'Cambria'
        }
      },
      {
        name: 'Arial Black',
        label: 'Arial Black',
        value: 'Arial Black',
        style: {
          // optional style to apply to the item
          fontFamily: 'Arial Black'
        }
      },
      {
        name: 'Courier',
        label: 'Courier',
        value: 'Courier',
        style: {
          // optional style to apply to the item
          fontFamily: 'Courier'
        }
      },
      {
        name: 'Courier New',
        label: 'Courier New',
        value: 'Courier New',
        style: {
          // optional style to apply to the item
          fontFamily: 'Courier New'
        }
      },
      {
        name: 'Sofia Sans',
        label: 'Sofia Sans',
        value: 'Sofia Sans',
        style: {
          // optional style to apply to the item
          fontFamily: 'Sofia Sans'
        }
      },
      {
        name: 'Sans-Serif',
        label: 'Sans-Serif',
        value: 'Sans-Serif',
        style: {
          // optional style to apply to the item
          fontFamily: 'Sans-Serif'
        }
      },
      {
        name: 'Serif',
        label: 'Serif',
        value: 'Serif',
        style: {
          // optional style to apply to the item
          fontFamily: 'Serif'
        }
      },
      {
        name: 'Times New Roman',
        label: 'Times New Roman',
        value: 'Times New Roman',
        style: {
          // optional style to apply to the item
          fontFamily: 'Times New Roman'
        }
      },
      {
        name: 'Verdana',
        label: 'Verdana',
        value: 'Verdana',
        style: {
          // optional style to apply to the item
          fontFamily: 'Verdana'
        }
      },
      {
        name: 'Helvetica',
        label: 'Helvetica',
        value: 'Helvetica',
        style: {
          // optional style to apply to the item
          fontFamily: 'Helvetica'
        }
      },
      {
        name: 'Calibri',
        label: 'Calibri',
        value: 'Calibri',
        style: {
          // optional style to apply to the item
          fontFamily: 'Calibri'
        }
      },
      {
        name: 'Impact',
        label: 'Impact',
        value: 'Impact',
        style: {
          // optional style to apply to the item
          fontFamily: 'Impact'
        }
      }
    ],
    // hiddenIcons: [],
    window: {
      width: 0,
      height: 0
    },
    searchInputGeneralSearch: ''
  }),
  computed: {
    panelIconsIds () {
      return [
        {
          domItem: 'share-icon',
          action: undefined
        },
        {
          domItem: 'print-icon',
          action: undefined
        },
        {
          domItem: 'divider-5',
          action: undefined
        },
        {
          domItem: 'format-page',
          action: undefined
        },
        {
          domItem: 'page-layout-footer',
          action: undefined
        },
        {
          domItem: 'page-layout-header',
          action: undefined
        },
        {
          domItem: 'divider-4',
          action: undefined
        },
        {
          domItem: 'magnify',
          action: 'magnify'
        },
        {
          domItem: 'comment-outline',
          action: 'add-comment'
        },
        {
          domItem: 'add-suggestion',
          action: 'add-suggestion'
        },
        {
          domItem: 'divider-3',
          action: 'add-suggestion'
        },
        {
          domItem: 'insert-table',
          action: 'insert-table'
        },
        {
          domItem: 'list-checkbox',
          action: undefined
        },
        {
          domItem: 'list-bulleted',
          action: 'bulleted-list'
        },
        {
          domItem: 'list-numbered',
          action: 'numbered-list'
        },
        {
          domItem: 'align-left',
          action: 'align-left'
        },
        {
          domItem: 'divider-2',
          action: undefined
        },
        {
          domItem: 'color-highlight',
          action: undefined
        },
        {
          domItem: 'format-color-text',
          action: undefined
        },
        {
          domItem: 'format-underline',
          action: undefined
        },
        {
          domItem: 'format-italic',
          action: undefined
        },
        {
          domItem: 'format-bold',
          action: undefined
        }
      ]
    },
    fileMenuItems () {
      let itemsArr
      let disableShareBtn
      if (this.account && this.account.companyId) disableShareBtn = false
      else disableShareBtn = true
      if (this.account && this.account.accountType === 'lawyer') {
        itemsArr = [
          { text: this.$t('actions|save'), icon: 'mdi-content-save', action: 'save', disabled: false },
          { text: this.$t('actions|save_as'), icon: 'mdi-content-save-outline', action: 'save-as', disabled: false },
          { text: this.$t('templates|new_template'), icon: mdiTextBoxOutline, action: 'new-template', disabled: false },
          { text: this.$t('editor|export_as_word'), icon: 'mdi-export', action: 'export-template', disabled: false },
          { text: this.$t('editor|export_as_pdf'), icon: 'mdi-export', action: 'export-template-pdf', disabled: false },
          { text: this.$t('actions|share'), icon: 'mdi-share-variant', action: 'share-template', disabled: disableShareBtn },
          { text: this.$t('editor|version_history'), icon: 'mdi-history', action: 'open-version-history', disabled: true }
        ]
      }
      if (this.account && this.account.accountType !== 'lawyer') {
        itemsArr = [
          { text: this.$t('editor|export_as_word'), icon: 'mdi-export', action: 'export-template', disabled: false },
          { text: this.$t('editor|export_as_pdf'), icon: 'mdi-export', action: 'export-template-pdf', disabled: false }
        ]
      }
      return itemsArr
    },
    viewMenuItems () {
      return [
        { text: this.$t('editor|seggeustion_view_text'), icon: 'mdi-eye', action: 'change-suggestion-view-text' },
        { text: this.$t('editor|seggeustion_view_editor'), icon: 'mdi-eye-off', action: 'change-suggestion-view-editor' }
      ]
    },
    editMenuItems () {
      return [
        { text: this.$t('editor|undo'), icon: 'mdi-undo', action: 'undo', shortCut: 'Ctrl-Z', disabled: false },
        { text: this.$t('editor|redo'), icon: 'mdi-redo', action: 'redo', shortCut: 'Ctrl-Z', disabled: false },
        { text: this.$t('editor|cut'), icon: 'mdi-content-cut', action: 'cut', shortCut: 'Ctrl-X', disabled: false },
        { text: this.$t('editor|copy'), icon: 'mdi-content-copy', action: 'copy', shortCut: 'Ctrl-C', disabled: false },
        { text: this.$t('editor|paste'), icon: 'mdi-content-paste', action: 'paste', shortCut: 'Ctrl-V', disabled: false }
      ]
    },
    position () {
      const doc = this.editor.state.doc
      return doc
    },
    getActiveHeading () {
      if (this.editor.isActive('heading', { level: 1 })) return this.$t('editor|heading') + ' 1'
      if (this.editor.isActive('heading', { level: 2 })) return this.$t('editor|heading') + ' 2'
      if (this.editor.isActive('heading', { level: 3 })) return this.$t('editor|heading') + ' 3'
      if (this.editor.isActive('heading', { level: 4 })) return this.$t('editor|heading') + ' 4'
      if (this.editor.isActive('heading', { level: 5 })) return this.$t('editor|heading') + ' 5'
      if (this.editor.isActive('heading', { level: 6 })) return this.$t('editor|heading') + ' 6'
      else return this.$t('editor|normal_text')
    },
    getActiveFont () {
      return this.font.label
    },
    activeTable () {
      return this.isActiveTable
    }
  },
  watch: {
    activeTable (value) {
      if (value) this.tableMenu = true
      else this.tableMenu = false
    },
    searchInputGeneralSearch (value) {
      if (value) {
        this.searchDialogModel = true
      } else {
        this.searchDialogModel = false
      }
    },
    selectedTextColor (value) {
      if (value) {
        this.$emit('text-color', value)
      } else {
        value = this.editor.getAttributes('textStyle').color || '#000000'
      }
    },
    searchInput (value) {
      if (!value) this.$emit('find-in-text', '')
    },
    searchMenu (value) {
      if (!value) this.$emit('find-in-text', '')
    },
    // 'window.width': {
    //   handler (newVal, oldVal) {
    //     if (newVal < oldVal) {
    //       if (oldVal === 0) return
    //       for (let i = 0; i < this.panelIconsIds.length; i++) {
    //         const panel = document.getElementById('panel')
    //         const panelBounding = panel.getBoundingClientRect()
    //         const icon = this.panelIconsIds[i]
    //         const domElement = document.getElementById(icon.domItem)
    //         if (domElement) {
    //           const iconBounding = domElement.getBoundingClientRect()
    //           const isAllreadyInArrayIndex = this.hiddenIcons.findIndex(item => item === domElement.id)
    //           if ((panelBounding.right - 26) <= iconBounding.left && isAllreadyInArrayIndex === -1) {
    //             domElement.style = 'display: none;'
    //             this.hiddenIcons.push(domElement.id)
    //           }
    //         }
    //       }
    //     }
    //     if (newVal > oldVal) {
    //       if (oldVal === 0) return
    //       const panelIconsArrayReverse = this.panelIconsIds.reverse()
    //       for (let i = 0; i < panelIconsArrayReverse.length; i++) {
    //         const panel = document.getElementById('panel')
    //         const panelBounding = panel.getBoundingClientRect()
    //         const icon = panelIconsArrayReverse[i]
    //         const domElement = document.getElementById(icon.domItem)
    //         if (domElement && window.getComputedStyle(domElement).display === 'none') {
    //           domElement.style = 'display: inherit'
    //         }
    //         if (domElement) {
    //           const iconBounding = domElement.getBoundingClientRect()
    //           const isAllreadyInArrayIndex = this.hiddenIcons.findIndex(item => item === domElement.id)
    //           if (panelBounding.right > iconBounding.left && isAllreadyInArrayIndex !== -1) {
    //             this.hiddenIcons.splice(isAllreadyInArrayIndex, 1)
    //           }
    //         }
    //       }
    //     }
    //   },
    //   deep: true
    // },
    position: {
      handler () {
        if (this.editor.isActive('textStyle', { fontFamily: 'Sofia Sans' })) {
          this.font = {
            name: 'Sofia Sans',
            value: 'Sofia Sans',
            label: 'Sofia Sans',
            style: {
            // optional style to apply to the item
              fontFamily: 'Sofia Sans'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Arial' })) {
          this.font = {
            name: 'Arial',
            value: 'Arial',
            label: 'Arial',
            style: {
            // optional style to apply to the item
              fontFamily: 'Arial'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Cambria' })) {
          this.font = {
            name: 'Cambria',
            value: 'Cambria',
            label: 'Cambria',
            style: {
            // optional style to apply to the item
              fontFamily: 'Cambria'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Arial Black' })) {
          this.font = {
            name: 'Arial Black',
            value: 'Arial Black',
            label: 'Arial Black',
            style: {
            // optional style to apply to the item
              fontFamily: 'Arial Black'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Courier' })) {
          this.font = {
            name: 'Courier',
            value: 'Courier',
            label: 'Courier',
            style: {
            // optional style to apply to the item
              fontFamily: 'Courier'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Courier New' })) {
          this.font = {
            name: 'Courier New',
            value: 'Courier New',
            label: 'Courier New',
            style: {
            // optional style to apply to the item
              fontFamily: 'Courier New'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Sans-Serif' })) {
          this.font = {
            name: 'Sans-Serif',
            value: 'Sans-Serif',
            label: 'Sans-Serif',
            style: {
            // optional style to apply to the item
              fontFamily: 'Sans-Serif'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Serif' })) {
          this.font = {
            name: 'Serif',
            value: 'Serif',
            label: 'Serif',
            style: {
            // optional style to apply to the item
              fontFamily: 'Serif'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Times New Roman' })) {
          this.font = {
            name: 'Times New Roman',
            value: 'Times New Roman',
            label: 'Times New Roman',
            style: {
            // optional style to apply to the item
              fontFamily: 'Times New Roman'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Verdana' })) {
          this.font = {
            name: 'Verdana',
            value: 'Verdana',
            label: 'Verdana',
            style: {
            // optional style to apply to the item
              fontFamily: 'Verdana'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Helvetica' })) {
          this.font = {
            name: 'Helvetica',
            value: 'Helvetica',
            label: 'Helvetica',
            style: {
            // optional style to apply to the item
              fontFamily: 'Helvetica'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Calibri' })) {
          this.font = {
            name: 'Calibri',
            value: 'Calibri',
            label: 'Calibri',
            style: {
            // optional style to apply to the item
              fontFamily: 'Calibri'
            }
          }
        }
        if (this.editor.isActive('textStyle', { fontFamily: 'Impact' })) {
          this.font = {
            name: 'Impact',
            value: 'Impact',
            label: 'Impact',
            style: {
            // optional style to apply to the item
              fontFamily: 'Impact'
            }
          }
        }
      }
    },
    actualFontSize (value) {
      if (value) {
        let newVal = null
        const p = value.indexOf('p')
        const x = value.indexOf('x')
        newVal = value.slice(0, p)
        value.slice(0, x)
        this.textSize = newVal
      } else {
        this.textSize = null
      }
    }
  },
  mounted () {
    this.handleIconMenu()
    // setTimeout(() => {
    //   this.calculateToolbarGroups()
    // }, '1000')
  },
  created () {
    // window.addEventListener('resize', this.handleIconMenu)
    // window.addEventListener('resize', this.callDebounceCalculate)
  },
  destroyed () {
    // window.removeEventListener('resize', this.handleIconMenu)
    // window.removeEventListener('resize', this.callDebounceCalculate)
  },
  methods: {
    // callDebounceCalculate: debounce(function () {
    //   this.calculateToolbarGroups()
    // }, 1300),
    // async calculateToolbarGroups () {
    //   this.ellipsesToolbars = {}
    //   this.hasToolbarEllipsis = false
    //   await this.$nextTick()
    //   const container = this.$refs.toolbarGroupContainer
    //   // const containerRect = container.getBoundingClientRect()
    //   const toolbarGroups = [...container.querySelectorAll('.toolbar-group')]
    //   toolbarGroups.forEach((groupEl) => {
    //     // const groupElRect = groupEl.getBoundingClientRect()
    //     // if => containerRect.width <= groupElRect.left + groupElRect.width - 280
    //     if (groupEl.offsetTop > 80) {
    //       const toolbarIndex = groupEl.dataset.toolbar
    //       this.ellipsesToolbars[toolbarIndex] = true
    //       this.hasToolbarEllipsis = true
    //     }
    //   })
    // },
    updateCategory (category) {
      EventBus.$emit('updateTempCat', category)
    },
    newSelectedFontColor (color) {
      this.$emit('text-color', color)
    },
    newSelectedBackgroundColor (color) {
      this.$emit('highlight', color)
    },
    checkActive (input) {
      const result = this.editor.isActive('textStyle', { fontFamily: input })
      return result
    },
    handleIconMenu () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    // isHidden (id) {
    //   const foundItem = this.hiddenIcons.find(item => item === id)
    //   if (foundItem) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    closeReset () {
      this.searchInputGeneralSearch = ''
      this.searchDialogModel = false
    },
    clearInput () {
      this.searchInputGeneralSearch = ''
    },
    translateCategoryIfNeeded (category) {
      if (category === 'none') return this.$t('common|uncategorized')
      else return category
    }
  }
}
</script>
