<template>
  <div>
    <div
      v-if="loading"
      class="text-center"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </div>
    <v-list-item
      v-else
      v-ripple
      two-line
      style="cursor: pointer;"
      class="pa-0"
    >
      <template v-slot:default="{ active }">
        <v-list-item-action
          v-if="hasSelection"
          class="pa-0 ma-3"
        >
          <v-checkbox
            :input-value="active"
          />
        </v-list-item-action>
        <v-list-item-action
          v-if="hasActions"
          class="mr-n1"
        >
          <v-row
            dense
            no-gutters
          >
            <v-col
              v-if="inCase && !inCaseWorkbench"
              cols="1"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    dark
                    large
                    class="handle-icon"
                    style="cursor: grab;"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.drag }}
                  </v-icon>
                </template>
                <span>{{ $t('actions|drag_document_to_workbench') }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              :cols="inCase && !inCaseWorkbench ? '11' : '12'"
              :class="inCase && !inCaseWorkbench ? 'mt-9 mr-n1 pl-1' : ''"
            >
              <v-icon
                v-if="inDialog && isIcon(document.raw.mimetype, document.raw.originalname)"
                class="mr-2"
                :color="iconType.color"
              >
                {{ iconType.icon }}
              </v-icon>
              <img
                v-if="inDialog && document && (document.raw.mimetype === 'application/vnd.apple.pages' || document && document.raw.mimetype ==='application/vnd.apple.numbers')"
                :src="getSvg(document.raw.mimetype, document.raw.originalname)"
                height="24px"
                class="mr-3"
                width="auto"
              >
              <img
                v-if="document.raw.mimetype === 'application/octet-stream' && !isIcon(document.raw.mimetype, document.raw.originalname)"
                :src="getOctetValue(document.raw.originalname)"
                height="24px"
                width="auto"
                class="mr-3"
              >
              <v-menu
                v-if="!inDialog"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    class="ml-n1"
                    v-on="on"
                  >
                    <v-icon>
                      {{ icons.mdiCog }}
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-if="inCase || inCaseWorkbench"
                    @click="$emit('open-doc')"
                  >
                    <v-icon
                      color="primary"
                      class="mr-2"
                    >
                      mdi-arrow-top-right
                    </v-icon>
                    <v-list-item-title>{{ $t('actions|open') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="$emit('deleteAction')">
                    <v-icon
                      color="primary"
                      class="mr-2"
                    >
                      mdi-link-off
                    </v-icon>
                    <v-list-item-title>{{ $t('actions|unassign') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-list-item-action>
        <v-list-item-action v-if="hasSearchActions">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click.native="openDocument(document, 'from-general-search')"
              >
                <v-icon
                  :color="iconType.color"
                >
                  {{ iconType.icon }}
                </v-icon>
              </v-btn>
            </template>
            <FileView
              ref="fileView"
            />
          </v-menu>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title
            v-if="!inCase"
            style="cursor: pointer;"
            :style="$vuetify.breakpoint.mobile ? 'white-space: normal' : ''"
            class="mb-n4"
            v-html="document.raw.originalname"
          />
          <v-list-item-title
            v-if="inCase"
            class="ml-2"
          >
            <v-icon
              small
              color="primary"
              class="mb-1"
            >
              mdi-file-outline
            </v-icon>
            {{ document.raw.originalname }}
          </v-list-item-title>

          <div
            class="ml-1 mt-3"
          >
            <CustomAutocomplete
              v-if="inCase"
              :item="document"
              :item-text="getFieldValue(document.info.document_type, selectedLanguage)"
              :index="index"
              :fields="_fields"
              :used-in-documents-table="false"
              :component-is-used-in="inCaseWorkbench ? 'inCaseWorkbench' : inCase ? 'inCase' : 'default'"
              :input-width="'100%'"
              :input-outline-style="'none'"
              :input-outline-style-on-focus="'none'"
              @set-input-value="setInputValue"
              @click.native.stop.prevent
            />
          </div>
        <!-- <v-menu
            v-model="menu"
            offset-y
            max-width="400px"
            :close-on-content-click="false"
            @click.native.stop.prevent
          >
            <template v-slot:activator="{ on, attrs }">
              <span
                class="ml-2 pa-0 primary--text"
                v-bind="attrs"
                v-on="on"
              >
                {{ document.info && document.info.document_type ? document.info.document_type : 'unknown' }}
                <v-icon
                  class="ml-1"
                >
                  {{ icons.mdiChevronDown }}
                </v-icon>
              </span>
            </template>
            <v-list
              v-show="!customField"
              style="height:300px; overflow-y: auto; cursor: pointer;"
            >
              <v-list-item
                @click="customField = true"
              >
                <v-list-item-content>
                  {{ $t('common|custom') }}
                </v-list-item-content>
                <v-list-item-action
                  class="d-flex"
                >
                  <v-icon>
                    {{ icons.mdiCog }}
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
              <div
                v-for="(item, i) in _fields"
                :key="i"
              >
                <v-list-item
                  v-if="item.name"
                  @click="updateDocumentType(item.name)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
            <v-card
              v-show="customField"
            >
              <v-card-text>
                <v-text-field
                  v-model="customFieldInput"
                  outlined
                  dense
                  :label="$t('actions|add_document_type')"
                  class="mt-4 mx-2"
                />
              </v-card-text>
              <v-card-actions>
                <v-btn
                  small
                  rounded
                  outlined
                  @click="customField = false"
                >
                  <v-icon
                    small
                    class="mr-2"
                  >
                    mdi-undo
                  </v-icon>
                  {{ $t('actions|back') }}
                </v-btn>
                <v-spacer />
                <v-btn
                  small
                  rounded
                  color="primary"
                  @click="updateDocumentType(customFieldInput); menu = false; customField = false"
                >
                  {{ $t('actions|submit') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu> -->
        </v-list-item-content>

        <v-list-item-action class="d-flex">
          <!-- <v-list-item-action-text v-text="item.action"></v-list-item-action-text> -->
          <v-icon
            v-if="hasDocIcons && !inDialog"
            :color="iconType.color"
          >
            {{ iconType.icon }}
          </v-icon>
          <div
            v-if="document.status === 'pending'"
            class="ml-2"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-progress-circular
                  v-if="document.status === 'pending'"
                  indeterminate
                  size="22"
                  :width="3"
                  color="accent"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span>{{ $t('documents|processing_document') }}</span>
            </v-tooltip>
          </div>
          <div
            v-if="document.status === 'error'"
            class="ml-2"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-alert
                </v-icon>
              </template>
              <span>{{ $t('documents|processing_error') }}</span>
            </v-tooltip>
          </div>
          <div
            v-if="document.status === 'processed'"
            class="ml-2"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="document.status === 'processed'"
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>{{ $t('documents|processed_successfully') }}</span>
            </v-tooltip>
          </div>
        </v-list-item-action>
      </template>
    </v-list-item>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mdiFileImage, mdiFilePdfBox, mdiFileWord, mdiMicrosoftExcel, mdiCog, mdiCheckCircle, mdiChevronDown, mdiDrag } from '@mdi/js'
import FileView from '@/components/dialogs/FileView.vue'
import CustomAutocomplete from '../InputFields/CustomAutocomplete.vue'
import generalMixin from '@/utils/generalMixin'

export default {
  components: {
    FileView,
    CustomAutocomplete
  },
  mixins: [
    generalMixin
  ],
  props: {
    document: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: null
    },
    hasSelection: {
      type: Boolean,
      default: false
    },
    hasActions: {
      type: Boolean,
      default: true
    },
    hasSearchActions: {
      type: Boolean,
      default: false
    },
    hasDocIcons: {
      type: Boolean,
      default: false
    },
    inCase: {
      type: Boolean,
      default: false
    },
    inCaseWorkbench: {
      type: Boolean,
      default: false
    },
    inDialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      icons: {
        mdiCog,
        mdiCheckCircle,
        mdiChevronDown,
        drag: mdiDrag
      },
      selectedLanguage: localStorage.getItem('preferedLanguage') || 'en',
      selectedDocumentType: null,
      customField: false,
      customFieldInput: null,
      menu: false
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
      loading: state => state.documents.loading
    }),
    documentSuggestions () {
      const suggestions = Object.keys(this.settings.fields).reduce((acc, key) => {
        if (this.settings.fields[key].type === 'file-input') {
          acc[key] = this.settings.fields[key]
        }
        return acc
      }, {})
      return suggestions
    },
    _fields () {
      let _filtered
      const suggestions = Object.keys(this.documentSuggestions).map((key) => {
        let name = this.documentSuggestions[key].label[this.selectedLanguage] ?? undefined
        if (Array.isArray(this.documentSuggestions[key].label[this.selectedLanguage])) {
          name = this.documentSuggestions[key].label[this.selectedLanguage].join(' / ')
        }
        return {
          name,
          fieldKey: key,
          language: this.selectedLanguage
        }
      })
      // remove suggestions with no label in this actual language
      const removeUndefined = (obj) => {
        for (var prop in obj) {
          if (obj.hasOwnProperty(prop) && obj[prop] === undefined) {
            return
          }
        }
        return obj
      }
      _filtered = suggestions.filter(removeUndefined)
      if (!_filtered.length) _filtered.push({ name: this.$t('common|no_results_found') })
      return _filtered
    },
    iconType () {
      return this.getIconType(this.document.raw.mimetype)
    }
  },
  mounted () {
    window.addEventListener('pref-language-changed', (event) => {
      this.selectedLanguage = event.detail.lang
    })
  },
  methods: {
    ...mapActions({
      updateDocument: 'documents/updateDocument'
    }),
    getIconType (fileType) {
      switch (fileType) {
      case 'application/pdf':
        return {
          icon: mdiFilePdfBox,
          color: '#c11e07'
        }
      case 'image/jpeg':
        return {
          icon: mdiFileImage,
          color: '#ffbd2e'
        }
      case 'image/png':
        return {
          icon: mdiFileImage,
          color: 'blue'
        }
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/msword':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/rtf':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/vnd.ms-word.document.macroenabled.12':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/msword-template':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/vnd.oasis.opendocument.text-template':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/vnd.oasis.opendocument.text-flat-xml':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/vnd.oasis.opendocument.text':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/vnd.ms-excel':
        return {
          icon: mdiMicrosoftExcel,
          color: 'green darken-3'
        }
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return {
          icon: mdiMicrosoftExcel,
          color: 'green darken-3'
        }
      case 'application/vnd.oasis.opendocument.spreadsheet':
        return {
          icon: mdiMicrosoftExcel,
          color: 'green darken-3'
        }
      case 'application/octet-stream':
        return {
          icon: 'mdi-file',
          color: 'grey lighten-2'
        }
      default:
        return {
          icon: 'mdi-file',
          color: 'grey'
        }
      }
    },
    getSvg (fileType, docName) {
      if (docName && docName.includes('.pages')) return require(`@/assets/pages.svg`)
      if (docName && docName.includes('.numbers')) return require(`@/assets/numbers.png`)
      if (fileType && fileType === 'application/vnd.apple.pages') return require(`@/assets/pages.svg`)
      if (fileType && fileType === 'application/vnd.apple.numbers') return require(`@/assets/numbers.png`)
    },
    getOctetValue (docName) {
      if (docName && docName.includes('.pages')) return require(`@/assets/pages.svg`)
      if (docName && docName.includes('.numbers')) return require(`@/assets/numbers.png`)
    },
    isIcon (fileType, docName) {
      let rule = true
      if (fileType === 'application/vnd.apple.pages' || fileType === 'application/vnd.apple.numbers') {
        rule = false
      } else if (fileType === 'application/octet-stream') {
        if (docName && docName.includes('.pages')) rule = false
        if (docName && docName.includes('.numbers')) rule = false
      }
      return rule
    }
  }
}
</script>
