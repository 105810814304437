<template>
  <v-dialog
    :value="isVisible"
    max-width="700px"
    height="70%"
    scrollable
  >
    <v-card
      :key="componentKey"
    >
      <v-card-title class="pa-5">
        <v-slide-y-transition leave-absolute>
          <v-text-field
            v-if="!isCustomField"
            ref="query"
            v-model="query"
            background-color="grey lighten-4"
            solo
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('actions|search_suggestion')"
            autocomplete="off"
            hide-details
            class="custom-placeholer-color"
          />
          <div
            v-else
            class="headline"
            style="white-space: normal;"
          >
            {{ $t('fields|create_custom_suggestion') }}
            <v-btn
              icon
              class="mr-2"
              style="position: absolute; right: 10px; top: 15px;"
              @click="isVisible = false"
            >
              <v-icon v-text="icons.close" />
            </v-btn>
          </div>
        </v-slide-y-transition>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-slide-y-transition leave-absolute>
          <div
            v-if="isCustomField"
            class="pa-4"
          >
            <v-text-field
              ref="newFieldName"
              v-model="newField.name"
              :label="$t('suggestion|suggestion')"
              required
              outlined
              dense
              :error-messages="newFieldNameErrors"
              class="mt-0"
              autocomplete="off"
              @input="$v.newField.name.$touch()"
              @blur="$v.newField.name.$touch()"
            />
            <v-select
              v-model="newField.fieldType"
              :items="fieldTypes"
              :label="$t('common|type')"
              item-text="text"
              item-value="value"
              required
              outlined
              dense
              :menu-props="{ bottom: true, offsetY: true }"
              :error-messages="newFieldTypeErrors"
              class="mt-4"
              autocomplete="off"
              @input="$v.newField.fieldType.$touch()"
              @blur="$v.newField.fieldType.$touch()"
            />
          </div>
          <div v-else>
            <v-list
              ref="list"
            >
              <!-- No results if searching -->
              <div
                v-if="!filteredFields.length && !fieldsInUseInQuestionnaireOnly.length && !documentsFieldTags.length && !myTemplateFieldTags.length"
                style="text-align: center;"
              >
                {{ $t('common|no_results_found') }}
              </div>

              <!-- Create Custom Suggestion List Option -->
              <v-list-item
                class="suggestion-list__item px-2"
                :class="`list-item-0`"
                :input-value="0 === selectedField"
                @click="openCustomSuggestion"
              >
                <v-list-item-icon>
                  <v-icon v-text="icons.cog" />
                </v-list-item-icon>
                <v-list-item-content class="px-2 ml-n8">
                  <v-list-item-title
                    style="white-space: normal;"
                  >
                    {{ $t('fields|create_custom_suggestion') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Language menu -->
              <div v-show="query === null || query === ''">
                <v-icon
                  class="ml-2"
                >
                  mdi-web
                </v-icon>
                <v-menu
                  v-if="!isCustomField"
                  bottom
                  :offset-x="false"
                  :offset-y="true"
                >
                  <template v-slot:activator="{ on }">
                    <span
                      class="ml-1 black--text"
                      style="cursor: pointer;"
                      v-on="on"
                    >
                      {{ $t('common|language') }}
                      <v-icon
                        class="ml-n1"
                      >
                        mdi-menu-down
                      </v-icon>
                    </span>
                  </template>

                  <v-list
                    v-model="fieldTagDialogSuggestionLanguage"
                    class="text-center"
                  >
                    <template v-for="lang in languages">
                      <v-list-item
                        :key="lang"
                        @click="setLanguage(lang)"
                      >
                        <v-list-item-title>{{ lang }}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-divider
                  class="mt-2"
                />
              </div>

              <!-- My Custom/Modified Suggestions List -->
              <div
                v-if="myTemplateFieldTags && myTemplateFieldTags.length"
              >
                <v-list-item-title
                  style="display: flex; align-items: center; justify-content: center; white-space: normal;"
                  class="mt-3"
                >
                  <v-icon
                    class="mr-2"
                  >
                    mdi-clipboard-account
                  </v-icon>
                  {{ $t('suggestions|custom_suggestions_from_this_template') }}
                </v-list-item-title>
                <v-list-item
                  v-for="(s, index) in myTemplateFieldTags"
                  :key="s.id"
                  class="suggestion-list__item px-2"
                  :class="`list-item-${index + 1}`"
                  :input-value="index + 1 === selectedField"
                  @click="selectSuggestion(s, 'modified-suggestion')"
                >
                  <v-list-item-content class="px-2">
                    <v-list-item-title>{{ s.customLabel ? s.name + '[' + s.customLabel + ']' : s.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="myTemplateFieldTags && myTemplateFieldTags.length"
                />
              </div>

              <!-- Suggestions from the workbench assigned documents -->
              <div
                v-if="documentsFieldTags && documentsFieldTags.length"
              >
                <v-list-item-title
                  style="display: flex; align-items: center; justify-content: center; white-space: normal;"
                  class="mt-3"
                >
                  <v-icon
                    class="mr-2"
                  >
                    mdi-file-multiple
                  </v-icon>
                  {{ $t('suggestions|custom_suggestions_from_all_workbench_documents') }}
                </v-list-item-title>
                <v-list-item
                  v-for="(s, index) in documentsFieldTags"
                  :key="s.id"
                  class="suggestion-list__item px-2"
                  :class="`list-item-${index + 1}`"
                  :input-value="index + 1 === selectedField"
                  @click="selectSuggestion(s, 'modified-suggestion')"
                >
                  <v-list-item-content class="px-2">
                    <v-list-item-title>{{ s.customLabel ? s.name + '[' + s.customLabel + ']' : s.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="documentsFieldTags && documentsFieldTags.length"
                />
              </div>

              <!-- Suggestions added in questionnaire but not in use in template list -->
              <div v-if="fieldsInUseInQuestionnaireOnly && fieldsInUseInQuestionnaireOnly.length">
                <v-list-item-title
                  v-if="fieldsInUseInQuestionnaireOnly.length"
                  style="display: flex; align-items: center; justify-content: center; white-space: normal;"
                  class="mt-3"
                >
                  <v-icon
                    class="ml-0"
                  >
                    mdi-help-box
                  </v-icon>
                  <span class="ml-3">
                    {{ $t('questionnaire|questionnaire_suggestions_not_in_use_in_template') }}
                  </span>
                </v-list-item-title>
                <v-list-item
                  v-for="(suggestion, index) in fieldsInUseInQuestionnaireOnly"
                  :key="suggestion.id"
                  class="suggestion-list__item px-2"
                  :class="`list-item-${index + 1}`"
                  :input-value="index + 1 === selectedField"
                  @click="selectSuggestion(suggestion, 'suggestion-in-use-in-questionnaire')"
                >
                  <template>
                    <v-list-item-content class="px-2">
                      <v-list-item-title class="d-flex justify-space-between">
                        {{ suggestion.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
                <v-divider
                  v-if="fieldsInUseInQuestionnaireOnly && fieldsInUseInQuestionnaireOnly.length"
                />
              </div>

              <!-- Lavvira suggestions -->
              <div v-if="filteredFields && filteredFields.length">
                <v-list-item-title
                  style="display: flex; align-items: center; justify-content: center; white-space: normal;"
                  class="mt-3"
                >
                  <img
                    src="@/assets/Lavvira_monogramm_logo.svg"
                    height="22px"
                    width="auto"
                  >
                  <span class="ml-2">{{ $t('suggestions|lavvira_suggestions') }}</span>
                </v-list-item-title>
                <v-list-item
                  v-for="(suggestion, index) in filteredFields"
                  :key="suggestion.id"
                  class="suggestion-list__item px-2"
                  :class="`list-item-${index + 1}`"
                  :input-value="index + 1 === selectedField"
                  @click="selectSuggestion(suggestion, 'lavvira-suggestion')"
                >
                  <template>
                    <v-list-item-content class="px-2">
                      <v-list-item-title
                        class="d-flex justify-space-between"
                        style="white-space: normal;"
                      >
                        {{ suggestion.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </div>
            </v-list>
          </div>
        </v-slide-y-transition>
      </v-card-text>
      <v-card-actions>
        <v-slide-y-transition>
          <v-btn
            v-if="isCustomField"
            color="primary"
            text
            @click="returnToList"
          >
            {{ $t('actions|back') }}
          </v-btn>
        </v-slide-y-transition>
        <v-spacer />
        <v-btn
          v-if="isCustomField"
          color="primary"
          rounded
          small
          min-width="150"
          @click="selectCustomSuggestion"
        >
          {{ $t('actions|add') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mdiCog, mdiClose } from '@mdi/js'
import { v4 as uuidv4 } from 'uuid'

export default {
  mixins: [validationMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    language: {
      type: String,
      default: 'en'
    },
    onSelect: {
      type: Function,
      default: null
    },
    command: {
      type: Function,
      required: true
    },
    removeFieldInput: {
      type: Boolean,
      default: true
    },
    templateFieldTags: {
      type: Array,
      default: () => ([])
    },
    actualTemplate: {
      type: Object,
      default: null
    },
    currentWorkbenchDocuments: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      isVisible: true,
      query: null,
      selectedField: null,
      isCustomField: false,
      fieldTagDialogSuggestionLanguage: localStorage.getItem('fieldTagDialogSuggestionLanguage') || localStorage.getItem('preferedLanguage') || 'en',
      icons: {
        cog: mdiCog,
        close: mdiClose
      },
      newField: {
        name: null,
        fieldType: null
      },
      newFieldTypes: [
        { value: 'text-input',
          text: 'Text'
        },
        {
          value: 'date-input',
          text: 'Date'
        },
        {
          value: 'currency-input',
          text: 'Currency'
        }
      ],
      componentKey: 0
    }
  },
  validations: {
    newField: {
      name: { required },
      fieldType: { required }
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
      templates: state => state.templates.templates,
      actualSuggestionsEditorStorage: state => state.editorStore.actualSuggestionsEditorStorage
    }),
    languages () {
      return this.settings.activeLanguages
    },
    fieldTypes () {
      if (this.removeFieldInput) return this.newFieldTypes
      else return [...this.newFieldTypes, 'file-input']
    },
    templateSuggestions () {
      const suggestions = Object.keys(this.settings.fields).reduce((acc, key) => {
        const item = this.settings.fields[key]
        if (this.removeFieldInput) {
          if (item.type !== 'file-input') {
            acc[key] = item
          }
        } else {
          acc[key] = item
        }
        return acc
      }, {})
      return suggestions
    },
    fields () {
      return this.templateSuggestions || []
    },
    _fields () {
      const suggestions = Object.keys(this.fields).map((key) => {
        let name = this.fields[key].label[this.fieldTagDialogSuggestionLanguage] ?? undefined
        if (Array.isArray(this.fields[key].label[this.fieldTagDialogSuggestionLanguage])) {
          name = this.fields[key].label[this.fieldTagDialogSuggestionLanguage].join(' / ')
        }
        return {
          id: uuidv4(),
          name,
          fieldType: this.fields[key].type,
          fieldKey: key,
          customHashtag: false,
          language: this.fieldTagDialogSuggestionLanguage,
          fieldClass: this.fields[key].class
        }
      })
      // remove suggestions with no label in this actual language
      const removeUndefined = (obj) => {
        for (var prop in obj) {
          if (obj.hasOwnProperty(prop) && obj[prop] === undefined) {
            return
          }
        }
        return obj
      }
      const _filtered = suggestions.filter(removeUndefined)
      return _filtered
    },
    filteredFields () {
      if (!this.query || this.query === '') return this._fields
      return this._fields.filter((s) => {
        const name = s.name.toLowerCase()
        const query = this.query.toLowerCase()
        return name.includes(query)
      })
    },
    fieldsInUseInQuestionnaireOnly () {
      let suggestions = []
      let filteredSuggestions = []
      let inUse
      if (this.actualTemplate.questionnaire.fields && this.actualTemplate.questionnaire.fields.length) {
        this.actualTemplate.questionnaire.fields.forEach(field => {
          inUse = this.actualSuggestionsEditorStorage.find(f => f.attrs.fieldKey.toLowerCase() === field.fieldKey.toLowerCase())
          if (!inUse) {
            suggestions.push(field)
          }
        })
      }
      filteredSuggestions = suggestions.filter(s => s.fieldType !== 'file-input')
      if (this.query && this.query.length) {
        filteredSuggestions = suggestions.filter((s) => {
          const name = s.name.toLowerCase()
          const query = this.query.toLowerCase()
          return name.includes(query) && s.fieldType !== 'file-input'
        })
      }
      return filteredSuggestions
    },
    newFieldKey () {
      return this.newField.name && this.newField.name.split(' ').join('_').toLowerCase()
    },
    customFieldExists () {
      return Object.keys(this.templateSuggestions).some((key) => key.toLowerCase() === this.newFieldKey)
    },
    newFieldNameErrors () {
      const errors = []
      if (!this.$v.newField.name.$dirty) return errors
      if (this.customFieldExists) errors.push(this.$t('suggestion|suggestion_is_already_existing'))
      !this.$v.newField.name.required && errors.push(this.$t('suggestion|suggestion_is_required'))
      return errors
    },
    newFieldTypeErrors () {
      const errors = []
      if (!this.$v.newField.fieldType.$dirty) return errors
      !this.$v.newField.fieldType.required && errors.push(this.$t('fields|field_type_is_required'))
      return errors
    },
    myTemplateFieldTags () {
      if (!this.templateFieldTags) return
      let myTags = []
      let filteredTags = []
      for (let i = 0; i < this.templateFieldTags.length; i++) {
        const tag = this.templateFieldTags[i]
        if (tag.customHashtag || tag.customLabel) {
          myTags.push(tag)
        }
      }
      filteredTags = myTags
      if (this.query && this.query.length) {
        filteredTags = myTags.filter((s) => {
          const name = s.name.toLowerCase()
          const query = this.query.toLowerCase()
          return name.includes(query)
        })
      }
      return filteredTags
    },
    documentsFieldTags () {
      let arr = []
      let _array = []
      this.currentWorkbenchDocuments.forEach(doc => {
        _array = Object.keys(doc.info.content[0].lavvira_labels)
          .map((entr) => {
            let item
            let name

            const entrExistsInSuggestionListIndex = Object.keys(this.settings.fields).findIndex(item => item === entr)
            if (entrExistsInSuggestionListIndex === -1) {
              name = entr.split('_').join(' ')
              item = {
                id: uuidv4(),
                name,
                fieldType: 'text-input',
                fieldKey: name,
                customHashtag: false,
                language: this.fieldTagDialogSuggestionLanguage
              }
            } else {
              item = {
                _delete: true
              }
            }
            return item
          })
        arr = _array.filter(item => {
          if (!item._delete) return item
        })
      })
      if (this.query && this.query.length) {
        const filteredArr = _array.filter(item => {
          if (!item._delete) return item
        })
        arr = filteredArr.filter((s) => {
          const name = s.name.toLowerCase()
          const query = this.query.toLowerCase()
          return name.includes(query)
        })
      }
      return arr
    }
  },
  watch: {
    query () {
      this.selectedField = null
    },
    async value (isVisible) {
      if (isVisible) {
        this.isCustomField = false
        this.query = null
        this.selectedField = null
        await this.$nextTick()
        this.$refs.query.focus()
      }
    },
    async isCustomField (value) {
      if (!value) {
        this.query = null
        this.selectedField = null
        this.resetCustomFields()
        await this.$nextTick()
        this.$refs.query.focus()
      } else {
        await this.$nextTick()
        this.$refs.newFieldName.focus()
      }
    }
  },
  async created () {
    this.componentKey++
    this.attachListners()
    await this.$nextTick()
    this.$refs.query.focus()
  },
  async beforeDestroy () {
    this.detachListeners()
  },
  methods: {
    attachListners () {
      document.addEventListener('keydown', this.onKeyDown)
    },
    detachListeners () {
      document.removeEventListener('keydown', this.onKeyDown)
    },
    onKeyDown (e) {
      if (e.keyCode === 40) {
        this.onArrowDown()
      } else if (e.keyCode === 38) {
        this.onArrowUp()
      } else if (e.keyCode === 13) {
        this.onEnter()
      }
    },
    async onArrowDown () {
      if (this.selectedField === null) this.selectedField = 0
      else if (this.filteredFields.length > this.selectedField) this.selectedField += 1
      if (this.isCustomField) return
      await this.$nextTick()
      const element = this.$refs.list.$el.querySelector(`.list-item-${this.selectedField}`)
      element.scrollIntoView({ block: 'nearest' })
    },
    async onArrowUp () {
      if (this.selectedField === null) this.selectedField = 0
      else if (this.selectedField !== 0) this.selectedField -= 1
      if (this.isCustomField) return
      await this.$nextTick()
      const element = this.$refs.list.$el.querySelector(`.list-item-${this.selectedField}`)
      element.scrollIntoView({ block: 'nearest' })
    },
    onEnter () {
      if (this.isCustomField) {
        this.selectCustomSuggestion()
      } else {
        if (this.selectedField === null) return
        if (this.selectedField === 0) return this.openCustomSuggestion()
        this.selectSuggestion(this.filteredFields[this.selectedField - 1], 'lavvira-suggestion')
      }
    },
    returnToList () {
      this.isCustomField = false
    },
    openCustomSuggestion () {
      this.isCustomField = true
    },
    selectCustomSuggestion () {
      this.$v.newField.$touch()
      if (this.$v.newField.$error || this.customFieldExists) return
      const suggestion = {
        id: uuidv4(),
        name: this.newField.name,
        fieldType: this.newField.fieldType,
        fieldStatus: 'tag',
        fieldKey: this.newFieldKey,
        customHashtag: true
      }
      const info = 'custom-suggestion'
      this.selectSuggestion(suggestion, info)
    },
    selectSuggestion (suggestion, info) {
      this.onSelect(suggestion, this.command, info)
      this.isVisible = false
      this.query = null
      this.selectedField = null
      this.resetCustomFields()
    },
    resetCustomFields () {
      this.newField = {
        name: null,
        fieldType: null
      }
    },
    setLanguage (lang) {
      localStorage.setItem('fieldTagDialogSuggestionLanguage', lang)
      this.fieldTagDialogSuggestionLanguage = lang
    }
  }
}
</script>

<style scoped>
  .custom-placeholer-color input,
  .custom-label-color input{
    color: red!important;
  }
</style>
