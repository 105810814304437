import axios from '@/plugins/axios'
import Vue from 'vue'
import store from '../index'
import autobahn from 'autobahn'

const SET_LOADING = 'SET_LOADING'
const SET_COMPANY_CASE_FORMS = 'SET_COMPANY_CASE_FORMS'
const ADD_COMPANY_CASE_FORM = 'ADD_COMPANY_CASE_FORM'
const UPDATE_COMPANY_CASE_FORM = 'UPDATE_COMPANY_CASE_FORM'
const DELETE_COMPANY_CASE_FORM = 'DELETE_COMPANY_CASE_FORM'
const RESET_COMPANY_CASE_FORMS = 'RESET_COMPANY_CASE_FORMS'

const state = () => {
  return {
    loading: false,
    companyCaseForms: [],
    companyCaseFormsSubscriptions: []
  }
}

const mutations = {
  [SET_LOADING] (state, value) {
    state.loading = value
  },
  [SET_COMPANY_CASE_FORMS] (state, payload) {
    state.companyCaseForms = payload
  },
  [ADD_COMPANY_CASE_FORM] (state, payload) {
    const index = state.companyCaseForms.findIndex(cf => cf._id === payload._id)
    if (index === -1) state.companyCaseForms.push(payload)
    else Vue.set(state.companyCaseForms, index, payload)
  },
  [UPDATE_COMPANY_CASE_FORM] (state, payload) {
    const index = state.companyCaseForms.findIndex(c => c._id === payload._id)
    if (index !== -1) {
      Vue.set(state.companyCaseForms, index, payload)
    }
  },
  [DELETE_COMPANY_CASE_FORM] (state, payload) {
    const index = state.companyCaseForms.findIndex(c => c._id === payload._id)
    if (index !== -1) {
      state.companyCaseForms.splice(index, 1)
    }
  },
  [RESET_COMPANY_CASE_FORMS] (state) {
    state.companyCaseForms = []
    state.companyCaseFormsSubscriptions = []
  }
}

const actions = {
  fetchCompanyCaseForms ({ commit }, { _id }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getSharedCaseForms/${_id}`)
        commit(SET_COMPANY_CASE_FORMS, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  resetCompanyCaseForms ({ commit }) {
    commit(RESET_COMPANY_CASE_FORMS)
  },
  companyCaseFormsUpdatesSubscription ({ commit }, { _id }) {
    try {
      const connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })

      connection.onopen = function (session) {
        // call a remote procedure
        session.subscribe(`lavvira.updates.${_id}`, async (...args) => {
          const caseFormTopic = args[0].includes('CaseForm')
          const createTask = args[0].includes('create')
          const updateTask = args[0].includes('update')
          const deleteTask = args[0].includes('delete')
          const removeTask = args[0].includes('remove')
          if (caseFormTopic && createTask) {
            commit(ADD_COMPANY_CASE_FORM, args[0][0])
          } else if (caseFormTopic && updateTask) {
            const _caseForm = store.state.companyCaseForms.companyCaseForms.find(c => c._id === args[0][0]._id)
            if (_caseForm) {
              commit(UPDATE_COMPANY_CASE_FORM, args[0][0])
            } else {
              // implemet 'share task'!!! to be clear
              commit(UPDATE_COMPANY_CASE_FORM, args[0][0])
            }
          } else if (caseFormTopic && deleteTask) {
            commit(DELETE_COMPANY_CASE_FORM, args[0][0])
          } else if (caseFormTopic && removeTask) {
            const isShared = args[0][0].sharedWith.company.find(c => c._id === store.state.company.company._id)
            if (isShared) {
              commit(UPDATE_COMPANY_CASE_FORM, args[0][0])
            } else {
              commit(UPDATE_COMPANY_CASE_FORM, args[0][0])
            }
          }
        }).then(
          function (subscription) {
            store.state.companyCaseForms.companyCaseFormsSubscriptions.push(subscription)
          }
        )
      }

      connection.onclose = function (reason, details) {
        // handle connection lost
        console.log(reason, details, 'connection.onclose')
      }
      connection.open()
    } catch (e) {
      console.error(e, 'ERROR in autobahn connection')
    }
  },
  unsubscribeCompanyCaseFormsUpdate ({ commit }, sub) {
    sub.unsubscribe().then(
      function (gone) {
        if (gone) commit(RESET_COMPANY_CASE_FORMS)
        // successfully unsubscribed
      },
      function (error) {
        // unsubscribe failed
        console.error(error, 'error unsubscribe')
      }
    )
  }
}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions
}
