<template>
  <v-list-item
    v-ripple
    @click="$emit('open-case', caseProp)"
  >
    <template v-slot:default="{ active }">
      <v-list-item-action v-if="hasSelection">
        <v-checkbox :input-value="active" />
      </v-list-item-action>
      <v-list-item-action v-if="hasActions">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              link
              :to="`/cases/${caseProp._id}`"
              v-on="on"
              @click="$emit('close-menu')"
            >
              <v-icon
                color="info"
                dark
              >
                mdi-briefcase-edit
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="$emit('deleteAction')">
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title v-text="caseProp.caseName" />
      </v-list-item-content>
    </template>
  </v-list-item>
</template>

<script>
import { mdiCog } from '@mdi/js'

export default {
  props: {
    caseProp: {
      type: Object,
      default: null
    },
    hasSelection: {
      type: Boolean,
      default: false
    },
    hasActions: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      icons: {
        mdiCog
      }
    }
  }
}
</script>
