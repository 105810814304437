// import moment from 'moment'
import Vue from 'vue'
import axios from '@/plugins/axios'
import store from '../index'
import autobahn from 'autobahn'
import { uniqBy } from '@/utils/utils'

const SET_LOADING = 'SET_LOADING'
const SET_ERROR = 'SET_ERROR'
const SET_CASES = 'SET_CASES'
const SET_CASE_ID = 'SET_CASE_ID'
const ADD_GROUP_CASE = 'ADD_GROUP_CASE'
const UPDATE_GROUP_CASE = 'UPDATE_GROUP_CASE'
const DELETE_CASE_WORKBENCH = 'DELETE_CASE_WORKBENCH'
const DELETE_COMMENT = 'DELETE_COMMENT'
const UPDATE_CASE_FILE = 'UPDATE_CASE_FILE'
const DELETE_GROUP_CASE = 'DELETE_GROUP_CASE'
const RESET_GROUP_CASES = 'RESET_GROUP_CASES'

// const dateFormat = date => moment(date).format('DD/MM/YYYY')

const state = () => {
  return {
    casesError: null,
    groupCases: [],
    groupCasesSubscriptions: [],
    caseId: null,
    caseError: null,
    loading: false
  }
}

const mutations = {
  [SET_LOADING] (state, value) {
    state.loading = value
  },
  [SET_ERROR] (state, { key, error } = {}) {
    state[key] = error
  },
  [SET_CASES] (state, payload) {
    state.groupCases.push(payload)
    // state group cases array must be filtered for unique identification ids
    // because the appwrapper calls for each group the cases shared with it.
    // As cases can of course be repeated, they must be filtered by _id
    state.groupCases = uniqBy(state.groupCases, '_id')
  },
  [SET_CASE_ID] (state, _id) {
    state.caseId = _id
  },
  [ADD_GROUP_CASE] (state, payload) {
    const index = state.groupCases.findIndex(c => c._id === payload._id)
    if (index === -1) state.groupCases.push(payload)
    else Vue.set(state.groupCases, index, payload)
  },
  [UPDATE_GROUP_CASE] (state, payload) {
    const index = state.groupCases.findIndex(c => c._id === payload._id)
    Vue.set(state.groupCases, index, payload)
  },
  [DELETE_CASE_WORKBENCH] (state, { _id, workbenchId }) {
    const index = state.groupCases.findIndex(c => c._id === _id)
    const workbenchIndex = state.groupCases[index].workbenches.findIndex(f => f._id === workbenchId)
    Vue.delete(state.groupCases[index].workbenches, workbenchIndex)
  },
  [DELETE_COMMENT] (state, payload) {
    const index = state.groupCases.findIndex(c => c._id === payload._id)
    Vue.set(state.groupCases, index, payload)
  },
  [UPDATE_CASE_FILE] (state, { _id, fileId, payload }) {
    const index = state.groupCases.findIndex(c => c._id === _id)
    const fileIndex = state.groupCases[index].caseFiles.findIndex(f => f._id === fileId)
    Vue.set(state.groupCases[index].caseFiles, fileIndex, payload)
  },
  [DELETE_GROUP_CASE] (state, _id) {
    const index = state.groupCases.findIndex(c => c._id === _id)
    if (index !== -1) state.groupCases.splice(index, 1)
  },
  [RESET_GROUP_CASES] (state) {
    state.groupCases = []
    state.groupCasesSubscriptions = []
  }
}

const actions = {
  fetchGroupCases ({ commit }, { _id }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getSharedCases/${_id}`)
        if (data.success) {
          for (let i = 0; i < data.data.length; i++) {
            const c = data.data[i]
            commit(SET_CASES, c)
            commit(SET_ERROR, { key: 'casesError', error: null })
          }
          resolve()
        } else {
          commit(SET_ERROR, { key: 'casesError', error: data })
          reject(data)
        }
      } catch (e) {
        commit(SET_ERROR, { key: 'casesError', error: e })
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  fetchGroupCase ({ commit }, _id) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getCase/${_id}`)
        if (data.success) {
          commit(ADD_GROUP_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  setCaseId ({ commit }, _id) {
    commit(SET_CASE_ID, _id)
  },
  addGroupCase ({ commit }, payload) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/createCase`, payload)
        if (data.success) {
          commit(ADD_GROUP_CASE, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  addCaseFromCaseModel ({ commit }, payload) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/createCaseFromCaseForm`, payload)
        if (data.success) {
          commit(ADD_GROUP_CASE, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  // addMyComment ({ commit }, { _id, workbenchId, templateId, payload } = {}) {
  //   commit(SET_LOADING, true)
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const { data } = await axios.post(`/updateCase/${_id}/${workbenchId}/${templateId}/addComment`, payload)
  //       if (data.success) {
  //         commit(UPDATE_CASE, data.data)
  //         resolve()
  //       } else {
  //         reject(data)
  //       }
  //     } catch (e) {
  //       reject(e)
  //     } finally {
  //       commit(SET_LOADING, false)
  //     }
  //   })
  // },
  updateGroupCase ({ commit }, { _id, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}`, payload)
        if (data.success && data.updatedDoc) {
          commit(UPDATE_GROUP_CASE, data.data)
          store.dispatch('groupDocuments/getGroupDocument', { _id: data.updatedDoc })
          resolve()
        }
        if (data.success && data.newAssignedDocs) {
          commit(UPDATE_GROUP_CASE, data.data)
          data.newAssignedDocs.forEach(doc => {
            store.dispatch('groupDocuments/getGroupDocument', { _id: doc })
          })
          resolve()
        }
        if (data.success) {
          commit(UPDATE_GROUP_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteGroupCase ({ commit }, { _id } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        await axios.post(`/deleteCase/${_id}`)
        commit(DELETE_GROUP_CASE, _id)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  addGroupCaseWorkbench ({ commit }, { _id, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/addWorkbench`, payload)
        if (data.success) {
          commit(UPDATE_GROUP_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateGroupCaseWorkbench ({ commit }, { _id, workbenchId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/${workbenchId}/`, payload)
        if (data.success) {
          commit(UPDATE_GROUP_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteGroupCaseWorkbenchDocument ({ commit }, { _id, workbenchId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/remove/${workbenchId}/`, payload)
        if (data.success) {
          commit(UPDATE_GROUP_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteGroupCaseWorkbench ({ commit }, { _id, workbenchId } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/delete/${workbenchId}`)
        if (data.success) {
          commit(DELETE_CASE_WORKBENCH, { _id, workbenchId })
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  addGroupCaseWorkbenchTemplate ({ commit }, { _id, workbenchId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/${workbenchId}/addTemplate`, payload)
        if (data.success) {
          commit(UPDATE_GROUP_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateGroupCaseWorkbenchTemplate ({ commit }, { _id, workbenchId, templateId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/${workbenchId}/${templateId}`, payload)
        if (data.success) {
          commit(UPDATE_GROUP_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteMyComment ({ commit }, { _id, workbenchId, templateId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/${workbenchId}/${templateId}/deleteComment`, payload)
        if (data.success) {
          commit(UPDATE_GROUP_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteGroupCaseWorkbenchTemplate ({ commit }, { _id, workbenchId, templateId } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/${workbenchId}/delete/${templateId}`)
        if (data.success) {
          commit(UPDATE_GROUP_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  resetGroupCases ({ commit }) {
    commit(RESET_GROUP_CASES)
  },
  groupCasesUpdatesSubscription ({ commit }, { _id }) {
    try {
      const connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })

      connection.onopen = function (session) {
        // call a remote procedure
        session.subscribe(`lavvira.updates.${_id}`, async (...args) => {
          const caseTopic = args[0].includes('Case')
          const createTask = args[0].includes('create')
          const updateTask = args[0].includes('update')
          const deleteTask = args[0].includes('delete')
          const removeTask = args[0].includes('remove')
          if (caseTopic && createTask) {
            commit(ADD_GROUP_CASE, args[0][0])
          } else if (caseTopic && updateTask) {
            const _case = store.state.groupCases.groupCases.find(c => c._id === args[0][0]._id)
            if (_case) {
              commit(UPDATE_GROUP_CASE, args[0][0])
            } else {
              // implemet 'share task'!!! to be clear
              commit(UPDATE_GROUP_CASE, args[0][0])
            }
          } else if (caseTopic && deleteTask) {
            commit(DELETE_GROUP_CASE, args[0][0])
          } else if (caseTopic && removeTask) {
            // the removе task is used when a group is removed from sharedWith.groups
            // since all group documents are in one array
            // it is necessary to check with which group the document is still shared
            let groupIds = []
            let sharedArray = []
            for (let i = 0; i < store.state.company.company.groups.length; i++) {
              const group = store.state.company.company.groups[i]
              groupIds.push(group._id)
            }
            groupIds.forEach(groupId => {
              const stillShared = args[0][0].sharedWith.groups.findIndex(g => g._id === groupId)
              sharedArray.push(stillShared)
            })
            if (sharedArray.some((index) => index >= 0)) {
              commit(UPDATE_GROUP_CASE, args[0][0])
            } else {
              commit(UPDATE_GROUP_CASE, args[0][0])
            }
          }
        })
      }

      connection.onclose = function (reason, details) {
        // handle connection lost
        console.log(reason, details, 'connection.onclose')
      }
      connection.open()
    } catch (e) {
      console.error(e, 'ERROR in autobahn connection')
    }
  },
  usnsubscribeGroupCasesUpdates ({ commit }, sub) {
    sub.unsubscribe().then(
      function (gone) {
        if (gone) commit(RESET_GROUP_CASES)
        // successfully unsubscribed sub1
      },
      function (error) {
        // unsubscribe failed
        console.error(error)
      }
    )
  }
}

const getters = {
  menuCases: (state) => {
    const temp = {}
    state.groupCases.forEach((c) => {
      if (c.createdBy._id in temp) {
        temp[c.createdBy._id].groupCases.push(c)
      } else {
        temp[c.createdBy._id] = {
          createdBy: c.createdBy,
          groupCases: [c]
        }
      }
    })
    return temp
  },
  getCurrentCase: (state) => {
    if (state.caseId) {
      return state.groupCases.find(c => c._id === state.caseId)
    }
    return null
  }
}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions,
  getters
}
