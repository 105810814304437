<template>
  <div>
    <v-checkbox
      v-model="model"
      :label="label"
      :hint="hint"
      persistent-hint
    />
  </div>
</template>

<script>
import InputMixin from './InputMixin'

export default {
  mixins: [InputMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  }
}
</script>
