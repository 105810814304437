import axios from '@/plugins/axios'
import store from '../index'
import { EventBus } from '@/utils/EventBus'

const SET_TOKEN = 'SET_TOKEN'
const RESET_TOKEN = 'RESET_TOKEN'

var newTokenRequest

const state = () => {
  return {
    token: null
  }
}
const getNewToken = (payload) => {
  newTokenRequest = setTimeout(() => { store.dispatch('auth/refreshToken') }, payload.exp - 30000)
}

const stopOldTokenTimeout = () => {
  clearTimeout(newTokenRequest)
}

const mutations = {
  [SET_TOKEN] (state, payload) {
    state.token = payload.token
    localStorage.setItem('access', true)
    axios.defaults.headers.common['authorization'] = 'Bearer ' + state.token
    // EventBus.$emit('new-access-token', payload.token)
    getNewToken(payload)
  },
  [RESET_TOKEN] (state) {
    state.token = null
    localStorage.setItem('access', false)
    axios.defaults.headers.common['authorization'] = null
  }
}

const actions = {
  setToken ({ commit }, payload = {}) {
    commit(SET_TOKEN, payload)
  },
  removeToken ({ commit }) {
    commit(RESET_TOKEN)
  },
  async refreshToken ({ commit }) {
    stopOldTokenTimeout()
    const _id = localStorage.getItem('#$a!C%(C&!#i[D]#')
    axios.defaults.withCredentials = true
    const { data } = await axios.post(`/refreshToken/${_id}`, { withCredentials: true })

    // var today = new Date()

    // var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()

    if (!data.success && data.msg === 'No token provided.') {
      EventBus.$emit('stop-app-loading')
      this.$router.push('/login')
    }
    if (data && data.token) await commit(SET_TOKEN, data)
    axios.defaults.withCredentials = false
  }
}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions
}
