import { mergeAttributes } from '@tiptap/core'
import Mention from '@tiptap/extension-mention'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import { EventBus } from '@/utils/EventBus'
import MyCommentsComponent from './MyComments.vue'
import Suggestion from '@tiptap/suggestion'
import store from '@/store/index.js'
// import { uniqBy } from '@/utils/utils'

var updateCommentValues

const Comment = Mention.extend({
  name: 'comment',

  addOptions () {
    return {
      ...this.parent,
      renderLabel ({ options, node }) {
        return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`
      },
      destroyed: {
        default: false
      },
      suggestion: {
        char: '*',
        command: ({ editor, range, props }) => {
          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: 'comment',
                attrs: props
              },
              {
                type: 'text',
                text: ' '
              }
            ])
            .run()
        },
        allow: ({ editor, range }) => {
          return editor.can().insertContentAt(range, { type: 'comment' })
        }
      }
    }
  },

  group: 'inline',

  inline: true,

  selectable: true,

  atom: false,

  addAttributes () {
    return {
      id: {
        parseHTML: element => {
          return {
            id: element.getAttribute('data-id')
          }
        },
        renderHTML: attributes => {
          if (!attributes.id) {
            return {}
          }

          return {
            'data-id': attributes.id
          }
        }
      },

      label: {
        parseHTML: element => {
          return {
            label: element.getAttribute('data-label')
          }
        },
        renderHTML: attributes => {
          if (!attributes.label) {
            return {}
          }

          return {
            'data-label': attributes.label
          }
        }
      },

      fontSize: {
        parseHTML: element => {
          return {
            label: element.getAttribute('data-fontSize')
          }
        },
        renderHTML: attributes => {
          if (!attributes.fontSize) {
            return {}
          }

          return {
            'data-fontSize': attributes.fontSize
          }
        }
      },

      createdBy: {
        parseHTML: element => {
          return {
            label: element.getAttribute('data-createdBy')
          }
        },
        renderHTML: attributes => {
          if (!attributes.createdBy) {
            return {}
          }

          return {
            'data-createdBy': attributes.createdBy
          }
        }
      },

      resolved: {
        parseHTML: element => {
          return {
            label: element.getAttribute('data-resolved')
          }
        },
        renderHTML: attributes => {
          if (!attributes.resolved) {
            return {}
          }

          return {
            'data-resolved': attributes.resolved
          }
        }
      },

      commentText: {
        parseHTML: element => {
          return {
            label: element.getAttribute('data-comment-text')
          }
        },
        renderHTML: attributes => {
          if (!attributes.commentText) {
            return {}
          }

          return {
            'data-comment-text': attributes.commentText
          }
        }
      },

      avatar: {
        parseHTML: element => {
          return {
            label: element.getAttribute('data-avatar')
          }
        },
        renderHTML: attributes => {
          if (!attributes.avatar) {
            return {}
          }

          return {
            'data-avatar': attributes.avatar
          }
        }
      },

      replies: {
        parseHTML: element => {
          return {
            label: element.getAttribute('data-replies')
          }
        },
        renderHTML: attributes => {
          if (!attributes.replies) {
            return []
          }

          return {
            'data-replies': attributes.replies
          }
        }
      }
    }
  },

  addStorage () {
    return {
      actualComments: []
      // actualComments: uniqBy([], 'id')
    }
  },

  async onCreate () {
    const setComments = async () => {
      updateCommentValues = setTimeout(() => {
        this.storage.actualComments = []
        this.editor.view.state.doc.descendants((node, pos) => {
          if (node.type.name === 'comment') {
            this.storage.actualComments.push(node)
          }
        })
        store.dispatch('editorStore/setComments', this.storage.actualComments)
        EventBus.$emit('update-component-key')
      },
      1200)
    }
    await setComments()
  },

  onTransaction ({ editor, transaction }) {
    if (!this.editor || !this.options.inCase) return
    if (!transaction.steps.length) return
    if (transaction.steps[0] && !transaction.steps[0].slice) return
    if (transaction.steps[0].slice.content.content.length) return
    const updateComments = () => {
      updateCommentValues = setTimeout(() => {
        this.storage.actualComments = []
        this.editor.view.state.doc.descendants((node, pos) => {
          if (node.type.name === 'comment') {
            this.storage.actualComments.push(node)
          }
        })
        store.dispatch('editorStore/setComments', this.storage.actualComments)
        EventBus.$emit('update-component-key')
        this.options.ydoc.transact(() => {
          this.options.ymap.set('comments', 'onTransactionUpdate')
        })
      }, 1300)
    }
    const stopTimeout = () => {
      clearTimeout(updateCommentValues)
    }
    stopTimeout()
    updateComments()
  },

  onSelectionUpdate () {
    this.editor.view.state.doc.descendants((node, pos) => {
      if (node.type.name === 'comment') {
        EventBus.$emit('select-comment', node)
      } else {
        EventBus.$emit('no-comment-selection')
      }
    })
  },

  onDestroy () {
    store.dispatch('editorStore/resetComments')
  },

  parseHTML () {
    return [
      {
        tag: 'span[data-comment]'
      }
    ]
  },

  renderHTML ({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes({ 'data-comment': '' }, this.options.HTMLAttributes, HTMLAttributes),
      this.options.renderLabel({
        options: this.options,
        node
      })
    ]
  },

  renderText ({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node
    })
  },

  addKeyboardShortcuts () {
    return {
      Backspace: () => this.editor.commands.command(({ tr, state }) => {
        let isComment = false
        const { selection } = state
        const { empty, anchor } = selection

        if (!empty) {
          return false
        }

        state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
          if (node.type.name === this.name) {
            isComment = true
            tr.insertText(this.options.suggestion.char || '', pos, pos + node.nodeSize)

            return false
          }
        })

        return isComment
      })
    }
  },

  addProseMirrorPlugins () {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion
      })
    ]
  },

  addNodeView () {
    return VueNodeViewRenderer(MyCommentsComponent)
  }
})

export default Comment
