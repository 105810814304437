<template>
  <div>
    <!-- DESKTOP -->
    <v-app-bar
      v-if="account && toolbarState && !$vuetify.breakpoint.mobile"
      app
      color="primary"
      dark
      flat
      dense
      class="elevation-5"
    >
      <div
        v-if="!drawer"
        class="d-flex align-center justify-center mr-3"
      >
        <img
          class="logo"
          src="@/assets/Lavvira_monogramm_logo.svg"
          height="28px"
          width="auto"
        >
      </div>
      <span v-if="drawer">
        <v-btn
          icon
          @click.stop="addMiniAction()"
        >
          <v-icon v-if="!mini">
            mdi-chevron-left
          </v-icon>
          <v-icon class="mx-n2">
            mdi-menu
          </v-icon>
          <v-icon v-if="mini">
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </span>
      <v-spacer />
      <v-btn
        v-if="needsToBeShown(company, account)"
        color="accent"
        x-small
        class="mr-4"
        to="/activate-account"
      >
        {{ $t('actions|activate_account') }}
      </v-btn>
      <v-btn
        v-if="account.accountType === 'lawyer' && !company"
        color="accent"
        x-small
        link
        to="/create-company"
        class="mr-2"
      >
        {{ $t('actions|upgrade_plan') }}
      </v-btn>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="500"
        offset-y
        z-index="1000"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            depressed
            icon
            dark
            v-on="on"
          >
            <v-icon>mdi-message-text</v-icon>
          </v-btn>
        </template>

        <v-card
          color="#FAFAFA"
          class="rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-lg"
        >
          <v-toolbar
            flat
            color="#FAFAFA"
            width="100%"
            class="toolbar"
            :rounded="false"
          >
            <v-toolbar-title class="font-weight-light">
              {{ $t('feedback|form') }}
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                @click.stop="closeAndReset"
                @mouseover="hoverCloseButtonIcon = true"
                @mouseleave="hoverCloseButtonIcon = false"
              >
                <v-icon
                  v-if="hoverCloseButtonIcon"
                  small
                >
                  mdi-close
                </v-icon>
                <v-icon
                  v-else
                >
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-list
            color="#FAFAFA"
          >
            <v-list-item>
              <v-list-item-title
                class="font-weight-bold"
              >
                {{ $t('feedback|welcome') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list
            color="#FAFAFA"
          >
            <v-list-item>
              <v-text-field
                v-model="feedbackTopic"
                :label="$t('feedback|topic')"
                dense
                outlined
                :rules="rules"
                counter="50"
                maxlength="50"
              >
                <v-icon
                  v-if="feedbackTopic.length"
                  slot="append"
                  color="primary"
                  @click="feedbackTopic = ''"
                >
                  mdi-close
                </v-icon>
              </v-text-field>
            </v-list-item>

            <v-list-item>
              <v-textarea
                v-model="feedbackBody"
                outlined
                dense
                name="feedback"
                :label="$t('feedback|your_feedback')"
                clearable
              />
            </v-list-item>
          </v-list>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="sendFeedback"
            >
              {{ $t('feedback|send_feedback') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <template>
        <v-badge
          overlap
          offset-x="23"
          offset-y="25"
        >
          <v-menu
            bottom
            left
            :offset-x="false"
            :offset-y="true"
            z-index="1000"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                icon
                dark
                v-on="on"
              >
                <v-icon>
                  mdi-earth
                </v-icon>
              </v-btn>
            </template>

            <v-list min-width="60">
              <template v-for="lang in settings.activeLanguages">
                <v-list-item
                  :key="lang"
                  @click="changeLocale(lang)"
                >
                  <v-list-item-icon>
                    <img
                      :src="require(`@/assets/flags/${lang}.svg`)"
                      width="15px"
                      height="18px"
                    >
                  </v-list-item-icon>
                  <v-list-item-title class="ml-n7">
                    {{ lang }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
          <template v-slot:badge>
            <v-avatar
              size="17"
              class="mt-n1"
            >
              <img
                v-if="actualLanguage"
                :src="require(`@/assets/flags/${actualLanguage}.svg`)"
                width="15px"
                height="18px"
              >
              <v-icon
                v-else
                x-small
                color="primary"
              >
                mdi-web
              </v-icon>
            </v-avatar>
          </template>
        </v-badge>
      </template>

      <template>
        <v-badge
          v-if="notificationsAmount > 0"
          bordered
          color="error"
          icon="mdi-lock"
          overlap
          offset-x="20"
          offset-y="25"
          class="mr-2"
        >
          <v-btn
            icon
            link
            to="/notifications"
            @click="clearNotificationsBadge"
          >
            <v-icon>mdi-bell</v-icon>
          </v-btn>
          <template
            v-slot:badge
          >
            {{ notificationsAmount }}
          </template>
        </v-badge>
        <v-btn
          v-else
          icon
          link
          to="/notifications"
        >
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </template>
      <v-menu
        bottom
        left
        :offset-x="false"
        :offset-y="true"
        z-index="1000"
      >
        <template #activator="{ on: onMenu }">
          <v-tooltip
            bottom
            color="black"
          >
            <template #activator="{ on: onTooltip }">
              <v-avatar
                size="35"
                color="primary"
                class="mr-2 my-2"
                @mouseover="hoverAvatarIcon = true"
                @mouseleave="hoverAvatarIcon = false"
              >
                <img
                  v-if="account && account.avatar && !hoverAvatarIcon"
                  :src="`${cfg.baseURL}downloadAvatar/${account._id}/${account.avatar.raw.filename}`"
                >
                <v-icon
                  v-if="hoverAvatarIcon"
                  large
                  color="white"
                  class="icon grow"
                  v-on="{ ...onMenu, ...onTooltip }"
                >
                  {{ mdiArrowDownDropCircleOutline }}
                </v-icon>
                <v-icon
                  v-if="!hoverAvatarIcon && !account.avatar"
                  dark
                >
                  mdi-account-circle
                </v-icon>
              </v-avatar>
            </template>

            <div class="d-flex flex-column justify-center align-center">
              <div
                style="font-size: 15px;"
              >
                {{ accountName(account) }}
              </div>
              <div text-xs-center>
                <span>{{ userMailDomain(account) }}</span>
              </div>
            </div>
          </v-tooltip>
        </template>
        <v-list min-width="250">
          <template v-for="(item, i) in items">
            <v-list-item
              v-if="!item.isHidden"
              :key="i"
              :to="item.to"
            >
              <v-icon
                class="mr-2"
                v-text="item.icon"
              />
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item
            @click="logOut"
          >
            <v-icon
              class="mr-2"
            >
              mdi-logout-variant
            </v-icon>
            {{ $t('profile|log_out') }}
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- MOBILE -->
    <v-app-bar
      v-if="account && toolbarState && $vuetify.breakpoint.mobile"
      app
      color="primary"
      dark
      flat
      dense
      class="elevation-5"
    >
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs, value }">
          <v-btn
            icon
            dark
            large
            class="ml-n2"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              {{ value ? 'mdi-menu' : 'mdi-menu-open' }}
            </v-icon>
          </v-btn>
        </template>
        <v-list
          color="white"
        >
          <template
            v-for="(item, index) in mobileDrawerMenu"
          >
            <v-list-item
              v-if="checkLinkVisibility(item)"
              :key="index"
              :disabled="item.disabled"
              :link="!!item.to"
              :to="item.to"
              color="primary"
            >
              <v-list-item-icon>
                <v-icon
                  v-if="item.icon"
                  :size="item.size || 24"
                >
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <!-- <v-menu
        v-model="mobileDrawer"
        bottom
        right
        offset-y
      >
        <v-list
          color="white"
        >
          <template
            v-for="(item, index) in mobileDrawerMenu"
          >
            <v-list-item
              v-if="checkLinkVisibility(item)"
              :key="index"
              :disabled="item.disabled"
              :link="!!item.to"
              :to="item.to"
              color="primary"
            >
              <v-list-item-icon>
                <v-icon
                  v-if="item.icon"
                  :size="item.size || 24"
                >
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-menu> -->

      <v-spacer />

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-if="needsToBeShown(company, account)"
            color="accent"
            dark
            class="mx-2"
            v-bind="attrs"
            v-on="on"
          >
            {{ mdiAccountReactivateOutline }}
          </v-icon>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in activateAccountMenu"
            :key="index"
            :to="item.to"
          >
            <v-icon
              class="mr-2"
              v-text="item.icon"
            />
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-if="account.accountType === 'lawyer' && !company"
            color="accent"
            dark
            class="mx-2"
            v-bind="attrs"
            v-on="on"
          >
            {{ mdiAccountGroupOutline }}
          </v-icon>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in activateCompanyMenu"
            :key="index"
            :to="item.to"
          >
            <v-icon
              class="mr-2"
              v-text="item.icon"
            />
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="500"
        offset-y
        z-index="1000"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            depressed
            icon
            dark
            v-on="on"
          >
            <v-icon>mdi-message-text</v-icon>
          </v-btn>
        </template>

        <v-card
          color="#FAFAFA"
          class="rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-lg"
        >
          <v-toolbar
            flat
            color="#FAFAFA"
            width="100%"
            class="toolbar"
            :rounded="false"
          >
            <v-toolbar-title class="font-weight-light">
              {{ $t('feedback|form') }}
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                @click.stop="closeAndReset"
                @mouseover="hoverCloseButtonIcon = true"
                @mouseleave="hoverCloseButtonIcon = false"
              >
                <v-icon
                  v-if="hoverCloseButtonIcon"
                  small
                >
                  mdi-close
                </v-icon>
                <v-icon
                  v-else
                >
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-list
            color="#FAFAFA"
          >
            <v-list-item>
              <v-list-item-title
                class="font-weight-bold"
              >
                {{ $t('feedback|welcome') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list
            color="#FAFAFA"
          >
            <v-list-item>
              <v-text-field
                v-model="feedbackTopic"
                :label="$t('feedback|topic')"
                dense
                outlined
                :rules="rules"
                counter="50"
                maxlength="50"
              >
                <v-icon
                  v-if="feedbackTopic.length"
                  slot="append"
                  color="primary"
                  @click="feedbackTopic = ''"
                >
                  mdi-close
                </v-icon>
              </v-text-field>
            </v-list-item>

            <v-list-item>
              <v-textarea
                v-model="feedbackBody"
                outlined
                dense
                name="feedback"
                :label="$t('feedback|your_feedback')"
                clearable
              />
            </v-list-item>
          </v-list>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="sendFeedback"
            >
              {{ $t('feedback|send_feedback') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <template>
        <v-badge
          overlap
          offset-x="23"
          offset-y="25"
        >
          <v-menu
            bottom
            left
            :offset-x="false"
            :offset-y="true"
            z-index="1000"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                icon
                dark
                v-on="on"
              >
                <v-icon>
                  mdi-earth
                </v-icon>
              </v-btn>
            </template>

            <v-list min-width="60">
              <template v-for="lang in settings.activeLanguages">
                <v-list-item
                  :key="lang"
                  @click="changeLocale(lang)"
                >
                  <v-list-item-icon>
                    <img
                      :src="require(`@/assets/flags/${lang}.svg`)"
                      width="15px"
                      height="18px"
                    >
                  </v-list-item-icon>
                  <v-list-item-title class="ml-n7">
                    {{ lang }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
          <template v-slot:badge>
            <v-avatar
              size="17"
              class="mt-n1"
            >
              <img
                v-if="actualLanguage"
                :src="require(`@/assets/flags/${actualLanguage}.svg`)"
                width="15px"
                height="18px"
              >
              <v-icon
                v-else
                x-small
                color="primary"
              >
                mdi-web
              </v-icon>
            </v-avatar>
          </template>
        </v-badge>
      </template>

      <template>
        <v-badge
          v-if="notificationsAmount > 0"
          bordered
          color="error"
          icon="mdi-lock"
          overlap
          offset-x="20"
          offset-y="25"
          class="mr-2"
        >
          <v-btn
            icon
            link
            to="/notifications"
            @click="clearNotificationsBadge"
          >
            <v-icon>mdi-bell</v-icon>
          </v-btn>
          <template
            v-slot:badge
          >
            {{ notificationsAmount }}
          </template>
        </v-badge>
        <v-btn
          v-else
          icon
          link
          to="/notifications"
        >
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </template>

      <v-btn icon>
        <v-icon
          @click="goToMobileSearch()"
        >
          mdi-magnify
        </v-icon>
      </v-btn>

      <v-menu
        bottom
        left
        :offset-x="false"
        :offset-y="true"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            class="ml-n2"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list min-width="180">
          <template v-for="(item, i) in items">
            <v-list-item
              v-if="!item.isHidden"
              :key="i"
              :to="item.to"
            >
              <v-icon
                class="mr-2"
                v-text="item.icon"
              />
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item
            @click="logOut"
          >
            <v-icon
              class="mr-2"
            >
              mdi-logout-variant
            </v-icon>
            {{ $t('profile|log_out') }}
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import {
  mdiBell,
  mdiChevronLeft,
  mdiChevronRight,
  mdiArrowDownDropCircleOutline,
  mdiAccountReactivateOutline,
  mdiAccountGroupOutline,
  mdiApplicationCog,
  mdiAccountTie,
  mdiTextBoxOutline,
  mdiFile
} from '@mdi/js'
import cfg from '@/config'
import axios from '@/plugins/axios'

export default {
  components: { },
  data () {
    return {
      mdiBell,
      mdiChevronLeft,
      mdiChevronRight,
      mdiArrowDownDropCircleOutline,
      mdiAccountReactivateOutline,
      mdiAccountGroupOutline,
      mdiApplicationCog,
      mdiAccountTie,
      mdiTextBoxOutline,
      mdiFile,
      size: '',
      hoverAvatarIcon: false,
      cfg,
      menu: false,
      hoverCloseButtonIcon: false,
      rules: [v => v.length <= 50 || 'Max 50 characters'],
      feedbackTopic: '',
      feedbackBody: '',
      mobileDrawer: false,
      disabledMenuItems: false
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      settings: state => state.settings.settings,
      drawer: state => state.app.drawer,
      mini: state => state.app.mini,
      toolbarState: state => state.app.toolbar,
      actualLanguage: state => state.account.actualLanguage
    }),
    items () {
      if (!this.account) return []
      return [
        { title: this.$t('profile|profile'), icon: 'mdi-face', to: '/profile', isHidden: this.account.accountType === 'god' }
      ]
    },
    activateAccountMenu () {
      return [
        { title: this.$t('actions|activate_account'), icon: mdiAccountReactivateOutline, to: '/activate-account', isHidden: this.account.accountType === 'god' }
      ]
    },
    activateCompanyMenu () {
      return [
        { title: this.$t('actions|upgrade_plan'), icon: mdiAccountGroupOutline, to: '/create-company', isHidden: this.account.accountType === 'god' }
      ]
    },
    mobileDrawerMenu () {
      return [
        {
          title: this.$t('settings|settings'),
          to: '/settings',
          requiresAccountType: ['god'],
          icon: mdiApplicationCog,
          size: 27,
          disabled: false
        },
        {
          title: this.$t('dashboard|dashboard'),
          to: '/dashboard',
          requiresAccountType: ['company', 'individual', 'lawyer'],
          icon: 'mdi-home',
          disabled: false
        },
        {
          title: this.$t('common|professionals'),
          to: '/professionals',
          requiresAccountType: ['company', 'individual'],
          icon: mdiAccountTie,
          disabled: this.disabledMenuItems
        },
        {
          title: this.$t('clients|clients'),
          to: '/clients',
          requiresAccountType: ['lawyer'],
          icon: 'mdi-account-group',
          disabled: this.disabledMenuItems
        },
        {
          title: this.$t('cases|cases'),
          to: '/cases',
          requiresAccountType: ['company', 'individual', 'lawyer'],
          icon: 'mdi-briefcase-edit',
          disabled: this.disabledMenuItems
        },
        {
          title: this.$t('case_models|case_models'),
          to: '/models',
          requiresAccountType: ['lawyer', 'god'],
          icon: 'mdi-briefcase-edit-outline',
          disabled: false
        },
        {
          title: this.$t('templates|templates'),
          to: '/templates-mobile',
          requiresAccountType: ['lawyer', 'god'],
          icon: mdiTextBoxOutline,
          disabled: false
        },
        {
          title: this.$t('documents|documents'),
          to: '/documents',
          requiresAccountType: ['company', 'individual', 'lawyer', 'god'],
          icon: mdiFile,
          disabled: false
        },
        {
          title: this.$t('members|members'),
          to: '/members',
          requiresAccountType: ['lawyer'],
          requiressubscriptionType: ['lavvira-group-s', 'lavvira-group-l', 'lavvira-group-xl'],
          icon: 'mdi-account-supervisor',
          disabled: false
        }
      ]
    },
    notificationsAmount () {
      let notifications = []
      if (this.account && Object.keys(this.account).length) {
        for (let i = 0; i < this.account.myNotifications.length; i++) {
          const n = this.account.myNotifications[i]
          if (!n.status_read_toolbar) {
            notifications.push(n)
          }
        }
        for (let i = 0; i < this.account.invitations.length; i++) {
          const invitation = this.account.invitations[i]
          if (invitation.status === 'pending') {
            notifications.push(invitation)
          }
        }
        if (this.company) {
          for (let i = 0; i < this.company.invitations.length; i++) {
            const inv = this.company.invitations[i]
            notifications.push(inv)
          }
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            if (group) {
              const access = group.groupMembers.find(member => member._id === this.account._id)
              if (access) {
                group.invitations.forEach(inv => {
                  notifications.push(inv)
                })
              }
            }
          }
        }
      }
      return notifications.length
    }
  },
  mounted () {
    if (this.account && this.account.accountData && this.account.accountData.requiredFieldsFilled) {
      this.disabledMenuItems = false
    } else {
      this.disabledMenuItems = true
    }
  },
  created () {
    EventBus.$on('switch-icon', this.toggleDrawer)
  },
  beforeDestroy () {
    EventBus.$off('switch-icon', this.toggleDrawer)
  },
  methods: {
    ...mapMutations({
      toggleDrawer: 'app/toggleDrawer',
      toggleMini: 'app/toggleMini'
    }),
    ...mapActions({
      updateAccount: 'account/updateAccount',
      clearMyNotifications: 'account/clearMyNotifications',
      resetClients: 'clients/resetClients',
      resetCompanyClients: 'companyClients/resetCompanyClients',
      resetGroupClients: 'groupClients/resetGroupClients',
      resetCases: 'cases/resetCases',
      resetCaseForms: 'caseForms/resetCaseForms',
      resetTemplates: 'templates/resetTemplates',
      resetDocuments: 'documents/resetDocuments',
      resetCompany: 'company/resetCompany',
      resetCompanyCases: 'companyCases/resetCompanyCases',
      resetCompanyCaseForms: 'companyCaseForms/resetCompanyCaseForms',
      resetCompanyTemplates: 'companyTemplates/resetCompanyTemplates',
      resetCompanyDocuments: 'companyDocuments/resetCompanyDocuments',
      resetGroupCases: 'groupCases/resetGroupCases',
      resetGroupCaseForms: 'groupCaseForms/resetGroupCaseForms',
      resetGroupTemplates: 'groupTemplates/resetGroupTemplates',
      resetGroupDocuments: 'groupDocuments/resetGroupDocuments',
      removeToken: 'auth/removeToken',
      resetAccount: 'account/resetAccount',
      logUserOut: 'account/logUserOut',
      addToast: 'toasts/addToast'
    }),
    checkLinkVisibility (item) {
      if (this.account && item && this.account.companyId && this.company) {
        return this.account.companyId && this.account.companyId.length && item.requiresAccountType.includes(this.account.accountType)
      } else if (this.account && item && !item.requiressubscriptionType) {
        return item.requiresAccountType.includes(this.account.accountType) && (item.requiressubscriptionType ? item.requiressubscriptionType === 'none' : 'none')
      }
    },
    accountName (account) {
      if (account.accountData.firstName && account.accountData.lastName) {
        return account.accountData.firstName + ' ' + account.accountData.lastName
      } else if (account.accountData.firstName && !account.accountData.lastName) {
        return account.accountData.firstName
      } else if (!account.accountData.firstName && account.accountData.lastName) {
        return !account.accountData.lastName
      } else {
        return account.email
      }
    },
    userMailDomain (acc) {
      const splitted = acc.email.split('@')
      return splitted[1]
    },
    async changeLocale (lang) {
      try {
        const updatedAccountData = Object.assign(this.account.accountData, { preferedLanguage: lang })
        await this.updateAccount({ accountData: updatedAccountData })
        this.$root.$i18n.locale = lang
        this.$i18n.locale = lang
        this.$vuetify.lang.current = lang
        // IMPORTANT => We have to change the $dialog confirm actions language manualy
        // because main js is not updating on language change.
        this.$root.$dialog._components.confirm.options.actions = {
          false: this.$t('actions|cancel'),
          true: {
            text: this.$t('actions|confirm'),
            color: 'primary'
          }
        }
        window.dispatchEvent(new CustomEvent('pref-language-changed', {
          detail: {
            lang: localStorage.getItem('preferedLanguage')
          }
        }))
      } catch (error) {
        console.error(error, 'Error while changing language!')
      }
    },
    getCountryFlag (lang) {
      if (lang === 'en') return 'gb'
      else return lang
    },
    closeAndReset () {
      this.feedbackTopic = ''
      this.feedbackBody = ''
      this.menu = false
    },
    async sendFeedback () {
      const _id = this.account._id
      const payload = {
        feedbackTopic: this.feedbackTopic,
        feedbackBody: this.feedbackBody
      }
      try {
        const { data } = await axios.post(`/sendFeedback/${_id}`, payload)
        if (data.success) {
          this.addToast({
            title: this.$t('feedback|thank_you'),
            color: 'white',
            snackbarColor: 'success'
          })
        }
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
        this.addToast({
          title: this.$t('error|something_went_wrong'),
          color: 'white',
          snackbarColor: 'error'
        })
      } finally {
        this.closeAndReset()
      }
    },
    addMiniAction () {
      if (this.mini && this.mini === true) {
        this.toggleMini(false)
      } else {
        this.toggleMini(true)
      }
    },
    clearNotificationsBadge () {
      this.clearMyNotifications()
    },
    upgradeToCompany () {
      this.$router.push('/create-company')
    },
    needsToBeShown (company, account) {
      let rule = true
      if (!company && account.subscriptionStatus === 'paid') rule = false
      else if (!company && account.subscriptionStatus === 'beta') rule = false
      else if (company && account.subscriptionStatus === 'beta') rule = false
      else if (company && account.subscriptionStatus === 'company paid') rule = false
      return rule
    },
    goToMobileSearch () {
      this.$router.push('/search')
    },
    async logOut () {
      const message = 'logging_out'
      EventBus.$emit('app-loading', message)
      localStorage.setItem('access', false)
      await this.logUserOut()
      await this.resetClients()
      await this.resetCompanyClients()
      await this.resetGroupClients()
      await this.resetCases()
      await this.resetCaseForms()
      await this.resetTemplates()
      await this.resetDocuments()
      await this.resetCompany()
      await this.resetCompanyCases()
      await this.resetCompanyCaseForms()
      await this.resetCompanyTemplates()
      await this.resetCompanyDocuments()
      await this.resetGroupCases()
      await this.resetGroupCaseForms()
      await this.resetGroupTemplates()
      await this.resetGroupDocuments()
      await this.removeToken()
      await this.resetAccount()
      EventBus.$emit('stop-app-loading')
      localStorage.clear()
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
  .circle {
    stroke-width: 59;
    stroke-linecap: round;
  }
</style>
