<template>
  <node-view-wrapper
    :key="componentKey"
    class="mention"
    v-bind="node.attrs"
    :class="classes"
    :style="style"
    @contextmenu.native="show"
  >
    <template v-if="node.attrs.fieldState === 'tag'">
      <span
        :style="style"
        @click="onMentionClick"
      >
        @{{ node.attrs.label }}
      </span>
      <span v-if="node.attrs.customLabel">[{{ node.attrs.customLabel }}]</span>
      <span v-if="node.attrs.fieldValue">: {{ node.attrs.fieldValue }}</span>
    </template>
    <template v-if="node.attrs.fieldState === 'view'">
      {{ node.attrs.fieldValue }}
    </template>
    <v-menu
      v-if="extension.options.edit"
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list
        subheader
        class="pa-2"
      >
        <v-list-item
          v-if="node.attrs.fieldValue"
          dense
          @click="switchState(node, node.attrs.fieldState)"
        >
          <v-icon
            color="primary"
            size="20"
            class="mr-3"
          >
            {{ node.attrs.fieldState === 'tag' ? 'mdi-eye' : 'mdi-eye-off' }}
          </v-icon>
          {{ node.attrs.fieldState === 'tag' ? $t('common|text_mode') : $t('common|editor_mode') }}
        </v-list-item>
        <v-list-item
          v-if="node.attrs.fieldValue"
          dense
          @click="clearValue"
        >
          <v-icon
            color="primary"
            size="20"
            class="mr-3"
          >
            mdi-minus-circle-outline
          </v-icon>
          {{ $t('actions|remove_value') }}
        </v-list-item>
        <v-list-item
          dense
          @click="appendCustomLabel"
        >
          <v-list-item-icon>
            <v-icon
              color="primary"
              size="20"
              :class="!node.attrs.customLabel ? 'mt-0 ml-n4' : 'mt-1 ml-n4'"
            >
              {{ !node.attrs.customLabel ? 'mdi-tag-outline' : icons.tagOff }}
            </v-icon>
          </v-list-item-icon>
          <span class="ml-n10">{{ !node.attrs.customLabel ? $t('suggestions|customize_suggestion') : $t('actions|remove_custom_label') }}</span>
        </v-list-item>
        <div
          v-if="extension.options.options && extension.options.options.inCase"
        >
          <v-divider />
          <template v-if="values.length">
            <div v-if="values.length">
              <v-subheader>
                <v-icon
                  color="primary"
                  size="20"
                  class="mr-3"
                >
                  {{ icons.question }}
                </v-icon>
                {{ $t('questionnaire|questionnaire_answer') }}
              </v-subheader>
              <v-list-item
                v-for="(field, index) in values"
                :key="`qv-${index}`"
                dense
                @click="onValueChange(field.value)"
              >
                <v-list-item-content
                  v-if="field && field.value"
                >
                  <v-list-item-title v-text="field.value" />
                </v-list-item-content>
              </v-list-item>
            </div>
            <!-- <div
              v-else
              class="ml-10"
            >
              <span
                style="font-size: 12px;"
              >
                No client answer available
              </span>
            </div> -->
            <v-divider />
          </template>
          <v-subheader>
            <v-icon
              color="primary"
              size="20"
              class="mr-3"
            >
              {{ icons.textBox }}
            </v-icon>
            {{ $t('documents|extracted_data') }}
          </v-subheader>
          <template v-if="extractedValues.length">
            <v-list-item
              v-for="(item, index) in extractedValues"
              :key="`ev-${index}`"
              dense
              @click="onValueChange(item.value)"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-bold"
                  v-text="item.value"
                />

                <v-list-item-subtitle v-text="item.doc ? item.doc.raw.originalname : $t('clients|client_data')" />
              </v-list-item-content>

              <v-list-item-action>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="item.doc"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="openDocument(item.doc)"
                    >
                      <v-icon
                        color="primary"
                      >
                        {{ icons.file }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('actions|open') }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="!item.doc"
                      icon
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="goToClient"
                    >
                      <v-icon>
                        mdi-account
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('actions|open') }}</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </template>
          <div
            v-else
            class="ml-10"
          >
            <span
              style="font-size: 14px;"
            >
              {{ $t('mixed|no_extracted_data_available') }}
            </span>
          </div>
        </div>
      </v-list>
    </v-menu>
    <file-view
      ref="fileView"
    />
  </node-view-wrapper>
</template>

<script>
import {
  mdiFile,
  mdiProgressQuestion,
  mdiTextBoxSearchOutline,
  mdiTagOffOutline
} from '@mdi/js'
import {
  mapState,
  mapMutations,
  mapActions
} from 'vuex'
import { NodeViewWrapper } from '@tiptap/vue-2'
import { EventBus } from '@/utils/EventBus'
import generalMixin from '@/utils/generalMixin.js'
import FileView from '../../../components/dialogs/FileView.vue'

export default {
  components: {
    NodeViewWrapper,
    FileView
  },
  mixins: [generalMixin],
  props: {
    editor: {
      type: Object,
      required: true
    },
    node: {
      type: Object,
      required: true
    },
    decorations: {
      type: Array,
      required: true
    },
    extension: {
      type: Object,
      required: true
    },
    updateAttributes: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      componentKey: 0,
      icons: {
        file: mdiFile,
        question: mdiProgressQuestion,
        textBox: mdiTextBoxSearchOutline,
        tagOff: mdiTagOffOutline
      },
      showMenu: false,
      x: 0,
      y: 0
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
      account: state => state.account.account,
      company: state => state.company.company,
      cases: state => state.cases.cases,
      companyCases: state => state.companyCases.companyCases,
      groupCases: state => state.groupCases.groupCases
    }),
    values () {
      // return this.extension.options.questionnaire.fields && this.extension.options.questionnaire.fields.length ? this.extension.options.questionnaire.fields.filter((f) => {
      //   if (f.key.toLowerCase() === this.node.attrs.fieldKey.toLowerCase()) {
      //     return f.customLabel === this.node.attrs.customLabel
      //   } else {
      //     return false
      //   }
      // }).map((f) => {
      //   f.active = false
      //   return f
      // }) : []
      let valArr = []
      this.extension.options.questionnaire.fields.filter((f) => {
        if (f.value && f.fieldKey.toLowerCase() === this.node.attrs.fieldKey.toLowerCase()) {
          valArr.push(f)
        }
      })
      return valArr
    },
    extractedValues () {
      let arr = []
      if (this.node.attrs && this.node.attrs.fieldKey) {
        // first we check if a company exists in the store and user has company account
        // if this is the case we apply the company profile info if available
        if (this.company) {
          switch (this.node.attrs.fieldKey) {
          case 'My_company_name':
            arr.push({
              value: this.company.companyData.companyName ||
                this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
            })
            break
          case 'My_company_email':
            arr.push({
              value: this.company.companyData.email ||
                this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
            })
            break
          case 'My_company_phone':
            arr.push({
              value: this.company.companyData.phone ||
                this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
            })
            break
          case 'My_company_fax':
            arr.push({
              value: this.company.companyData.fax ||
                this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
            })
            break
          case 'My_company_registration_number':
            arr.push({
              value: this.company.companyData.registrationNumber ||
                this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
            })
            break
          case 'My_company_address_street':
            arr.push({
              value: this.company.companyData.address ||
                this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
            })
            break
          case 'My_company_address_city':
            arr.push({
              value: this.company.companyData.city ||
                this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
            })
            break
          case 'My_company_address_country':
            arr.push({
              value: this.company.companyData.nationality ||
                this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
            })
            break
          case 'My_company_address_postal_code':
            arr.push({
              value: this.company.companyData.postal_code ||
                this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
            })
            break
          }
        }

        // then we check if the node(suggestion) is related to profile(personal data)
        // and we apply the info if available
        switch (this.node.attrs.fieldKey) {
        case 'My_first_name':
          arr.push({
            value: this.account.accountData.firstName ||
            this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'My_last_name':
          arr.push({
            value: this.account.accountData.lastName ||
            this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'My_address_street':
          arr.push({
            value: this.account.accountData.address ||
            this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'My_address_city':
          arr.push({
            value: this.account.accountData.city ||
            this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'My_address_postal_code':
          arr.push({
            value: this.account.accountData.postal_code ||
            this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'My_address_country':
          arr.push({
            value: this.account.accountData.nationality ||
            this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Client_first_name':
          arr.push({
            value: this.extension.options.caseData.client.clientData.given_names ||
            this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Client_last_name':
          arr.push({
            value: this.extension.options.caseData.client.clientData.surname ||
            this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Client_date_of_birth':
          arr.push({
            value: this.extension.options.caseData.client.clientData.date_of_birth ||
            this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Client_place_of_birth':
          arr.push({
            value: this.extension.options.caseData.client.clientData.place_of_birth ||
            this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Client_address_street':
          arr.push({
            value: this.extension.options.caseData.client.clientData.address ||
            this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Client_address_city':
          arr.push({
            value: this.extension.options.caseData.client.clientData.city ||
            this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Client_address_postal_code':
          arr.push({
            value: this.extension.options.caseData.client.clientData.postal_code ||
            this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Client_address_country':
          arr.push({
            value: this.extension.options.caseData.client.clientData.nationality ||
            this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Company_client_name':
          arr.push({
            value: this.extension.options.caseData.client.clientData.company_name ||
            this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Company_client_type':
          arr.push({
            value: this.extension.options.caseData.client.clientData.company_type ||
            this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Company_client_shared_capital':
          arr.push({
            value: this.extension.options.caseData.client.clientData.shared_capital ||
            this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Company_client_selected_currency':
          arr.push({
            value: this.extension.options.caseData.client.clientData.selected_currency
              ? this.extension.options.caseData.client.clientData.selected_currency.symbol
              : this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          arr.push({
            value: this.extension.options.caseData.client.clientData.selected_currency
              ? this.extension.options.caseData.client.clientData.selected_currency.name
              : this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          arr.push({
            value: this.extension.options.caseData.client.clientData.selected_currency
              ? this.extension.options.caseData.client.clientData.selected_currency.code
              : this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Company_client_rcs_number':
          arr.push({
            value: this.extension.options.caseData.client.clientData.rcs_number ||
            this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Company_client_place_of_registration':
          arr.push({
            value: this.extension.options.caseData.client.clientData.place_of_registration ||
            this.node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        }
      }

      // then we get the data from the assigned documents
      this.extension.options.relatedDocuments.forEach((doc) => {
        if (doc.info && doc.info.content && Array.isArray(doc.info.content)) {
          doc.info.content.forEach(content => {
            if (content.lavvira_labels) {
              Object.keys(content.lavvira_labels).forEach((key) => {
                if ((this.node.attrs.fieldKey.toLowerCase() === key.toLowerCase()) || this.node.attrs.fieldClass?.toLowerCase() === key.toLowerCase()) {
                  content.lavvira_labels[key].forEach((val) => {
                    arr.push({
                      value: val,
                      doc
                    })
                  })
                }
              })
            }
          })
        }
      })
      return arr
    },
    relatedDocuments () {
      return this.extension.options.relatedDocuments
    },
    classes () {
      const c = []
      if (this.node.attrs.fieldState === 'view') c.push('view-state')
      if (this.node.attrs.fieldValue) c.push('is-full')
      return c
    },
    style () {
      if (this.node.attrs && this.node.attrs.fontSize) {
        return `font-size: ${this.node.attrs.fontSize};`
      } else {
        return ''
      }
    },
    actualQuestionnaire () {
      return this.extension.options.questionnaire
    }
  },
  watch: {
    actualQuestionnaire: {
      handler (value) {
        if (!value) {
          this.componentKey = 0
        }
        if (value.length) {
          this.componentKey += 1
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations({
      updateQuestionairreFrontEnd: 'cases/updateQuestionairreFrontEnd'
    }),
    ...mapActions({
      updateQuestionnaireInCase: 'questionnaire/updateQuestionnaireInCase'
    }),
    onMentionClick () {
      EventBus.$emit('select-mention', this.node.attrs.fontSize)
    },
    show (e) {
      e.preventDefault()
      if (!this.extension.options.edit) return
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.showMenu = true
      })
    },
    async setCustomValue () {
      const res = await this.$dialog.prompt({
        title: this.$t('common|custom_value'),
        text: this.$t('actions|enter_custom_field_value'),
        textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
          type: 'text'
        }
      })
      if (res) this.onValueChange(res)
    },
    async appendCustomLabel () {
      if (this.node.attrs.customLabel) {
        this.updateAttributes({
          customLabel: undefined
        })
        if (this.extension.options.caseData) {
          const _id = this.extension.options.questionnaire._id
          const caseId = this.extension.options.caseData._id
          const workbenchId = this.extension.options.workbench._id
          const templateId = this.extension.options.templateId
          const payload = {
            fieldId: this.node.attrs.id,
            customLabel: null
          }
          this.updateQuestionnaireInCase({ _id, caseId, workbenchId, templateId, payload })
        }
        return
      }
      const res = await this.$dialog.prompt({
        title: 'Customize suggestion',
        text: `Your custom label`,
        textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
          // https://github.com/yariksav/vuetify-dialog/blob/master/src/components/Prompt.vue
          type: 'text',
          outlined: true,
          dense: true,
          class: 'mt-6',
          'prepend-inner-icon': 'mdi-tag-outline',
          clearable: true
        },
        actions: {
          actionOptions: {
            text: 'submit',
            rounded: true,
            dense: true,
            color: 'primary',
            small: true,
            flat: false
          }
        }
      })
      if (res) {
        // this.updateAttributes({
        //   customLabel: res,
        //   fieldKey: this.node.attrs.fieldKey
        // })
        this.updateAttributes({
          customLabel: res
        })
        this.extension.options.ydoc.transact(() => {
          this.extension.options.ymap.set('suggestions', 'onAppendCustomLabel')
        })
        if (this.extension.options.caseData) {
          const _id = this.extension.options.questionnaire._id
          const caseId = this.extension.options.caseData._id
          const workbenchId = this.extension.options.workbench._id
          const templateId = this.extension.options.templateId
          const payload = {
            fieldId: this.node.attrs.id,
            customLabel: res,
            fieldKey: this.node.attrs.fieldKey
          }
          try {
            this.updateQuestionnaireInCase({ _id, caseId, workbenchId, templateId, payload })
          } catch (error) {
            this.updateAttributes({
              customLabel: null
            })
            this.extension.options.ydoc.transact(() => {
              this.extension.options.ymap.set('suggestions', 'onErrorAppendCustomLabel')
            })
            console.error(error, 'error')
          }
        }
      }
    },
    switchState (node, state) {
      let newState
      if (state === 'tag') newState = 'view'
      if (state === 'view') newState = 'tag'
      this.updateAttributes({
        fieldState: newState
      })
    },
    onValueChange (value) {
      this.updateAttributes({
        fieldValue: value
      })
    },
    async clearValue () {
      this.updateAttributes({
        fieldValue: null,
        fieldState: 'tag'
      })
      // this.switchState('tag')
    },
    goToClient () {
      const clientId = this.extension.options.caseData.client._id
      this.$router.push(`/clients/${clientId}`)
    }
  }
}
</script>
