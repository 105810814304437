<template>
  <div>
    <v-text-field
      v-if="!view"
      v-model.number="model"
      :label="label"
      :hint="hint"
      outlined
      dense
      type="number"
      :error-messages="error"
      :readonly="readonly"
      persistent-hint
    />
    <v-sheet
      v-else
      color="white"
      elevation="3"
      class="relative pa-1"
    >
      <slot />
      <div>Field Name: {{ label }}</div>
      <div>Field Type: File Input</div>
      <div v-if="!value">
        Pending
      </div>
    </v-sheet>
  </div>
</template>

<script>
import InputMixin from './InputMixin'

export default {
  mixins: [InputMixin],
  props: {
    value: {
      type: Number,
      default: null
    }
  }
}
</script>
