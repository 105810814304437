import axios from '@/plugins/axios'
import autobahn from 'autobahn'
import store from '../index'
import { translateNotification } from '@/utils/utils'
import { EventBus } from '@/utils/EventBus'
import { i18n } from '@/i18n/index'

const SET_ACCOUNT = 'SET_ACCOUNT'
const SET_LOADING = 'SET_LOADING'
const RESET_ACCOUNT = 'RESET_ACCOUNT'
const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
const FETCH_ACCOUNT = 'FETCH_ACCOUNT'
const ADD_TEMPLATE_CATEGORIES_ACC = 'ADD_TEMPLATE_CATEGORIES_ACC'
const UPDATE_TEMPLATE_CATEGORIES_ACC = 'UPDATE_CASE_MODEL_CATEGORIES_ACC'
const DELETE_TEMPLATE_CATEGORIES_ACC = 'DELETE_CASE_MODEL_CATEGORIES_ACC'
const ADD_CASE_MODEL_CATEGORIES_ACC = 'ADD_CASE_MODEL_CATEGORIES_ACC'
const UPDATE_CASE_MODEL_CATEGORIES_ACC = 'UPDATE_CASE_MODEL_CATEGORIES_ACC'
const DELETE_CASE_MODEL_CATEGORIES_ACC = 'DELETE_CASE_MODEL_CATEGORIES_ACC'
const LOG_OUT = 'LOG_OUT'

const state = () => {
  return {
    account: null,
    loading: false,
    actualLanguage: null,
    loadingMessage: 'Getting account information'
  }
}

const mutations = {
  [SET_ACCOUNT] (state, payload) {
    localStorage.setItem('#$a!C%(C&!#i[D]#', payload._id)
    localStorage.setItem('type', payload.accountType)
    localStorage.setItem('preferedLanguage', payload.accountData.preferedLanguage)
    state.actualLanguage = payload.accountData.preferedLanguage
    state.account = payload
  },
  [SET_LOADING] (state, value) {
    state.loading = value
  },
  [UPDATE_ACCOUNT] (state, payload) {
    state.account = payload
    state.actualLanguage = payload.accountData.preferedLanguage
    localStorage.setItem('preferedLanguage', payload.accountData.preferedLanguage)
  },
  [FETCH_ACCOUNT] (state, payload) {
    state.account = payload
    state.actualLanguage = payload.accountData.preferedLanguage
    localStorage.setItem('preferedLanguage', payload.accountData.preferedLanguage)
  },
  [ADD_TEMPLATE_CATEGORIES_ACC] (state, payload) {
    state.account = payload
    state.actualLanguage = payload.accountData.preferedLanguage
    localStorage.setItem('preferedLanguage', payload.accountData.preferedLanguage)
    // When creating, deleting or updating a category in the account (myTemplateCategories),
    // all templates need to receive the updated account in shared with,
    // because they no longer contain actual version of the account in the sharedWith.accounts array in the store.
    // The backend populates sharedWith.accounts and the templates are respectively outdated.
    store.state.templates.templates.forEach(temp => {
      const index = temp.sharedWith.accounts.findIndex(acc => acc._id === payload._id)
      if (index !== -1) {
        temp.sharedWith.accounts[index] = payload
      }
    })
  },
  [UPDATE_TEMPLATE_CATEGORIES_ACC] (state, payload) {
    state.account = payload
    state.actualLanguage = payload.accountData.preferedLanguage
    localStorage.setItem('preferedLanguage', payload.accountData.preferedLanguage)
    // When creating, deleting or updating a category in the account (myTemplateCategories),
    // all templates need to receive the updated account in shared with,
    // because they no longer contain actual version of the account in the sharedWith.accounts array in the store.
    // The backend populates sharedWith.accounts and the templates are respectively outdated.
    store.state.templates.templates.forEach(temp => {
      const index = temp.sharedWith.accounts.findIndex(acc => acc._id === payload._id)
      if (index !== -1) {
        temp.sharedWith.accounts[index] = payload
      }
    })
  },
  [DELETE_TEMPLATE_CATEGORIES_ACC] (state, payload) {
    state.account = payload
    state.actualLanguage = payload.accountData.preferedLanguage
    localStorage.setItem('preferedLanguage', payload.accountData.preferedLanguage)
    // When creating, deleting or updating a category in the account (myTemplateCategories),
    // all templates need to receive the updated account in shared with,
    // because they no longer contain actual version of the account in the sharedWith.accounts array in the store.
    // The backend populates sharedWith.accounts and the templates are respectively outdated.
    store.state.templates.templates.forEach(temp => {
      const index = temp.sharedWith.accounts.findIndex(acc => acc._id === payload._id)
      if (index !== -1) {
        temp.sharedWith.accounts[index] = payload
      }
    })
  },
  [ADD_CASE_MODEL_CATEGORIES_ACC] (state, payload) {
    state.account = payload
    state.actualLanguage = payload.accountData.preferedLanguage
    localStorage.setItem('preferedLanguage', payload.accountData.preferedLanguage)
    // When creating, deleting or updating a category in the account (myCaseModelsCategories),
    // all case models need to receive the updated account in shared with,
    // because they no longer contain actual version of the account in the sharedWith.accounts array in the store.
    // The backend populates sharedWith.accounts and the case models are respectively outdated.
    store.state.caseForms.caseForms.forEach(cf => {
      const index = cf.sharedWith.accounts.findIndex(acc => acc._id === payload._id)
      if (index !== -1) {
        cf.sharedWith.accounts[index] = payload
      }
    })
  },
  [UPDATE_CASE_MODEL_CATEGORIES_ACC] (state, payload) {
    state.account = payload
    state.actualLanguage = payload.accountData.preferedLanguage
    localStorage.setItem('preferedLanguage', payload.accountData.preferedLanguage)
    // When creating, deleting or updating a category in the account (myCaseModelsCategories),
    // all case models need to receive the updated account in shared with,
    // because they no longer contain actual version of the account in the sharedWith.accounts array in the store.
    // The backend populates sharedWith.accounts and the case models are respectively outdated.
    store.state.caseForms.caseForms.forEach(cf => {
      const index = cf.sharedWith.accounts.findIndex(acc => acc._id === payload._id)
      if (index !== -1) {
        cf.sharedWith.accounts[index] = payload
      }
    })
  },
  [DELETE_CASE_MODEL_CATEGORIES_ACC] (state, payload) {
    state.account = payload
    state.actualLanguage = payload.accountData.preferedLanguage
    localStorage.setItem('preferedLanguage', payload.accountData.preferedLanguage)
    // When creating, deleting or updating a category in the account (myCaseModelsCategories),
    // all case models need to receive the updated account in shared with,
    // because they no longer contain actual version of the account in the sharedWith.accounts array in the store.
    // The backend populates sharedWith.accounts and the case models are respectively outdated.
    store.state.caseForms.caseForms.forEach(cf => {
      const index = cf.sharedWith.accounts.findIndex(acc => acc._id === payload._id)
      if (index !== -1) {
        cf.sharedWith.accounts[index] = payload
      }
    })
  },
  [RESET_ACCOUNT] (state, payload) {
    state.account = null
    state.actualLanguage = null
    state.collaborationReconnect = []
  },
  [LOG_OUT] (state, payload) {
    state.account = null
    state.actualLanguage = null
  }
}

const actions = {
  async getAndSetAccount ({ commit }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/setAccount`)
        commit(SET_ACCOUNT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  async setAccount ({ state, commit }, payload = {}) {
    commit(SET_ACCOUNT, payload)
  },
  fetchAccount ({ commit }, { _id }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getAccount/${_id}`)
        commit(FETCH_ACCOUNT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateAccount ({ commit }, payload) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateAccount`, payload)
        commit(UPDATE_ACCOUNT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateAccountPassword ({ commit }, payload) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updatePassword`, payload)
        commit(UPDATE_ACCOUNT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateEmailNotifications ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateAccountEmailNotifications/${_id}`, payload)
        commit(UPDATE_ACCOUNT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  addCaseModelCategories ({ commit }, payload) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/createCaseModelCategories`, payload)
        commit(ADD_CASE_MODEL_CATEGORIES_ACC, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
        EventBus.$emit('refresh-case-models')
      }
    })
  },
  addTemplateCategories ({ commit }, payload) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/createTemplateCategories`, payload)
        commit(ADD_TEMPLATE_CATEGORIES_ACC, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateCaseModelCategories ({ commit }, payload) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCaseModelCategories`, payload)
        commit(UPDATE_CASE_MODEL_CATEGORIES_ACC, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
        EventBus.$emit('refresh-case-models')
      }
    })
  },
  updateTemplateCategoriesAcc ({ commit }, payload) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateTemplateCategories`, payload)
        commit(UPDATE_TEMPLATE_CATEGORIES_ACC, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  createMyNote ({ commit }, payload) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/createNote`, payload)
        commit(UPDATE_ACCOUNT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateMyNote ({ commit }, payload) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateNote`, payload)
        commit(UPDATE_ACCOUNT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteMyNote ({ commit }, payload) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/deleteNote`, payload)
        commit(UPDATE_ACCOUNT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteCaseModelCategories ({ commit }, payload) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/deleteCaseModelCategories`, payload)
        commit(DELETE_CASE_MODEL_CATEGORIES_ACC, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
        EventBus.$emit('refresh-case-models')
      }
    })
  },
  deleteTemplateCategoriesAcc ({ commit }, payload) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/deleteTemplateCategories`, payload)
        commit(DELETE_TEMPLATE_CATEGORIES_ACC, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteMyNotification ({ commit }, payload) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/deleteNotification`, payload)
        commit(UPDATE_ACCOUNT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteAllMyNotifications ({ commit }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/deleteAllNotifications`)
        commit(UPDATE_ACCOUNT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  clearMyNotifications ({ commit }, payload) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/clearNotifications`)
        commit(UPDATE_ACCOUNT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteSomeNotifications ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/deleteNotifications/${_id}`, payload)
        commit(UPDATE_ACCOUNT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  markNotificationsAsRead ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/markNotificationsAsRead/${_id}`, payload)
        commit(UPDATE_ACCOUNT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  sendInvitationToProfessionalUser ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/inviteProfessional/${_id}`, payload)
        commit(UPDATE_ACCOUNT, data.data)
        store.dispatch('toasts/addToast', {
          title: i18n.t('message|invitation_sent_success'),
          color: 'white',
          snackbarColor: 'success'
        })
        resolve()
      } catch (e) {
        console.error(e, 'ERROR')
        if (!e.response.data.success && typeof e.response.data.msg === 'string' && e.response.data.msg.includes('user not found')) {
          store.dispatch('toasts/addToast', {
            title: `${e.response.data.msg} ${i18n.t('warning|check_email_and_try_again')}`,
            color: 'white',
            snackbarColor: 'error'
          })
        }
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  acceptInvitationFromProfessional ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/acceptAnProfessionalInvitation/${_id}`, payload)
        commit(UPDATE_ACCOUNT, data.data)
        store.dispatch('toasts/addToast', {
          title: i18n.t('message|invitation_accepted'),
          color: 'white',
          snackbarColor: 'success'
        })
        resolve()
      } catch (e) {
        if (!e.response.data.success && e.response.data.msg.includes('user not found')) {
          store.dispatch('toasts/addToast', {
            title: `${e.response.data.msg} ${i18n.t('warning|check_email_and_try_again')}`,
            color: 'white',
            snackbarColor: 'error'
          })
        }
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  acceptCompanyMembership ({ commit }, { payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/acceptCompanyMembershipInvitation`, payload)
        // calling mutation in company.js module from store._mutations to set the company
        store._mutations['company/SET_COMPANY'][0](data.data.company)
        commit(UPDATE_ACCOUNT, data.data.account)
        store.dispatch('auth/setToken', data.data.token)
        store.dispatch('toasts/addToast', {
          title: i18n.t('message|invitation_accepted'),
          color: 'white',
          snackbarColor: 'success'
        })
        resolve()
      } catch (e) {
        store.dispatch('toasts/addToast', {
          title: i18n.t('expressions|error_occurred'),
          color: 'white',
          snackbarColor: 'error'
        })
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  rejectActualInvitation ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/declineInvitation/${_id}`, payload)
        commit(UPDATE_ACCOUNT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  rejectLawFirmInvitation ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/rejectLawFirmInvitation/${_id}`, payload)
        commit(UPDATE_ACCOUNT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  cancelInvitation ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/cancelInvitation/${_id}`, payload)
        commit(UPDATE_ACCOUNT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  cancelInvitationFromClientAccount ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/cancelInvitationFromClientAccount/${_id}`, payload)
        commit(UPDATE_ACCOUNT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  removeInvitation ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/removeAccountInvitation/${_id}`, payload)
        commit(UPDATE_ACCOUNT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteTheDeclinedInvitation ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/removeDeclinedInvitation/${_id}`, payload)
        commit(UPDATE_ACCOUNT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteProfessional ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/deleteProfessional/${_id}`, payload)
        commit(UPDATE_ACCOUNT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  logUserOut ({ commit }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const _id = localStorage.getItem('#$a!C%(C&!#i[D]#')
        axios.defaults.withCredentials = true
        const { data } = await axios.post(`/logOut/${_id}`, { withCredentials: true })
        commit(LOG_OUT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  setNotificationsSubscription ({ commit }) {
    try {
      const _id = store.state.account.account._id
      const connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })
      connection.onopen = function (session) {
        // call a remote procedure
        session.subscribe(`lavvira.notifications.${_id}`, (...args) => {
          if (
            // collaboration => data saved
            args[0][0].details &&
            args[0][0].details.from &&
            args[0][0].details.from === 'collaboration' &&
            args[0][0].details.type === 'saved_content'
          ) {
            EventBus.$emit('collaboration-feedback', args[0][0].details)
          } else if (
            // collaboration => token expired => refresh token
            args[0][0].details &&
            args[0][0].details.from &&
            args[0][0].details.from === 'collaboration' &&
            args[0][0].details.type === 'token_expired'
          ) {
            store.dispatch('auth/refreshToken')
          } else {
            EventBus.$emit('incomming_notification', args[0][0])
            store.dispatch('account/fetchAccount', { _id })
            store.dispatch('toasts/addToast', {
              title: translateNotification(args[0][0]).content.translation,
              color: 'white',
              snackbarColor: args[0][0].status === 'success' ? 'success' : 'error'
            })
          }
        })
      }

      connection.onclose = function (reason, details) {
        // handle connection lost
        console.log(reason, details, 'connection.onclose')
      }
      connection.open()
    } catch (e) {
      console.error(e, 'ERROR in autobahn connection')
    }
  },
  accountUpdatesSubscription ({ commit }, { _id }) {
    try {
      const connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })

      connection.onopen = function async (session) {
        session.subscribe(`lavvira.updates.${_id}`, async (...args) => {
          const accountTopic = args[0].includes('Account')
          const updateTask = args[0].includes('update')
          if (accountTopic && updateTask) {
            if (store.state.account.account && store.state.account.account._id === args[0][0]._id) {
              commit(UPDATE_ACCOUNT, args[0][0])
              if (args[0][4] && args[0][4].actionType && args[0][4].actionType === 'declineInvitation') {
                store.dispatch('toasts/addToast', {
                  title: `${i18n.t('warning|invitation_declined')} ${args[0][4].declinedBy}`,
                  color: 'white',
                  snackbarColor: 'error'
                })
              }
              if (args[0][4] && args[0][4].actionType && args[0][4].actionType === 'inviteProfessional') {
                store.dispatch('toasts/addToast', {
                  title: `${i18n.t('message|received_invitation')} ${args[0][4].sentBy}`,
                  color: 'white',
                  snackbarColor: 'success'
                })
              }
              if (args[0][4] && args[0][4].accountAddedToCompany) {
                // if account was added to company first refresh token to get the companyId into the new token
                const token = store.state.auth.token
                await store.dispatch('auth/refreshToken', { token })
                // then get the company obj
                await store.dispatch('company/setCompany')
                // subscribe for company updates and get the company resources
                EventBus.$emit('subscribe-for-company-updates', store.state.account.account.companyId)
              }
              if (args[0][4] && args[0][4].removedFromCompany) {
                // if this account was removed from the company
                if (store.state.account.account._id === args[0][4].accountId) {
                  // unsubscribe all company and group updates
                  store.state.company.companySubscriptions.forEach(sub => {
                    store.dispatch('company/unsubscribeCompanyUpdate', sub)
                  })
                  store.state.companyClients.companyClientsSubscriptions.forEach(sub => {
                    store.dispatch('companyClients/unsubscribeCompanyClientsUpdate', sub)
                  })
                  store.state.companyCases.companyCasesSubscriptions.forEach(sub => {
                    store.dispatch('companyCases/unsubscribeCompanyCasesUpdate', sub)
                  })
                  store.state.companyCaseForms.companyCaseFormsSubscriptions.forEach(sub => {
                    store.dispatch('companyCaseForms/unsubscribeCompanyCaseFormsUpdate', sub)
                  })
                  store.state.companyTemplates.companyTemplatesSubscriptions.forEach(sub => {
                    store.dispatch('companyTemplates/unsubscribeCompanyTemplatesUpdate', sub)
                  })
                  store.state.companyDocuments.companyDocumentsSubscriptions.forEach(sub => {
                    store.dispatch('companyDocuments/unsubscribeCompanyDocumentsUpdate', sub)
                  })

                  // unsubscribe all group updates
                  store.state.groupClients.groupClientsSubscriptions.forEach(sub => {
                    store.dispatch('groupClients/usnsubscribeGroupClientsUpdates', sub)
                  })
                  store.state.groupCases.groupCasesSubscriptions.forEach(sub => {
                    store.dispatch('groupCases/usnsubscribeGroupCasesUpdates', sub)
                  })
                  store.state.groupCaseForms.groupCaseFormsSubscriptions.forEach(sub => {
                    store.dispatch('groupCaseForms/usnsubscribeGroupCaseFormsUpdates', sub)
                  })
                  store.state.groupTemplates.groupTemplatesSubscriptions.forEach(sub => {
                    store.dispatch('groupTemplates/usnsubscribeGroupTemplatesUpdates', sub)
                  })
                  store.state.groupDocuments.groupDocumentsSubscriptions.forEach(sub => {
                    store.dispatch('groupDocuments/usnsubscribeGroupDocumentsUpdates', sub)
                  })

                  // refresh token to remove the companyId from the token
                  const token = store.state.auth.token
                  await store.dispatch('auth/refreshToken', { token })
                }
              }
            }
          }
        })
      }

      connection.onclose = function (reason, details) {
        // handle connection lost
        console.log(reason, details, 'connection.onclose')
      }
      connection.open()
    } catch (e) {
      console.error(e, 'ERROR in autobahn connection')
    }
  },
  resetAccount ({ commit }) {
    commit(RESET_ACCOUNT)
  },
  deleteAccount ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/deleteUserAccount/${_id}`, payload)
        if (data.success) {
          resolve()
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  }
}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions
}
