<template>
  <v-navigation-drawer
    v-if="drawer && account"
    app
    :value="drawer"
    mobile-breakpoint="1000"
    :mini-variant="mini"
    permanent
    color="primary"
    dark
    width="220"
  >
    <div class="menu-container">
      <v-progress-circular
        v-if="loadingData"
        class="loading-progress"
        indeterminate
        size="60"
        width="8"
        color="primary"
      />
      <template v-else>
        <v-container>
          <div
            v-if="!mini"
            class="mt-n7 mb-10 ml-3"
          >
            <img
              class="logo"
              src="@/assets/Lavirra_logo_SVG.svg"
              height="22px"
              width="auto"
            >
            <span
              v-if="account.subscriptionStatus === 'beta'"
              :style="`
                font-size: 11px;
                color: ${$vuetify.theme.themes.light.lightBackground};
                position: absolute; top: 12px;
              `"
            >
              BETA
            </span>
            <span
              v-if="!account.companyId && account.subscriptionStatus === 'trial'"
              :style="`
                font-size: 11px;
                color: ${$vuetify.theme.themes.light.lightBackground};
                position: absolute; top: 12px;
              `"
            >
              TRIAL
            </span>
            <span
              v-if="!account.companyId && account.subscriptionStatus === 'unpaid'"
              :style="`
                font-size: 11px;
                color: ${$vuetify.theme.themes.light.lightBackground};
                position: absolute; top: 12px;
                cursor: pointer;
              `"
              @click="$router.push('/activate-account')"
            >
              TRIAL EXPIRED
            </span>
          </div>
          <div
            v-else
            class="d-flex align-center justify-center pt-2 pb-6"
          >
            <img
              class="logo"
              src="@/assets/Lavvira_monogramm_logo.svg"
              height="22px"
              width="auto"
            >
          </div>
        </v-container>
        <v-list
          :key="componentKey"
          dense
          :class="!mini ? 'l-menu-list mt-n2' : 'mini-menu-icons'"
        >
          <template v-for="item in appMenu">
            <v-tooltip
              :key="item.to"
              right
              color="warning"
              max-width="230"
            >
              <template v-slot:activator="{ on }">
                <div v-on="item.disabled ? on : ''">
                  <v-list-item
                    v-if="checkLinkVisibility(item)"
                    :disabled="item.disabled"
                    :link="!!item.to"
                    :to="item.to"
                    class="text-body-1 font-weight-bold"
                  >
                    <v-list-item-icon>
                      <v-icon
                        v-if="item.icon"
                        :size="item.size || 24"
                      >
                        {{ item.icon }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </template>
              <span>{{ $t('action|activate_section') }}</span>
            </v-tooltip>
          </template>
        </v-list>
        <v-divider
          v-if="account && account.accountType === 'lawyer'"
        />
        <v-list
          v-if="account && account.accountType === 'lawyer'"
          dense
          expand
        >
          <v-list-group
            v-for="item in additionalMenu"
            :key="item.title"
            v-model="item.active"
            color="white"
            no-action
            :class="mini ? 'mini-menu-icons' : 'ml-1 pb-3'"
            @click="switchMenuIcon(); disableMini()"
          >
            <template #prependIcon>
              <v-icon
                class="ml-1"
              >
                {{ item.icon }}
              </v-icon>
            </template>
            <template #appendIcon>
              <v-icon
                style="position: absolute; right: 1px;"
              >
                mdi-menu-down
              </v-icon>
            </template>
            <template v-slot:activator>
              <v-list-item-content
                style="position: absolute; left: 53px;"
                class="pr-5"
              >
                <v-list-item-title
                  style="font-size: 14px; white-space: normal;"
                >
                  {{ item.title }} {{ item.subtitle }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item class="pa-0">
              <ProgressCard
                :storage-type="item.storageType"
              />
            </v-list-item>
          </v-list-group>
        </v-list>
      </template>
    </div>
    <v-menu
      v-model="dialog"
      transition="scale-transition"
      scrollable
      top
      rounded
      :open-on-click="false"
      :offset-y="offset"
      :close-on-content-click="closeOnContentClick"
      min-width="600px"
      color="background"
      content-class="vdialognew hide-scrollbar-chrome hide-scrollbar-firefox mt-n4 outlined"
      @keydown.esc="closeDialogEscapeKey()"
    >
      <template v-slot:activator="{ on }">
        <div class="search ml-2 mb-2 text-left">
          <v-text-field
            v-if="!mini"
            v-model="searchInput"
            dense
            hide-details
            rounded="rounded"
            light
            background-color="white"
            style="height: 80%; font-size: 0.8em"
            v-on="on"
            @keydown.esc="closeReset"
          >
            <v-input
              slot="label"
              style="font-size: 14px;"
            >
              {{ $t('mixed|general_search') }}:
            </v-input>
            <v-icon
              slot="prepend-inner"
              size="18"
              class="ml-n3 mt-1"
            >
              mdi-magnify
            </v-icon>
            <v-icon
              v-if="searchInput"
              slot="append"
              size="18"
              class="mt-1"
              @click="clearInput()"
            >
              mdi-close
            </v-icon>
          </v-text-field>
          <v-icon
            v-else
            class="ml-2"
            @click="switchMenuIcon(); disableMini()"
          >
            mdi-magnify
          </v-icon>
        </div>
      </template>
      <SearchDialog
        :search-input="searchInput"
        @closeReset="closeReset()"
      />
    </v-menu>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import { mdiApplicationCog, mdiFile, mdiTextBoxOutline, mdiDatabase, mdiAccountTie } from '@mdi/js'
import SearchDialog from '../dialogs/SearchDialog.vue'
import ProgressCard from '../cards/ProgressCard.vue'

export default {
  components: { SearchDialog, ProgressCard },
  data () {
    return {
      searchInput: '',
      componentKey: 0,
      hoverMoreStorage: false,
      hoverMoreMembers: false,
      hoverMoreTranslations: false,
      closeOnContentClick: false,
      dialog: false,
      offset: true,
      disabledMenuItems: false
    }
  },
  computed: {
    ...mapState({
      drawer: state => state.app.drawer,
      mini: state => state.app.mini,
      account: state => state.account.account,
      company: state => state.company.company,
      documents: state => state.documents.documents,
      loadingData: state => state.app.loadingData
    }),
    actualAccount () {
      return this.account
    },
    appMenu () {
      return [
        {
          title: this.$t('settings|settings'),
          to: '/settings',
          requiresAccountType: ['god'],
          icon: mdiApplicationCog,
          size: 27,
          disabled: false
        },
        {
          title: this.$t('dashboard|dashboard'),
          to: '/dashboard',
          requiresAccountType: ['company', 'individual', 'lawyer'],
          icon: 'mdi-home',
          disabled: false
        },
        {
          title: this.$t('common|professionals'),
          to: '/professionals',
          requiresAccountType: ['company', 'individual'],
          icon: mdiAccountTie,
          disabled: this.disabledMenuItems
        },
        {
          title: this.$t('clients|clients'),
          to: '/clients',
          requiresAccountType: ['lawyer'],
          icon: 'mdi-account-group',
          disabled: this.disabledMenuItems
        },
        {
          title: this.$t('cases|cases'),
          to: '/cases',
          requiresAccountType: ['company', 'individual', 'lawyer'],
          icon: 'mdi-briefcase-edit',
          disabled: this.disabledMenuItems
        },
        {
          title: this.$t('case_models|case_models'),
          to: '/models',
          requiresAccountType: ['lawyer', 'god'],
          icon: 'mdi-briefcase-edit-outline',
          disabled: false
        },
        {
          title: this.$t('templates|templates'),
          to: '/templates',
          requiresAccountType: ['lawyer', 'god'],
          icon: mdiTextBoxOutline,
          disabled: false
        },
        {
          title: this.$t('documents|documents'),
          to: '/documents',
          requiresAccountType: ['company', 'individual', 'lawyer', 'god'],
          icon: mdiFile,
          disabled: false
        },
        {
          title: this.$t('members|members'),
          to: '/members',
          requiresAccountType: ['lawyer'],
          requiressubscriptionType: ['lavvira-group-s', 'lavvira-group-l', 'lavvira-group-xl'],
          icon: 'mdi-account-supervisor',
          disabled: false
        }
      ]
    },
    additionalMenu () {
      if (this.company) {
        return [
          {
            title: this.$t('storage|my_personal'),
            subtitle: this.$t('storage|storage').toLowerCase(),
            index: 1,
            active: false,
            storageType: 'personal',
            icon: mdiDatabase
          },
          {
            title: this.$t('storage|company'),
            subtitle: this.$t('storage|storage').toLowerCase(),
            index: 2,
            active: false,
            storageType: 'company',
            icon: mdiDatabase
          }
        ]
      } else {
        return [
          {
            title: this.$t('storage|my_personal'),
            subtitle: this.$t('storage|storage').toLowerCase(),
            index: 1,
            storageType: 'account',
            active: false,
            icon: mdiDatabase
          }
        ]
      }
    },
    actualMembers () {
      let membersAmount
      if (this.company && this.company.members) {
        membersAmount = this.company.members.length
      }
      return membersAmount
    },
    subscriptionSize () {
      let availableSeats
      if (this.company && this.company.subscriptionType === 'lavvira-group-s') {
        availableSeats = 3
      }
      if (this.company && this.company.subscriptionType === 'lavvira-group-l') {
        availableSeats = 7
      }
      return availableSeats
    },
    unfilteredDocuments () {
      let document
      if (this.account._id === this.id) {
        return this.documents.map((doc) => {
          return {
            doc: document,
            fileType: doc.raw.mimetype,
            documentType: doc.info.document_type,
            docName: doc.raw.originalname,
            docSize: doc.raw.size
          }
        })
      }
      return document
    },
    filteredDocuments () {
      const myAccountId = this.account._id
      return this.documents.filter(function (doc) {
        return doc.createdBy._id === myAccountId
      })
    },
    storageSize () {
      let size = this.filteredDocuments.reduce((acc, doc) => {
        return (acc += doc.raw.size)
      }, 0)
      if ((size / 1024 / 1024) > 1025) {
        return ((size / 1024 / 1024 / 1024).toFixed(2) + ' GB' + ' / 5 GB')
      }
      return ((size / 1024 / 1024).toFixed(2) + ' MB' + ' / 5 GB')
    },
    rawStorageSize () {
      const size = this.filteredDocuments.reduce((acc, doc) => {
        return (acc += doc.raw.size)
      }, 0)
      return size
    },
    relDiff () {
      //  Percentage of decrease example = |5000 - 8|/5000 = 4992/5000 = 0.9984 = 99.84%
      let a = 5000
      let b = (this.rawStorageSize / 1024 / 1024).toFixed(2)
      let c = a - b
      return Number((100 - (100 * (c / a))).toFixed(2))
    },
    relDiffMembers () {
      //  Percentage of decrease example = |5000 - 8|/5000 = 4992/5000 = 0.9984 = 99.84%
      let a = Number(this.subscriptionSize)
      let b = Number(this.actualMembers)
      let c = a - b
      return Number((100 - (100 * (c / a))).toFixed(2))
    },
    amountDocuments () {
      let array = this.filteredDocuments
      let sum = 0
      for (var i = 0; i < array.length; i++) {
        sum += array[i]
        sum = sum.length
      }
      return sum
    },
    amountAccounts () {
      let array = this.account
      let sum = 0
      for (var i = 0; i < array.length; i++) {
        sum += array[i]
        sum = sum.length
      }
      // alert(sum)
      return sum
    },
    companySize () {
      let allAcc = this.amountAccounts
      if (allAcc >= 5) {
        return ('No seats available')
      }
      alert(allAcc)
      return (allAcc)
    }
  },
  watch: {
    actualAccount: {
      handler (value) {
        if (!value) {
          this.componentKey = 0
        }
        if (value && value.companyId) {
          this.componentKey++
        }
        if (value && !value.companyId) {
          this.componentKey++
        }
      },
      deep: true
    },
    searchInput (value) {
      if (value) {
        this.dialog = true
      } else {
        this.dialog = false
      }
    }
  },
  mounted () {
    if (this.account && this.account.accountData && this.account.accountData.requiredFieldsFilled) {
      this.disabledMenuItems = false
    } else {
      this.disabledMenuItems = true
    }
  },
  updated () {
    if (this.account && this.account.accountData && this.account.accountData.requiredFieldsFilled) {
      this.disabledMenuItems = false
    } else {
      this.disabledMenuItems = true
    }
  },
  methods: {
    ...mapMutations({
      toggleMini: 'app/toggleMini'
    }),
    checkLinkVisibility (item) {
      if (this.account && item && this.account.companyId && this.company) {
        return this.account.companyId && this.account.companyId.length && item.requiresAccountType.includes(this.account.accountType)
      } else if (this.account && item && !item.requiressubscriptionType) {
        return item.requiresAccountType.includes(this.account.accountType) && (item.requiressubscriptionType ? item.requiressubscriptionType === 'none' : 'none')
      }
    },
    disableMini () {
      this.toggleMini(false)
    },
    clearInput () {
      this.searchInput = ''
    },
    closeReset () {
      this.searchInput = ''
      this.dialog = false
    },
    switchMenuIcon () {
      EventBus.$emit('switch-icon', {})
    }
  }
}
</script>

<style lang="scss" scoped>
  .menu-container {
    position: relative;
    height: 100%;
  }
  .search {
    position: absolute;
    width: 90%;
    bottom: 3px;
  }
  .l-menu-list {
    .v-list-item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 18px;
      &:after {
        display: none;
      }
      .v-list-item__icon {
        position: absolute;
        left: 23px;
      }
      .v-list-item__content {
        width: 100%;
        display: inline-flex;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        font-size: 14px;
        margin-left: 38px;
      }
    }
  }
  .mini-menu-icons {
    align-self: center;
    margin-left: 0px;
    padding-top: 0px;
  }
  .loading-progress {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 3rem;
  }
  .logo {
    margin-bottom: -20%;
    margin-top: -15%;
  }
  .vdialognew {
    height: 600px;
    background-color: #FAFAFA;
    bottom: 50px;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .hide-scrollbar-chrome::-webkit-scrollbar {
      display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar-firefox {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .outlined {
    outline: 1px dashed #ecb652;
  }
</style>
