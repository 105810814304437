import axios from '@/plugins/axios'

const UPDATE_SETTINGS = 'ADD_TOAST'

const state = () => {
  return {
    settings: null
  }
}

const mutations = {
  [UPDATE_SETTINGS] (state, settings) {
    state.settings = settings
  }
}

const actions = {
  fetchSettings ({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getSettings`)
        commit(UPDATE_SETTINGS, data.data)
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  },
  updateSettings ({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateSettings`, payload)
        commit(UPDATE_SETTINGS, data.data)
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  }
}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions
}
