import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/i18n/lang/en.json'
import fr from '@/i18n/lang/fr.json'
import bg from '@/i18n/lang/bg.json'
import de from '@/i18n/lang/de.json'
import es from '@/i18n/lang/es.json'
import it from '@/i18n/lang/it.json'
import ro from '@/i18n/lang/ro.json'

function getPrefferedLanuageFromUser () {
  return localStorage.getItem('preferedLanguage')
}

function getUserLanguage () {
  const browserLang = (window.navigator.userLanguage || window.navigator.language).toLowerCase()
  const prefLang = localStorage.getItem('preferedLanguage')
  if (!prefLang) {
    const eng = browserLang.indexOf('en')
    const de = browserLang.indexOf('de')
    const fr = browserLang.indexOf('fr')
    const bg = browserLang.indexOf('bg')
    const es = browserLang.indexOf('es')
    const it = browserLang.indexOf('it')
    const ro = browserLang.indexOf('ro')
    if (eng !== -1) localStorage.setItem('preferedLanguage', 'en')
    if (de !== -1) localStorage.setItem('preferedLanguage', 'de')
    if (fr !== -1) localStorage.setItem('preferedLanguage', 'fr')
    if (bg !== -1) localStorage.setItem('preferedLanguage', 'bg')
    if (es !== -1) localStorage.setItem('preferedLanguage', 'es')
    if (it !== -1) localStorage.setItem('preferedLanguage', 'it')
    if (ro !== -1) localStorage.setItem('preferedLanguage', 'ro')
  }
  switch (browserLang) {
  case 'en' || 'en-au' || 'en-bz' || 'en-ca' || 'en-ie' || 'en-jm' || 'en-nz' || 'en-ph' || 'en-za' || 'en-tt' || 'en-gb' || 'en-us' || 'en-zw':
    return 'en'
  case 'fr' || 'fr-be' || 'fr-ca' || 'fr-fr' || 'fr-lu' || 'fr-mc' || 'fr-ch':
    return 'fr'
  case 'de' || 'de-at' || 'de-de' || 'de-li' || 'de-lu' || 'de-ch':
    return 'de'
  case 'bg':
    return 'bg'
  case 'es':
    return 'es'
  case 'it':
    return 'it'
  case 'ro':
    return 'ro'
  default:
    return 'en'
  }
}
Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: getPrefferedLanuageFromUser() || getUserLanguage(),
  fallbackLocale: getUserLanguage() || 'en',
  messages: {
    en,
    fr,
    bg,
    de,
    es,
    it,
    ro
  }
})

// const loadedLanguages = ['en'] // our default language that is preloaded

// function setI18nLanguage (lang) {
//   i18n.locale = lang
//   document.querySelector('html').setAttribute('lang', lang)
//   return lang
// }

// export function loadLanguageAsync (lang = 'en') {
//   // If the same language
//   if (i18n.locale === lang) {
//     return Promise.resolve(setI18nLanguage(lang))
//   }

//   // If the language was already loaded
//   if (loadedLanguages.includes(lang)) return Promise.resolve(setI18nLanguage(lang))

//   // If the language hasn't been loaded yet
//   return import(/* webpackChunkName: "lang-[request]" */ `@/i18n/lang/${lang}.js`).then(
//     messages => {
//       i18n.setLocaleMessage(lang, messages.default)
//       loadedLanguages.push(lang)
//       return setI18nLanguage(lang)
//     }
//   )
// }
