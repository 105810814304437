<template>
  <v-footer>
    <v-row>
      <v-col cols="12">
        <div class="text-center">
          &copy; {{ (new Date()).getFullYear() }} LAVVIRA
        </div>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {

}
</script>

<style lang="sass" scoped>

</style>
