<template>
  <div class="toast-controller">
    <template v-for="toast in toasts">
      <Toast
        :key="toast.id"
        :data="toast"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Toast from './Toast'

export default {
  components: {
    Toast
  },
  computed: {
    ...mapState({
      toasts: state => state.toasts.toasts
    })
  }
}
</script>
