import Vue from 'vue'
import axios from '@/plugins/axios'
import store from '../index'
import autobahn from 'autobahn'
import { i18n } from '@/i18n/index'

const SET_LOADING = 'SET_LOADING'
const SET_COMPANY_DOCUMENTS = 'SET_COMPANY_DOCUMENTS'
const ADD_COMPANY_DOCUMENT = 'ADD_COMPANY_DOCUMENT'
const UPDATE_COMPANY_DOCUMENT = 'UPDATE_COMPANY_DOCUMENT'
const REMOVE_COMPANY_DOCUMENT = 'REMOVE_COMPANY_DOCUMENT'
const DELETE_COMPANY_DOCUMENT = 'DELETE_COMPANY_DOCUMENT'
const RESET_COMPANY_DOCUMENTS = 'RESET_COMPANY_DOCUMENTS'
const SET_COMPANY_FOLDERS = 'SET_COMPANY_FOLDERS'
const ADD_COMPANY_FOLDER = 'ADD_COMPANY_FOLDER'
const UPDATE_COMPANY_FOLDER = 'UPDATE_COMPANY_FOLDER'
const DELETE_COMPANY_FOLDER = 'DELETE_COMPANY_FOLDER'

const state = () => {
  return {
    loading: false,
    companyDocuments: [],
    companyFolders: [],
    companyDocumentsSubscriptions: []
  }
}

const mutations = {
  [SET_LOADING] (state, value) {
    state.loading = value
  },
  [SET_COMPANY_DOCUMENTS] (state, payload) {
    state.companyDocuments = payload
  },
  [ADD_COMPANY_DOCUMENT] (state, payload) {
    // We check if the update comes from an express or a crossbar.
    // If it comes from crossbar we need to do an additional check,
    // because if the user has made the request
    // the document has already been returned by express and is available in the documents
    if (payload.message && payload.message === 'watchmanUpdater') {
      const index = state.companyDocuments.findIndex(doc => doc._id === payload.doc._id)
      if (index === -1) {
        state.companyDocuments.push(payload.doc)
      }
    } else if (payload.message && payload.message === 'watchmanUpdateOnShare') {
      state.companyDocuments.push(payload.doc)
    } else {
      state.companyDocuments.push(payload)
    }
  },
  [UPDATE_COMPANY_DOCUMENT] (state, payload) {
    const index = state.companyDocuments.findIndex(doc => doc._id === payload._id)
    Vue.set(state.companyDocuments, index, payload)
  },
  [REMOVE_COMPANY_DOCUMENT] (state, payload) {
    const index = state.companyDocuments.findIndex(doc => doc._id === payload._id)
    if (index !== -1) {
      state.companyDocuments.splice(index, 1)
    }
  },
  [DELETE_COMPANY_DOCUMENT] (state, payload) {
    const index = state.companyDocuments.findIndex(doc => doc._id === payload._id)
    if (index !== -1) {
      state.companyDocuments.splice(index, 1)
    }
  },
  [RESET_COMPANY_DOCUMENTS] (state) {
    state.companyDocuments = []
    state.companyDocumentsSubscriptions = []
  },
  [SET_COMPANY_FOLDERS] (state, payload) {
    state.companyFolders = payload
  },
  [ADD_COMPANY_FOLDER] (state, payload) {
    const idx = state.companyFolders.findIndex(f => f._id === payload._id)
    if (idx === -1) state.companyFolders.push(payload)
  },
  [UPDATE_COMPANY_FOLDER] (state, payload) {
    const idx = state.companyFolders.findIndex(f => f._id === payload._id)
    if (idx !== -1) Vue.set(state.companyFolders, idx, payload)
  },
  [DELETE_COMPANY_FOLDER] (state, payload) {
    const idx = state.companyFolders.findIndex(f => f._id === payload._id)
    if (idx !== -1) Vue.delete(state.companyFolders, idx)
  }
}

const actions = {
  getCompanyDocuments ({ commit }, { _id }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getSharedDocuments/${_id}`) // has to be company._id
        commit(SET_COMPANY_DOCUMENTS, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  getCompanyDocument ({ commit }, payload = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getDocument/${payload._id}`)
        commit(UPDATE_COMPANY_DOCUMENT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  uploadCompanyDocument ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const formData = new FormData()
        Object.keys(payload).forEach((key) => {
          formData.append(key, payload[key])
        })
        const { data } = await axios.post(`/uploadDocument/${_id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        if (data.success) {
          commit(ADD_COMPANY_DOCUMENT, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateCompanyDocument ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateDocument/${_id}`, {
          payload: payload
        })
        if (data.success && data.onNewShareId && store.state.company.company && data.onNewShareId !== store.state.company.company._id) {
          const index = store.state.groupDocuments.groupDocuments.findIndex(doc => doc._id === data.data._id)
          if (index !== -1) {
            store.state.groupDocuments.groupDocuments.splice(index, 1, data.data)
          } else {
            store.state.groupDocuments.groupDocuments.push(data.data)
          }
          resolve(data)
        }
        if (data.success && !data.onNewShareId) {
          commit(UPDATE_COMPANY_DOCUMENT, data.data)
          resolve(data)
        } else if (!data.success && data.data.msg === 'account not found') {
          this.addToast({
            title: i18n.t('error|lavvira_account_not_found'),
            color: 'white',
            snackbarColor: 'error'
          })
        } else if (!data.success) {
          reject(data)
        }
      } catch (e) {
        if (e.response.data && e.response.data === 'Acc Not Found') {
          store.dispatch('toasts/addToast', {
            title: i18n.t('error|lavvira_account_not_found'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
        if (e.response.data && e.response.data === 'Already shared') {
          store.dispatch('toasts/addToast', {
            title: i18n.t('error|already_shared'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  removeCompanyDocument ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/removeDocument/${_id}`, {
          payload: payload
        })
        if (data.success) {
          commit(REMOVE_COMPANY_DOCUMENT, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteCompanyDocument ({ commit }, { document } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        await axios.post(`/deleteDocument/${document._id}`)
        const _id = document._id
        commit(DELETE_COMPANY_DOCUMENT, { _id })
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  resetCompanyDocuments ({ commit }) {
    commit(RESET_COMPANY_DOCUMENTS)
  },
  getCompanyFolders ({ commit }, { _id }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getSharedWithMeFolders/${_id}`) // has to be company._id
        commit(SET_COMPANY_FOLDERS, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  createNewCompanyFolder ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/createNewFolder/${_id}`, {
          payload: payload
        })
        commit(ADD_COMPANY_FOLDER, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  companyDocumentsUpdatesSubscription ({ commit }, { _id }) {
    try {
      const connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })

      connection.onopen = function (session) {
        session.subscribe(`lavvira.updates.${_id}`, async (...args) => {
          const companyDocumentsTopic = args[0].includes('Documents')
          const folderTopic = args[0].includes('Folders')
          const createTask = args[0].includes('create')
          const updateTask = args[0].includes('update')
          const removeTask = args[0].includes('remove')
          const deleteTask = args[0].includes('delete')
          if (companyDocumentsTopic && createTask) {
            const message = 'watchmanUpdater'
            const doc = args[0][0]
            commit({ type: 'ADD_COMPANY_DOCUMENT', doc, message })
          } else if (companyDocumentsTopic && updateTask) {
            const doc = store.state.companyDocuments.companyDocuments.find(d => d._id === args[0][0]._id)
            if (doc) {
              commit(UPDATE_COMPANY_DOCUMENT, args[0][0])
            } else {
              // implemet 'share task'!!! to be clear
              const message = 'watchmanUpdateOnShare'
              const doc = args[0][0]
              commit({ type: 'ADD_COMPANY_DOCUMENT', doc, message })
            }
          } else if (companyDocumentsTopic && removeTask) {
            const isShared = args[0][0].sharedWith.company.find(c => c._id === store.state.company.company._id)
            if (isShared) {
              commit(UPDATE_COMPANY_DOCUMENT, args[0][0])
            } else {
              const _id = args[0][0]._id
              commit(DELETE_COMPANY_DOCUMENT, { _id })
            }
          } else if (companyDocumentsTopic && deleteTask) {
            const _id = args[0][0]._id
            commit(DELETE_COMPANY_DOCUMENT, { _id })
          } else if (folderTopic && createTask) {
            commit(ADD_COMPANY_FOLDER, args[0][0])
          } else if (folderTopic && updateTask) {
            if (
              args[0][4] &&
              args[0][4].folderWasSharedWithCompany &&
              args[0][4].companyId === store.state.company.company._id
            ) {
              commit(ADD_COMPANY_FOLDER, args[0][0])
            } else {
              commit(UPDATE_COMPANY_FOLDER, args[0][0])
            }
          } else if (folderTopic && deleteTask) {
            commit(DELETE_COMPANY_FOLDER, args[0][0])
          } else if (folderTopic && removeTask) {
            if (
              args[0][4] &&
              args[0][4].removedFolder &&
              args[0][4].userType &&
              args[0][4].userType === 'company' &&
              args[0][4].userId === _id
            ) {
              commit(DELETE_COMPANY_FOLDER, args[0][0].returnFolder)
            }
          }
        }).then(
          function (subscription) {
            store.state.companyDocuments.companyDocumentsSubscriptions.push(subscription)
          }
        )
      }

      connection.onclose = function (reason, details) {
        // handle connection lost
        console.log(reason, details, 'connection.onclose')
      }
      connection.open()
    } catch (e) {
      console.error(e, 'ERROR in autobahn connection')
    }
  },
  unsubscribeCompanyDocumentsUpdate ({ commit }, sub) {
    sub.unsubscribe().then(
      function (gone) {
        if (gone) commit(RESET_COMPANY_DOCUMENTS)
        // successfully unsubscribed
      },
      function (error) {
        // unsubscribe failed
        console.error(error, 'error unsubscribe')
      }
    )
  }
}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions
}
