<template>
  <div class="app-scrollbar">
    <!-- <div v-if="$vuetify.breakpoint.mobile">
      <div
        style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: 800px;
        "
      >
        <img
          src="@/assets/Lavirra_logo_SVG.svg"
          height="48px"
          width="auto"
          style="
            position: absolute;
            top: 40%;
            text-align: center;
          "
        >
        <v-icon
          x-large
          style="
            position: absolute;
            top: 47%;
            text-align: center;
          "
        >
          mdi-worker
        </v-icon>
        <div
          style="
            position: absolute;
            top: 50%;
            text-align: center;
          "
        >
          <br><span>Please excuse us for the inconvenience.</span><br>
          <span>The mobile version of LAVVIRA is under construction.</span>
        </div>
      </div>
    </div> -->
    <v-app
      :style="`position: ${position}; width: 100%; background-color: ${$vuetify.theme.themes[theme].background}`"
    >
      <v-overlay
        absolute
        :value="loading"
        opacity="0.46"
        class="full-screen"
        text-xs-center
      >
        <div class="d-flex flex-column justify-center align-center">
          <v-progress-circular
            :size="50"
            color="white"
            indeterminate
          />
          <img
            src="@/assets/Lavirra_logo_SVG.svg"
            height="28px"
            width="auto"
            class="mt-6"
          >
          <span
            class="mt-4 ml-2"
            style="font-size: 15px;"
          >
            {{ message }}
          </span>
        </div>
      </v-overlay>

      <core-menu
        v-if="!$vuetify.breakpoint.mobile && coreMenusVisiblity && !loading"
      />
      <core-toolbar
        v-if="coreMenusVisiblity && !loading"
      />
      <core-content
        v-if="!loading"
      />
      <toast-controller
        v-if="!loading"
      />
    </v-app>
  </div>
</template>

<script>
import ToastController from '@/components/toasts/ToastController'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/utils/EventBus'

export default {
  components: {
    ToastController
  },
  data () {
    return {
      loading: true,
      message: this.$t('loading|getting_account_data')
    }
  },
  computed: {
    ...mapState({
      focus: state => state.app.focus
    }),
    coreMenusVisiblity () {
      return !this.$route.meta || !this.$route.meta.viewOnly
    },
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    position () {
      if (this.$vuetify.breakpoint.mobile) return 'relative'
      else return 'fixed'
    }
  },
  created () {
    EventBus.$on('app-loading', this.onAppLoading)
    EventBus.$on('stop-app-loading', this.onStopAppLoading)
    this.stopLoading()
    if (this.$router.history.current.fullPath === '/login') this.loading = false
  },
  beforeDestroy () {
    EventBus.$off('app-loading', this.onAppLoading)
    EventBus.$off('stop-app-loading', this.onStopAppLoading)
  },
  methods: {
    ...mapMutations({
      toggleFocus: 'app/toggleFocus'
    }),
    onAppLoading (mess) {
      if (mess === 'ref_token/setAccount') this.message = this.$t('loading|getting_account_settings')
      if (mess === 'logging_out') this.message = this.$t('loading|logging_out')
      this.loading = true
    },
    onStopAppLoading () {
      this.message = ''
      this.loading = false
    },
    stopLoading () {
      setTimeout(this.onStopAppLoading, 3000)
    }
  }
}
</script>

<style lang="scss">
  @import "@/styles/index.scss";

  html {
    font-size: 19px !important;
    &.overflow-y {
      overflow-y: hidden;
    }
    &.html-overflow-hidden {
      overflow-y: hidden;
      height: 100%;
    }
    body, #app, main.v-main {
      height: 100%;
      background-color: #d7d7d7;
    }
  }
  .relative {
    position: relative;
  }
  .static {
    position: static;
  }
  .white-dialog {
    background-color: white;
  }
  .app-scrollbar {
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 0px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 0px;
        box-shadow: none;
        background-color: #a39898;
    }
  }
  .full-screen {
    height: 100%;
    width: 100%;
  }
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar:horizontal{
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(32, 32, 32, 0.5);
    border-radius: 20px;
    border: transparent;
  }
</style>
