import Vue from 'vue'
import axios from '@/plugins/axios'
import store from '../index'
import autobahn from 'autobahn'
import { i18n } from '@/i18n/index'

const SET_LOADING = 'SET_LOADING'
const SET_CLIENTS = 'SET_CLIENTS'
const SET_CLIENT_AS_CLIENT = 'SET_CLIENT_AS_CLIENT'
const ADD_CLIENT = 'ADD_CLIENT'
const UPDATE_CLIENT_CASES = 'UPDATE_CLIENT_CASES'
const UPDATE_CLIENT = 'UPDATE_CLIENT'
const DELETE_CLIENT = 'DELETE_CLIENT'
const RESET_CLIENTS = 'RESET_CLIENTS'

const state = () => {
  return {
    loading: false,
    clients: [],
    meAsClient: []
  }
}

const mutations = {
  [SET_LOADING] (state, value) {
    state.loading = value
  },
  [SET_CLIENTS] (state, payload) {
    state.clients = payload
  },
  [SET_CLIENT_AS_CLIENT] (state, payload) {
    state.meAsClient = payload
  },
  [ADD_CLIENT] (state, payload) {
    const index = state.clients.findIndex(cl => cl._id === payload._id)
    if (index === -1) state.clients.push(payload)
  },
  [UPDATE_CLIENT] (state, payload) {
    const index = state.clients.findIndex(c => c._id === payload._id)
    if (index === -1 && payload.sharedWith.accounts.includes(store.state.account.account._id)) {
      state.push(payload)
    } else if (index !== -1 && payload.sharedWith.accounts.includes(store.state.account.account._id)) {
      Vue.set(state.clients, index, payload)
    }
  },
  [UPDATE_CLIENT_CASES] (state, payload) {
    let casesToBeRefreshed = []
    store.state.companyCases.companyCases.forEach(_case => {
      if (_case.client._id === payload._id) casesToBeRefreshed.push({ caseId: _case._id, type: 'company' })
    })
    store.state.groupCases.groupCases.forEach(_case => {
      if (_case.client._id === payload._id) casesToBeRefreshed.push({ caseId: _case._id, type: 'group' })
    })
    store.state.cases.cases.forEach(_case => {
      if (_case.client._id === payload._id) casesToBeRefreshed.push({ caseId: _case._id, type: 'acc' })
    })
    if (casesToBeRefreshed.length) {
      casesToBeRefreshed.forEach(obj => {
        if (obj.type === 'company') store.dispatch('companyCases/fetchCompanyCase', obj.caseId)
        if (obj.type === 'group') store.dispatch('groupCases/fetchGroupCase', obj.caseId)
        if (obj.type === 'acc') store.dispatch('cases/fetchCase', obj.caseId)
      })
    }
  },
  [DELETE_CLIENT] (state, _id) {
    // sometimes _id is the entire client object so we need to compare with _id._id in the findIndex fncn
    const index = state.clients.findIndex(c => c._id === _id._id ? _id._id : _id)
    if (index !== -1) {
      state.clients.splice(index, 1)
    }
  },
  [RESET_CLIENTS] (state) {
    state.clients = []
  }
}

const actions = {
  fetchClients ({ commit }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getClients`)
        commit(SET_CLIENTS, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  fetchClient ({ commit }, { _id }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getClient/${_id}`)
        commit(UPDATE_CLIENT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  getMyClientAccountAsClient ({ commit }, { _id }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getClientAsClient/${_id}`)
        commit(SET_CLIENT_AS_CLIENT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  addClient ({ commit }, payload) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/createClient`, payload)
        if (data.success) {
          commit(ADD_CLIENT, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateClient ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateClient/${_id}`, payload)
        if (data.success) {
          commit(UPDATE_CLIENT, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteClient ({ commit }, { _id, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        await axios.post(`/deleteClient/${_id}`, payload)
        commit(DELETE_CLIENT, _id)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  inviteAccountToBecomeClient ({ commit }, { _id, payload }) {
    commit(SET_LOADING, false)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/inviteAnAccountToBecomeClient/${_id}`, payload)
        commit(ADD_CLIENT, data.data)
        store.dispatch('toasts/addToast', {
          title: `${i18n.t('message|invitation_sent_success')}`,
          color: 'white',
          snackbarColor: 'success'
        })
        resolve(data)
      } catch (e) {
        if (!e.response.data.success && typeof e.response.data.msg === 'string' && e.response.data.msg.includes('user not found')) {
          store.dispatch('toasts/addToast', {
            title: `${e.response.data.msg} ${i18n.t('warning|check_email_and_try_again')}`,
            color: 'white',
            snackbarColor: 'error'
          })
        }
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  sendInvitationViaEmail ({ commit }, { _id, payload }) {
    commit(SET_LOADING, false)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/sendEmailInvitation/${_id}`, payload)
        commit(ADD_CLIENT, data.data)
        store.dispatch('toasts/addToast', {
          title: `${i18n.t('message|invitation_sent_success')}`,
          color: 'white',
          snackbarColor: 'success'
        })
        resolve(data)
      } catch (e) {
        if (!e.response.data.success && typeof e.response.data.msg === 'string' && e.response.data.msg.includes('user not found')) {
          store.dispatch('toasts/addToast', {
            title: `${e.response.data.msg} ${i18n.t('warning|check_email_and_try_again')}`,
            color: 'white',
            snackbarColor: 'error'
          })
        }
        if (e && e.response.msg === 'message|user_already_has_account') {
          store.dispatch('toasts/addToast', {
            title: i18n.$t(e.response.msg),
            color: 'white',
            snackbarColor: 'error'
          })
        }
        if (!e.response.data.success && e.response.data.msg.code === 'EENVELOPE') {
          store.dispatch('toasts/addToast', {
            title: `${i18n.t('error|server_could_not_deliver_mail')} ${e.response.data.msg.rejected[0]}.  ${i18n.t('warning|account_or_domain_blacklisted')}`,
            color: 'white',
            snackbarColor: 'error'
          })
        }
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  acceptInvitationFromClient ({ commit }, { _id, payload }) {
    commit(SET_LOADING, false)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/acceptAnClientlInvitation/${_id}`, payload)
        commit(ADD_CLIENT, data.data)
        store.dispatch('account/fetchAccount', { _id })
        store.dispatch('toasts/addToast', {
          title: `${i18n.t('message|invitation_accepted')}`,
          color: 'white',
          snackbarColor: 'success'
        })
        resolve(data)
      } catch (e) {
        if (e && !e.response.data.success && e.response.data.msg && e.response.data.msg.includes('user not found')) {
          store.dispatch('toasts/addToast', {
            title: `${e.response.data.msg} ${i18n.t('warning|check_email_and_try_again')}`,
            color: 'white',
            snackbarColor: 'error'
          })
        }
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  resetClients ({ commit }) {
    commit(RESET_CLIENTS)
  },
  clientsUpdatesSubscription ({ commit }, { _id }) {
    try {
      const connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })

      connection.onopen = function (session) {
        // call a remote procedure
        session.subscribe(`lavvira.updates.${_id}`, async (...args) => {
          const clientsTopic = args[0].includes('Client')
          const createTask = args[0].includes('create')
          const updateTask = args[0].includes('update')
          const deleteTask = args[0].includes('delete')
          if (args[0][4] && args[0][4].updateType === 'clientProfileUpdate') {
            commit(UPDATE_CLIENT_CASES, args[0][0])
          }
          if (clientsTopic && createTask) {
            commit(ADD_CLIENT, args[0][0])
          } else if (clientsTopic && updateTask) {
            const client = store.state.clients.clients.find(c => c._id === args[0][0]._id)
            if (client) {
              commit(UPDATE_CLIENT, args[0][0])
            } else {
              // implemet 'share task'!!! to be clear
              commit(UPDATE_CLIENT, args[0][0])
            }
          } else if (clientsTopic && deleteTask) {
            commit(DELETE_CLIENT, args[0][0])
          }
        })
      }

      connection.onclose = function (reason, details) {
        // handle connection lost
        console.log(reason, details, 'connection.onclose')
      }
      connection.open()
    } catch (e) {
      console.error(e, 'ERROR in autobahn connection')
    }
  }
}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions
}
