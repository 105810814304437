import axios from '@/plugins/axios'
import Vue from 'vue'
import store from '../index'
import autobahn from 'autobahn'

const INIT = 'INIT'
const SET_TEMPLATES = 'SET_TEMPLATES'
const ADD_COMPANY_TEMPLATE = 'ADD_COMPANY_TEMPLATE'
const ADD_COMMENT = 'ADD_COMMENT'
const UPDATE_COMPANY_TEMPLATE = 'UPDATE_COMPANY_TEMPLATE'
const DELETE_COMPANY_TEMPLATE = 'DELETE_COMPANY_TEMPLATE'
const DELETE_COMMENT = 'DELETE_COMMENT'
const SET_TEMPLATE_ID = 'SET_TEMPLATE_ID'
const SET_LOADING = 'SET_LOADING'
const SET_SCELETON_CARD = 'SET_SCELETON_CARD'
const REMOVE_SCELETON_CARD = 'REMOVE_SCELETON_CARD'
const RESET_COMPANY_TEMPLATES = 'RESET_COMPANY_TEMPLATES'

const state = () => {
  return {
    init: false,
    templateId: null,
    companyTemplates: [],
    companyTemplatesSubscriptions: [],
    loading: false
  }
}

const mutations = {
  [INIT] (state) {
    state.init = true
  },
  [SET_TEMPLATES] (state, payload) {
    state.companyTemplates = payload
  },
  [ADD_COMPANY_TEMPLATE] (state, payload) {
    const index = state.companyTemplates.findIndex(c => c._id === payload._id)
    if (index === -1) {
      state.companyTemplates.push(payload)
    }
  },
  [ADD_COMMENT] (state, payload) {
    const index = state.companyTemplates.findIndex(c => c._id === payload._id)
    Vue.set(state.companyTemplates, index, payload)
  },
  [UPDATE_COMPANY_TEMPLATE] (state, payload) {
    const index = state.companyTemplates.findIndex(c => c._id === payload._id)
    Vue.set(state.companyTemplates, index, payload)
  },
  [DELETE_COMPANY_TEMPLATE] (state, payload) {
    const index = state.companyTemplates.findIndex(c => c._id === payload._id)
    if (index !== -1) {
      state.companyTemplates.splice(index, 1)
    }
  },
  [DELETE_COMMENT] (state, payload) {
    const index = state.companyTemplates.findIndex(c => c._id === payload._id)
    Vue.set(state.companyTemplates, index, payload)
  },
  [SET_TEMPLATE_ID] (state, id) {
    state.templateId = id
  },
  [SET_LOADING] (state, value) {
    state.loading = value
  },
  [SET_SCELETON_CARD] (state, sceleton) {
    state.companyTemplates.push(sceleton)
  },
  [REMOVE_SCELETON_CARD] (state) {
    const index = state.companyTemplates.findIndex(t => t.name === 'loading')
    state.companyTemplates.splice(index, 1)
  },
  [RESET_COMPANY_TEMPLATES] (state) {
    state.companyTemplates = []
    state.companyTemplatesSubscriptions = []
  }
}

const actions = {
  fetchCompanyTemplates ({ commit }, { _id }) {
    return new Promise(async (resolve) => {
      try {
        const { data } = await axios.get(`/getSharedTemplates/${_id}`)
        commit(SET_TEMPLATES, data.data)
      } catch (e) {
        console.error(e, 'fetchTemplates ERROR')
      } finally {
        resolve()
      }
    })
  },
  addCompanyTemplate ({ commit, rootState }, payload = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const routeName = rootState.account.account.accountType === 'god' ? 'createSharedTemplate' : 'createTemplate'
        const { data } = await axios.post(`/${routeName}`, payload)
        if (data.success) {
          commit(ADD_COMPANY_TEMPLATE, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  addMyComnpanyComment ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/addComment/${_id}`, payload)
        commit(ADD_COMMENT, data.data)
        resolve()
      } catch (e) {
        console.error(e, 'updateTemplate ERROR')
        reject(e)
      }
    })
  },
  replyToCompanyComment ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/replyComment/${_id}`, payload)
        commit(ADD_COMMENT, data.data)
        resolve()
      } catch (e) {
        console.error(e, 'updateTemplate ERROR')
        reject(e)
      }
    })
  },
  updateMyCompanyComment ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateComment/${_id}`, payload)
        commit(ADD_COMMENT, data.data)
        resolve()
      } catch (e) {
        console.error(e, 'updateComment ERROR')
        reject(e)
      }
    })
  },
  updateCompanyTemplate ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateTemplate/${_id}`, payload)
        if (data.success) {
          const available = data.data.sharedWith.company.find(c => c._id === store.state.company.company._id)
          if (available) {
            commit(UPDATE_COMPANY_TEMPLATE, data.data)
            resolve()
          } else {
            commit(DELETE_COMPANY_TEMPLATE, data.data)
            resolve()
          }
        } else {
          reject(data)
        }
      } catch (e) {
        console.error(e, 'updateTemplate ERROR')
        reject(e)
      }
    })
  },
  deleteCompanyTemplate ({ commit }, payload = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/deleteTemplate/${payload._id}`)
        if (data.success) {
          commit(DELETE_COMPANY_TEMPLATE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        console.error(e, 'deleteTemplate ERROR')
        reject(e)
      }
    })
  },
  // deleteMyComment ({ commit }, { _id, payload } = {}) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const { data } = await axios.post(`/deleteComment/${_id}`, payload)
  //       commit(DELETE_COMMENT, data.data)
  //       resolve()
  //     } catch (e) {
  //       console.error(e, 'deleteComment ERROR')
  //       reject(e)
  //     }
  //   })
  // },
  deleteCompanyCategoryInTemplate ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateTemplate/${_id}/deleteTemplateCategory`, payload)
        if (data.success) {
          commit(UPDATE_COMPANY_TEMPLATE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  companyTemplatesUpdatesSubscription ({ commit }, { _id }) {
    try {
      const connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })

      connection.onopen = function (session) {
        // call a remote procedure
        session.subscribe(`lavvira.updates.${_id}`, async (...args) => {
          const templateTopic = args[0].includes('Template')
          const createTask = args[0].includes('create')
          const updateTask = args[0].includes('update')
          const deleteTask = args[0].includes('delete')
          const removeTask = args[0].includes('remove')
          if (args[0][4] && args[0][4].updateType === 'questionnaire update' && args[0][4].recipients === 'company_and_groups') {
            commit(UPDATE_COMPANY_TEMPLATE, args[0][0])
          }
          if (templateTopic && createTask) {
            commit(ADD_COMPANY_TEMPLATE, args[0][0])
          } else if (templateTopic && updateTask) {
            const template = store.state.companyTemplates.companyTemplates.find(t => t._id === args[0][0]._id)
            if (template) {
              commit(UPDATE_COMPANY_TEMPLATE, args[0][0])
            } else {
              // implemet 'share task'!!! to be clear
              commit(ADD_COMPANY_TEMPLATE, args[0][0])
            }
          } else if (templateTopic && deleteTask) {
            commit(DELETE_COMPANY_TEMPLATE, args[0][0])
          } else if (templateTopic && removeTask) {
            const isShared = args[0][0].sharedWith.company.find(c => c._id === store.state.company.company._id)
            if (isShared) {
              commit(UPDATE_COMPANY_TEMPLATE, args[0][0])
            } else {
              commit(DELETE_COMPANY_TEMPLATE, args[0][0])
            }
          }
        }).then(
          function (subscription) {
            store.state.companyTemplates.companyTemplatesSubscriptions.push(subscription)
          }
        )
      }

      connection.onclose = function (reason, details) {
        // handle connection lost
        console.log(reason, details, 'connection.onclose')
      }
      connection.open()
    } catch (e) {
      console.error(e, 'ERROR in autobahn connection')
    }
  },
  unsubscribeCompanyTemplatesUpdate ({ commit }, sub) {
    sub.unsubscribe().then(
      function (gone) {
        if (gone) commit(RESET_COMPANY_TEMPLATES)
        // successfully unsubscribed
      },
      function (error) {
        // unsubscribe failed
        console.error(error, 'error unsubscribe')
      }
    )
  },
  setCompanyTemplateId ({ commit }, _id) {
    commit(SET_TEMPLATE_ID, _id)
  },
  setCompanySceletonCard ({ commit }, { sceleton }) {
    commit(SET_SCELETON_CARD, sceleton)
  },
  deleteCompanySceleton ({ commit }) {
    commit(REMOVE_SCELETON_CARD)
  },
  resetCompanyTemplates ({ commit }) {
    commit(RESET_COMPANY_TEMPLATES)
  }
}

const getters = {
  getCurrentCompanyTemplate: (state) => {
    if (state.templateId) {
      return state.companyTemplates.find(c => c._id === state.templateId)
    }
    return null
  }
}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions,
  getters
}
