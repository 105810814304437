import { render, staticRenderFns } from "./CommentCardMobile.vue?vue&type=template&id=4d0e8272&scoped=true"
import script from "./CommentCardMobile.vue?vue&type=script&lang=js"
export * from "./CommentCardMobile.vue?vue&type=script&lang=js"
import style0 from "./CommentCardMobile.vue?vue&type=style&index=0&id=4d0e8272&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d0e8272",
  null
  
)

export default component.exports