<template>
  <v-container
    :id="containerId"
    class="d-flex pa-0 ml-2 mr-2"
    style="overflow-x: auto; overflow-y: hidden;"
    @mouseenter="navEl = true"
    @mouseleave="navEl = false"
  >
    <div
      v-for="(element, index) in routeElements"
      :id="element.text"
      :key="index"
      class="d-flex"
    >
      <v-chip
        v-if="routeElements && routeElements.length > 1"
        outlined
        :color="hoverChipArray.includes(index) ? 'accent' : activeChip.includes(index) ? 'primary' : ''"
        class="ma-2"
        @click="$emit('router-action', element)"
        @mouseover="hoverChipArray.push(index)"
        @mouseleave="hoverChipArray = []"
      >
        <v-icon
          small
          class="mr-2"
        >
          mdi-folder-outline
        </v-icon>
        {{ element.text }}
      </v-chip>
      <v-icon
        v-if="index <= routeElements.length - 2"
      >
        mdi-arrow-right
      </v-icon>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    routeElements: {
      type: Array,
      default: () => ([])
    },
    containerId: {
      type: String,
      default: ''
    },
    activeFolderObject: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      navEl: false,
      hoverChipArray: [],
      activeChip: []
    }
  },
  computed: {
    activeFolder () {
      return this.activeFolderObject
    }
  },
  watch: {
    activeFolder (val) {
      if (!val) this.activeChip = [0]
      else {
        const idx = this.routeElements.findIndex(el => el._id === val._id)
        if (idx !== -1) this.activeChip = [idx]
      }
    }
  },
  mounted () {
    var item = document.getElementById(this.containerId)
    window.addEventListener('wheel', (e) => {
      if (this.navEl) {
        if (e.deltaY > 0) item.scrollLeft += 100
        else item.scrollLeft -= 100
      }
    })
  }
}
</script>

<style>

</style>
