<template>
  <v-toolbar
    flat
    color="primary"
  >
    <v-text-field
      v-model="commentText"
      dark
      outlined
      dense
      rounded
      autofocus
      :label="$t('editor|add_comment')"
      append-icon="mdi-send"
      class="mt-6"
      @click:append="addComment()"
    />
  </v-toolbar>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { EventBus } from '@/utils/EventBus'
import { v4 as uuidv4 } from 'uuid'

export default {
  mixins: [validationMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    template: {
      type: Object,
      default: null
    },
    caseData: {
      type: Object,
      default: null
    },
    workbench: {
      type: Object,
      default: null
    },
    text: {
      type: String,
      default: ''
    },
    from: {
      type: Number,
      default: null
    },
    to: {
      type: Number,
      default: null
    },
    incommingId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      commentText: '',
      hoverCloseButtonIcon: false
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      settings: state => state.settings.settings,
      templates: state => state.templates.templates
    })
  },
  created () {
    EventBus.$on('emit-comment-id', this.onCommentIdEvent)
    EventBus.$on('emit-comment-text', this.onEmitCommentText)
  },
  beforeDestroy () {
    EventBus.$off('emit-comment-id', this.onCommentIdEvent)
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      updateTemplate: 'templates/updateTemplate',
      addMyComment: 'cases/updateCaseWorkbenchTemplate'
    }),
    onCommentIdEvent (assignedId) {
      this.incommingId = assignedId
      this.isVisible = true
    },
    onEmitCommentText (text) {
      this.text = text
    },
    async addComment () {
      const commentId = uuidv4()
      const commentForTemplate = {
        id: commentId,
        text: this.commentText,
        textThatHasBeenCommentedOn: this.text,
        from: this.from,
        to: this.to
      }
      this.$emit('comment-for-template', commentForTemplate)
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
