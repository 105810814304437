<template>
  <node-view-wrapper
    class="comment"
    :class="classes"
    :style="style"
    v-bind="node.attrs"
  >
    <template v-if="!node.attrs.resolved">
      <span
        contenteditable="true"
        style="outline: 0px solid transparent;"
        :style="style"
        @focus="hoverCommentCard(node.attrs.label, node.attrs.id, node.attrs.fontSize)"
        @blur="updateContent(node.attrs)"
      >
        {{ node.attrs.label }}
        <!-- <v-icon
          x-small
          @click="selectComment(node.attrs.label, node.attrs.id)"
        >
          mdi-comment-text-outline
        </v-icon> -->
      </span>
    </template>
    <template v-if="node.attrs.resolved">
      <span
        contenteditable="true"
        style="outline: 0px solid transparent;"
        class="px-0"
        @focus="hoverCommentCard(node.attrs.label, node.attrs.id, node.attrs.fontSize)"
        @blur="updateContent(node.attrs)"
      >
        {{ node.attrs.label }}
      </span>
    </template>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import { EventBus } from '@/utils/EventBus'

export default {
  components: {
    NodeViewWrapper
  },
  props: {
    nodeViewProps,
    editor: {
      type: Object,
      required: true
    },
    node: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    decorations: {
      type: Array,
      required: true
    },
    extension: {
      type: Object,
      required: true
    },
    updateAttributes: {
      type: Function,
      required: true
    }
  },
  computed: {
    classes () {
      const c = []
      if (this.node.attrs.resolved) c.push('resolved')
      return c
    },
    style () {
      if (this.node.attrs && this.node.attrs.fontSize) {
        return `font-size: ${this.node.attrs.fontSize};`
      } else {
        return ''
      }
    }
  },
  watch: {
    selected (value) {
      if (value) {
        EventBus.$emit('select-comment', this.node.attrs.label, this.node.attrs.id)
      }
    }
  },
  methods: {
    selectComment (label, id) {
      EventBus.$emit('select-comment', label, id)
    },
    updateContent (comment) {
      this.editor.view.state.doc.descendants((node, pos) => {
        if (node.type.name === 'comment') {
          if (node.attrs.id === comment.id) {
            const newText = document.getElementById(comment.id).firstElementChild.innerText.trim()
            this.editor.chain().focus().setNodeSelection(pos).run()
            this.editor.commands.updateAttributes('comment', { label: newText })
          }
        }
      })
    },
    hoverCommentCard (label, id, fontSize) {
      EventBus.$emit('select-comment', label, id, fontSize)
    }
  }
}
</script>

<style lang="scss" scoped>
// .comment {
//   background: #FAF594;
//   // border: 1px solid #0D0D0D;
//   border-radius: 0.5rem;
//   margin: 1rem 0;
//   position: relative;
// }

// .label {
//   margin-left: 1rem;
//   background-color: #0D0D0D;
//   font-size: 0.6rem;
//   letter-spacing: 1px;
//   font-weight: bold;
//   text-transform: uppercase;
//   color: #fff;
//   position: absolute;
//   top: 0;
//   padding: 0.25rem 0.75rem;
//   border-radius: 0 0 0.5rem 0.5rem;
// }

// .content {
//   margin: 2.5rem 1rem 1rem;
//   padding: 0.5rem;
//   border: 2px dashed #0D0D0D20;
//   border-radius: 0.5rem;
// }
</style>
