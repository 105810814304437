import { render, staticRenderFns } from "./FileViewSplitScreen.vue?vue&type=template&id=dbd4272c&scoped=true"
import script from "./FileViewSplitScreen.vue?vue&type=script&lang=js"
export * from "./FileViewSplitScreen.vue?vue&type=script&lang=js"
import style0 from "./FileViewSplitScreen.vue?vue&type=style&index=0&id=dbd4272c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbd4272c",
  null
  
)

export default component.exports