<template>
  <v-dialog
    v-model="isVisible"
    keydown
    :max-width="tab === 2 ? '100%' : '1366px'"
    scrollable
  >
    <v-card
      v-if="fileOptions && isVisible"
    >
      <v-toolbar
        color="white"
      >
        <v-col
          cols="4"
        >
          <v-toolbar-title
            v-html="fileOptions.filename"
          />
        </v-col>
        <v-col cols="8">
          <template>
            <v-tabs
              v-model="tab"
              align-with-title
              class="mt-1"
            >
              <v-tabs-slider :color="selectedTabObject.disabled ? 'white' : 'accent'" />

              <v-tab
                v-for="(t, index) in availableTabs"
                :key="index"
              >
                {{ t.text }}
              </v-tab>
            </v-tabs>
          </template>
          <v-btn
            icon
            style="position: absolute; top: 8px; right: 16px;"
            @click="closeAndReset()"
            @mouseover="hoverCloseButtonIcon = true"
            @mouseleave="hoverCloseButtonIcon = false"
          >
            <v-icon
              v-if="hoverCloseButtonIcon"
              small
            >
              mdi-close
            </v-icon>
            <v-icon
              v-else
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-col>
      </v-toolbar>
      <v-tabs-items
        v-model="tab"
      >
        <v-tab-item
          eager
        >
          <v-card-text
            style="height: 80vh;"
          >
            <template>
              <iframe
                v-if="src && (lavviraPreview || (pdfPreviewOnly && !previewImage))"
                :src="src"
                style="width: 100%; height: 100%; border:none;"
              />
              <div
                v-if="!src && !pdfPreviewOnly && !previewImage"
              >
                <v-sheet
                  color="grey"
                  class="pa-3"
                >
                  <v-snackbar
                    v-model="show"
                    :timeout="timeout"
                    centered
                    color="warning"
                    elevation="28"
                  >
                    <v-icon>
                      mdi-alert-octagon-outline
                    </v-icon>
                    {{ snackBarText }}
                  </v-snackbar>
                  <v-skeleton-loader
                    class="mx-auto mt-7"
                    tile
                    max-width="300"
                    min-height="500"
                    type="article@3"
                  />
                </v-sheet>
              </div>
              <div
                v-if="previewImage"
                :style="`height: ${$vuetify.breakpoint.height - 250}px; width: 70%; border: 1px solid grey; overflow-y: auto;`"
                class="rounded-lg mx-auto center-item"
              >
                <img
                  :src="src"
                >
              </div>
            </template>
          </v-card-text>
        </v-tab-item>
        <v-tab-item
          v-if="!pdfPreviewOnly && !previewImage"
          eager
        >
          <!--
            read only option has to be false for readonly mode
          -->
          <!-- style="margin: auto; width: 80%; border: 1px solid black; padding: 20px;" -->
          <v-row
            v-if="fileOptions && fileOptions.json"
            class="mt-1"
          >
            <v-col
              v-if="drawer"
              cols="3"
            >
              <v-card
                v-if="drawer"
                width="100%"
                :height="$vuetify.breakpoint.height - 222"
                class="ml-2 pa-0"
                style="overflow-y: auto"
              >
                <v-navigation-drawer
                  v-model="drawer"
                  floating
                  class="pa-0"
                  width="100%"
                >
                  <v-toolbar
                    dense
                    flat
                    style="position: sticky; top: 0px; z-index: 1;"
                  >
                    <v-subheader>{{ $t('documents|extracted_data') }}</v-subheader>
                    <v-spacer />
                    <v-icon
                      @click="drawer = false"
                    >
                      mdi-menu-open
                    </v-icon>
                  </v-toolbar>
                  <v-list
                    class="mt-1"
                    :min-height="$vuetify.breakpoint.height - 288"
                    expand
                  >
                    <v-list-group
                      v-for="(item, index) in extractedDocumentData"
                      :key="index"
                      :value="true"
                    >
                      <template v-slot:activator>
                        <v-hover v-slot:default="{ hover }">
                          <v-list-item-content>
                            <v-list-item-title
                              v-if="!hover"
                              style="font-weight: bold"
                              v-text="item.title"
                            />
                            <span
                              v-if="hover"
                              style="font-weight: bold"
                            >
                              {{ item.title }}
                            </span>
                          </v-list-item-content>
                        </v-hover>
                      </template>

                      <v-list-item
                        v-for="(child, i) in item.extracted"
                        :key="i"
                      >
                        <v-list-item-content>
                          <v-text-field
                            :id="child.key"
                            v-model="child.extractedData"
                            :placeholder="$t('actions|correct_available_data')"
                            :label="child.extractedData.toString()"
                            append-outer-icon="mdi-delete"
                            @focus="findInText(child); changeBtnKey(index, i); deactivatePopUpMenu = true"
                            @blur="deactivatePopUpMenu = false"
                            @click:append-outer="deleteField(child, i)"
                          />
                          <v-btn
                            v-if="btnKey && btnKey[0] === index && btnKey[1] === i"
                            x-small
                            outlined
                            rounded
                            color="primary"
                            class="mx-auto px-4"
                            min-width="130"
                            @click="replaceInText(child.extractedData, item.category)"
                          >
                            {{ $t('common|apply_changes') }}
                          </v-btn>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                  <div class="static-footer text-center">
                    <v-divider
                      class="ml-4 mb-1"
                    />
                    <v-btn
                      :small="actualLanguage === 'bg'"
                      :x-small="actualLanguage !== 'bg'"
                      rounded
                      outlined
                      color="primary"
                      class="my-2 mx-1 px-1 text-wrap"
                      @click="restoreOriginalData()"
                    >
                      <span
                        :style="actualLanguage === 'bg' ? 'font-size: 9px;' : ''"
                      >
                        {{ $t('file|restore_original_data') }}
                      </span>
                    </v-btn>
                  </div>
                </v-navigation-drawer>
              </v-card>
            </v-col>
            <v-col
              :cols="drawer ? '9' : '12'"
            >
              <Editor
                ref="editor"
                :case-data="caseData"
                :options="{
                  inCase: inCase
                }"
                :read-only-option="false"
                :file-view-drawer="!drawer"
                :actual-document="actualDocument"
                :deactivate-pop-up-menu="deactivatePopUpMenu"
                @acticvate-file-view-drawer="drawer = true"
              />
            </v-col>
          </v-row>

          <div
            v-else
          >
            <v-sheet
              color="grey"
              class="pa-3"
            >
              <v-snackbar
                v-model="show"
                :timeout="timeout"
                centered
                color="warning"
                elevation="28"
              >
                <v-icon>
                  mdi-alert-octagon-outline
                </v-icon>
                {{ snackBarError }}
              </v-snackbar>
              <v-skeleton-loader
                class="mx-auto mt-7"
                tile
                max-width="300"
                min-height="500"
                type="article@3"
              />
            </v-sheet>
          </div>
        </v-tab-item>
        <v-tab-item>
          <FileViewSplitScreen
            :content="content"
            :src="src"
            :case-data="caseData"
            :lavvira-preview="lavviraPreview"
            :pdf-preview-only="pdfPreviewOnly"
            :preview-image="previewImage"
            :read-only-option="false"
            :file-view-drawer="!drawer"
            :actual-document="actualDocument"
            :in-case="inCase"
            :show="show"
            @acticvate-file-view-drawer="drawer = true"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Editor from '@/pages/TemplateEditor/Editor.vue'
import { v4 as uuidv4 } from 'uuid'
import FileViewSplitScreen from './FileViewSplitScreen.vue'

export default {
  components: { Editor, FileViewSplitScreen },
  props: {
    global: {
      type: Boolean,
      default: false
    },
    fromGeneralSearch: {
      type: Boolean,
      default: false
    },
    caseData: {
      type: Object,
      default: null
    },
    inCase: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isVisible: false,
      inParsedView: true,
      drawer: false,
      loading: false,
      src: null,
      pdfPreviewOnly: false,
      previewImage: false,
      lavviraPreview: false,
      fileOptions: null,
      html: null,
      textPlain: null,
      content: null,
      tab: 0,
      hoverCloseButtonIcon: false,
      show: false,
      snackbar: false,
      snackBarText: this.$t('message|document_is_processed'),
      snackBarError: this.$t('error|document_processing_error'),
      timeout: 3000,
      searchResults: [],
      btnKey: null,
      deactivatePopUpMenu: false
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
      account: state => state.account.account,
      documents: state => state.documents.documents,
      companyDocuments: state => state.companyDocuments.companyDocuments,
      groupDocuments: state => state.groupDocuments.groupDocuments,
      sharedWithMeDocuments: state => state.documents.sharedWithMeDocuments,
      folders: state => state.documents.folders,
      sharedWithMeFolders: state => state.documents.sharedWithMeFolders,
      companyFolders: state => state.companyDocuments.companyFolders,
      groupFolders: state => state.groupDocuments.groupFolders,
      actualLanguage: state => state.account.actualLanguage
    }),
    allAvailableDocuments () {
      let documentsArray = []
      documentsArray.push(
        ...this.documents,
        ...this.companyDocuments,
        ...this.groupDocuments,
        ...this.sharedWithMeDocuments
      )
      for (let idx = 0; idx < this.folders.length; idx++) {
        const folder = this.folders[idx]
        documentsArray.push(...folder.documents)
      }
      for (let idx = 0; idx < this.companyFolders.length; idx++) {
        const cFolder = this.companyFolders[idx]
        documentsArray.push(...cFolder.documents)
      }
      for (let idx = 0; idx < this.groupFolders.length; idx++) {
        const gFolder = this.groupFolders[idx]
        documentsArray.push(...gFolder.documents)
      }
      for (let idx = 0; idx < this.sharedWithMeFolders.length; idx++) {
        const shFolder = this.sharedWithMeFolders[idx]
        documentsArray.push(...shFolder.documents)
      }
      return documentsArray
    },
    availableTabs () {
      let tabsToReturn = []
      if (this.pdfPreviewOnly || this.previewImage) {
        tabsToReturn = [
          {
            text: this.$t('actions|preview'),
            disabled: true
          }
        ]
      } else {
        tabsToReturn = [
          {
            text: this.$t('file|pdf_view'),
            disabled: false
          },
          {
            text: this.$t('file|lavvira_view'),
            disabled: false
          },
          {
            text: this.$t('mixed|split_screen_compare'),
            disabled: false
          }
        ]
      }
      return tabsToReturn
    },
    selectedTabObject () {
      return this.availableTabs[this.tab]
    },
    actualDocument () {
      let _currentDoc
      _currentDoc =
      this.allAvailableDocuments.find(doc => doc._id === this.fileOptions._id)
      // this.fileOptions.doc ||
      // this.documents.find(d => d._id === this.fileOptions._id) ||
      // this.companyDocuments.find(d => d._id === this.fileOptions._id) ||
      // this.groupDocuments.find(d => d._id === this.fileOptions._id) ||
      // this.sharedWithMeDocuments.find(d => d._id === this.fileOptions._id)
      return _currentDoc
    },
    extractedDocumentData () {
      let _array = []
      let extractedCategory = null
      if (!this.actualDocument.info.content[0].lavvira_labels) return _array
      _array = Object.entries(this.actualDocument.info.content[0].lavvira_labels)
        .map((entr) => {
          let addKeyToExtractedData = []
          entr[1].forEach(_string => {
            let textStart
            let textEnd
            let unresolvedPos
            this.$refs.editor.editor.view.state.doc.descendants((node, pos) => {
              if (node.isText) {
                const foundTextIndex = node.text.indexOf(_string)
                if (foundTextIndex !== -1) {
                  const $pos = this.$refs.editor.editor.state.doc.resolve(pos)
                  unresolvedPos = pos
                  textStart = pos - $pos.textOffset
                  textEnd = textStart + $pos.parent.child($pos.index()).nodeSize
                }
              }
            })
            const _item = {
              category: entr[0],
              extractedData: _string,
              key: uuidv4(),
              pos: unresolvedPos,
              startPos: textStart,
              endPos: textEnd
            }
            addKeyToExtractedData.push(_item)
          })
          const found = Object.entries(this.settings.fields).find(pair => pair[0] === entr[0])
          if (found) {
            const _actualLanguage = this.actualLanguage || 'en'
            extractedCategory = found[1].label[_actualLanguage]
          } else {
            extractedCategory = entr[0].split('_').join(' ')
          }
          const item = {
            title: extractedCategory || entr[0].split('_').join(' '),
            extracted: addKeyToExtractedData,
            newData: entr[1],
            category: entr[0]
          }
          return item
        })
      return _array
    }
  },
  watch: {
    tab (value) {
      if (value === 1) {
        this.show = true
        this.drawer = true
      } else if (value === 2) {
        this.show = true
      } else {
        this.show = false
        this.drawer = false
      }
    }
  },
  created () {},
  beforeDestroy () {},
  methods: {
    ...mapActions({
      updateDocument: 'documents/updateDocument'
    }),
    async restoreOriginalData () {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('common|are_you_sure_reset_data_to_original'),
        type: 'warning'
      })
      if (!res) return
      if (res) {
        const _id = this.fileOptions._id
        const payload = {
          restoreOriginalExtractedData: true
        }
        try {
          const data = await this.updateDocument({ _id, payload })
          this.content = data.data.parsed.text_json
          this.$refs.editor.setContent(this.content)
        } catch (error) {
          console.error(error)
        }
      }
    },
    openDocument (document) {
      if (!document || !document.raw) return
      const is = (typeof document.createdBy)
      if (document.parsed) {
        let path
        if (document.createdBy && is === 'string') {
          path = `/downloadDocument/${document.createdBy}/${document.raw.pdf_filename}`
        } else if (is === 'object' && document.createdBy._id === this.account.companyId) {
          path = `/downloadDocument/${this.account.companyId}/${document.raw.pdf_filename}`
        } else if (is === 'object' && document.createdBy._id) {
          path = `/downloadDocument/${document.createdBy._id}/${document.raw.pdf_filename}`
        }
        this.openFile({
          path,
          mimetype: document.raw.mimetype,
          filename: document.raw.originalname,
          json: document.parsed.text_json,
          extractedData: document.info.content[0].lavvira_labels,
          _id: document._id,
          parsed: true
        })
      } else {
        let path
        if (document.createdBy && is === 'string') {
          path = `/downloadDocument/${document.createdBy}/${document.raw.pdf_filename}`
        } else if (is === 'object' && document.createdBy._id === this.account.companyId) {
          path = `/downloadDocument/${this.account.companyId}/${document.raw.pdf_filename}`
        } else if (is === 'object' && document.createdBy._id) {
          path = `/downloadDocument/${document.createdBy._id}/${document.raw.pdf_filename}`
        }
        this.openFile({
          path,
          mimetype: document.raw.mimetype,
          filename: document.raw.originalname,
          parsed: false
        })
      }
    },
    async openFile (fileOptions = {}) {
      if (this.fromGeneralSearch) this.$emit('closeAndReset')
      if (!fileOptions.parsed && !fileOptions.onlyFilePreviewOnUpload) {
        this.fileOptions = fileOptions
        this.isVisible = true
        this.lavviraPreview = true
        this.loading = true
        try {
          const { data } = await this.$axios({
            url: fileOptions.path,
            method: 'GET',
            responseType: 'blob' // important
          })
          this.src = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
        } catch (e) {
          console.error(e, 'ERROR')
        } finally {
          this.loading = false
        }
      } else if (fileOptions.onlyFilePreviewOnUpload) {
        this.fileOptions = fileOptions
        this.isVisible = true
        if (fileOptions.type === 'application/pdf') {
          this.pdfPreviewOnly = true
          this.src = window.URL.createObjectURL(new Blob([fileOptions.file], { type: 'application/pdf' }))
        } else {
          this.previewImage = true
          const reader = new FileReader()
          reader.readAsDataURL(fileOptions.file)
          reader.onload = () => {
            this.src = reader.result
          }
        }
      } else {
        this.fileOptions = fileOptions
        this.html = fileOptions.html
        this.textPlain = fileOptions.textPlain
        this.isVisible = true
        this.lavviraPreview = true
        this.loading = true
        this.content = fileOptions.json
        try {
          const { data } = await this.$axios({
            url: fileOptions.path,
            method: 'GET',
            responseType: 'blob' // important
          })
          this.src = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          this.$refs.editor.setContent(this.content)
        } catch (e) {
          console.error(e, 'ERROR')
        } finally {
          this.loading = false
        }
      }
    },
    closeAndReset () {
      this.isVisible = false
      this.inParsedView = true
      this.pdfPreviewOnly = false
      this.previewImage = false
      this.lavviraPreview = false
      this.drawer = false
      this.loading = false
      this.tab = 0
      this.src = null
      this.fileOptions = null
      this.html = null
      this.textPlain = null
      this.content = null
      this.hoverCloseButtonIcon = false
      this.show = false
      this.snackbar = false
      this.searchResults = []
      this.btnKey = null
    },
    // async applyData (label, topic) {
    //   let _field = document.getElementById(label.key)
    //   // Init a timeout variable to be used below
    //   let timeout = null

    //   // Listen for keystroke events
    //   _field.addEventListener('keyup', (e) => {
    //     // Clear the timeout if it has already been set.
    //     // This will prevent the previous task from executing
    //     // if it has been less than <MILLISECONDS>
    //     clearTimeout(timeout)

    //     // Make a new timeout set to go off in 1000ms (1 second)
    //     timeout = setTimeout(async () => {
    //       if (!_field.value.length) _field.value = ' '
    //       this.$refs.editor.editor.commands.focus(label.pos)
    //       this.$refs.editor.editor.commands.setTextSelection({ from: label.startPos, to: label.endPos })
    //       this.$refs.editor.editor.commands.insertContent(_field.value)
    //       const _id = this.fileOptions._id
    //       const editorJSON = this.$refs.editor.editor.getJSON()
    //       const payload = {
    //         topic: topic,
    //         newLabel: _field.value,
    //         oldLabel: label.extractedData,
    //         updateExtractedData: true,
    //         json: editorJSON
    //       }
    //       try {
    //         await this.updateDocument({ _id, payload })
    //         // this.$refs.editor.editor.commands.setContent(res.data.parsed.text_json)
    //         // this.refreshDialogKey++
    //       } catch (error) {
    //         console.error(error)
    //       }
    //       // this.textValueToBeApplied = ({ pos: label.pos, start: label.startPos, end: label.endPos, input: _field.value })
    //     }, 1500)
    //   })
    // },
    async findInText (label) {
      if (label) {
        let _field = document.getElementById(label.key)
        this.field = _field
        const _label = label.extractedData.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
        // const _label = label.extractedData
        this.searchResults = []
        const mergedTextNodes = []
        let index = 0

        // if (!label) {
        //   return
        // }
        this.$refs.editor.editor.view.state.doc.descendants((node, pos) => {
          if (node.isText) {
            if (mergedTextNodes[index]) {
              mergedTextNodes[index] = {
                text: mergedTextNodes[index].text + node.text,
                pos: mergedTextNodes[index].pos
              }
            } else {
              mergedTextNodes[index] = {
                text: node.text,
                pos
              }
            }
          } else {
            index += 1
          }
        })
        mergedTextNodes.forEach(({ text, pos }) => {
          const search = this.findRegExp(_label)
          let m
          // eslint-disable-next-line no-cond-assign
          while ((m = search.exec(text))) {
            if (m[0] === '') {
              break
            }
            this.searchResults.push({
              from: pos + m.index,
              to: pos + m.index + m[0].length,
              text: label.extractedData
            })
          }
        })
        for (let i = 0; i < this.searchResults.length; i++) {
          const res = this.searchResults[i]
          this.$refs.editor.editor.commands.setTextSelection({ from: res.from, to: res.to })
          this.$refs.editor.editor.commands.scrollIntoView(res.from)
          this.$refs.editor.editor.commands.updateAttributes('textStyle', { backgroundColor: '#E64A19' })
          setTimeout(() => {
            this.$refs.editor.editor.commands.setTextSelection({ from: res.from, to: res.to })
            this.$refs.editor.editor.commands.updateAttributes('textStyle', { backgroundColor: '#FFFF00' })
          }, '2500')
        }
      } else {
        return
      }
      this.$refs.editor.editor.commands.blur()
    },
    findRegExp (input) {
      return RegExp(input, 'gui')
    },
    async replaceInText (replaceWith, category) {
      const firstResult = this.searchResults[0]
      if (!firstResult) {
        return
      }
      for (let index = 0; index < this.searchResults.length; index++) {
        const res = this.searchResults[index]
        this.$refs.editor.editor.commands.setTextSelection({ from: res.from, to: res.to })
        this.$refs.editor.editor.commands.insertContent(replaceWith)
      }
      const _id = this.fileOptions._id
      if (this.fromGeneralSearch) {
        this.$refs.editor.editor.view.state.doc.descendants((node, pos) => {
          if (node.isText) {
            node.marks.forEach(mark => {
              if (mark.attrs.backgroundColor && mark.attrs.backgroundColor === '#89CFF0') {
                const $pos = this.$refs.editor.editor.state.doc.resolve(pos)
                const textStart = pos - $pos.textOffset
                const textEnd = textStart + $pos.parent.child($pos.index()).nodeSize
                this.$refs.editor.editor.commands.setTextSelection({ from: textStart, to: textEnd })
                this.$refs.editor.editor.commands.updateAttributes('textStyle', { backgroundColor: null })
                this.$refs.editor.editor.commands.setTextSelection({ from: pos - 1, to: pos - 1 })
              }
            })
          }
        })
      }
      const editorJSON = this.$refs.editor.editor.getJSON()
      const payload = {
        topic: category,
        newLabel: replaceWith,
        oldLabel: firstResult.text,
        updateExtractedData: true,
        text_json: editorJSON
      }
      try {
        await this.updateDocument({ _id, payload })
      } catch (error) {
        console.error(error)
      }
    },
    changeBtnKey (index, i) {
      this.btnKey = [index, i]
    },
    async deleteField (child, i) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('common|are_you_sure_you_want_to_delete_this_resource'),
        type: 'warning'
      })
      if (!res) return
      const _id = this.fileOptions._id
      const payload = {
        category: child.category,
        label: child.extractedData,
        deleteExtractedData: true,
        inCase: this.inCase,
        caseId: this.caseData ? this.caseData._id : null
      }
      try {
        await this.updateDocument({ _id, payload })
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style scoped>
.topright {
  position: absolute;
  top: 8px;
  right: 16px;
  font-size: 18px;
}
.page {
  background: white;
  display: block;
  margin: 0 auto;
  width: 18cm;
  height: 20.7cm;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.2cm rgba(0,0,0,0.2);
}
.static-footer {
  background-color: white;
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  bottom: 0em;
  z-index: 2;
}
.center-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
