<template>
  <div
    v-if="editor"
    :style="_style"
  >
    <AddComment
      v-if="commentDialog"
      :value="commentDialog"
      :template="template"
      :case-data="caseData"
      :workbench="workbench"
      :from="from"
      :to="to"
      :text="textThatHasBeenCommentedOn"
      @comment-for-template="addComment"
      @close-comment-dialog="commentDialog = false"
    />
    <EditorRibbonPanel
      v-if="readOnlyOption"
      :editor="editor"
      :template="template"
      :account="account"
      :disable-comment-in-template="template && !caseData && !currentCaseForm"
      :actual-font-size="actualFontSize"
      :is-active-table="editor.isActive('table')"
      :in-template-only="template && !caseData && !currentCaseForm"
      :loading-saving-data="loadingSavingData"
      :connection-status="connectionStatus"
      @save="saveInfo"
      @save-as="saveAs"
      @update-template-name="updateTemplateName"
      @change-template-description="changeTemplateDescription"
      @undo="undoStep"
      @redo="redoStep"
      @cut="cut"
      @copy="copy"
      @paste="paste"
      @bold="toggleBold()"
      @italic="editor.chain().focus().toggleItalic().run()"
      @underline="editor.chain().focus().toggleUnderline().run()"
      @highlight="toggleHighlight"
      @text-color="setFontColor"
      @font-family="setFontFamily"
      @text-size="setFontSize"
      @align-left="editor.chain().focus().setTextAlign('left').run()"
      @align-center="alignCenter"
      @align-justify="editor.chain().focus().setTextAlign('justify').run()"
      @align-right="editor.chain().focus().setTextAlign('right').run()"
      @numbered-list="editor.chain().focus().toggleOrderedList().run()"
      @bulleted-list="editor.chain().focus().toggleBulletList().run()"
      @insert-table="editorAddons.insertTable.visible = true"
      @add-row-after="editor.chain().focus().addRowAfter().run()"
      @add-row-before="editor.chain().focus().addRowBefore().run()"
      @remove-row="editor.chain().focus().deleteRow().run()"
      @add-col-before="editor.chain().focus().addColumnBefore().run()"
      @add-col-after="editor.chain().focus().addColumnAfter().run()"
      @remove-col="editor.chain().focus().deleteColumn().run()"
      @split-cell="editor.chain().focus().splitCell().run()"
      @merge-cells="editor.chain().focus().mergeCells().run()"
      @toggle-header-row="editor.chain().focus().toggleHeaderRow().run()"
      @toggle-header-col="editor.chain().focus().toggleHeaderColumn().run()"
      @toggle-header-cell="editor.chain().focus().toggleHeaderCell().run()"
      @set-table-cell-color="setTableBackgroundColor"
      @delete-table="editor.chain().focus().deleteTable().run()"
      @add-suggestion="addSuggestion"
      @add-comment="openCommentPopup"
      @change-suggestion-language="changeSuggestionLanguage"
      @line-spacing="setLineSpacing"
      @select-heading="selectHeading"
      @new-template="addNewTemplate"
      @export-template="exportDocument('docx')"
      @export-template-pdf="exportDocument('pdf')"
      @find-in-text="findInText"
      @replace-with="replaceInText"
      @replace-all-with="replaceAll"
      @share-template="shareTemplate"
      @open-version-history="openVersionHistory"
      @change-suggestion-view-text="changeSuggestionsViewText"
      @change-suggestion-view-editor="changeSuggestionsViewEditorMode"
      @print-document="printDocument('print')"
      @drop-cap="editor.commands.toggleDropCap()"
      @strike-through="editor.commands.setStrike()"
      @unstrike-through="editor.commands.unsetStrike()"
      @toggle-superscript="editor.commands.toggleSuperscript()"
      @toggle-subscript="editor.commands.toggleSubscript()"
    />
    <v-row
      no-gutters
      :style="`background-color: #eee;`"
    >
      <v-col
        cols="2"
        class="pl-6 pt-6 sticky"
        :style="`max-height: ${($vuetify.breakpoint.height - 268).toString() + 'px'}; overflow-y: auto;`"
      >
        <div
          v-if="readOnlyOption && headings.length"
          class="ml-n5"
        >
          <div class="ml-2">
            {{ $t('common|summary') }}
          </div>
          <v-list-item
            v-for="(heading, i) in headings"
            :key="i"
            dense
            @click="scrollToHeading(heading.pos)"
          >
            <v-hover v-slot:default="{ hover }">
              <v-list-item-content>
                <v-list-item-title
                  v-if="!hover"
                  style="font-size: 15px;"
                >
                  {{ heading.nodeText }}
                </v-list-item-title>
                <span
                  v-else
                  style="font-size: 15px;"
                >
                  {{ heading.nodeText }}
                </span>
              </v-list-item-content>
            </v-hover>
          </v-list-item>
        </div>
      </v-col>
      <v-slider
        v-if="readOnlyOption"
        v-model="slider"
        max="100"
        min="50"
        style="position: absolute; left: 0px; bottom: 0px; width: 100px; height: 28px"
      />

      <v-col
        :cols="!readOnlyOption ? '12' : '10'"
        class="py-0 pl-0 pr-0"
      >
        <!-- read only editor - data extraction module -->
        <v-btn
          v-if="fileViewDrawer && !readOnlyOption"
          x-small
          outlined
          rounded
          color="primary"
          class="mt-n6 ml-4"
          @click="$emit('acticvate-file-view-drawer')"
        >
          <v-icon
            class="mr-1"
            x-small
          >
            mdi-menu
          </v-icon>
          {{ $t('editor|edit extracted data') }}
        </v-btn>
        <div
          :class="readOnlyOption ? 'editor-content-container' : 'editor-content-container-read-only'"
          :style="`max-height: ${($vuetify.breakpoint.height - 246).toString() + 'px'}; overflow-x: auto;`"
        >
          <div
            v-if="editor"
            class="editor mx-auto"
          >
            <div class="editor-content-wrapper">
              <div>
                <!-- DATA EXTRACTION MENU - Custom bubble menu -->
                <v-menu
                  v-model="menu"
                  :absolute="false"
                  :close-on-click="false"
                  :close-on-content-click="false"
                  :position-x="fileViewDrawer ? 260 : 180"
                  :position-y="menuTop"
                  :value="true"
                  offset-overflow
                  attach
                >
                  <v-container
                    style="background-color: white; min-height: 200px;"
                  >
                    <v-card
                      class="ma-0 px-0 pb-6 pt-2"
                      flat
                    >
                      <v-subheader
                        class="mt-n4 ml-n4"
                      >
                        {{ $t('editor|add_text_to_extraxted_data') }}:
                      </v-subheader>
                      <v-card
                        width="350"
                        flat
                        outlined
                      >
                        <v-card-text
                          class="black--text"
                        >
                          {{ actualTextSelection }}
                        </v-card-text>
                      </v-card>
                      <v-autocomplete
                        v-model="selectedDataItem"
                        :items="_fields"
                        item-text="name"
                        dense
                        outlined
                        return-object
                        prepend-inner-icon="mdi-magnify"
                        append-outer-icon="mdi-web"
                        class="mt-5"
                      >
                        <template #label>
                          <span>
                            {{ $t('common|search') }}/{{ $t('actions|select') }}
                            <v-icon
                              color="primary"
                            >
                              mdi-magnify
                            </v-icon>
                          </span>
                        </template>
                        <template slot="append-outer">
                          <v-menu
                            bottom
                            :offset-x="false"
                            :offset-y="true"
                          >
                            <template v-slot:activator="{ on }">
                              <v-icon
                                v-on="on"
                              >
                                mdi-web
                              </v-icon>
                            </template>

                            <v-list
                              v-model="selectedLanguage"
                              min-width="50"
                            >
                              <template v-for="lang in languages">
                                <v-list-item
                                  :key="lang"
                                  @click="selectedLanguage = lang"
                                >
                                  <v-list-item-title>{{ lang }}</v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-list>
                          </v-menu>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list
                            v-if="item.name === $t('actions|add_new_category')"
                            dense
                          >
                            <v-list-item
                              style="cursor: pointer;"
                            >
                              <v-list-item-content>
                                <v-list-item-title>
                                  <v-row
                                    no-gutters
                                    align="center"
                                  >
                                    <span>
                                      <v-icon
                                        class="mr-2"
                                      >
                                        {{ icons.cog }}
                                      </v-icon>
                                      {{ item.name }}
                                    </span>
                                  </v-row>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>

                          <v-list
                            dense
                          >
                            <v-list-item
                              v-if="item.name !== $t('actions|add_new_category')"
                              style="cursor: pointer;"
                            >
                              <v-list-item-content>
                                <v-list-item-title>
                                  <v-row
                                    no-gutters
                                    align="center"
                                  >
                                    <span>{{ item.name }}</span>
                                  </v-row>
                                </v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-icon>
                                <v-chip
                                  v-if="item.custom"
                                  text-color="white"
                                  color="accent4"
                                  small
                                >
                                  {{ $t('common|custom') }}
                                </v-chip>
                              </v-list-item-icon>
                            </v-list-item>
                          </v-list>
                        </template>
                      </v-autocomplete>
                    </v-card>
                  </v-container>
                  <v-btn
                    x-small
                    color="primary"
                    rounded
                    :disabled="!selectedDataItem"
                    min-width="150"
                    style="position: absolute; bottom: 13px; right: 10px;"
                    @click="applySelectedData(actualTextSelection, selectedDataItem, false)"
                  >
                    {{ $t('common|apply') }}
                  </v-btn>
                </v-menu>
                <editor-content
                  id="attachId"
                  class="editor__content mx-auto"
                  :editor="editor"
                />
              </div>
            </div>

            <!-- Insert table dialog - editor normal mode -->
            <v-dialog
              v-model="editorAddons.insertTable.visible"
              max-width="600px"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ $t('editor|table_columns_rows') }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-text-field
                          v-model.number="editorAddons.insertTable.cols"
                          type="number"
                          :label="$t('editor|table_columns')"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-text-field
                          v-model.number="editorAddons.insertTable.rows"
                          type="number"
                          :label="$t('editor|table_rows')"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeAndReset();"
                  >
                    {{ $t('actions|close') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="editor.chain().focus().insertTable({ rows: editorAddons.insertTable.rows, cols: editorAddons.insertTable.cols, withHeaderRow: true }).run(); editorAddons.insertTable.visible = false"
                  >
                    {{ $t('editor|insert_table') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiFormatStrikethrough,
  mdiFormatUnderline,
  mdiCog
} from '@mdi/js'
import {
  Editor,
  EditorContent,
  VueRenderer
} from '@tiptap/vue-2'
import { Color } from '@/tiptap/extensions/colorStyle'
import { DropCap } from './DropCap/dropCap'
import Document from '@tiptap/extension-document'
import Text from '@tiptap/extension-text'
import StarterKit from '@tiptap/starter-kit'
import FieldTagDialog from './FieldTagDialog.vue'
import HashTag from './HashTag/HashTag'
import Comment from './Comment/Comment'
import FontSize from './FontSize/FontSize'
import AddComment from '../../components/dialogs/AddComment.vue'
import Table from '@tiptap/extension-table'
import CustomTable from './CustomTable/CustomTable.js'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import FontFamily from '@tiptap/extension-font-family'
import Heading from '@tiptap/extension-heading'
import Typography from '@tiptap/extension-typography'
import Paragraph from '@tiptap/extension-paragraph'
import Underline from '@tiptap/extension-underline'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Strike from '@tiptap/extension-strike'
import Superscript from '@tiptap/extension-superscript'
import Subscript from '@tiptap/extension-subscript'
import CharacterCount from '@tiptap/extension-character-count'
import EditorRibbonPanel from './EditorRibbonPanel.vue'
import Collaboration from '@tiptap/extension-collaboration'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import * as Y from 'yjs'
import axios from '@/plugins/axios'
import exportTemplate from './exportDocx.js'
import store from '@/store/index.js'
import autobahn from 'autobahn'
import { WebsocketProvider } from 'y-websocket'
import { IndexeddbPersistence } from 'y-indexeddb'
import { mapState, mapActions } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import { EventBus } from '@/utils/EventBus'
import { NodeSelection } from 'prosemirror-state'
var prosemirrorState = require('prosemirror-state')
// import LineHeight from '@tiptap/extension-line-height'
// import store from '@/store/index.js'
// import showfontfamily from './getClipboardFontType'
// import { TextSelection } from 'prosemirror-state'

var updateHeadingsAndHashtagsCSSValues
// var updateFontSizeMarksIfNone

const getRandomElement = list => {
  return list[Math.floor(Math.random() * list.length)]
}

const defaultInsertTable = {
  visible: false,
  callback: null,
  close: null,
  rows: 3,
  cols: 3
}

export default {

  components: {
    EditorContent,
    EditorRibbonPanel,
    AddComment
  },

  props: {
    tab: {
      type: Number,
      default: 0
    },
    // suggestions: {
    //   type: Array,
    //   default: () => ([])
    // },
    language: {
      type: String,
      default: 'en'
    },
    questionnaire: {
      type: Object,
      default: null
    },
    relatedDocuments: {
      type: Array,
      default: () => ([])
    },
    edit: {
      type: Boolean,
      default: false
    },
    templateFieldTags: {
      type: Array,
      default: () => ([])
    },
    readOnlyOption: {
      type: Boolean,
      default: true
    },
    template: {
      type: Object,
      default: null
    },
    templateId: {
      type: String,
      default: null
    },
    workbench: {
      type: Object,
      default: null
    },
    caseData: {
      type: Object,
      default: null
    },
    currentCaseForm: {
      type: Object,
      default: null
    },
    account: {
      type: Object,
      default: null
    },
    nodeValuesToBeApplied: {
      type: Array,
      default: () => ([])
    },
    textValueToBeApplied: {
      type: Object,
      default: null
    },
    fileViewDrawer: {
      type: Boolean,
      default: true
    },
    actualDocument: {
      type: Object,
      default: null
    },
    deactivatePopUpMenu: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: null
    }
  },

  provide () {
    return {
      editorInstance: this.editorInstance
    }
  },

  data () {
    return {
      // extractedData: [],
      headings: [],
      selectedColor: '',
      drawer: true,
      mini: false,
      selectedText: this.selectionInText || '',
      searchResults: [],
      icons: {
        strikeIcon: mdiFormatStrikethrough,
        underlineIcon: mdiFormatUnderline,
        cog: mdiCog
      },
      editorInstance: {
        editor: null
      },
      editor: null,
      suggestionQuery: null,
      navigatedUserIndex: 0,
      editorJSON: null,
      editorHTML: null,
      suggestionListVisibility: false,
      editorAddons: {
        insertTable: { ...defaultInsertTable }
      },
      provider: null,
      ydoc: null,
      ymap: null,
      historyYdoc: null,
      actualUsers: [],
      actualJsonContent: null,
      wordsAmountData: null,
      actualFontSize: null,
      commentDialog: false,
      from: null,
      to: null,
      slider: localStorage.getItem('editor-slider') || 75,
      menu: false,
      domElement: null,
      menuTop: null,
      actualTextSelection: null,
      selectedDataItem: null,
      loadingSavingData: false,
      selectedLanguage: localStorage.getItem('preferedLanguage'),
      actualUserLanguage: localStorage.getItem('preferedLanguage'),
      connectionStatus: null,
      reconnectAttempts: 0,
      persistence: null,
      isOnline: true
    }
  },

  computed: {
    ...mapState({
      token: state => state.auth.token,
      settings: state => state.settings.settings,
      company: state => state.company.company
    }),
    languages () {
      return this.settings.activeLanguages
    },
    actualQuestionnaire () {
      if (this.questionnaire) return this.questionnaire
      else return {}
    },
    actualToken () {
      return this.token
    },
    _style () {
      let _class
      if (this.account && this.account.accountType === 'lawyer' && this.readOnlyOption && this.tab === 0) {
        _class = 'position: fixed; right: 17.8%; left: 17.8%;'
      } else if (((this.account && this.account.accountType === 'company') || (this.account && this.account.accountType === 'individual')) && this.readOnlyOption) {
        // _class = 'position: fixed; right: 17.8%; left: 1.3%;'
        _class = 'position: fixed; right: 17.6%; left: 17.6%;'
      } else if (!this.readOnlyOption) {
        // the editor is read only
        _class = ''
      } else if (this.tab && this.tab === 2) {
        _class = ''
      }
      return _class
    },
    // filteredSuggestions () {
    //   if (!this.suggestionQuery || this.suggestionQuery === '') return this.suggestions
    //   return this.suggestions.filter((s) => {
    //     const name = s.name.toLowerCase()
    //     const query = this.suggestionQuery.toLowerCase()
    //     return name.includes(query)
    //   })
    // },
    // hasResults () {
    //   return this.filteredSuggestions.length
    // },
    // comments () {
    //   let templates = []
    //   if (this.template) {
    //     templates = this.template.comments
    //   }
    //   return templates
    // },
    selectionInText () {
      if (!this.editor.state.selection.anchor && !this.editor.state.selection.head) return
      const from = this.editor.state.selection.anchor
      const to = this.editor.state.selection.head
      const doc = this.editor.state.doc
      const text = doc.textBetween(from, to, ' ')
      return text
    },
    // extractedDocumentData () {
    //   let arr = []
    //   if (this.actualDocument) {
    //     const _array = Object.entries(this.actualDocument.info.content[0].lavvira_labels)
    //       .map((entr) => {
    //         const item = {
    //           title: entr[0].split('_').join(' ')
    //         }
    //         return item
    //       })
    //     arr = [{ title: 'Add new category' }, { title: 'Languages' }, ..._array]
    //   }
    //   return arr
    // },
    // _extractedDocumentData: {
    //   get () {
    //     return this.extractedData
    //   },
    //   set (value) {
    //     this.extractedData = value
    //   }
    // },
    currentWorkbenchDocuments () {
      if (this.options.inCase) return this.workbench.assignedDocuments
      else return []
    },
    _fields () {
      let fromDocument = []
      const fields = Object.keys(this.settings.fields).reduce((acc, key) => {
        const item = this.settings.fields[key]
        if (item.type !== 'file-input') {
          acc[key] = item
        }
        return acc
      }, {})
      const suggestions = Object.keys(fields).map((key) => {
        let name = fields[key].label[this.selectedLanguage] ?? undefined
        if (Array.isArray(fields[key].label[this.selectedLanguage])) {
          name = fields[key].label[this.selectedLanguage].join(' / ')
        }
        return {
          id: uuidv4(),
          name,
          fieldType: fields[key].type,
          fieldKey: key,
          customHashtag: false,
          language: this.selectedLanguage
        }
      })
      const removeUndefined = (obj) => {
        for (var prop in obj) {
          if (obj.hasOwnProperty(prop) && obj[prop] === undefined) {
            return
          }
        }
        return obj
      }

      const _filtered = suggestions.filter(removeUndefined)
      // _filtered.splice(0, 0, { name: this.$t('actions|add_new_category') })
      if (
        this.actualDocument &&
        this.actualDocument.info &&
        this.actualDocument.info.content[0] &&
        this.actualDocument.info.content[0].lavvira_labels
      ) {
        fromDocument = Object.entries(this.actualDocument.info.content[0].lavvira_labels)
          .map((entr) => {
            const field = {
              name: entr[0].split('_').join(' ')
            }
            const foundIndex = _filtered.findIndex(_item => _item.fieldKey === entr[0])
            if (foundIndex === -1) {
              field['custom'] = true
              return field
            }
          })
      }
      const _custom = fromDocument.filter(removeUndefined)
      _custom.splice(0, 0, { name: this.$t('actions|add_new_category') })
      return [..._custom, ..._filtered]
    }
  },
  watch: {
    nodeValuesToBeApplied: {
      handler (value) {
        if (value) {
          this.$emit('loading')
          this.editor.view.state.doc.descendants((node, pos) => {
            if (node.type.name === 'mention') {
              value.forEach(val => {
                if ((val.node.attrs.customLabel && val.node.attrs.customLabel === node.attrs.customLabel) && (node.attrs.fieldKey === val.node.attrs.fieldKey)) {
                  this.editor.commands.setNodeSelection(pos)
                  this.editor.commands.updateAttributes('mention', { fieldValue: val.data })
                }
                if (!val.node.attrs.customLabel && node.attrs.fieldKey === val.node.attrs.fieldKey) {
                  this.editor.commands.setNodeSelection(pos)
                  this.editor.commands.updateAttributes('mention', { fieldValue: val.data })
                }
              })
            }
          })
          this.$emit('stop-loading')
        }
      },
      deep: true
    },
    'editor.storage.heading.actualHeadings': {
      handler (value) {
        if (this.editor && value) {
          this.headings = value
        }
      },
      deep: true
    },
    slider (value) {
      if (value) {
        const element = document.getElementsByClassName('editor-content-wrapper')
        Object.entries(element).forEach(entr => {
          const insertDecimal = (num) => {
            return Number((num * 1.2 / 100).toFixed(2))
          }
          const transformedNum = insertDecimal(value)
          entr[1].style.transform = `scale(${transformedNum})`
          entr[1].style.transformOrigin = `center top`
          localStorage.setItem('editor-slider', value)
        })
      }
    },
    async selectedDataItem (value) {
      if (value) {
        let item = null
        if (typeof value === 'object') item = value.name
        if (typeof value === 'string') item = value
        if (item === this.$t('actions|add_new_category')) {
          this.menu = false
          const res = await this.$dialog.prompt({
            title: this.$t('actions|create_new_category'),
            text: this.$t('actions|enter_new_category'),
            textField: {
            // Any addtional props/attrs that will be binded to v-text-field component
              type: 'text',
              outlined: true,
              dense: true
            }
          })
          if (res) {
          // this.extractedData.push(...this.extractedDocumentData)
          // this.extractedData.push({ title: res })
            this.selectedDataItem = res
            this.applySelectedData(this.actualTextSelection, res, true)
          } else {
            this.selectedDataItem = null
            this.menu = true
          }
        }
      }
    },
    menu (value) {
      if (!value) {
        // this.selectedDataItem = null
      }
    },
    actualQuestionnaire (val) {
      if (val) {
        const index = this.editor.options.extensions.findIndex(ext => ext.name === 'mention')
        this.editor.options.extensions[index].options.questionnaire = val
      }
    },
    actualToken (val) {
      if (val) {
        this.onNewAccessToken(val)
      }
    },
    template (val) {
      if (
        this.caseData &&
        (this.account.accountType === 'individual' ||
        this.account.accountType === 'company') &&
        val.sharedWithClient === false
      ) {
        this.$router.push({ path: `/cases/${this.caseData._id}/${this.caseData.workbenches[0]._id}` })
      }
    }
  },
  async mounted () {
    this.makeEditorSetup()
  },

  async created () {
    window.addEventListener('offline', (e) => {
      this.isOnline = false
    })
    window.addEventListener('online', (e) => {
      this.isOnline = true
      if (this.provider) this.provider.connect()
    })
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection
    if (connection) {
      connection.addEventListener('change', (e) => {
        // Network type changed => we are using this to check the connection
        // in Chrome/Chromium based browsers because window.addEventListener('online', (e)) is not working
        // for a unknown reason
        if (e.type === 'change') {
          if (connection.downlink === 0) this.isOnline = false
          else {
            this.isOnline = true
            if (this.provider) this.provider.connect()
          }
        }
      })
    }
    this.$nextTick(() => {
      // this.extractedData = this.extractedDocumentData
      const element = document.getElementsByClassName('editor-content-wrapper')
      Object.entries(element).forEach(entr => {
        const insertDecimal = (num) => {
          return Number((num * 1.2 / 100).toFixed(2))
        }
        const transformedNum = insertDecimal(this.slider)
        entr[1].style.transform = `scale(${transformedNum})`
        entr[1].style.transformOrigin = `center top`
      })
      if (this.editor.readOnlyOption) {
        setTimeout(() => {
          this.editor.commands.setTextSelection({ from: 1, to: 2 })
        }, 1000)
      }
    })
    await this.$nextTick(() => {
      setTimeout(() => {
        if (this.ydoc) {
          this.ydoc.transact(() => {
            this.ymap.set('headings', 'onCreated')
          })
        }
      }, 1000)
    })
    EventBus.$on('collaboration-feedback', this.onCollaborationFeedback)
    EventBus.$on('select-comment', this.onSelectComment)
    EventBus.$on('select-mention', this.onMentionSelect)
    EventBus.$on('comment-text-update', this.onCommentTextUpdate)
    EventBus.$on('comment-reply', this.onCommentReply)
    EventBus.$on('comment-reply-text-update', this.onCommentReplyTextUpdate)
    EventBus.$on('delete-reply', this.onDeleteReply)
    EventBus.$on('resolve-comment', this.onResolveComment)
    EventBus.$on('unresolve-comment', this.onUnresolveComment)
    EventBus.$on('comment-deleted', this.onCommentDeleted)
    EventBus.$on('pdf-loading', this.onPdfLoading)
    EventBus.$on('pdf-done', this.onPdfDone)
    EventBus.$on('focus-editor', this.onFocusEditor)
    // EventBus.$on('new-access-token', this.onNewAccessToken)
  },

  async beforeDestroy () {
    if (this.provider) {
      let usersOnline = []
      for (let user of this.provider.awareness.states.keys()) {
        usersOnline.push(user.toString())
      }
    }
    this.destroyPopup()
    EventBus.$off('collaboration-feedback', this.onCollaborationFeedback)
    EventBus.$off('select-comment', this.onSelectComment)
    EventBus.$off('select-mention', this.onMentionSelect)
    EventBus.$off('comment-text-update', this.onCommentTextUpdate)
    EventBus.$off('comment-reply', this.onCommentReply)
    EventBus.$off('comment-reply-text-update', this.onCommentReplyTextUpdate)
    EventBus.$off('delete-reply', this.onDeleteReply)
    EventBus.$off('resolve-comment', this.onResolveComment)
    EventBus.$off('unresolve-comment', this.onUnresolveComment)
    EventBus.$off('comment-deleted', this.onCommentDeleted)
    EventBus.$off('pdf-loading', this.onPdfLoading)
    EventBus.$off('pdf-done', this.onPdfDone)
    EventBus.$off('focus-editor', this.onFocusEditor)
    // EventBus.$off('new-access-token', this.onNewAccessToken)

    if (this.caseData) this.updateStorage(this.caseData)
    else if (this.currentCaseForm) this.updateStorage(this.currentCaseForm)
    else this.updateStorage(this.template)
  },

  async destroyed () {
    // this.editor.destroy()
    if (this.provider) {
      await this.persistence.clearData()
      await this.persistence.destroy()
      await this.ydoc.destroy()
      await this.provider.disconnect()
      // this.resetEditorComponentData()
    }
  },

  methods: {
    ...mapActions({
      refreshToken: 'auth/refreshToken',
      fetchTemplate: 'templates/fetchTemplate',
      addToast: 'toasts/addToast',
      updateTemplate: 'templates/updateTemplate',
      deleteMyComment: 'cases/deleteMyComment',
      saveAsTemplate: 'templates/saveAsTemplate',
      saveAsTemplateInCase: 'cases/saveAsTemplateInCase',
      saveAsTemplateInCaseForm: 'caseForms/saveAsTemplateInCaseForm',
      updateDocument: 'documents/updateDocument'
    }),
    async makeEditorSetup () {
      this.$emit('loading')
      if (this.readOnlyOption && (this.template || this.caseData || this.currentCaseForm)) {
        const user = {
          name: this.account.accountData.firstName && this.account.accountData.lastName ? this.account.accountData.firstName + ' ' + this.account.accountData.lastName : this.account.email,
          color: this.getRandomColor(),
          avatarSrc: this.account.avatar ? `${process.env.VUE_APP_BACKEND_PATH}downloadAvatar/${this.account._id}/${this.account.avatar.raw.filename}` : ''
        }
        this.ydoc = new Y.Doc()
        this.ymap = this.ydoc.getMap('updates')
        const token = this.token
        const url = process.env.VUE_APP_COLLABORATION_PATH
        const room = this.caseData ? `case/${this.caseData._id}/${this.workbench._id}/${this.template._id}` : this.currentCaseForm ? `model/${this.currentCaseForm._id}/${this.workbench._id}/${this.template._id}` : `template/${this.template._id}`
        const provider = new WebsocketProvider(
          url,
          room,
          this.ydoc,
          {
            params: {
              Authorization: `Bearer ${token}`
            },
            resyncInterval: 10000
          }
        )
        this.provider = provider
        const persistence = new IndexeddbPersistence(`lavvira-db-${room}`, this.ydoc)
        this.persistence = persistence
        this.persistence.on('synced', () => {
          // If the connection is down the user can work offline => we send message to the ribbon panel
          if (!this.isOnline || (this.connectionStatus === this.$t('mixed|you_are_connected') && this.connectionStatus !== 'You are synced!')) {
            this.connectionStatus = 'You are now in offline editing mode. Content will be synced once you are back online!'
          }
        })
        provider.on('status', event => {
          if (event.status === 'connecting') {
            console.log('Connecting')
            this.connectionStatus = this.$t('mixed|connecting')
          }
        })
        provider.on('status', event => {
          if (event.status === 'connected') {
            this.connectionStatus = this.$t('mixed|you_are_connected')
            console.log('Connected')
            this.$emit('stop-loading')
          }
        })
        provider.on('synced', async () => {
          console.log('SYNCED')
          this.connectionStatus = 'You are synced!'
          for (let user of provider.awareness.states.keys()) {
            this.actualUsers.push(user.toString())
          }
          if (this.actualUsers.length <= 1) {
            this.setInitialContent()
          }
          this.$emit('stop-loading')
        })
        provider.on('status', async (event) => {
          if (event.status === 'disconnected') {
            this.reconnectAttempts++
            console.log('DISCONNECTED')
            this.connectionStatus = this.$t('mixed|you_are_not_connected')
            if (this.reconnectAttempts > 7) {
              provider.disconnect()
            }
          }
        })
        // if (provider.ws) {
        //   provider.ws.onmessage = (event) => {}
        // }
        const editor = new Editor({
          editorProps: {
            // transformPasted: (pslice) => {},
            // createSelectionBetween: (view, anchor, head) => {},
            handleKeyDown: (view, event) => {
              if (event.code === 'KeyZ' && event.ctrlKey) this.undoStep()
              if (event.code === 'KeyY' && event.ctrlKey) this.redoStep()
              this.loadingSavingData = true
              // setTimeout(() => {
              //   this.loadingSavingData = false
              // },
              // 2500)
            },
            handlePaste: (view, event, slice) => {
              // const font = showfontfamily(event.originalTarget)
              // const data = event.clipboardData?.getData('text/html')
              const newSlice = this.setClipboardDataToNormal(slice, view.state, event)
              view._props.dispatchTransaction(view.state.tr.replaceSelection(newSlice))
              setTimeout(() => {
                this.ydoc.transact(() => {
                  this.ymap.set('comments', 'onSetClipboardDataToNormal')
                  this.ymap.set('suggestions', 'onSetClipboardDataToNormal')
                  this.ymap.set('headings', 'onSetClipboardDataToNormal')
                })
              }, 200)
              return true
            },
            handleClick: (view, pos, event) => {
              const DEFAULT_FONT_SIZE = null
              let selection = this.editor.state.selection
              // if (selection.node && (selection.node.type.name === 'mention' || selection.node.type.name === 'comment')) {
              //   this.actualFontSize = selection.node.attrs.fontSize || DEFAULT_FONT_SIZE
              // }
              if (this.editor.state.selection.constructor.name === 'TextSelection') {
                let tr = this.editor.state.tr
                let storedMarks = tr.storedMarks ||
                this.editor.state.storedMarks ||
                (selection instanceof prosemirrorState.TextSelection &&
                  selection.$cursor &&
                  selection.$cursor.marks &&
                  selection.$cursor.marks()) || []
                if (storedMarks.length) {
                  this.actualFontSize = storedMarks[0].attrs.fontSize
                } else {
                  this.actualFontSize = DEFAULT_FONT_SIZE
                }
              }
              // this.editor.state.doc.nodesBetween(selection.from, selection.to, (node, pos) => {
              //   if (node.type.name === 'mention' && node.attrs.fontSize) {
              //     this.actualFontSize = node.attrs.fontSize
              //   }
              // })
            }
            // transformPastedHTML: (html) => {}
            // transformPasted: (slice) => {}
            // clipboardTextParser: (str, $context) => {
            //   var doc = document.cloneNode(false)
            //   var dom = doc.createElement('div')
            //   dom.innerHTML = str
            //   var parser = editor.someProp('clipboardParser') || editor.someProp('domParser') || editor.Model.DOMParser.fromSchema(editor.state.schema)
            // }
          },
          extensions: [
            Collaboration.configure({
              document: this.ydoc,
              field: 'doc'
            }),
            CollaborationCursor.configure({
              provider: provider,
              user: user,
              render: (user) => {
                const img = document.createElement('IMG')
                img.src = user.avatarSrc
                img.classList.add('collaboration-cursor__avatar')
                const cursor = document.createElement('span')
                cursor.classList.add('collaboration-cursor__caret')
                cursor.setAttribute('style', `border-color: ${user.color}`)
                const label = document.createElement('div')
                label.classList.add('collaboration-cursor__label')
                label.setAttribute('style', `background-color: ${user.color}`)
                label.insertBefore(document.createTextNode(user.name), null)
                label.appendChild(img)
                cursor.insertBefore(label, null)
                return cursor
              }
            }),
            Document,
            Text,
            Bold,
            Italic,
            Strike,
            Superscript,
            Subscript,
            StarterKit.configure({
              // history should be switched off because of ydoc (ydoc has own history)
              // the other moduls are imported separetly because of configuration and bugs
              history: false,
              paragraph: false,
              heading: false,
              bold: false,
              strike: false,
              document: false,
              italic: false,
              text: false
            }),
            CharacterCount.configure({
              mode: 'nodeSize'
            }),
            Heading.configure({
              levels: [1, 2, 3, 4, 5, 6]
            }),
            TextAlign.configure({
              types: ['heading', 'paragraph'],
              alignments: ['left', 'center', 'right', 'justify']
            }),
            TextStyle,
            FontFamily,
            FontSize,
            Color,
            DropCap,
            Typography,
            CustomTable,
            TableRow,
            TableCell.extend({
              addAttributes () {
                return {
                  ...this.parent?.(),
                  backgroundColor: {
                    default: '#FFFFFF',
                    // Customize the HTML parsing (for example, to load the initial content)
                    parseHTML: element => element.getAttribute('table-background-color'),
                    // … and customize the HTML rendering.
                    renderHTML: attributes => {
                      return {
                        'table-background-color': attributes.backgroundColor,
                        style: `background-color: ${attributes.backgroundColor}`
                      }
                    }
                  },
                  border: {
                    default: '2px solid #ced4da;',
                    // Customize the HTML parsing (for example, to load the initial content)
                    parseHTML: element => element.getAttribute('border'),
                    // … and customize the HTML rendering.
                    renderHTML: attributes => {
                      return {
                        'border': attributes.border,
                        style: `border: ${attributes.border}`
                      }
                    }
                  },
                  colwidth: {
                    default: null,
                    parseHTML: (element) => {
                      const colwidth = element.getAttribute('colwidth')
                      const value = colwidth ? [parseInt(colwidth, 10)] : null
                      return value
                    },
                    renderHTML: (attributes) => {
                      return {
                        colwidth: attributes.colwidth,
                        style: attributes.colwidth
                          ? `width: ${attributes.colwidth}px`
                          : null
                      }
                    }
                  }
                }
              }
            }),
            TableHeader.extend({
              addAttributes () {
                return {
                  ...this.parent?.(),
                  colwidth: {
                    default: null,
                    parseHTML: (element) => {
                      const colwidth = element.getAttribute('colwidth')
                      const value = colwidth ? [parseInt(colwidth, 10)] : null
                      return value
                    },
                    renderHTML: (attributes) => {
                      return {
                        colwidth: attributes.colwidth,
                        style: attributes.colwidth
                          ? `width: ${attributes.colwidth}px`
                          : null
                      }
                    }
                  }
                }
              }
            }),
            Paragraph.extend({
              addAttributes () {
                return {
                  color: {
                    default: null,
                    // Take the attribute values
                    renderHTML: attributes => {
                      // … and return an object with HTML attributes.
                      return {
                        style: `color: ${attributes.color}`
                      }
                    }
                  },
                  lineHeight: {
                    default: '1.2',
                    renderHTML: attributes => {
                      // … and return an object with HTML attributes.
                      return {
                        style: `line-height: ${attributes.lineHeight}`
                      }
                    }
                  }
                }
              }
            }),
            Underline.configure({
              HTMLAttributes: {
                class: 'underline'
              }
            }),
            Comment.configure({
              ymap: this.ymap,
              ydoc: this.ydoc,
              template: this.template,
              HTMLAttributes: {
                class: 'comment'
              },
              destroyed: this.editor,
              inCase: this.options.inCase,
              suggestion: {
                render: (...args) => {
                  let component
                  return {
                    onStart: props => {
                      component = new VueRenderer(AddComment, {
                        parent: this,
                        propsData: {
                          ...props,
                          value: true,
                          onSelect: this.addComment,
                          text: this.textThatHasBeenCommentedOn,
                          fontSize: this.actualFontSize
                        }
                      })
                    },
                    onExit () {
                      component.destroy()
                    }
                  }
                }
              }
            }),
            HashTag.configure({
              ymap: this.ymap,
              ydoc: this.ydoc,
              questionnaire: this.actualQuestionnaire,
              relatedDocuments: this.relatedDocuments,
              caseData: this.caseData,
              workbench: this.workbench,
              templateId: this.templateId,
              edit: this.edit,
              options: this.options,
              refreshQuestionairreFields: this.refreshQuestionairreFields,
              saveSuggestion: this.saveSuggestion,
              HTMLAttributes: {
                class: 'mention'
              },
              suggestion: {
                render: (...args) => {
                  let component
                  return {
                    onStart: props => {
                      component = new VueRenderer(FieldTagDialog, {
                        parent: this,
                        propsData: {
                          ...props,
                          value: true,
                          language: this.language,
                          onSelect: this.selectSuggestion,
                          templateFieldTags: this.templateFieldTags,
                          actualTemplate: this.template,
                          currentWorkbenchDocuments: this.currentWorkbenchDocuments
                        }
                      })
                    },
                    onExit () {
                      component.destroy()
                    }
                  }
                }
              }
            })
          ],
          content: {
            type: 'doc',
            content: [
              {
                type: 'paragraph'
              }
            ]
          }
        })
        this.editor = editor
        this.editorInstance.editor = editor
        this.editor.setOptions({ editable: this.readOnlyOption })

        this.ymap.observe(event => {
          for (const [key, value] of event.changes.keys) {
            if (key && key === 'updateSuccess') {
              this.loadingSavingData = false
            }
            if (value && key === 'comments') {
              this.editor.storage.comment.actualComments = []
              this.editor.view.state.doc.descendants((node, pos) => {
                if (node.type.name === 'comment') {
                  this.editor.storage.comment.actualComments.push(node)
                }
              })
              store.dispatch('editorStore/setComments', this.editor.storage.comment.actualComments)
              EventBus.$emit('update-component-key')
            }
            if (key === 'suggestions') {
              this.editor.storage.mention.actualHashTags = []
              this.editor.view.state.doc.descendants((node, pos) => {
                if (node.type.name === 'mention') {
                  Object.assign(node, { pos: pos })
                  this.editor.storage.mention.actualHashTags.push(node)
                }
              })
              store.dispatch('editorStore/setSuggestions', this.editor.storage.mention.actualHashTags)
            }
            if (key === 'headings') {
              let heading
              let actualHeadings = []
              this.editor.state.doc.descendants((node, pos) => {
                if (node.type.name === 'heading') {
                  Object.assign(node, { pos: pos })
                  actualHeadings.push(node)
                }
              })
              this.headings = []
              for (let i = 0; i < actualHeadings.length; i++) {
                const node = actualHeadings[i]
                if (node.type.name === 'heading' && node.content.content[0]) {
                  if (node.content.content[0].type.name === 'comment') {
                    heading = {
                      nodeText: node.content.content[0].attrs.label,
                      pos: node.pos
                    }
                  } else if (node.content.content[0].type.name === 'mention') {
                    heading = {
                      nodeText: node.content.content[0].attrs.label,
                      pos: node.pos
                    }
                  } else {
                    heading = {
                      nodeText: node.content.content[0].text,
                      pos: node.pos
                    }
                  }
                  this.headings.push(heading)
                }
              }
              // this.editor.state.doc.descendants((node, pos) => {
              //   if (node.type.name === 'heading' && node.content.content[0]) {
              //     if (node.content.content[0].type.name === 'comment') {
              //       heading = {
              //         nodeText: node.content.content[0].attrs.label,
              //         pos: pos
              //       }
              //     } else if (node.content.content[0].type.name === 'mention') {
              //       heading = {
              //         nodeText: node.content.content[0].attrs.label,
              //         pos: pos
              //       }
              //     } else {
              //       heading = {
              //         nodeText: node.content.content[0].text,
              //         pos: pos
              //       }
              //     }
              //     headingsArr.push(heading)
              //     if (this.headings.length) {
              //       const index = this.headings.findIndex(n => n.nodeText === node.content.content[0].text || n.nodeText === node.content.content[0].attrs.label)
              //       if (index === -1) {
              //         this.headings.push(heading)
              //       }
              //     } else {
              //       const heading = {
              //         nodeText: node.content.content[0].text,
              //         pos: pos
              //       }
              //       this.headings.push(heading)
              //     }
              //   }
              // })
              // for (let i = 0; i < this.headings.length; i++) {
              //   const index = headingsArr.findIndex(n => n.nodeText === this.headings[i].nodeText)
              //   if (index === -1) {
              //     this.headings.splice(i, 1)
              //   }
              // }
            }
          }
        })
        this.editor.on('transaction', ({ editor, transaction }) => {
          this.headingsUpdate(editor, transaction)
        })
        editor.on('create', ({ editor }) => {
          setTimeout(() => {
            const isEmpty = editor.state.doc.textContent.length === 0
            if (isEmpty) editor.chain().focus().setTextSelection(0).run()
            // else editor.chain().focus().setTextSelection(1).run()
            // editor.commands.focus('end', { scrollIntoView: true })
          }, 1000)
        })
        this.$emit('stop-loading')
        // this.editor.on('update', () => { this.onUpdate() })
        // editor.on('selectionUpdate', ({ editor }) => { this.onSelectionUpdate() })
        // this.getTableData()
        // provider.connect()
      } else {
        // THE READ ONLY EDITOR IN FILEVIEW.VUE
        const editor = new Editor({
          extensions: [
            StarterKit.configure({
              // history should be switched off because of ydoc (ydoc has own history)
              // the other moduls are imported separetly because of configuration and bugs
              history: false,
              paragraph: false,
              bold: false,
              strike: false,
              document: false,
              italic: false,
              text: false
            }),
            TextAlign.configure({
              types: ['heading', 'paragraph'],
              alignments: ['left', 'center', 'right', 'justify']
            }),
            Document,
            Text,
            Bold,
            Italic,
            Strike,
            TextStyle,
            FontFamily,
            FontSize,
            Color,
            DropCap,
            Typography,
            TableRow,
            TableHeader,
            Paragraph.extend({
              addAttributes () {
                return {
                  color: {
                    default: null,
                    // Take the attribute values
                    renderHTML: attributes => {
                      // … and return an object with HTML attributes.
                      return {
                        style: `color: ${attributes.color}`
                      }
                    }
                  },
                  lineHeight: {
                    default: '1.2',
                    renderHTML: attributes => {
                      // … and return an object with HTML attributes.
                      return {
                        style: `line-height: ${attributes.lineHeight}`
                      }
                    }
                  }
                }
              }
            }),
            TableCell.extend({
              addAttributes () {
                return {
                  ...this.parent?.(),
                  backgroundColor: {
                    default: null,
                    // Customize the HTML parsing (for example, to load the initial content)
                    parseHTML: element => element.getAttribute('table-background-color'),
                    // … and customize the HTML rendering.
                    renderHTML: attributes => {
                      return {
                        'table-background-color': attributes.backgroundColor,
                        style: `background-color: ${attributes.backgroundColor}`
                      }
                    }
                  },
                  border: {
                    default: 'none',
                    // Customize the HTML parsing (for example, to load the initial content)
                    parseHTML: element => element.getAttribute('border'),
                    // … and customize the HTML rendering.
                    renderHTML: attributes => {
                      return {
                        'border': attributes.border,
                        style: `border: ${attributes.border}`
                      }
                    }
                  }
                }
              }
            }),
            Table,
            // Highlight.configure({
            //   multicolor: true
            // }),
            Underline.configure({
              HTMLAttributes: {
                class: 'underline'
              }
            })
          ],
          content: ``
        })
        this.editor = editor
        this.editor.on('selectionUpdate', ({ editor }) => {
          // The selection in editor read only mode has changed.
          setTimeout(() => {
            const { from, to, empty } = editor.state.selection

            if (empty || this.deactivatePopUpMenu) {
              this.menu = false
            } else {
              const actualSelection = editor.state.doc.textBetween(from, to, ' ')
              if (actualSelection) {
                const getOffset = (el) => {
                  var _x = 0
                  var _y = 0
                  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
                    _x += el.offsetLeft - el.scrollLeft
                    _y += el.offsetTop - el.scrollTop
                    el = el.offsetParent
                  }
                  return { top: _y, left: _x }
                }

                this.actualTextSelection = actualSelection
                const element = editor.view.coordsAtPos(from)
                const htmlEl = document.elementFromPoint(element.left, element.top)

                // var x = getOffset(htmlEl).left
                var y = getOffset(htmlEl).top
                if (this.fileViewDrawer === true) {
                  this.menuTop = Math.trunc(y) - 130
                } else {
                  this.menuTop = Math.trunc(y) - 130
                }
                this.menu = true
              }
            }
          }, '500')
        })
        this.editor.chain().focus()
        this.editorInstance.editor = editor
        this.editor.setOptions({ editable: this.readOnlyOption })
      }
    },
    onNewAccessToken (newToken) {
      let resetProvider = true
      if (this.provider && this.template) {
        const token = newToken
        const url = process.env.VUE_APP_COLLABORATION_PATH
        const room = this.caseData
          ? `case/${this.caseData._id}/${this.workbench._id}/${this.template._id}`
          : this.currentCaseForm
            ? `model/${this.currentCaseForm._id}/${this.workbench._id}/${this.template._id}`
            : `template/${this.template._id}`
        const newProvider = new WebsocketProvider(
          url,
          room,
          this.ydoc,
          {
            params: {
              Authorization: `Bearer ${token}`
            },
            resyncInterval: 10000
          }
        )
        while (this.provider.wsconnecting) {
          resetProvider = false
        }
        if (!this.provider.wsconnecting && resetProvider) {
          this.provider = newProvider
          this.provider.connect()
        }
      }
    },
    onSelectComment (label, id, fontSize) {
      this.actualFontSize = fontSize
    },
    onMentionSelect (fontSize) {
      this.actualFontSize = fontSize
    },
    onPdfLoading () {
      this.$emit('loading')
    },
    onPdfDone () {
      this.$emit('stop-loading')
    },
    convertRgbToHex (r, g, b) {
      const rgbToHex = (rgb) => {
        let hex = Number(rgb).toString(16).toUpperCase()
        if (hex.length < 2) {
          hex = '0' + hex
        }
        return hex
      }
      let red = rgbToHex(r)
      let green = rgbToHex(g)
      let blue = rgbToHex(b)
      return red + green + blue
    },
    scrollToHeading (pos) {
      const makeNodeSelection = () => {
        const { state } = this.editor.view
        let tr = state.tr
        const selection = NodeSelection.create(state.doc, pos)
        tr = tr.setSelection(selection)
        this.editor.view.dispatch(tr)
      }
      this.editor.commands.setTextSelection(pos)
      makeNodeSelection()
      this.editor.chain().focus().scrollIntoView(pos).run()
      const from = this.editor.state.selection.$anchor.pos
      const to = this.editor.state.selection.$head.pos
      this.editor.chain().focus().setTextSelection({ from: from, to: to }).run()
    },
    headingsUpdate (editor, transaction) {
      if (!transaction.steps.length) return
      if (transaction.steps[0] && !transaction.steps[0].slice) return
      if (transaction.steps[0].slice.content.content.length) return
      let heading
      let actualHeadings = []
      this.editor.state.doc.descendants((node, pos) => {
        if (node.type.name === 'heading') {
          Object.assign(node, { pos: pos })
          actualHeadings.push(node)
        }
      })
      this.headings = []
      for (let i = 0; i < actualHeadings.length; i++) {
        const node = actualHeadings[i]
        if (node.type.name === 'heading' && node.content.content[0]) {
          if (node.content.content[0].type.name === 'comment') {
            heading = {
              nodeText: node.content.content[0].attrs.label,
              pos: node.pos
            }
          } else if (node.content.content[0].type.name === 'mention') {
            heading = {
              nodeText: node.content.content[0].attrs.label,
              pos: node.pos
            }
          } else {
            heading = {
              nodeText: node.content.content[0].text,
              pos: node.pos
            }
          }
          this.headings.push(heading)
        }
      }
    },
    onUpdate () {
      const handleHeadingsAndHashtagsCSS = () => {
        updateHeadingsAndHashtagsCSSValues = setTimeout(() => {
          let headingsArr = []
          let heading
          this.editor.state.doc.descendants((node, pos) => {
            if (node.type.name === 'heading' && node.content.content[0]) {
              if (node.content.content[0].type.name === 'comment') {
                heading = {
                  nodeText: node.content.content[0].attrs.label,
                  pos: pos
                }
              } else if (node.content.content[0].type.name === 'mention') {
                heading = {
                  nodeText: node.content.content[0].attrs.label,
                  pos: pos
                }
              } else {
                heading = {
                  nodeText: node.content.content[0].text,
                  pos: pos
                }
              }
              headingsArr.push(heading)
              if (this.headings.length) {
                const index = this.headings.findIndex(n => n.nodeText === node.content.content[0].text || n.nodeText === node.content.content[0].attrs.label)
                if (index === -1) {
                  this.headings.push(heading)
                }
              } else {
                const heading = {
                  nodeText: node.content.content[0].text,
                  pos: pos
                }
                this.headings.push(heading)
              }
            }
          })
          for (let i = 0; i < this.headings.length; i++) {
            const index = headingsArr.findIndex(n => n.nodeText === this.headings[i].nodeText)
            if (index === -1) {
              this.headings.splice(i, 1)
            }
          }
          // set css(font-size/font-family) to the suggestions
          // if (this.editor.storage.mention.actualHashTags.length) {
          //   var r
          //   this.editor.storage.mention.actualHashTags.forEach(node => {
          //     const element = document.getElementById(node.attrs.id).nextSibling
          //     r = document.querySelector(':root')
          //     r.style.setProperty('--font-size', element && element.style ? element.style.fontSize : 'inherit')
          //     r.style.setProperty('--font-family', element && element.style ? element.style.fontFamily : 'inherit')
          //   })
          // }
          // if (this.editor.storage.comment.actualComments.length) {
          //   var rt
          //   this.editor.storage.comment.actualComments.forEach(node => {
          //     const element = document.getElementById(node.attrs.id).nextElementSibling
          //     rt = document.querySelector(':root')
          //     rt.style.setProperty('--font-size-comment', element && element.style ? element.style.fontSize : 'inherit')
          //     rt.style.setProperty('--font-family-comment', element && element.style ? element.style.fontFamily : 'inherit')
          //   })
          // }
        }, 500)
      }
      const stopTimeout = () => {
        clearTimeout(updateHeadingsAndHashtagsCSSValues)
      }
      stopTimeout()
      handleHeadingsAndHashtagsCSS()
    },
    onSelectionUpdate () {
      const DEFAULT_FONT_SIZE = '11px'
      let selection = this.editor.state.selection
      let tr = this.editor.state.tr
      let empty = this.editor.state.selection.empty
      if (empty) {
        let storedMarks = tr.storedMarks ||
            this.editor.state.storedMarks ||
            (selection instanceof prosemirrorState.TextSelection &&
                selection.$cursor &&
                selection.$cursor.marks &&
                selection.$cursor.marks()) ||
            []
        if (storedMarks.length) {
          this.actualFontSize = storedMarks[0].attrs.fontSize
        } else {
          this.actualFontSize = DEFAULT_FONT_SIZE
        }
      }
    },
    setClipboardDataToNormal (slice, state, event) {
      this.$emit('loading')
      let returnSlice
      slice.content.content.forEach(node => {
        if (node.type.name === 'bulletList' && node.content.content.length) {
          node.content.content.forEach((listItem, index) => {
            if (listItem.content.content.length) {
              listItem.content.content.forEach(_paragraph => {
                if (_paragraph.content.content.length) {
                  _paragraph.content.content.forEach(subNode => {
                    let newAttrs = {}
                    let newCommentAttrs = {}
                    if (subNode.type.name === 'comment') {
                      Object.entries(subNode.attrs).forEach(entr => {
                        const _commKey = entr[0]
                        let _commVal = Object.values(entr[1])[0]
                        if (_commVal === 'false' || _commVal === 'true') {
                          _commVal = JSON.parse(_commVal)
                        }
                        if (_commKey === 'replies') {
                          this.editor.view.state.doc.descendants((n, pos) => {
                            if (n.type.name === 'comment') {
                              if (n.attrs.id === subNode.attrs.id.id) {
                                _commVal = n.attrs.replies
                              }
                            }
                          })
                        }
                        newCommentAttrs[`${_commKey}`] = _commVal
                      })
                      subNode.attrs = newCommentAttrs
                      // const textNode = this.editor.schema.text(subNode.attrs.label.label, [subNode.marks])
                      // node.content.content.slice(index, 1)
                      // node.content.content.splice(index, 0, textNode)
                    }
                    if (subNode.type.name === 'mention' && (typeof subNode.attrs.customHashtag === 'object' || typeof subNode.attrs.fieldKey === 'object' ||
                      typeof subNode.attrs.fieldValue === 'object')) {
                      Object.entries(subNode.attrs).forEach(entr => {
                        const _key = entr[0]
                        let _val = Object.values(entr[1])[0]
                        if (_key === 'id') {
                          _val = uuidv4()
                        }
                        if (_val === 'false' || _val === 'true') {
                          _val = JSON.parse(_val)
                        }
                        newAttrs[`${_key}`] = _val
                      })
                      subNode.attrs = newAttrs
                    }
                    if (subNode.marks.length) {
                      subNode.marks.forEach(mark => {
                        if (mark.type.name === 'textStyle' && (typeof mark.attrs.fontSize === 'object' || typeof mark.attrs.color === 'object' ||
                          typeof mark.attrs.backgroundColor === 'object')) {
                          mark.attrs = {
                            fontFamily: mark.attrs.fontFamily,
                            fontSize: mark.attrs.fontSize.fontSize,
                            color: mark.attrs.color.color,
                            backgroundColor: mark.attrs.backgroundColor ? mark.attrs.backgroundColor.color : null
                          }
                        }
                        if (mark.attrs.backgroundColor && mark.attrs.backgroundColor.indexOf('rgb') !== -1) {
                          let rgb = mark.attrs.backgroundColor.slice(4, -1).split(', ')
                          const hex = '#' + this.convertRgbToHex(rgb[0], rgb[1], rgb[2])
                          mark.attrs.backgroundColor = hex
                        }
                        if (mark.attrs.color && mark.attrs.color.indexOf('rgb') !== -1) {
                          let rgb = mark.attrs.color.slice(4, -1).split(', ')
                          const hex = '#' + this.convertRgbToHex(rgb[0], rgb[1], rgb[2])
                          mark.attrs.color = hex
                        }
                      })
                    }
                  })
                }
              })
            }
          })
        }
        if (node.type.name === 'orderedList' && node.content.content.length) {
          node.content.content.forEach((listItem, index) => {
            if (listItem.content.content.length) {
              listItem.content.content.forEach(_paragraph => {
                if (_paragraph.content.content.length) {
                  _paragraph.content.content.forEach(subNode => {
                    let newAttrs = {}
                    let newCommentAttrs = {}
                    if (subNode.type.name === 'comment') {
                      Object.entries(subNode.attrs).forEach(entr => {
                        const _commKey = entr[0]
                        let _commVal = Object.values(entr[1])[0]
                        if (_commVal === 'false' || _commVal === 'true') {
                          _commVal = JSON.parse(_commVal)
                        }
                        if (_commKey === 'replies') {
                          this.editor.view.state.doc.descendants((n, pos) => {
                            if (n.type.name === 'comment') {
                              if (n.attrs.id === subNode.attrs.id.id) {
                                _commVal = n.attrs.replies
                              }
                            }
                          })
                        }
                        newCommentAttrs[`${_commKey}`] = _commVal
                      })
                      subNode.attrs = newCommentAttrs
                      // const textNode = this.editor.schema.text(subNode.attrs.label.label, [subNode.marks])
                      // node.content.content.slice(index, 1)
                      // node.content.content.splice(index, 0, textNode)
                    }
                    if (subNode.type.name === 'mention' && (typeof subNode.attrs.customHashtag === 'object' || typeof subNode.attrs.fieldKey === 'object' ||
                  typeof subNode.attrs.fieldValue === 'object')) {
                      Object.entries(subNode.attrs).forEach(entr => {
                        const _key = entr[0]
                        let _val = Object.values(entr[1])[0]
                        if (_key === 'id') {
                          _val = uuidv4()
                        }
                        if (_val === 'false' || _val === 'true') {
                          _val = JSON.parse(_val)
                        }
                        newAttrs[`${_key}`] = _val
                      })
                      subNode.attrs = newAttrs
                    }
                    if (subNode.marks.length) {
                      subNode.marks.forEach(mark => {
                        if (mark.type.name === 'textStyle' && (typeof mark.attrs.fontSize === 'object' || typeof mark.attrs.color === 'object' ||
                      typeof mark.attrs.backgroundColor === 'object')) {
                          mark.attrs = {
                            fontFamily: mark.attrs.fontFamily,
                            fontSize: mark.attrs.fontSize.fontSize,
                            color: mark.attrs.color.color,
                            backgroundColor: mark.attrs.backgroundColor ? mark.attrs.backgroundColor.color : null
                          }
                        }
                        if (mark.attrs.backgroundColor && mark.attrs.backgroundColor.indexOf('rgb') !== -1) {
                          let rgb = mark.attrs.backgroundColor.slice(4, -1).split(', ')
                          const hex = '#' + this.convertRgbToHex(rgb[0], rgb[1], rgb[2])
                          mark.attrs.backgroundColor = hex
                        }
                        if (mark.attrs.color && mark.attrs.color.indexOf('rgb') !== -1) {
                          let rgb = mark.attrs.color.slice(4, -1).split(', ')
                          const hex = '#' + this.convertRgbToHex(rgb[0], rgb[1], rgb[2])
                          mark.attrs.color = hex
                        }
                      })
                    }
                  })
                }
              })
            }
          })
        }
        if (node.type.name === 'heading' && node.content.content.length) {
          node.content.content.forEach((subNode, index) => {
            let newAttrs = {}
            let newCommentAttrs = {}
            if (subNode.type.name === 'comment') {
              Object.entries(subNode.attrs).forEach(entr => {
                const _commKey = entr[0]
                let _commVal = Object.values(entr[1])[0]
                if (_commVal === 'false' || _commVal === 'true') {
                  _commVal = JSON.parse(_commVal)
                }
                if (_commKey === 'replies') {
                  this.editor.view.state.doc.descendants((n, pos) => {
                    if (n.type.name === 'comment') {
                      if (n.attrs.id === subNode.attrs.id.id) {
                        _commVal = n.attrs.replies
                      }
                    }
                  })
                }
                newCommentAttrs[`${_commKey}`] = _commVal
              })
              subNode.attrs = newCommentAttrs
              // const textNode = this.editor.schema.text(subNode.attrs.label.label, [subNode.marks])
              // node.content.content.slice(index, 1)
              // node.content.content.splice(index, 0, textNode)
            }
            if (subNode.type.name === 'mention' && (typeof subNode.attrs.customHashtag === 'object' || typeof subNode.attrs.fieldKey === 'object' ||
              typeof subNode.attrs.fieldValue === 'object')) {
              Object.entries(subNode.attrs).forEach(entr => {
                const _key = entr[0]
                let _val = Object.values(entr[1])[0]
                if (_key === 'id') {
                  _val = uuidv4()
                }
                if (_val === 'false' || _val === 'true') {
                  _val = JSON.parse(_val)
                }
                newAttrs[`${_key}`] = _val
              })
              subNode.attrs = newAttrs
            }
            if (subNode.marks.length) {
              subNode.marks.forEach(mark => {
                if (mark.type.name === 'textStyle' && (typeof mark.attrs.fontSize === 'object' || typeof mark.attrs.color === 'object' ||
                typeof mark.attrs.backgroundColor === 'object')) {
                  mark.attrs = {
                    fontFamily: mark.attrs.fontFamily,
                    fontSize: mark.attrs.fontSize.fontSize,
                    color: mark.attrs.color.color,
                    backgroundColor: mark.attrs.backgroundColor ? mark.attrs.backgroundColor.color : null
                  }
                }
                if (mark.attrs.backgroundColor && mark.attrs.backgroundColor.indexOf('rgb') !== -1) {
                  let rgb = mark.attrs.backgroundColor.slice(4, -1).split(', ')
                  const hex = '#' + this.convertRgbToHex(rgb[0], rgb[1], rgb[2])
                  mark.attrs.backgroundColor = hex
                }
                if (mark.attrs.color && mark.attrs.color.indexOf('rgb') !== -1) {
                  let rgb = mark.attrs.color.slice(4, -1).split(', ')
                  const hex = '#' + this.convertRgbToHex(rgb[0], rgb[1], rgb[2])
                  mark.attrs.color = hex
                }
              })
            }
          })
        }
        if (node.type.name === 'paragraph' && node.content.content.length) {
          node.content.content.forEach((subNode, index) => {
            let newAttrs = {}
            let newCommentAttrs = {}
            if (subNode.type.name === 'comment') {
              Object.entries(subNode.attrs).forEach(entr => {
                const _commKey = entr[0]
                let _commVal = Object.values(entr[1])[0]
                if (_commVal === 'false' || _commVal === 'true') {
                  _commVal = JSON.parse(_commVal)
                }
                if (_commKey === 'replies') {
                  this.editor.view.state.doc.descendants((n, pos) => {
                    if (n.type.name === 'comment') {
                      if (n.attrs.id === subNode.attrs.id.id) {
                        _commVal = n.attrs.replies
                      }
                    }
                  })
                }
                newCommentAttrs[`${_commKey}`] = _commVal
              })
              subNode.attrs = newCommentAttrs
              // const textNode = this.editor.schema.text(subNode.attrs.label.label, [subNode.marks])
              // node.content.content.slice(index, 1)
              // node.content.content.splice(index, 0, textNode)
            }
            if (subNode.type.name === 'mention' && (typeof subNode.attrs.customHashtag === 'object' || typeof subNode.attrs.fieldKey === 'object' ||
              typeof subNode.attrs.fieldValue === 'object')) {
              Object.entries(subNode.attrs).forEach(entr => {
                const _key = entr[0]
                let _val = Object.values(entr[1])[0]
                if (_key === 'id') {
                  _val = uuidv4()
                }
                if (_val === 'false' || _val === 'true') {
                  _val = JSON.parse(_val)
                }
                newAttrs[`${_key}`] = _val
              })
              subNode.attrs = newAttrs
            }
            if (subNode.marks.length) {
              subNode.marks.forEach(mark => {
                if (mark.type.name === 'textStyle' && (typeof mark.attrs.fontSize === 'object' || typeof mark.attrs.color === 'object' ||
                typeof mark.attrs.backgroundColor === 'object')) {
                  mark.attrs = {
                    fontFamily: mark.attrs.fontFamily,
                    fontSize: mark.attrs.fontSize.fontSize,
                    color: mark.attrs.color.color,
                    backgroundColor: mark.attrs.backgroundColor ? mark.attrs.backgroundColor.color : null
                  }
                }
                if (mark.attrs.backgroundColor && mark.attrs.backgroundColor.indexOf('rgb') !== -1) {
                  let rgb = mark.attrs.backgroundColor.slice(4, -1).split(', ')
                  const hex = '#' + this.convertRgbToHex(rgb[0], rgb[1], rgb[2])
                  mark.attrs.backgroundColor = hex
                }
                if (mark.attrs.color && mark.attrs.color.indexOf('rgb') !== -1) {
                  let rgb = mark.attrs.color.slice(4, -1).split(', ')
                  const hex = '#' + this.convertRgbToHex(rgb[0], rgb[1], rgb[2])
                  mark.attrs.color = hex
                }
              })
            }
          })
        }
        if (node.type.name === 'table') {
          node.content.content.forEach(tableRow => {
            tableRow.forEach(tableCell => {
              tableCell.content.content.forEach(item => {
                item.forEach(node => {
                  if (node.type.name === 'heading' && node.content.content.length) {
                    node.content.content.forEach((subNode, index) => {
                      let newAttrs = {}
                      let newCommentAttrs = {}
                      if (subNode.type.name === 'comment') {
                        Object.entries(subNode.attrs).forEach(entr => {
                          const _commKey = entr[0]
                          let _commVal = Object.values(entr[1])[0]
                          if (_commVal === 'false' || _commVal === 'true') {
                            _commVal = JSON.parse(_commVal)
                          }
                          if (_commKey === 'replies') {
                            this.editor.view.state.doc.descendants((n, pos) => {
                              if (n.type.name === 'comment') {
                                if (n.attrs.id === subNode.attrs.id.id) {
                                  _commVal = n.attrs.replies
                                }
                              }
                            })
                          }
                          newCommentAttrs[`${_commKey}`] = _commVal
                        })
                        subNode.attrs = newCommentAttrs
                        // const textNode = this.editor.schema.text(subNode.attrs.label.label, [subNode.marks])
                        // node.content.content.slice(index, 1)
                        // node.content.content.splice(index, 0, textNode)
                      }
                      if (subNode.type.name === 'mention' && (typeof subNode.attrs.customHashtag === 'object' || typeof subNode.attrs.fieldKey === 'object' ||
                        typeof subNode.attrs.fieldValue === 'object')) {
                        Object.entries(subNode.attrs).forEach(entr => {
                          const _key = entr[0]
                          let _val = Object.values(entr[1])[0]
                          if (_key === 'id') {
                            _val = uuidv4()
                          }
                          if (_val === 'false' || _val === 'true') {
                            _val = JSON.parse(_val)
                          }
                          newAttrs[`${_key}`] = _val
                        })
                        subNode.attrs = newAttrs
                      }
                      if (subNode.marks.length) {
                        subNode.marks.forEach(mark => {
                          if (mark.type.name === 'textStyle' && (typeof mark.attrs.fontSize === 'object' || typeof mark.attrs.color === 'object' ||
                            typeof mark.attrs.backgroundColor === 'object')) {
                            mark.attrs = {
                              fontFamily: mark.attrs.fontFamily,
                              fontSize: mark.attrs.fontSize.fontSize,
                              color: mark.attrs.color.color,
                              backgroundColor: mark.attrs.backgroundColor ? mark.attrs.backgroundColor.color : null
                            }
                          }
                          if (mark.attrs.backgroundColor && mark.attrs.backgroundColor.indexOf('rgb') !== -1) {
                            let rgb = mark.attrs.backgroundColor.slice(4, -1).split(', ')
                            const hex = '#' + this.convertRgbToHex(rgb[0], rgb[1], rgb[2])
                            mark.attrs.backgroundColor = hex
                          }
                          if (mark.attrs.color && mark.attrs.color.indexOf('rgb') !== -1) {
                            let rgb = mark.attrs.color.slice(4, -1).split(', ')
                            const hex = '#' + this.convertRgbToHex(rgb[0], rgb[1], rgb[2])
                            mark.attrs.color = hex
                          }
                        })
                      }
                    })
                  }
                  let newAttrs = {}
                  let newCommentAttrs = {}
                  if (node.type.name === 'comment') {
                    Object.entries(node.attrs).forEach(entr => {
                      const _commKey = entr[0]
                      let _commVal = Object.values(entr[1])[0]
                      if (_commVal === 'false' || _commVal === 'true') {
                        _commVal = JSON.parse(_commVal)
                      }
                      if (_commKey === 'replies') {
                        this.editor.view.state.doc.descendants((n, pos) => {
                          if (n.type.name === 'comment') {
                            if (n.attrs.id === node.attrs.id.id) {
                              _commVal = n.attrs.replies
                            }
                          }
                        })
                      }
                      newCommentAttrs[`${_commKey}`] = _commVal
                    })
                    node.attrs = newCommentAttrs
                    // const textNode = this.editor.schema.text(subNode.attrs.label.label, [subNode.marks])
                    // node.content.content.slice(index, 1)
                    // node.content.content.splice(index, 0, textNode)
                  }
                  if (node.type.name === 'mention' && (typeof node.attrs.customHashtag === 'object' || typeof node.attrs.fieldKey === 'object' ||
                    typeof node.attrs.fieldValue === 'object')) {
                    Object.entries(node.attrs).forEach(entr => {
                      const _key = entr[0]
                      let _val = Object.values(entr[1])[0]
                      if (_key === 'id') {
                        _val = uuidv4()
                      }
                      if (_val === 'false' || _val === 'true') {
                        _val = JSON.parse(_val)
                      }
                      newAttrs[`${_key}`] = _val
                    })
                    node.attrs = newAttrs
                  }
                  if (node.marks.length) {
                    node.marks.forEach(mark => {
                      if (mark.type.name === 'textStyle' && (typeof mark.attrs.fontSize === 'object' || typeof mark.attrs.color === 'object' ||
                        typeof mark.attrs.backgroundColor === 'object')) {
                        mark.attrs = {
                          fontFamily: mark.attrs.fontFamily,
                          fontSize: mark.attrs.fontSize.fontSize,
                          color: mark.attrs.color.color,
                          backgroundColor: mark.attrs.backgroundColor ? mark.attrs.backgroundColor.color : null
                        }
                      }
                      if (mark.attrs.backgroundColor && mark.attrs.backgroundColor.indexOf('rgb') !== -1) {
                        let rgb = mark.attrs.backgroundColor.slice(4, -1).split(', ')
                        const hex = '#' + this.convertRgbToHex(rgb[0], rgb[1], rgb[2])
                        mark.attrs.backgroundColor = hex
                      }
                      if (mark.attrs.color && mark.attrs.color.indexOf('rgb') !== -1) {
                        let rgb = mark.attrs.color.slice(4, -1).split(', ')
                        const hex = '#' + this.convertRgbToHex(rgb[0], rgb[1], rgb[2])
                        mark.attrs.color = hex
                      }
                    })
                  }
                })
              })
            })
          })
        }
      })
      returnSlice = slice
      this.$emit('stop-loading')
      return returnSlice
    },
    // getTableData () {
    //   this.editor.view.state.doc.descendants((node, pos) => {
    //     const width = this.editor.view.nodeDOM(pos)?.offsetWidth
    //     if (node.type.name === 'table') {
    //       const width = this.editor.view.nodeDOM(pos)?.offsetWidth
    //       // Update the `width` attribute
    //       tr.setNodeMarkup(pos, undefined, {
    //         ...node.attrs,
    //         width
    //       })
    //     }
    //   })
    // },
    async setInitialContent () {
      if (!this.template && !this.caseData && !this.currentCaseForm) return
      if (this.template && !this.caseData && !this.currentCaseForm) {
        let content
        const _id = this.template._id
        const { data } = await axios.get(`/getTemplate/${_id}`)
        if (data.data.templateData.json) {
          content = data.data.templateData.json
        }
        if (!data.data.templateContentBinary.length && content && content.content.length) {
          this.setContent(content)
        }
        if (!data.data.templateContentBinary.length && !data.data.templateData.json) {
          content = ' '
          this.setContent(content)
        }
      }
      if (this.caseData && !this.currentCaseForm) {
        const _id = this.caseData._id
        const { data } = await axios.get(`/getCase/${_id}`)
        const actualWb = data.data.workbenches.find(wb => wb._id === this.workbench._id)
        const actualTemp = actualWb.templates.find(t => t._id === this.$route.params.templateId)
        const content = actualTemp.templateData.json
        this.setContent(content)
      }
      if (!this.caseData && this.currentCaseForm) {
        const _id = this.currentCaseForm._id
        const { data } = await axios.get(`/getCaseForm/${_id}`)
        const actualWb = data.data.workbenches.find(wb => wb._id === this.workbench._id)
        const actualTemp = actualWb.templates.find(t => t._id === this.$route.params.templateId)
        const content = actualTemp.templateData.json
        this.setContent(content)
      }
    },
    onCommentTextUpdate (comment, newText) {
      this.editor.view.state.doc.descendants((node, pos) => {
        if (node.type.name === 'comment') {
          if (node.attrs.id === comment.id) {
            this.editor.chain().focus().setNodeSelection(pos).run()
            this.editor.commands.updateAttributes('comment', { commentText: newText })
            this.ydoc.transact(() => {
              this.ymap.set('comments', 'onCommentTextUpdate')
            })
            // EventBus.$emit('update-component-key')
          }
        }
      })
    },
    onCommentReply (comment, replyText, accountData, avatar) {
      let user
      if (accountData.firstName && accountData.lastName) user = accountData.firstName + ' ' + accountData.lastName
      else if (accountData.email) user = accountData.email
      if (this.editor.storage.comment && this.editor.storage.comment.actualComments.length) {
        this.editor.view.state.doc.descendants((node, pos) => {
          if (node.type.name === 'comment') {
            if (node.attrs.id === comment.id) {
              const reply = {
                id: uuidv4(),
                author: user,
                avatar: avatar,
                replyText: replyText
              }
              let tempArr = []
              if (!node.attrs.replies.arr) {
                let repliesArr = [reply]
                this.editor.chain().focus().setNodeSelection(pos).run()
                this.editor.commands.updateAttributes('comment', { replies: { arr: repliesArr } })
              } else {
                tempArr.push(...node.attrs.replies.arr)
                tempArr.push(reply)
                this.editor.chain().focus().setNodeSelection(pos).run()
                this.editor.commands.updateAttributes('comment', { replies: { arr: tempArr } })
              }
            }
          }
        })
        this.ydoc.transact(() => {
          this.ymap.set('comments', 'updateReply')
        })
        // this.editor.storage.comment.actualComments = []
        // this.editor.view.state.doc.descendants((node, pos) => {
        //   if (node.type.name === 'comment') {
        //     this.editor.storage.comment.actualComments.push(node)
        //   }
        // })
        // store.dispatch('editorStore/setComments', this.editor.storage.comment.actualComments)
        // EventBus.$emit('update-component-key')
      }
    },
    onCommentReplyTextUpdate (comment, reply, newText) {
      this.editor.view.state.doc.descendants((node, pos) => {
        if (node.type.name === 'comment') {
          if (node.attrs.id === comment.id) {
            let tempArr = []
            const replyIndex = comment.replies.arr.findIndex(rep => rep.id === reply.id)
            if (replyIndex !== -1) {
              tempArr.push(...node.attrs.replies.arr)
              tempArr[replyIndex].replyText = newText
              this.editor.chain().focus().setNodeSelection(pos).run()
              this.editor.commands.updateAttributes('comment', { replies: { arr: tempArr } })
              this.ydoc.transact(() => {
                this.ymap.set('comments', 'onCommentReplyTextUpdate')
              })
              // EventBus.$emit('update-component-key')
            }
          }
        }
      })
    },
    onDeleteReply (reply, comment) {
      this.editor.view.state.doc.descendants((node, pos) => {
        if (node.type.name === 'comment') {
          if (node.attrs.id === comment.id) {
            const foundReplyIndex = node.attrs.replies.arr.findIndex(rep => rep.id === reply.id)
            if (foundReplyIndex !== -1) {
              node.attrs.replies.arr.splice(foundReplyIndex, 1)
              let tempArr = node.attrs.replies.arr
              this.editor.chain().focus().setNodeSelection(pos).run()
              this.editor.commands.updateAttributes('comment', { replies: { arr: tempArr } })
              this.ydoc.transact(() => {
                this.ymap.set('comments', 'onDeleteReply')
              })
              // EventBus.$emit('update-component-key')
            }
          }
        }
      })
    },
    onResolveComment (comment) {
      this.editor.view.state.doc.descendants((node, pos) => {
        if (node.type.name === 'comment') {
          if (node.attrs.id === comment.id) {
            this.editor.chain().focus().setNodeSelection(pos).run()
            this.editor.commands.updateAttributes('comment', { resolved: true })
            this.ydoc.transact(() => {
              this.ymap.set('comments', 'onResolveComment')
            })
            // EventBus.$emit('update-component-key')
          }
        }
      })
    },
    onUnresolveComment (comment) {
      this.editor.view.state.doc.descendants((node, pos) => {
        if (node.type.name === 'comment') {
          if (node.attrs.id === comment.id) {
            this.editor.chain().focus().setNodeSelection(pos).run()
            this.editor.commands.updateAttributes('comment', { resolved: false })
            this.ydoc.transact(() => {
              this.ymap.set('comments', 'onUnresolveComment')
            })
            // EventBus.$emit('update-component-key')
          }
        }
      })
    },
    onCommentDeleted (comment) {
      this.editor.view.state.doc.descendants((node, pos) => {
        if (node.type.name === 'comment') {
          if (node.attrs.id === comment.id) {
            const text = node.attrs.label ? node.attrs.label : ' '
            this.editor.chain().focus().setNodeSelection(pos).run()
            this.editor.chain().focus().insertContent(text).run()
            this.ydoc.transact(() => {
              this.ymap.set('comments', 'onUnresolveComment')
            })
          }
        }
      })
    },
    async deleteComment (comment) {
      this.$emit('loading')
      const commentToDelete = comment
      const payload = {
        commentToDelete
      }
      try {
        await this.deleteMyComment({ _id: this.caseData._id, workbenchId: this.workbench._id, templateId: this.template._id, payload })
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      } finally {
        this.$emit('stop-loading')
      }
    },
    getRandomColor () {
      return getRandomElement([
        '#958DF1',
        '#F98181',
        '#FBBC88',
        '#FAF594',
        '#70CFF8',
        '#94FADB',
        '#B9F18D',
        '#ce93d8',
        '#5c6bc0',
        '#1565c0',
        '#80d8ff',
        '#00bcd4',
        '#4db6ac',
        '#66bb6a',
        '#fbc02d',
        '#bdbdbd'
      ])
    },
    // async convertAndSetConntentOnDisconnect () {
    //   const json = yDocToProsemirrorJSON(this.ydoc, 'doc')
    //   if (json && json.content.length) {
    //     this.setContent(json)
    //   } else {
    //     const _id = this.template._id
    //     const { data } = await axios.get(`/getTemplate/${_id}`)
    //     const content = data.data.templateData.json
    //     this.setContent(content)
    //   }
    // },
    getData () {
      const editorJSON = this.editor.getJSON()
      const editorHTML = this.editor.getHTML()
      return {
        json: editorJSON,
        html: editorHTML
      }
    },
    transferContentToLabel (text) {
      this.selectedText = text
    },
    setContent (content) {
      this.editor.commands.setContent(content)
    },
    // we have to replace our suggestion text with a mention
    // so it's important to pass also the position of your suggestion text
    selectSuggestion (suggestion, command, info) {
      let fontSize = null
      const { selection } = this.editor.state
      this.editor.state.doc.nodesBetween(selection.from, selection.to, (node, pos) => {
        if (node.type.name === 'paragraph' && node.content && node.content.content.length) {
          for (let i = 0; i < node.content.content.length; i++) {
            const subNode = node.content.content[i]
            if (subNode.type.name === 'text' && subNode.marks.length) {
              for (let idx = 0; idx < subNode.marks.length; idx++) {
                const mark = subNode.marks[idx]
                if (mark.attrs.fontSize) fontSize = mark.attrs.fontSize
              }
            }
          }
        }
      })
      let allredyUsedInQuestionnaire
      if (this.template.questionnaire.fields && this.template.questionnaire.fields.length) {
        allredyUsedInQuestionnaire = this.template.questionnaire.fields.find(sugg => sugg.fieldKey.toLowerCase() === suggestion.fieldKey.toLowerCase())
      }
      if (info && info === 'suggestion-in-use-in-questionnaire') {
        command({
          id: uuidv4(),
          // key: allredyUsedInQuestionnaire.key.toLowerCase(),
          label: allredyUsedInQuestionnaire.name,
          fieldType: allredyUsedInQuestionnaire.fieldType,
          fieldState: 'tag',
          fieldKey: allredyUsedInQuestionnaire.name.toLowerCase().split(' ').join('_'),
          fieldValue: null,
          customLabel: allredyUsedInQuestionnaire.customLabel ? allredyUsedInQuestionnaire.customLabel : null,
          customHashtag: allredyUsedInQuestionnaire.customHashtag,
          language: allredyUsedInQuestionnaire.language ?? null,
          fieldClass: suggestion.fieldClass ?? null,
          fontSize: allredyUsedInQuestionnaire.fontSize ?? null
        })
      } else if (info && info === 'modified-suggestion') {
        command({
          id: uuidv4(),
          // key: suggestion.key,
          label: suggestion.name,
          fieldType: suggestion.fieldType,
          fieldState: 'tag',
          fieldKey: suggestion.fieldKey,
          fieldValue: suggestion.fieldValue,
          customLabel: suggestion.customLabel,
          customHashtag: suggestion.customHashtag,
          language: suggestion.language ?? null,
          fieldClass: suggestion.fieldClass ?? null,
          fontSize: allredyUsedInQuestionnaire.fontSize ?? null
        })
      } else if (info && info === 'lavvira-suggestion') {
        command({
          id: suggestion.id,
          // key: suggestion.key,
          label: suggestion.name,
          fieldType: suggestion.fieldType,
          fieldState: 'tag',
          fieldKey: suggestion.fieldKey,
          fieldValue: suggestion.fieldValue,
          customLabel: suggestion.customLabel,
          customHashtag: suggestion.customHashtag,
          language: suggestion.language ?? null,
          fieldClass: suggestion.fieldClass ?? null,
          fontSize: fontSize
        })
      } else if (info && info === 'custom-suggestion') {
        command({
          id: suggestion.id,
          // key: suggestion.key,
          label: suggestion.name,
          fieldType: suggestion.fieldType,
          fieldState: 'tag',
          fieldKey: suggestion.name.toLowerCase().split(' ').join('_'),
          fieldValue: suggestion.fieldValue,
          customLabel: suggestion.customLabel,
          customHashtag: suggestion.customHashtag,
          language: suggestion.language ?? null,
          fieldClass: suggestion.fieldClass ?? null,
          fontSize: fontSize
        })
      }
      let allMentions = []
      this.editor.view.state.doc.descendants((node, pos) => {
        if (node.type.name === 'mention') {
          Object.assign(node, { pos: pos })
          allMentions.push(node)
        }
      })
      for (let i = 0; i < allMentions.length; i++) {
        const _node = allMentions[i]
        var r
        if (_node.attrs.id === suggestion.id) {
          const element = document.getElementById(_node.attrs.id).nextSibling
          if (element) {
            r = document.querySelector(':root')
            // r.style.setProperty('--font-size', element && element.style ? element.style.fontSize : 'inherit')
            r.style.setProperty('--font-family', element && element.style ? element.style.fontFamily : 'inherit')
          }
          this.editor.storage.mention.actualHashTags.splice(i, 0, _node)
        }
      }
      store.dispatch('editorStore/setSuggestions', this.editor.storage.mention.actualHashTags)
      this.ydoc.transact(() => {
        this.ymap.set('suggestions', 'onSelectSuggestion')
      })
      this.editor.chain().focus()
    },
    selectCustomSuggestion () {
      this.editor.chain().focus()
    },
    closeAndReset () {
      this.editorAddons.insertTable.close = () => {
        this.editorAddons.insertTable = { ...defaultInsertTable }
      }
      this.editorAddons.insertTable.close()
    },
    // renders a popup with suggestions
    // tiptap provides a virtualNode object for using popper.js (or tippy.js) for popups
    renderPopup (node) {
      this.suggestionListVisibility = true
    },

    destroyPopup () {
      this.suggestionListVisibility = false
      this.editor.chain().focus()
    },

    // testReplaceAll () {
    //   const content = this.getData().json
    //   const getFieldRecursive = (field, arr, index) => {
    //     if (field.type === 'field-tag') {
    //       const qfield = this.questionnaire.fields.find(f => f.key === field.attrs.fieldKey)
    //       if (qfield && qfield.value) {
    //         arr[index] = {
    //           type: 'text',
    //           text: `${qfield.value}`
    //         }
    //       }
    //     } else if (field.content) {
    //       field.content.forEach((f, index) => {
    //         getFieldRecursive(f, field.content, index)
    //       })
    //     }
    //   }
    //   getFieldRecursive(content)
    // },
    suggestionChangeInQuestionnaire (suggestion) {
      const content = this.getData().json
      const getFieldRecursive = (field, arr, index) => {
        if (field.type === 'mention') {
          const qfield = this.questionnaire.fields.find(f => f.id === field.attrs.id)
          if (suggestion.fieldType === 'file-input') {
            // This operation is performed when the suggestion is changed from the questionnaire. Then we need to change the id of the suggestion.
            // The old suggestion is now a new requested document and to keep the actual suggestion we just change the id.
            arr[index].attrs.id = uuidv4()
          }
          if (qfield && qfield.name && suggestion.fieldType !== 'file-input') {
            arr[index].attrs.id = qfield.id
            arr[index].attrs.label = qfield.name
            // arr[index].attrs.key = qfield.key
            arr[index].attrs.fieldKey = qfield.fieldKey
            arr[index].attrs.fieldType = qfield.fieldType
          }
        } else if (field.content) {
          field.content.forEach((f, index) => {
            getFieldRecursive(f, field.content, index)
          })
        }
      }
      getFieldRecursive(content)
      this.editor.commands.clearContent()
      this.editor.commands.setContent(content)
    },
    undoStep () {
      this.editor.chain().focus().undo().run()
      this.ydoc.transact(() => {
        this.ymap.set('comments', 'onUndo')
      })
      this.ydoc.transact(() => {
        this.ymap.set('suggestions', 'onUndo')
      })
      this.ydoc.transact(() => {
        this.ymap.set('headings', 'onUndo')
      })
      // this.editor.storage.mention.actualHashTags = []
      // this.editor.storage.comment.actualComments = []
      // this.editor.view.state.doc.descendants((node, pos) => {
      //   if (node.type.name === 'mention') {
      //     Object.assign(node, { pos: pos })
      //     this.editor.storage.mention.actualHashTags.push(node)
      //   }
      //   if (node.type.name === 'comment') {
      //     this.editor.storage.comment.actualComments.push(node)
      //   }
      // })
      // store.dispatch('editorStore/setSuggestions', this.editor.storage.mention.actualHashTags)
      // store.dispatch('editorStore/setComments', this.editor.storage.comment.actualComments)
      // EventBus.$emit('update-component-key')
    },
    redoStep () {
      this.editor.chain().focus().redo().run()
      this.ydoc.transact(() => {
        this.ymap.set('comments', 'onRedo')
      })
      this.ydoc.transact(() => {
        this.ymap.set('suggestions', 'onRedo')
      })
      this.ydoc.transact(() => {
        this.ymap.set('headings', 'onRedo')
      })
      // this.editor.storage.mention.actualHashTags = []
      // this.editor.storage.comment.actualComments = []
      // this.editor.view.state.doc.descendants((node, pos) => {
      //   if (node.type.name === 'mention') {
      //     Object.assign(node, { pos: pos })
      //     this.editor.storage.mention.actualHashTags.push(node)
      //   }
      //   if (node.type.name === 'comment') {
      //     this.editor.storage.comment.actualComments.push(node)
      //   }
      // })
      // store.dispatch('editorStore/setSuggestions', this.editor.storage.mention.actualHashTags)
      // store.dispatch('editorStore/setComments', this.editor.storage.comment.actualComments)
      // EventBus.$emit('update-component-key')
    },
    toggleHighlight (selectedColor) {
      if (selectedColor === '#FFFFFF') this.editor.commands.setBackgroundColor(null)
      else this.editor.commands.setBackgroundColor(selectedColor)
    },
    setFontColor (selectedFontColor) {
      if (selectedFontColor === '#FFFFFF') this.editor.chain().focus().setColor(null).run()
      else this.editor.chain().focus().setColor(selectedFontColor).run()
    },
    setFontFamily (font) {
      this.editor.chain().focus().setFontFamily(font.style.fontFamily).run()
    },
    setFontSize (num) {
      const setMentionFontSize = (editor, fontSize) => {
        const { selection, tr } = editor.state
        editor.view.focus()
        const attrs = { fontSize }
        editor.state.doc.nodesBetween(selection.from, selection.to, (node, pos) => {
          if (node.type.name === 'mention' || node.type.name === 'comment') {
            tr.setNodeMarkup(pos, null, { ...node.attrs, ...attrs })
          }
        })
        editor.view.dispatch(tr)
      }
      const editor = this.editor
      const fontSize = num.toString() + 'px;'
      setMentionFontSize(editor, fontSize)
      let _num = num.toString() + 'px;'
      this.editor.view.state.selection.$anchor.path.forEach(item => {
        if (typeof item === 'object') {
          if (item.type.name === 'paragraph') {
            item.content.content.forEach(subItem => {
              if (subItem.type.name === 'mention' || subItem.type.name === 'comment') {
                this.editor.commands.setMark('textStyle', { fontSize: _num })
              }
            })
          }
        }
      })
      this.editor.chain().focus().setFontSize(_num).run()
    },
    setTableBackgroundColor (color) {
      this.editor.commands.setCellAttribute('backgroundColor', color)
    },
    openCommentPopup () {
      // this.from = this.editor.state.selection.anchor
      // this.to = this.editor.state.selection.head
      // const doc = this.editor.state.doc
      // this.editor.chain().focus().run()
      // this.editor.commands.setTextSelection({ from: this.from, to: this.to })
      // const text = doc.textBetween(this.from, this.to, ' ')
      // this.textThatHasBeenCommentedOn = text
      // this.commentDialog = true
      const { selection } = this.editor.state
      const { state } = this.editor
      const { from, to } = selection
      this.from = from
      this.to = to
      const text = state.doc.textBetween(from, to, ' ')
      this.textThatHasBeenCommentedOn = text
      this.commentDialog = true
    },
    addComment (comment) {
      let fontSize = null
      let userName
      const { selection } = this.editor.state
      this.editor.state.doc.nodesBetween(selection.from, selection.to, (node, pos) => {
        if (node.type.name === 'paragraph' && node.content && node.content.content.length) {
          for (let i = 0; i < node.content.content.length; i++) {
            const subNode = node.content.content[i]
            if (subNode.type.name === 'text' && subNode.marks.length) {
              for (let idx = 0; idx < subNode.marks.length; idx++) {
                const mark = subNode.marks[idx]
                if (mark.attrs.fontSize) fontSize = mark.attrs.fontSize
              }
            }
          }
        }
      })
      if (this.account && this.account.accountData.firstName && this.account.accountData.lastName) {
        userName = this.account.accountData.firstName + ' ' + this.account.accountData.lastName
      } else {
        userName = this.account.accountData.email
      }
      this.editor.chain().focus().setTextSelection({ from: comment.from, to: comment.to }).run()
      this.editor.chain().focus().insertContent({ type: 'comment',
        attrs: {
          id: comment.id,
          label: comment.textThatHasBeenCommentedOn,
          fontSize: fontSize,
          createdBy: userName,
          resolved: false,
          commentText: comment.text,
          avatar: this.account.avatar ? `${process.env.VUE_APP_BACKEND_PATH}downloadAvatar/${this.account._id}/${this.account.avatar.raw.filename}` : '',
          replies: []
        }
      }).run()
      this.ydoc.transact(() => {
        this.ymap.set('comments', 'addComment')
      })
      // this.editor.storage.comment.actualComments = []
      // this.editor.view.state.doc.descendants((node, pos) => {
      //   if (node.type.name === 'comment') {
      //     this.editor.storage.comment.actualComments.push(node)
      //   }
      // })
      // store.dispatch('editorStore/setComments', this.editor.storage.comment.actualComments)
      // EventBus.$emit('update-component-key')
    },
    addSuggestion () {
      this.editor.chain().focus().insertContent('@').run()
    },
    setLineSpacing (input) {
      input = Number(input)
      if (input === 'Custom spacing') {
      } else {
        this.editor.commands.updateAttributes('paragraph', { lineHeight: input })
      }
    },
    selectHeading (heading) {
      setTimeout(async () => {
        this.ydoc.transact(() => {
          this.ymap.set('headings', 'onSelectHeading')
        })
      }, 1000)
      switch (heading) {
      case 'Heading 1':
        return this.editor.chain().focus().setMark('textStyle', { fontSize: null }).setHeading({ level: 1 }).run()
      case 'Heading 2':
        return this.editor.chain().focus().setMark('textStyle', { fontSize: null }).setHeading({ level: 2 }).run()
      case 'Heading 3':
        return this.editor.chain().focus().setMark('textStyle', { fontSize: null }).setHeading({ level: 3 }).run()
      case 'Heading 4':
        return this.editor.chain().focus().setMark('textStyle', { fontSize: null }).setHeading({ level: 4 }).run()
      case 'Heading 5':
        return this.editor.chain().focus().setMark('textStyle', { fontSize: null }).setHeading({ level: 5 }).run()
      case 'Heading 6':
        return this.editor.chain().focus().setMark('textStyle', { fontSize: null }).setHeading({ level: 6 }).run()
      case 'Normal text':
        return this.editor.chain().focus().setParagraph().run()
      }
    },
    updateHeadingsSummary () {
      this.ydoc.transact(() => {
        this.ymap.set('headings', 'onSelectHeading')
      })
    },
    toggleBold () {
      this.editor.chain().focus().toggleBold().run()
    },
    alignCenter () {
      this.editor.chain().focus().setTextAlign('center').run()
    },
    addNewTemplate () {
      EventBus.$emit('dialog-add-template', {})
    },
    shareTemplate () {
      EventBus.$emit('dialog-share-template', this.template, { fromEditor: true })
    },
    findInText (searchInput) {
      if (this.searchResults.length) {
        for (let i = 0; i < this.searchResults.length; i++) {
          const res = this.searchResults[i]
          this.editor.commands.setTextSelection({ from: res.from, to: res.to })
          this.toggleHighlight('#FFFFFF')
        }
      }
      searchInput = searchInput.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
      this.searchResults = []
      const mergedTextNodes = []
      let index = 0

      if (!searchInput && this.searchResults.length) {
        for (let i = 0; i < this.searchResults.length; i++) {
          const res = this.searchResults[i]
          this.editor.commands.setTextSelection({ from: res.from, to: res.to })
          this.toggleHighlight('#FFFFFF')
        }
      }
      this.editor.view.state.doc.descendants((node, pos) => {
        if (node.isText) {
          if (mergedTextNodes[index]) {
            mergedTextNodes[index] = {
              text: mergedTextNodes[index].text + node.text,
              pos: mergedTextNodes[index].pos
            }
          } else {
            mergedTextNodes[index] = {
              text: node.text,
              pos
            }
          }
        } else {
          index += 1
        }
      })
      mergedTextNodes.forEach(({ text, pos }) => {
        const search = this.findRegExp(searchInput)
        let m
        // eslint-disable-next-line no-cond-assign
        while ((m = search.exec(text))) {
          if (m[0] === '') {
            break
          }
          this.searchResults.push({
            from: pos + m.index,
            to: pos + m.index + m[0].length
          })
        }
      })
      for (let i = 0; i < this.searchResults.length; i++) {
        const res = this.searchResults[i]
        this.editor.commands.setTextSelection({ from: res.from, to: res.to })
        this.toggleHighlight('#FFFF00')
        this.editor.commands.scrollIntoView(res.from)
      }
    },
    findRegExp (input) {
      return RegExp(input, 'gui')
    },
    replaceInText (replaceWith, searchInput) {
      if (replaceWith === ('Kur za levski' || 'Kurzalevski' || 'KURZALEVSKI' || 'KUR ZA LEVSKI' || 'Kur za Levski' || 'Kur Za Levski' || 'кур за левски' || 'Кур за левски' || 'Кур за Левски')) {
        replaceWith = 'Kur za CSKA'
      }
      const firstResult = this.searchResults[0]

      if (!firstResult) {
        return
      }

      const res = this.searchResults[0]
      this.editor.commands.setTextSelection({ from: res.from, to: res.to })
      this.editor.commands.insertContent(replaceWith)
      if (this.searchResults.length) {
        const res2 = this.searchResults[1]
        this.editor.commands.scrollIntoView(res2)
      }
      this.findInText(searchInput)
    },
    replaceAll (replaceWith) {
      let offset
      if (replaceWith === ('kur za levski' || 'Kur za levski' || 'Kurzalevski' || 'KURZALEVSKI' || 'KUR ZA LEVSKI' || 'Kur za Levski' || 'Kur Za Levski' || 'кур за левски' || 'Кур за левски' || 'Кур за Левски')) {
        replaceWith = 'Kur za CSKA'
      }
      if (!this.searchResults.length) {
        return
      }
      this.searchResults.forEach(({ from, to }, index) => {
        this.editor.commands.setTextSelection({ from, to })
        this.editor.commands.insertContent(replaceWith)
        offset = this.rebaseNextResult(replaceWith, index, offset)
      })
    },
    rebaseNextResult (replace, index, lastOffset = 0) {
      const nextIndex = index + 1

      if (!this.searchResults[nextIndex]) {
        return null
      }

      const { from: currentFrom, to: currentTo } = this.searchResults[index]
      const offset = (currentTo - currentFrom - replace.length) + lastOffset
      const { from, to } = this.searchResults[nextIndex]

      this.searchResults[nextIndex] = {
        to: to - offset,
        from: from - offset
      }

      return offset
    },
    refreshQuestionairreFields () {
      this.$emit('refreshQuestionairreFields')
    },
    saveSuggestion (_id, workbenchId, templateId, suggestion) {
      this.$emit('saveSuggestion', _id, workbenchId, templateId, suggestion)
    },
    openVersionHistory () {
      if (this.caseData && !this.currentCaseForm) {
        EventBus.$emit('drawer-overview-case-template-data-history', this.caseData._id, this.workbench._id, this.template._id)
      }
      if (this.currentCaseForm && !this.caseData) {
        EventBus.$emit('drawer-overview-model-template-data-history', this.currentCaseForm._id, this.workbench._id, this.template._id)
      }
      if (!this.caseData && !this.currentCaseForm && this.template) {
        EventBus.$emit('drawer-overview-template-data-history', this.template._id)
      }
    },
    changeSuggestionsViewText () {
      this.editor.view.state.doc.descendants((node, pos) => {
        if (node.type.name === 'mention') {
          this.editor.commands.setNodeSelection(pos)
          this.editor.commands.updateAttributes('mention', { fieldState: 'view' })
        }
      })
    },
    changeSuggestionsViewEditorMode () {
      this.editor.view.state.doc.descendants((node, pos) => {
        if (node.type.name === 'mention') {
          this.editor.commands.setNodeSelection(pos)
          this.editor.commands.updateAttributes('mention', { fieldState: 'tag' })
        }
      })
    },
    exportDocument (type) {
      const doc = this.editor.state.doc
      const templateName = this.template.name + '.docx'
      exportTemplate(doc, templateName, type)
    },
    printDocument (type) {
      const doc = this.editor.state.doc
      const templateName = this.template.name + '.docx'
      exportTemplate(doc, templateName, type)
    },
    updateTemplateName (templateName) {
      this.template.name = templateName
      this.$emit('update-template-name', this.template, 'from editor')
    },
    changeTemplateDescription () {
      if (this.template && !this.caseData && !this.currentCaseForm) {
        this.$emit('update-template-description', this.template)
      }
    },
    cut () {
      document.execCommand('cut')
    },
    copy () {
      document.execCommand('copy')
    },
    async paste () {
      // this.editor.commands.focus()
      // var keyboardEvent = document.createEvent('KeyboardEvent')
      // var initMethod = typeof keyboardEvent.initKeyboardEvent !== 'undefined' ? 'initKeyboardEvent' : 'initKeyEvent'

      // keyboardEvent[initMethod](
      //   'keydown', // event type: keydown, keyup, keypress
      //   true, // bubbles
      //   true, // cancelable
      //   window, // view: should be window
      //   true, // ctrlKey
      //   false, // altKey
      //   false, // shiftKey
      //   false, // metaKey
      //   86, // keyCode: unsigned long - the virtual key code, else 0
      //   0 // charCode: unsigned long - the Unicode character associated with the depressed key, else 0
      // )
      // document.dispatchEvent(keyboardEvent)
      this.addToast({
        title: this.$t('message|use_ctrl_v'),
        color: 'black',
        snackbarColor: 'info'
      })
    },
    onFocusEditor () {
      this.editor.commands.focus()
    },
    panelWidth () {
      const element = document.getElementById('panel')
      return element.scrollWidth.toString() + 'px'
    },
    changeSuggestionLanguage (lang) {
      const settingsFields = Object.entries(this.settings.fields)
      this.editor.view.state.doc.descendants((node, pos) => {
        if (node.type.name === 'mention') {
          const match = settingsFields.find(f => {
            return f[1].label[node.attrs.language] === node.attrs.label
          })
          if (match && match[1].label[lang]) {
            this.editor.commands.setNodeSelection(pos)
            this.editor.commands.updateAttributes('mention', {
              label: match[1].label[lang],
              language: lang
            })
          }
        }
      })
      EventBus.$emit('refresh-view')
    },
    async saveAs () {
      const res = await this.$dialog.prompt({
        title: this.$t('actions|save_template_as'),
        text: this.$t('cases|enter_name'),
        showClose: false,
        textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
          outlined: true,
          dense: true,
          type: 'text',
          class: 'mt-5'
        }
      })
      if (res) {
        if (this.template && !this.caseData && !this.currentCaseForm) {
          const _id = this.template._id
          const payload = {
            templateName: res
          }
          try {
            await this.saveAsTemplate({ _id, payload })
            this.addToast({
              title: this.$t('message|template_save_success'),
              color: 'white',
              snackbarColor: 'success'
            })
          } catch (error) {
            this.addToast({
              title: this.$t('error|template_save_fail'),
              color: 'white',
              snackbarColor: 'error'
            })
            console.error(error, 'error')
          }
        }
        if (this.template && this.caseData && !this.currentCaseForm) {
          const _id = this.caseData._id
          const workbenchId = this.workbench._id
          const templateId = this.template._id
          const payload = {
            templateName: res
          }
          try {
            await this.saveAsTemplateInCase({ _id, workbenchId, templateId, payload })
            this.addToast({
              title: this.$t('message|template_save_success'),
              color: 'white',
              snackbarColor: 'success'
            })
          } catch (error) {
            this.addToast({
              title: this.$t('error|template_save_fail'),
              color: 'white',
              snackbarColor: 'error'
            })
            console.error(error, 'error')
          }
        }
        if (this.template && !this.caseData && this.currentCaseForm) {
          const _id = this.currentCaseForm._id
          const workbenchId = this.workbench._id
          const templateId = this.template._id
          const payload = {
            templateName: res
          }
          try {
            await this.saveAsTemplateInCaseForm({ _id, workbenchId, templateId, payload })
            this.addToast({
              title: this.$t('message|template_save_success'),
              color: 'white',
              snackbarColor: 'success'
            })
          } catch (error) {
            this.addToast({
              title: this.$t('error|template_save_fail'),
              color: 'white',
              snackbarColor: 'error'
            })
            console.error(error, 'error')
          }
        }
      }
    },
    saveInfo () {
      this.addToast({
        title: this.$t('message|document_already_saved'),
        color: 'white',
        snackbarColor: 'info'
      })
    },
    async applySelectedData (actualTextSelection, selectedDataLabel, newCustomLabel) {
      const _id = this.actualDocument._id
      const payload = {
        extractedData: actualTextSelection,
        newExtractedData: true,
        label: newCustomLabel ? selectedDataLabel.split(' ').join('_') : selectedDataLabel.fieldKey ? selectedDataLabel.fieldKey : selectedDataLabel.name.split(' ').join('_'),
        inCase: this.options.inCase,
        caseId: this.caseData ? this.caseData._id : null
      }
      try {
        await this.updateDocument({ _id, payload })
        this.menu = false
        this.actualTextSelection = null
        this.selectedDataItem = null
      } catch (error) {
        console.error(error)
      }
    },
    onCollaborationFeedback (details) {
      if (details.type === 'saved_content') this.loadingSavingData = false
      else if (details.type === 'error') {
        this.addToast({
          title: this.$t('error|document_not_saved'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async updateStorage (item) {
      if (item && item.createdBy && this.readOnlyOption) {
        try {
          const connection = new autobahn.Connection({
            url: process.env.VUE_APP_CROSSBAR_PATH,
            realm: process.env.VUE_APP_CROSSBAR_REALM
          })
          let itemId = null
          let collectionName = null
          let _createdBy = null
          if (item.createdBy._id && item.createdBy._id.length) _createdBy = item.createdBy._id
          else _createdBy = item.createdBy
          if (item.onCreatedBy === 'Group' && this.company) {
            collectionName = 'Company'
            const group = this.company.groups.find(g => g._id === _createdBy)
            if (group) {
              itemId = group.belongsToCompany._id ? group.belongsToCompany._id : group.belongsToCompany
            }
          } else {
            collectionName = item.onCreatedBy
            itemId = item.createdBy._id ? item.createdBy._id : item.createdBy
          }
          connection.onopen = async (session) => {
            try {
              await session.call('lavvira.watchman_light.storage_updater', [itemId, collectionName])
            } catch (error) {
              console.error(error, 'error update storage')
            }
          }
          connection.onclose = function (reason, details) {
            // handle connection lost
            console.log(reason, details, 'connection.onclose')
          }
          connection.open()
        } catch (error) {
          console.error(error, 'error autobahn')
        }
      }
    },
    resetEditorComponentData () {
      this.headings = []
      this.selectedColor = ''
      this.drawer = true
      this.mini = false
      this.selectedText = this.selectionInText || ''
      this.searchResults = []
      this.icons = {
        strikeIcon: mdiFormatStrikethrough,
        underlineIcon: mdiFormatUnderline,
        cog: mdiCog
      }
      this.editorInstance = {
        editor: null
      }
      this.editor = null
      this.suggestionQuery = null
      this.navigatedUserIndex = 0
      this.editorJSON = null
      this.editorHTML = null
      this.suggestionListVisibility = false
      this.editorAddons = {
        insertTable: { ...defaultInsertTable }
      }
      this.provider = null
      this.ydoc = null
      this.ymap = null
      this.historyYdoc = null
      this.actualUsers = []
      this.actualJsonContent = null
      this.wordsAmountData = null
      this.actualFontSize = null
      this.commentDialog = false
      this.from = null
      this.to = null
      this.slider = localStorage.getItem('editor-slider') || 75
      this.menu = false
      this.domElement = null
      this.menuTop = null
      this.actualTextSelection = null
      this.selectedDataItem = null
      this.loadingSavingData = false
      this.selectedLanguage = localStorage.getItem('preferedLanguage')
      this.actualUserLanguage = localStorage.getItem('preferedLanguage')
      this.connectionStatus = null
      this.reconnectAttempts = 0
      this.persistence = null
    }
  }
}
</script>

<style lang="scss">
  :root {
    // --font-size: inherit;
    --font-family: inherit;
    --font-size-comment: inherit;
    --font-family-comment: inherit;
  }
  $color-black: #333;
  $color-white: white;
  .editor-content-container {
    background-color: #eee;
    /* top, right, bottom, left */
    padding: 4% 28% 4% 8% !important;
    overflow-y: auto;
    > .editor {
       > .editor-content-wrapper {
        position: relative;
        min-width: 80vh;
        background-color: $color-white;
      }
    }
  }
  .editor__content {
    color: $color-black;
    position: relative;
    /* top, right, bottom, left */
    padding: 10% 10% 4% 15% !important;
    min-height: 95vh;
  }
  .editor-content-container-read-only {
    background-color: #eee;
    height: 800px;
    /* top, right, bottom, left */
    padding: 0% 8% 1% 8% !important;
    margin: 0% 0% 0% 0% !important;
    overflow-y: auto;
    > .editor {
      display: flex;
      flex-direction: column;
       > .editor-content-wrapper {
        position: relative;
        background-color: rgb(255, 255, 255);
      }
    }
  }
  .ProseMirror {
    outline: none!important;
    > * + * {
      margin-top: 0.75em;
    }

    ul,
    ol {
      padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
    }

    strong {
      font-weight: 600;
    }

    code {
      background-color: rgba(#616161, 0.1);
      color: #616161;
    }

    pre {
      background: #0D0D0D;
      color: #FFF;
      font-family: 'JetBrainsMono', monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    blockquote {
      padding-left: 1rem;
      border-left: 2px solid rgba(#0D0D0D, 0.1);
    }

    hr {
      border: none;
      border-top: 2px solid rgba(#0D0D0D, 0.1);
      margin: 2rem 0;
    }

    .mention {
      display: inline;
      position: relative;
      color: #995cfc;
      background-color: white;
      padding: 0;
      font-family:  var(--font-family);
      // font-size: var(--font-size);
      font-weight: bold;
      white-space: normal!important;
      &.view-state {
        background-color: inherit;
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        white-space: normal!important;
        padding: 0;
      }
    }

    .comment {
      display: inline;
      color: inherit;
      font-family: var(--font-family-comment);
      font-size: var(--font-size-comment);
      white-space: normal!important;
      background-color: rgba(#80560a, 0.2);
      border-radius: 0.1rem;
      padding: 0;
      &.resolved {
        background-color: inherit;
        color: inherit;
        font-family: var(--font-family-comment);
        font-size: var(--font-size-comment);
        font-weight: inherit;
        white-space: normal!important;
        padding: 0;
        border-radius: none;
      }
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      // min-width: 0px!important;
      margin: 0;
      overflow: hidden;

      td {
        border: 2px solid #ced4da;
      }
      th {
        min-width: 1em;
        border: 2px solid #ced4da;
        padding: 3px 5px;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;

        > * {
          margin-bottom: 0;
        }
      }

      th {
        font-weight: bold;
        text-align: left;
        background-color: #f1f3f5;
      }
      .tableWrapper {
        padding: 1rem 0;
        overflow-x: auto;
      }
    }
  }
  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
  p.drop-cap::first-letter {
    font-family: inherit;
    font-weight: 900;
    font-size: 56px;
    float: left;
    line-height: 1;
    margin-right: 9px;
  }
  .suggestion-list {
    padding: 0.2rem;
    border: 2px solid rgba($color-black, 0.1);
    font-size: 0.8rem;
    font-weight: bold;
    &__no-results {
      padding: 0.2rem 0.5rem;
    }
    &__item {
      border-radius: 5px;
      padding: 0.2rem 0.5rem;
      margin-bottom: 0.2rem;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
      &.is-selected,
      &:hover {
        background-color: rgba($color-white, 0.2);
      }
      &.is-empty {
        opacity: 0.5;
      }
    }
  }
  .underline {
    text-decoration: underline;
  }
  .sticky {
    position: sticky;
    top: 550px;
  }
</style>

<style lang="scss">
/* Give a remote user a caret */
.collaboration-cursor__caret {
  position: relative;
  margin-left: -1px;
  margin-right: -1px;
  border-left: 1px solid #0D0D0D;
  border-right: 1px solid #0D0D0D;
  word-break: normal;
  pointer-events: none;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  position: absolute;
  top: -1.4em;
  left: 1px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  user-select: none;
  color: #0D0D0D;
  padding: 0.1rem 0.1rem 0.1rem 1.4rem;
  border-radius: 3px 3px 3px 0;
  white-space: nowrap;
}

.collaboration-cursor__avatar {
  position: absolute;
  left: 0.1rem;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.collaboration-cursor__avatar:hover {
  width: 35px;
  height: 35px;
}
.dropup {
  position: relative;
  display: inline-block;
}
</style>
