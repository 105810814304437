import axios from '@/plugins/axios'
import store from '../index'

const SET_LOADING = 'SET_LOADING'
const UPDATE_QUESTIONNAIRE_CASE = 'UPDATE_QUESTIONNAIRE_CASE'
const UPDATE_QUESTIONNAIRE_TEMPLATE = 'UPDATE_QUESTIONNAIRE_TEMPLATE'
const UPDATE_QUESTIONNAIRE_CASE_MODEL = 'UPDATE_QUESTIONNAIRE_CASE_MODEL'

const mutations = {
  [SET_LOADING] (state, value) {
    state.loading = value
  },
  [UPDATE_QUESTIONNAIRE_TEMPLATE] (state, { questionnaire, templateId }) {
    const _templateIndex = store.state.templates.templates.findIndex(_t => _t._id === templateId)
    const _companyTemplateIndex = store.state.companyTemplates.companyTemplates.findIndex(_t => _t._id === templateId)
    const _groupTemplateIndex = store.state.groupTemplates.groupTemplates.findIndex(_t => _t._id === templateId)
    if (_templateIndex !== -1) {
      store.state.templates.templates[_templateIndex].questionnaire = questionnaire
    }
    if (_companyTemplateIndex !== -1) {
      store.state.companyTemplates.companyTemplates[_companyTemplateIndex].questionnaire = questionnaire
    }
    if (_groupTemplateIndex !== -1) {
      store.state.groupTemplates.groupTemplates[_groupTemplateIndex].questionnaire = questionnaire
    }
  },
  [UPDATE_QUESTIONNAIRE_CASE] (state, { questionnaire, caseId, workbenchId, templateId, updateRequiredDocuments, requiredDocuments }) {
    const _caseIndex = store.state.cases.cases.findIndex(_c => _c._id === caseId)
    const _companyCaseIndex = store.state.companyCases.companyCases.findIndex(_c => _c._id === caseId)
    const _groupCaseIndex = store.state.groupCases.groupCases.findIndex(_c => _c._id === caseId)
    if (_caseIndex !== -1) {
      const workbenchIndex = store.state.cases.cases[_caseIndex].workbenches.findIndex(wb => wb._id === workbenchId)
      if (workbenchIndex !== -1) {
        if (updateRequiredDocuments && requiredDocuments && requiredDocuments.length) {
          store.state.cases.cases[_caseIndex].workbenches[workbenchIndex].requiredDocuments = requiredDocuments
        }
        const templateIndex = store.state.cases.cases[_caseIndex].workbenches[workbenchIndex].templates.findIndex(temp => temp._id === templateId)
        if (templateIndex !== -1) {
          store.state.cases.cases[_caseIndex].workbenches[workbenchIndex].templates[templateIndex].questionnaire = questionnaire
        }
      }
    }
    if (_companyCaseIndex !== -1) {
      const companyCaseWorkbenchIndex = store.state.companyCases.companyCases[_companyCaseIndex].workbenches.findIndex(wb => wb._id === workbenchId)
      if (companyCaseWorkbenchIndex !== -1) {
        const companyCaseTemplateIndex = store.state.companyCases.companyCases[_companyCaseIndex].workbenches[companyCaseWorkbenchIndex].templates.findIndex(temp => temp._id === templateId)
        if (companyCaseTemplateIndex !== -1) {
          store.state.companyCases.companyCases[_companyCaseIndex].workbenches[companyCaseWorkbenchIndex].templates[companyCaseTemplateIndex].questionnaire = questionnaire
        }
      }
    }
    if (_groupCaseIndex !== -1) {
      const groupCaseWorkbenchIndex = store.state.groupCases.groupCases[_groupCaseIndex].workbenches.findIndex(wb => wb._id === workbenchId)
      if (groupCaseWorkbenchIndex !== -1) {
        const groupCaseTemplateIndex = store.state.groupCases.groupCases[_groupCaseIndex].workbenches[groupCaseWorkbenchIndex].templates.findIndex(temp => temp._id === templateId)
        if (groupCaseTemplateIndex !== -1) {
          store.state.groupCases.groupCases[_groupCaseIndex].workbenches[groupCaseWorkbenchIndex].templates[groupCaseTemplateIndex].questionnaire = questionnaire
        }
      }
    }
  },
  [UPDATE_QUESTIONNAIRE_CASE_MODEL] (state, { questionnaire, caseModelId, workbenchId, templateId }) {
    const _caseFormIndex = store.state.caseForms.caseForms.findIndex(_cf => _cf._id === caseModelId)
    const _companyCaseFormIndex = store.state.companyCaseForms.companyCaseForms.findIndex(_cf => _cf._id === caseModelId)
    const _groupCaseFormIndex = store.state.groupCaseForms.groupCaseForms.findIndex(_cf => _cf._id === caseModelId)
    if (_caseFormIndex !== -1) {
      const workbenchIndex = store.state.caseForms.caseForms[_caseFormIndex].workbenches.findIndex(wb => wb._id === workbenchId)
      if (workbenchIndex !== -1) {
        const templateIndex = store.state.caseForms.caseForms[_caseFormIndex].workbenches[workbenchIndex].templates.findIndex(temp => temp._id === templateId)
        if (templateIndex !== -1) {
          store.state.caseForms.caseForms[_caseFormIndex].workbenches[workbenchIndex].templates[templateIndex].questionnaire = questionnaire
        }
      }
    }
    if (_companyCaseFormIndex !== -1) {
      const companyCaseFormWorkbenchIndex = store.state.companyCaseForms.companyCaseForms[_companyCaseFormIndex].workbenches.findIndex(wb => wb._id === workbenchId)
      if (companyCaseFormWorkbenchIndex !== -1) {
        const companyCaseFormTemplateIndex = store.state.companyCaseForms.companyCaseForms[_companyCaseFormIndex].workbenches[companyCaseFormWorkbenchIndex].templates.findIndex(temp => temp._id === templateId)
        if (companyCaseFormTemplateIndex !== -1) {
          store.state.companyCaseForms.companyCaseForms[_companyCaseFormIndex].workbenches[companyCaseFormWorkbenchIndex].templates[companyCaseFormTemplateIndex].questionnaire = questionnaire
        }
      }
    }
    if (_groupCaseFormIndex !== -1) {
      const groupCaseFormWorkbenchIndex = store.state.groupCaseForms.groupCaseForms[_groupCaseFormIndex].workbenches.findIndex(wb => wb._id === workbenchId)
      if (groupCaseFormWorkbenchIndex !== -1) {
        const groupCaseFormTemplateIndex = store.state.groupCaseForms.groupCaseForms[_groupCaseFormIndex].workbenches[groupCaseFormWorkbenchIndex].templates.findIndex(temp => temp._id === templateId)
        if (groupCaseFormTemplateIndex !== -1) {
          store.state.groupCases.groupCases[_groupCaseFormIndex].workbenches[groupCaseFormWorkbenchIndex].templates[groupCaseFormTemplateIndex].questionnaire = questionnaire
        }
      }
    }
  }
}

const state = () => {
  return {
    loading: false
  }
}

const actions = {
  updateQuestionnaireInTemplate ({ commit }, { _id, templateId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateTemplateQuestionnaire/${_id}/${templateId}`, payload)
        const questionnaire = data.data
        commit(UPDATE_QUESTIONNAIRE_TEMPLATE, { questionnaire, templateId })
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateQuestionnaireInCase ({ commit }, { _id, caseId, workbenchId, templateId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCaseQuestionnaire/${_id}/${caseId}/${workbenchId}/${templateId}`, payload)
        const questionnaire = data.data
        if ((data.addNewField || data.deleteField || data.updateFieldContent) && data.fieldType === 'file-input') {
          const requiredDocuments = data.requiredDocuments
          const updateRequiredDocuments = true
          commit(UPDATE_QUESTIONNAIRE_CASE, { questionnaire, caseId, workbenchId, templateId, updateRequiredDocuments, requiredDocuments })
        } else {
          commit(UPDATE_QUESTIONNAIRE_CASE, { questionnaire, caseId, workbenchId, templateId })
        }
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateQuestionnaireInCaseModel ({ commit }, { _id, caseModelId, workbenchId, templateId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCaseModelQuestionnaire/${_id}/${caseModelId}/${workbenchId}/${templateId}`, payload)
        const questionnaire = data.data
        commit(UPDATE_QUESTIONNAIRE_CASE_MODEL, { questionnaire, caseModelId, workbenchId, templateId })
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  }
}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions
}
