import axios from '@/plugins/axios'
import Vue from 'vue'
import store from '../index'
import autobahn from 'autobahn'

const INIT = 'INIT'
const SET_GROUP_TEMPLATES = 'SET_GROUP_TEMPLATES'
const ADD_GROUP_TEMPLATE = 'ADD_GROUP_TEMPLATE'
const ADD_COMMENT = 'ADD_COMMENT'
const UPDATE_GROUP_TEMPLATE = 'UPDATE_GROUP_TEMPLATE'
const DELETE_GROUP_TEMPLATE = 'DELETE_GROUP_TEMPLATE'
const DELETE_COMMENT = 'DELETE_COMMENT'
const SET_TEMPLATE_ID = 'SET_TEMPLATE_ID'
const SET_LOADING = 'SET_LOADING'
const SET_GROUP_SCELETON_CARD = 'SET_GROUP_SCELETON_CARD'
const REMOVE_GROUP_SCELETON_CARD = 'REMOVE_GROUP_SCELETON_CARD'
const RESET_GROUP_TEMPLATES = 'RESET_GROUP_TEMPLATES'

const state = () => {
  return {
    init: false,
    templateId: null,
    groupTemplates: [],
    groupTemplatesSubscriptions: [],
    loading: false
  }
}

const mutations = {
  [INIT] (state) {
    state.init = true
  },
  [SET_GROUP_TEMPLATES] (state, payload) {
    state.groupTemplates.push(...payload)
  },
  [ADD_GROUP_TEMPLATE] (state, payload) {
    const index = state.groupTemplates.findIndex(t => t._id === payload._id)
    if (index === -1) {
      state.groupTemplates.push(payload)
    }
  },
  [ADD_COMMENT] (state, payload) {
    const index = state.groupTemplates.findIndex(t => t._id === payload._id)
    Vue.set(state.groupTemplates, index, payload)
  },
  [UPDATE_GROUP_TEMPLATE] (state, payload) {
    const index = state.groupTemplates.findIndex(t => t._id === payload._id)
    Vue.set(state.groupTemplates, index, payload)
  },
  [DELETE_GROUP_TEMPLATE] (state, payload) {
    const index = state.groupTemplates.findIndex(t => t._id === payload._id)
    if (index !== -1) {
      state.groupTemplates.splice(index, 1)
    }
  },
  [DELETE_COMMENT] (state, payload) {
    const index = state.groupTemplates.findIndex(t => t._id === payload._id)
    Vue.set(state.groupTemplates, index, payload)
  },
  [SET_TEMPLATE_ID] (state, id) {
    state.templateId = id
  },
  [SET_LOADING] (state, value) {
    state.loading = value
  },
  [SET_GROUP_SCELETON_CARD] (state, sceleton) {
    state.groupTemplates.push(sceleton)
  },
  [REMOVE_GROUP_SCELETON_CARD] (state) {
    const index = state.groupTemplates.findIndex(t => t.name === 'loading')
    state.groupTemplates.splice(index, 1)
  },
  [RESET_GROUP_TEMPLATES] (state) {
    state.groupTemplates = []
    state.groupTemplatesSubscriptions = []
  }
}

const actions = {
//   fetchCompanyTemplates ({ commit, state }, shouldInit = false) {
//     if (shouldInit) {
//       commit(INIT)
//     } else {
//       if (state.init) return
//     }
//     return new Promise(async (resolve) => {
//       try {
//         const { data } = await axios.get('/getSharedTemplates')
//         commit(SET_TEMPLATES, data.data)
//       } catch (e) {
//         console.error(e, 'fetchTemplates ERROR')
//       } finally {
//         resolve()
//       }
//     })
//   },
  fetchGroupTemplates ({ commit }, { _id }) {
    return new Promise(async (resolve) => {
      try {
        const { data } = await axios.get(`/getSharedTemplates/${_id}`)
        // for (let i = 0; i < data.data.length; i++) {
        //   const template = data.data[i]
        //   commit(SET_GROUP_TEMPLATES, template)
        // }
        commit(SET_GROUP_TEMPLATES, data.data)
      } catch (e) {
        console.error(e, 'fetchTemplates ERROR')
      } finally {
        resolve()
      }
    })
  },
  addGroupTemplate ({ commit, rootState }, payload = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const routeName = rootState.account.account.accountType === 'god' ? 'createSharedTemplate' : 'createTemplate'
        const { data } = await axios.post(`/${routeName}`, payload)
        if (data.success) {
          commit(ADD_GROUP_TEMPLATE, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  addMyGroupComment ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/addComment/${_id}`, payload)
        commit(ADD_COMMENT, data.data)
        resolve()
      } catch (e) {
        console.error(e, 'updateTemplate ERROR')
        reject(e)
      }
    })
  },
  replyToGroupComment ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/replyComment/${_id}`, payload)
        commit(ADD_COMMENT, data.data)
        resolve()
      } catch (e) {
        console.error(e, 'updateTemplate ERROR')
        reject(e)
      }
    })
  },
  updateMyGroupComment ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateComment/${_id}`, payload)
        commit(ADD_COMMENT, data.data)
        resolve()
      } catch (e) {
        console.error(e, 'updateComment ERROR')
        reject(e)
      }
    })
  },
  updateGroupTemplate ({ commit }, { _id, payload } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateTemplate/${_id}`, payload)
        if (data.success) {
          commit(UPDATE_GROUP_TEMPLATE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        console.error(e, 'updateTemplate ERROR')
        reject(e)
      }
    })
  },
  deleteGroupTemplate ({ commit }, payload = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/deleteTemplate/${payload._id}`)
        commit(DELETE_GROUP_TEMPLATE, data.data)
        resolve()
      } catch (e) {
        console.error(e, 'deleteTemplate ERROR')
        reject(e)
      }
    })
  },
  // deleteMyComment ({ commit }, { _id, payload } = {}) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const { data } = await axios.post(`/deleteComment/${_id}`, payload)
  //       commit(DELETE_COMMENT, data.data)
  //       resolve()
  //     } catch (e) {
  //       console.error(e, 'deleteComment ERROR')
  //       reject(e)
  //     }
  //   })
  // },
  deleteGroupCategoryInTemplate ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateTemplate/${_id}/deleteTemplateCategory`, payload)
        if (data.success) {
          commit(UPDATE_GROUP_TEMPLATE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  groupTemplatesUpdatesSubscription ({ commit }, { _id }) {
    try {
      const connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })

      connection.onopen = function (session) {
        // call a remote procedure
        session.subscribe(`lavvira.updates.${_id}`, async (...args) => {
          const templateTopic = args[0].includes('Template')
          const createTask = args[0].includes('create')
          const updateTask = args[0].includes('update')
          const deleteTask = args[0].includes('delete')
          const removeTask = args[0].includes('remove')
          if (templateTopic && updateTask) {
            const template = store.state.groupTemplates.groupTemplates.find(t => t._id === args[0][0]._id)
            if (template) {
              commit(UPDATE_GROUP_TEMPLATE, args[0][0])
            } else {
              // implemet 'share task'!!! to be clear
              commit(ADD_GROUP_TEMPLATE, args[0][0])
            }
          } else if (templateTopic && createTask) {
            commit(ADD_GROUP_TEMPLATE, args[0][0])
          } else if (templateTopic && deleteTask) {
            commit(DELETE_GROUP_TEMPLATE, args[0][0])
          } else if (templateTopic && removeTask) {
            let groupIds = []
            let sharedArray = []
            for (let i = 0; i < store.state.company.company.groups.length; i++) {
              const group = store.state.company.company.groups[i]
              groupIds.push(group._id)
            }
            groupIds.forEach(groupId => {
              const stillShared = args[0][0].sharedWith.groups.findIndex(g => g._id === groupId)
              sharedArray.push(stillShared)
            })
            if (sharedArray.some((index) => index >= 0)) {
              commit(UPDATE_GROUP_TEMPLATE, args[0][0])
            } else {
              commit(DELETE_GROUP_TEMPLATE, args[0][0])
            }
          }
        }).then(
          function (subscription) {
            store.state.groupTemplates.groupTemplatesSubscriptions.push(subscription)
          }
        )
      }

      connection.onclose = function (reason, details) {
      // handle connection lost
        console.error(reason, details, 'connection.onclose')
      }
      connection.open()
    } catch (e) {
      console.error(e, 'ERROR in autobahn connection')
    }
  },
  usnsubscribeGroupTemplatesUpdates ({ commit }, sub) {
    sub.unsubscribe().then(
      function (gone) {
        if (gone) commit(RESET_GROUP_TEMPLATES)
        // successfully unsubscribed
      },
      function (error) {
        // unsubscribe failed
        console.error(error)
      }
    )
  },
  setCompanyTemplateId ({ commit }, _id) {
    commit(SET_TEMPLATE_ID, _id)
  },
  setGroupSceletonCard ({ commit }, { sceleton }) {
    commit(SET_GROUP_SCELETON_CARD, sceleton)
  },
  deleteGroupSceleton ({ commit }) {
    commit(REMOVE_GROUP_SCELETON_CARD)
  },
  resetGroupTemplates ({ commit }) {
    commit(RESET_GROUP_TEMPLATES)
  }
}

const getters = {
  getCurrentCompanyTemplate: (state) => {
    if (state.templateId) {
      return state.companyTemplates.find(c => c._id === state.templateId)
    }
    return null
  }
}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions,
  getters
}
