<template>
  <v-card
    width="60%"
    height="100%"
    class="ma-auto"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title
          class="font-weight-bold"
        >
          Dark Mode
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-switch v-model="$vuetify.theme.dark" />
      </v-list-item-action>
    </v-list-item>
    <v-divider />
    <v-card-text>
      <v-card
        v-for="(_theme, index) in themes"
        :key="index"
        class="my-2"
        hover
        outlined
        :disabled="$vuetify.theme.themes.name === _theme.name"
        @click="setTheme(_theme)"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ _theme.name }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-avatar
              v-if="$vuetify.theme.themes.name === _theme.name"
              color="success"
              size="30"
            >
              <v-icon>mdi-check</v-icon>
            </v-avatar>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ThemeChanger',
  props: {
    theme: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    menu: false,
    themes: [
      {
        name: 'Lake',
        dark: {
          primary: '#4b5056',
          secondary: '#234e79',
          accent: '#3e8bbc',
          accent2: '#d9d9d9',
          accent3: '#a9a9a9',
          error: '#EF322E',
          info: '#2196F3',
          success: '#40E0D0',
          warning: '#FFC107'
        },
        light: {
          primary: '#234e79',
          accent: '#ecb652',
          accent2: '#2b5f94',
          accent3: '#e1f5fe ',
          secondary: '#3e8bbc',
          success: '#a5d64c',
          info: '#ff53d0',
          warning: '#ff8e00',
          error: '#b0341c',
          background: '#FFFFFF'
        }
      },
      {
        name: 'Earth',
        dark: {
          primary: '#E65100',
          accent: '#7CB342',
          accent2: '#fc610d',
          secondary: '#689F38',
          success: '#4CAF50',
          info: '#6156d8',
          warning: '#1565C0',
          error: '#FF7043'
        },
        light: {
          primary: '#8e8741',
          secondary: '#659ebc',
          accent: '#659ebc',
          accent2: '#daad86',
          error: '#f44336',
          warning: '#ff5722',
          info: '#2196f3',
          success: '#cddc39',
          background: '#FFFFFF'
        }
      },
      {
        name: 'Deep',
        dark: {
          primary: '#230064',
          accent: '#FFCA28',
          secondary: '#607D8B',
          success: '#FFEB3B',
          info: '#2196F3',
          warning: '#9C27B0',
          error: '#B71C1C'
        },
        light: {
          primary: '#230064',
          accent: '#f3bec4',
          accent2: '#d7aa71',
          secondary: '#7ea4b6',
          success: '#ffea70',
          info: '#229eff',
          warning: '#e239ff',
          error: '#e82424',
          background: '#FFFFFF'
        }
      }
    ]
  }),
  created () {
    if (this.theme) {
      this.setTheme(this.theme)
    }
  },
  methods: {
    setTheme (theme) {
      // close menu
      this.$emit('changeTheme', theme)
      this.menu = false
      const name = theme.name
      const dark = theme.dark
      const light = theme.light
      // set themes
      Object.keys(dark).forEach(i => {
        this.$vuetify.theme.themes.dark[i] = dark[i]
      })
      Object.keys(light).forEach(i => {
        this.$vuetify.theme.themes.light[i] = light[i]
      })
      // also save theme name to disable selection
      this.$vuetify.theme.themes.name = name
    }
  }
}
</script>

<style scoped></style>
