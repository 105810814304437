<template>
  <v-card
    :flat="isFlat"
    :height="listHeight"
    class="document-list mb-1"
  >
    <v-toolbar
      v-if="toolbar"
      flat
      max-height="64px"
    >
      <v-text-field
        v-model="_search"
        dense
        class="mt-2"
        :label="searchLabel"
        solo-inverted
        rounded
        prepend-inner-icon="mdi-magnify"
      >
        <div
          slot="label"
          style="font-size: 14px;"
        >
          {{ $t('actions|search_documents') }}:
        </div>
      </v-text-field>

      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        color="accent"
      />

      <v-btn
        v-if="toolbar.hasClose"
        style="position: absolute; right: 15px; bottom: 43px;"
        icon
        @click="$emit('toolbarClose')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <template v-if="documents.length || companyDocuments.length || groupDocuments.length || _workbenchDocuments.length">
      <div
        v-if="!rightPanelCase && !rightPanelWorkbench && !fromWorkbench && !activeFolderObject"
      >
        <v-expand-transition>
          <div v-show="selectedSource && selectedSource.includes($t('documents|my_documents'))">
            <v-subheader>{{ $t('folders|folders') }}</v-subheader>
            <v-list-item-group
              v-model="selectedFolder"
              color="primary"
            >
              <v-list-item
                v-for="(item, i) in filteredFolders"
                :key="i"
                @click="openFolder(item)"
              >
                <v-list-item-icon>
                  <v-icon>
                    mdi-folder-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.raw.originalname }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>

            <v-subheader>
              {{ $t('documents|my_documents') }}
            </v-subheader>
            <v-list-item-group
              v-if="listGroup"
              v-model="_listGroup"
              multiple
              active-class=""
              class="document-list-container app-scrollbar rounded"
              style="overflow-x: hidden;"
            >
              <template v-for="(doc, index) in documents">
                <document-item
                  :key="doc._id"
                  :document="doc"
                  :has-doc-icons="true"
                  :has-selection="true"
                  :has-actions="true"
                  :in-case="inCase"
                  :in-dialog="true"
                  @click.native="$emit('click', doc)"
                />

                <v-divider
                  v-if="index < documents.length - 1"
                  :key="index"
                />
              </template>
            </v-list-item-group>
            <div
              v-else
              class="ml-4"
              style="font-size: 15px;"
            >
              {{ $t('expressions|no_available_documents') }}
            </div>
          </div>
        </v-expand-transition>

        <div v-if="company">
          <v-expand-transition>
            <div
              v-show="selectedSource && selectedSource.includes(company.companyData.companyName)"
            >
              <v-subheader>
                {{ $t('documents|documents') + ' ' + $t('common|from') + ' ' + company.companyData.companyName }}
              </v-subheader>
              <v-list-item-group
                v-if="companyDocuments.length"
                v-model="_selectedCompanyDocuments"
                multiple
                active-class=""
                class="document-list-container app-scrollbar"
              >
                <template v-for="(doc, index) in companyDocuments">
                  <document-item
                    :key="doc._id"
                    :document="doc"
                    :has-doc-icons="true"
                    :has-selection="true"
                    :has-actions="true"
                    :in-case="inCase"
                    :in-dialog="true"
                    @click.native="$emit('click', doc)"
                  />

                  <v-divider
                    v-if="index < companyDocuments.length - 1"
                    :key="index"
                  />
                </template>
              </v-list-item-group>
              <div
                v-else
                class="ml-4"
                style="font-size: 15px;"
              >
                {{ $t('expressions|no_available_documents') }}
              </div>
            </div>
          </v-expand-transition>
        </div>

        <div
          v-for="(group, i) in availableGroups"
          :key="i"
        >
          <v-expand-transition>
            <div v-show="selectedSource && selectedSource.includes(group)">
              <v-subheader>
                {{ $t('documents|documents') + ' ' + $t('common|from') + ' ' + group }}
              </v-subheader>
              <v-list-item-group
                v-if="selectGroupDocuments(group).length"
                v-model="_selectedGroupDocuments"
                multiple
                active-class=""
                class="document-list-container app-scrollbar"
              >
                <template v-for="(doc, index) in selectGroupDocuments(group)">
                  <document-item
                    :key="doc._id"
                    :document="doc"
                    :has-doc-icons="true"
                    :has-selection="true"
                    :has-actions="true"
                    :in-case="inCase"
                    :in-dialog="true"
                    @click.native="$emit('click', doc)"
                  />

                  <v-divider
                    v-if="index < selectGroupDocuments.length - 1"
                    :key="index"
                  />
                </template>
              </v-list-item-group>
              <div
                v-else
                class="ml-4"
                style="font-size: 15px;"
              >
                {{ $t('expressions|no_available_documents') }}
              </div>
            </div>
          </v-expand-transition>
        </div>
      </div>

      <div v-if="activeFolderObject">
        <DocumentsTableNav
          :route-elements="routeElements"
          :container-id="'NAV-TABLE'"
          :active-folder-object="activeFolderObject"
          @router-action="onRouterAction"
        />
        <template>
          <v-list-item-group
            v-model="selectedFolder"
            color="primary"
          >
            <v-list-item
              v-for="(item, i) in activeFolderObject.subfolders"
              :key="i"
              @click="openFolder(item)"
            >
              <v-list-item-icon>
                <v-icon>
                  mdi-folder-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.raw.originalname }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </template>

        <v-list-item-group
          v-model="_activeFolderDocuments"
          multiple
          active-class=""
          class="document-list-container app-scrollbar"
        >
          <template v-for="(d, num) in activeFolderObject.documents">
            <document-item
              :key="d._id"
              :document="d"
              :has-doc-icons="true"
              :has-selection="true"
              :has-actions="true"
              :in-case="inCase"
              :in-dialog="true"
            />

            <v-divider
              v-if="num < activeFolderObject.documents - 1"
              :key="num"
            />
          </template>
        </v-list-item-group>
      </div>

      <div v-if="fromWorkbench">
        <v-subheader>
          {{ $t('mixed|actual_case_documents').toUpperCase() }}
        </v-subheader>
        <v-list-item-group
          v-if="listGroup"
          v-model="_listGroup"
          multiple
          active-class=""
          class="document-list-container app-scrollbar"
        >
          <template v-for="(doc, index) in documents">
            <document-item
              :key="doc._id"
              :document="doc"
              :has-doc-icons="true"
              :has-selection="true"
              :has-actions="true"
              :in-case="inCase"
              :in-dialog="true"
              @open-doc="$emit('click', doc)"
            />

            <v-divider
              v-if="index < documents.length - 1"
              :key="index"
            />
          </template>
        </v-list-item-group>
      </div>
    </template>

    <!-- Case documents/right panel -->
    <template v-if="rightPanelCase">
      <div v-if="_documents.length">
        <draggable
          :key="componentKey"
          class="list-group mt-n4"
          :list="_documents"
          handle=".handle-icon"
          :group="{ draggable: '.list-group-item', name: 'docs', pull: 'clone', put: false }"
          @start="onDragStart()"
          @end="onDragEnd()"
        >
          <div
            v-for="(doc, index) in _documents"
            :key="index"
            class="list-group-item"
          >
            <document-list-item-case-panel-right
              :document="doc"
              :index="index"
              :in-case="inCase"
              @open-doc="$emit('click', doc)"
              @deleteAction="$emit('delete', doc)"
            />
          </div>
        </draggable>
      </div>
      <div v-else>
        <v-card-text>
          {{ $t('expressions|no_available_documents') }}
        </v-card-text>
      </div>
    </template>

    <!-- Workbench documents -->
    <template v-if="_workbenchDocuments">
      <draggable
        v-if="rightPanelWorkbench"
        class="list-group"
        :list="_workbenchDocuments"
        group="docs"
        @change="onIncommingDoc"
        @start="onWorkbenchDocumentDrag"
        @end="onWorkbenchDocumentDragEnd"
      >
        <div
          v-for="(doc, index) in _workbenchDocuments"
          :key="doc._id"
          class="list-group-item"
        >
          <document-list-item-case-workbench
            :key="doc._id"
            :document="doc"
            :index="index"
            :in-case="inCase"
            :in-case-workbench="true"
            @open-doc="$emit('click', doc)"
            @deleteAction="$emit('delete', doc)"
          />
        </div>
      </draggable>
    </template>
    <div v-else>
      <v-card-text>
        {{ $t('expressions|no_available_documents') }}
      </v-card-text>
    </div>
    <v-card-actions>
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
import { uniqBy } from '@/utils/utils'
import { EventBus } from '@/utils/EventBus'
import draggable from 'vuedraggable'
import DocumentListItemCasePanelRight from './DocumentListItemCasePanelRight.vue'
import DocumentListItemCaseWorkbench from './DocumentListItemCaseWorkbench.vue'
import DocumentsTableNav from '../../pages/Documents/DocumentsTableNav.vue'

export default {
  components: {
    draggable,
    DocumentListItemCasePanelRight,
    DocumentListItemCaseWorkbench,
    DocumentsTableNav
  },
  props: {
    rightPanelCase: {
      type: Boolean,
      default: false
    },
    rightPanelWorkbench: {
      type: Boolean,
      default: false
    },
    toolbar: {
      type: Object,
      default: () => ({
        hasClose: false
      })
    },
    search: {
      type: String,
      default: null
    },
    searchLabel: {
      type: String,
      default: 'Search'
    },
    documents: {
      type: Array,
      default: () => ([])
    },
    workbenchDocuments: {
      type: Array,
      default: () => ([])
    },
    companyDocuments: {
      type: Array,
      default: () => ([])
    },
    groupDocuments: {
      type: Array,
      default: () => ([])
    },
    personalFolders: {
      type: Array,
      default: () => ([])
    },
    companyFolders: {
      type: Array,
      default: () => ([])
    },
    groupFolders: {
      type: Array,
      default: () => ([])
    },
    loading: {
      type: Boolean,
      default: false
    },
    listGroup: {
      type: Array,
      default: () => ([])
    },
    isFlat: {
      type: Boolean,
      default: false
    },
    inCase: {
      type: Boolean,
      default: false
    },
    fromCaseView: {
      type: Boolean,
      default: false
    },
    fromWorkbench: {
      type: Boolean,
      default: false
    },
    selectedCompanyDocuments: {
      type: Array,
      default: () => ([])
    },
    selectedGroupDocuments: {
      type: Array,
      default: () => ([])
    },
    selectedSource: {
      type: Array,
      default: () => ([])
    },
    company: {
      type: Object,
      default: null
    },
    availableGroups: {
      type: Array,
      default: () => ([])
    },
    listHeight: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      availableItemOptions: {
        group: {
          pull: 'clone',
          put: false
        },
        sort: false
      },
      clonedDragItemOptions: {
        group: 'items'
      },
      componentKey: 1,
      selectedFolder: null,
      activeFolder: false,
      activeFolderObject: null,
      selectedFolderDocuments: [],
      selectedFolderDocumentsIndexes: []
    }
  },
  computed: {
    _search: {
      get () {
        return this.search
      },
      set (value) {
        this.$emit('search', value)
      }
    },
    _listGroup: {
      get () {
        return this.listGroup
      },
      set (value) {
        this.$emit('updateGroup', value)
      }
    },
    _documents: {
      get () {
        return this.documents
      },
      set (value) {
        return value
      }
    },
    _workbenchDocuments: {
      get () {
        return this.workbenchDocuments
      },
      set (value) {
        // this.$store.commit('updateList', value)
      }
    },
    _selectedCompanyDocuments: {
      get () {
        return this.selectedCompanyDocuments
      },
      set (value) {
        this.$emit('updateCompanyDocs', value)
      }
    },
    _selectedGroupDocuments: {
      get () {
        return this.selectedGroupDocuments
      },
      set (value) {
        this.$emit('updateGroupDocs', value)
      }
    },
    _activeFolderDocuments: {
      get () {
        return this.selectedFolderDocumentsIndexes
      },
      set (value) {
        this.selectedFolderDocuments = []
        this.selectedFolderDocumentsIndexes = []
        for (let idx = 0; idx < value.length; idx++) {
          const selectedDocIDX = value[idx]
          this.selectedFolderDocuments.push(this.activeFolderObject.documents[selectedDocIDX])
          this.selectedFolderDocumentsIndexes.push(selectedDocIDX)
        }
        this.$emit('selectedFolderDocuments', this.selectedFolderDocuments, this.activeFolderObject)
      }
    },
    filteredFoldersByPermissions () {
      let actualFolders = []
      if (this.selectedSource && this.selectedSource.includes(this.$t('documents|my_documents'))) {
        if (this.personalFolders && this.personalFolders.length) {
          actualFolders = this.personalFolders
        }
      }
      if (this.company && this.selectedSource && this.selectedSource.includes(this.company.companyData.companyName)) {
        actualFolders = this.companyFolders
      }
      if (this.availableGroups && this.availableGroups.length) {
        for (let i = 0; i < this.availableGroups.length; i++) {
          const group = this.availableGroups[i]
          if (this.selectedSource && this.selectedSource.includes(group.groupName)) {
            for (let i = 0; i < this.groupFolders.length; i++) {
              const groupFol = this.groupFolders[i]
              groupFol.sharedWith.groups.forEach(grId => {
                if (this.group._id === grId) {
                  actualFolders.push(groupFol)
                }
              })
            }
          }
        }
      }
      // if (this.isSharedWithMe) {
      //   const myAccountId = this.account._id
      //   actualFolders = this.sharedWithMeFolders
      //   return actualFolders.filter(function (fol) {
      //     const is = (typeof fol.createdBy)
      //     if (is === 'string') {
      //       return fol.createdBy !== myAccountId
      //     } else {
      //       return fol.createdBy._id !== myAccountId
      //     }
      //   })
      // }
      return actualFolders
    },
    filteredFolders () {
      if (!this.activeFolder) {
        return this.filteredFoldersByPermissions.map((folder) => {
          return {
            ...folder
          }
        }).filter(f => f.role === 'main')
      } else {
        return this.activeFolderObject.subfolders
      }
    },
    routeElements () {
      let elements = [{ text: 'Main', _id: null }]
      if (this.activeFolderObject) {
        elements = []
        elements.push({ text: this.activeFolderObject.raw.originalname, _id: this.activeFolderObject._id })
        const getAllParentFolders = (folId) => {
          if (folId) {
            const foundParentFolder = this.personalFolders.find(f => f._id === folId)
            elements.push({ text: foundParentFolder.raw.originalname, _id: foundParentFolder._id })
            getAllParentFolders(foundParentFolder.belongsTo)
          }
        }
        getAllParentFolders(this.activeFolderObject.belongsTo)
      }
      if (this.activeFolder) elements.push({ text: 'Main', _id: null })
      const res = elements.reverse()
      this.$emit('route-elements', res)
      return res
    }
  },
  watch: {
    _documents (val) {
      if (val) {
        this.componentKey++
      }
    }
  },
  methods: {
    selectGroupDocuments (actualGroupName) {
      let docs = []
      this.groupDocuments.forEach(doc => {
        const found = doc.sharedWith.groups.find(group => group.groupName === actualGroupName)
        if (found) {
          docs.push(doc)
        }
      })
      return uniqBy([...docs], '_id')
    },
    onDragStart () {
      EventBus.$emit('drag-area-active')
    },
    async onDragEnd () {
      EventBus.$emit('drag-area-not-active')
    },
    onIncommingDoc (evnt) {
      this.$emit('newWorkbenchDocument', evnt)
    },
    onWorkbenchDocumentDrag (evnt) {
      this.$emit('dragStartWorkbenchDocuments', evnt)
    },
    onWorkbenchDocumentDragEnd (evnt) {
      this.$emit('dragEndWorkbenchDocuments', evnt)
    },
    openFolder (folder) {
      this.activeFolderObject = folder
      this.activeFolder = true
    },
    onRouterAction (element) {
      if (element.text === 'Main' && element._id === null) {
        this.activeFolderObject = null
        this.activeFolder = false
      } else {
        this.activeFolderObject = this.personalFolders.find(f => f._id === element._id) ||
        this.companyFolders.find(f => f._id === element._id) ||
        this.groupFolders.find(f => f._id === element._id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .document-list {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .document-list-container {
    flex-grow: 1;
    overflow-y: auto;
    // background-color: #F6F4F4;
  }
</style>
