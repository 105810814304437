import Vue from 'vue'
import axios from '@/plugins/axios'
import store from '../index'
import autobahn from 'autobahn'
import { uniqBy } from '@/utils/utils'

const SET_LOADING = 'SET_LOADING'
const SET_GROUP_CLIENTS = 'SET_GROUP_CLIENTS'
const SET_CLIENT_AS_CLIENT = 'SET_CLIENT_AS_CLIENT'
const ADD_GROUP_CLIENT = 'ADD_GROUP_CLIENT'
const UPDATE_GROUP_CLIENT = 'UPDATE_GROUP_CLIENT'
const UPDATE_GROUP_CLIENT_CASES = 'UPDATE_GROUP_CLIENT_CASES'
const DELETE_GROUP_CLIENT = 'DELETE_GROUP_CLIENT'
const RESET_GROUP_CLIENTS = 'RESET_GROUP_CLIENTS'

const state = () => {
  return {
    loading: false,
    groupClients: [],
    groupClientsSubscriptions: []
  }
}

const mutations = {
  [SET_LOADING] (state, value) {
    state.loading = value
  },
  [SET_GROUP_CLIENTS] (state, payload) {
    state.groupClients.push(payload)
    // state group clients array must be filtered for unique identification ids
    // because the appwrapper calls for each group the clients shared with it.
    // As clients can of course be repeated, they must be filtered by _id
    state.groupClients = uniqBy(state.groupClients, '_id')
  },
  [SET_CLIENT_AS_CLIENT] (state, payload) {
    state.meAsClient.push(payload)
  },
  [ADD_GROUP_CLIENT] (state, payload) {
    state.groupClients.push(payload)
  },
  [UPDATE_GROUP_CLIENT] (state, payload) {
    const index = state.groupClients.findIndex(c => c._id === payload._id)
    if (index === -1) state.groupClients.push(payload)
    else Vue.set(state.groupClients, index, payload)
  },
  [UPDATE_GROUP_CLIENT_CASES] (state, payload) {
    let casesToBeRefreshed = []
    store.state.companyCases.companyCases.forEach(_case => {
      if (_case.client._id === payload._id) casesToBeRefreshed.push({ caseId: _case._id, type: 'company' })
    })
    store.state.groupCases.groupCases.forEach(_case => {
      if (_case.client._id === payload._id) casesToBeRefreshed.push({ caseId: _case._id, type: 'group' })
    })
    store.state.cases.cases.forEach(_case => {
      if (_case.client._id === payload._id) casesToBeRefreshed.push({ caseId: _case._id, type: 'acc' })
    })
    if (casesToBeRefreshed.length) {
      casesToBeRefreshed.forEach(obj => {
        if (obj.type === 'company') store.dispatch('companyCases/fetchCompanyCase', obj.caseId)
        if (obj.type === 'group') store.dispatch('groupCases/fetchGroupCase', obj.caseId)
        if (obj.type === 'acc') store.dispatch('cases/fetchCase', obj.caseId)
      })
    }
  },
  [DELETE_GROUP_CLIENT] (state, _id) {
    const index = state.groupClients.findIndex(c => c._id === _id)
    if (index !== -1) {
      state.groupClients.splice(index, 1)
    }
  },
  [RESET_GROUP_CLIENTS] (state) {
    state.groupClients = []
    state.groupClientsSubscriptions = []
  }
}

const actions = {
  fetchGroupClients ({ commit }, { _id }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getSharedClients/${_id}`)
        for (let i = 0; i < data.data.length; i++) {
          const client = data.data[i]
          commit(SET_GROUP_CLIENTS, client)
        }
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  resetGroupClients ({ commit }) {
    commit(RESET_GROUP_CLIENTS)
  },
  groupClientsUpdatesSubscription ({ commit }, { _id }) {
    try {
      const connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })

      connection.onopen = function (session) {
        // call a remote procedure
        session.subscribe(`lavvira.updates.${_id}`, async (...args) => {
          const clientsTopic = args[0].includes('Client')
          const createTask = args[0].includes('create')
          const updateTask = args[0].includes('update')
          const deleteTask = args[0].includes('delete')
          if (args[0][4] && args[0][4].updateType === 'clientProfileUpdate') {
            commit(UPDATE_GROUP_CLIENT_CASES, args[0][0])
          }
          if (clientsTopic && createTask) {
            commit(ADD_GROUP_CLIENT, args[0][0])
          } else if (clientsTopic && updateTask) {
            const client = store.state.clients.clients.find(c => c._id === args[0][0]._id)
            if (client) {
              commit(UPDATE_GROUP_CLIENT, args[0][0])
            } else {
              // implemet 'share task'!!! to be clear
              commit(UPDATE_GROUP_CLIENT, args[0][0])
            }
          } else if (clientsTopic && deleteTask) {
            commit(DELETE_GROUP_CLIENT, args[0][0]._id)
          }
        }).then(
          function (subscription) {
            store.state.groupClients.groupClientsSubscriptions.push(subscription)
          }
        )
      }

      connection.onclose = function (reason, details) {
        // handle connection lost
        console.error(reason, details, 'connection.onclose')
      }
      connection.open()
    } catch (e) {
      console.error(e, 'ERROR in autobahn connnection')
    }
  },
  usnsubscribeGroupClientsUpdates ({ commit }, sub) {
    sub.unsubscribe().then(
      function (gone) {
        if (gone) commit(RESET_GROUP_CLIENTS)
        // successfully unsubscribed sub1
      },
      function (error) {
        // unsubscribe failed
        console.error(error)
      }
    )
  }
}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions
}
