<template>
  <v-dialog
    :value="isVisible"
    max-width="600px"
    height="70%"
    scrollable
  >
    <v-card>
      <v-toolbar
        flat
        class="general-form-toolbar"
        width="100%"
        :rounded="false"
      >
        <v-toolbar-title>Add Comment</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            @click="$emit('close-comment-dialog')"
            @mouseover="hoverCloseButtonIcon = true"
            @mouseleave="hoverCloseButtonIcon = false"
          >
            <v-icon
              v-if="hoverCloseButtonIcon"
              small
              @click="isVisible = false"
            >
              mdi-close
            </v-icon>
            <v-icon
              v-else
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-textarea
          v-model="commentText"
          background-color="amber lighten-4"
          color="primary"
          label="Your comment"
          outlined
          class="mt-4"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="addComment(); isVisible = false"
        >
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { EventBus } from '@/utils/EventBus'
import { v4 as uuidv4 } from 'uuid'

export default {
  mixins: [validationMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    template: {
      type: Object,
      default: null
    },
    caseData: {
      type: Object,
      default: null
    },
    workbench: {
      type: Object,
      default: null
    },
    text: {
      type: String,
      default: ''
    },
    from: {
      type: Number,
      default: null
    },
    to: {
      type: Number,
      default: null
    },
    incommingId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isVisible: true,
      commentText: '',
      hoverCloseButtonIcon: false
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      settings: state => state.settings.settings,
      templates: state => state.templates.templates
    })
  },
  watch: {
    async value (isVisible) {
      if (isVisible) {
        this.query = null
        await this.$nextTick()
        this.$refs.query.focus()
      }
    }
  },
  created () {
    EventBus.$on('emit-comment-id', this.onCommentIdEvent)
    EventBus.$on('emit-comment-text', this.onEmitCommentText)
  },
  beforeDestroy () {
    EventBus.$off('emit-comment-id', this.onCommentIdEvent)
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      updateTemplate: 'templates/updateTemplate',
      addMyComment: 'cases/updateCaseWorkbenchTemplate'
    }),
    onCommentIdEvent (assignedId) {
      this.incommingId = assignedId
      this.isVisible = true
    },
    onEmitCommentText (text) {
      this.text = text
    },
    async addComment () {
      // EventBus.$emit('save-comment-in-template', this.template._id)
      // const templateData = this.template.templateData
      const commentId = uuidv4()
      const commentForTemplate = {
        id: commentId,
        text: this.commentText,
        textThatHasBeenCommentedOn: this.text,
        from: this.from,
        to: this.to
      }
      // const comment = {
      //   id: commentId,
      //   text: this.commentText,
      //   resolved: false,
      //   createdBy: this.account._id
      // }
      this.$emit('comment-for-template', commentForTemplate)
      this.$emit('close-comment-dialog')
      this.loading = false
      // const payload = {
      //   comment
      // }
      // try {
      //   await this.addMyComment({ _id: this.caseData._id, workbenchId: this.workbench._id, templateId: this.template._id, payload })
      // } catch (e) {
      //   this.error = e
      //   console.error(this.error, 'error')
      // } finally {
      //   this.$emit('close-comment-dialog')
      //   this.loading = false
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.general-form-toolbar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 50;
}
</style>
