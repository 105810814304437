// import moment from 'moment'
import Vue from 'vue'
import axios from '@/plugins/axios'
import store from '../index'
import autobahn from 'autobahn'
import vuetify from '@/plugins/vuetify'
import { EventBus } from '@/utils/EventBus'

const SET_LOADING = 'SET_LOADING'
const SET_ERROR = 'SET_ERROR'
const SET_CASES = 'SET_CASES'
const SET_SHARED_WITH_ME_CASES = 'SET_SHARED_WITH_ME_CASES'
const SET_CASE_ID = 'SET_CASE_ID'
const ADD_CASE = 'ADD_CASE'
const ADD_SHARED_CASE = 'ADD_SHARED_CASE'
const UPDATE_CASE = 'UPDATE_CASE'
const UPDATE_CASE_CLIENT = 'UPDATE_CASE_CLIENT'
const UPDATE_CASE_SUGGESTION = 'UPDATE_CASE_SUGGESTION'
const DELETE_CASE_WORKBENCH = 'DELETE_CASE_WORKBENCH'
const DELETE_COMMENT = 'DELETE_COMMENT'
const DELETE_CASE = 'DELETE_CASE'
const SET_TEMPLATE = 'SET_TEMPLATE'
const SET_TAB_STATE = 'SET_TAB_STATE'
const SET_COMMENTS = 'SET_COMMENTS'
// const SET_SUGGESTIONS = 'SET_SUGGESTIONS'
const RESET_CASES = 'RESET_CASES'

// const dateFormat = date => moment(date).format('DD/MM/YYYY')

const state = () => {
  return {
    casesError: null,
    cases: [],
    sharedWithMeCases: [],
    caseId: null,
    caseError: null,
    template: null,
    loading: false,
    actualTabCases: {},
    actualCommentsEditorStorage: []
    // actualSuggestionsEditorStorage: []
  }
}

const mutations = {
  [SET_LOADING] (state, value) {
    state.loading = value
  },
  [SET_ERROR] (state, { key, error } = {}) {
    state[key] = error
  },
  [SET_CASES] (state, payload) {
    state.cases = payload
  },
  [SET_SHARED_WITH_ME_CASES] (state, payload) {
    state.sharedWithMeCases = payload
  },
  [SET_CASE_ID] (state, _id) {
    state.caseId = _id
  },
  [ADD_CASE] (state, payload) {
    const myAcc = payload.sharedWith.accounts.find(acc => acc._id === store.state.account.account._id)
    if (myAcc) {
      const index = state.cases.findIndex(c => c._id === payload._id)
      if (index === -1) state.cases.push(payload)
      else Vue.set(state.cases, index, payload)
    }
    if (store.state.account.account.companyId && payload.sharedWith.company.includes(store.state.account.account.companyId)) {
      const index = store.state.companyCases.companyCases.findIndex(c => c._id === payload._id)
      if (index === -1) store.state.companyCases.companyCases.push(payload)
      else Vue.set(store.state.companyCases.companyCases, index, payload)
    }
    if (store.state.account.account.companyId) {
      let groupIds = []
      for (let i = 0; i < store.state.company.company.groups.length; i++) {
        const group = store.state.company.company.groups[i]
        groupIds.push(group._id)
      }
      const index = store.state.groupCases.groupCases.findIndex(c => c._id === payload._id)
      groupIds.forEach(groupId => {
        if (payload.sharedWith.groups.includes(groupId)) {
          if (index === -1) store.state.groupCases.groupCases.push(payload)
          else Vue.set(store.state.groupCases.groupCases, index, payload)
        }
      })
    }
  },
  [ADD_SHARED_CASE] (state, payload) {
    state.cases.push(payload)
  },
  [UPDATE_CASE] (state, payload) {
    const index = state.cases.findIndex(c => c._id === payload._id)
    Vue.set(state.cases, index, payload)
  },
  [UPDATE_CASE_CLIENT] (state, payload) {
    const index = state.cases.findIndex(c => c._id === payload._id)
    const companyIndex = store.state.companyCases.companyCases.findIndex(c => c._id === payload._id)
    const groupIndex = store.state.groupCases.groupCases.findIndex(c => c._id === payload._id)
    if (index !== -1) Vue.set(state.cases, index, payload)
    if (companyIndex !== -1) Vue.set(store.state.companyCases.companyCases, companyIndex, payload)
    if (groupIndex !== -1) Vue.set(store.state.groupCases.groupCases, groupIndex, payload)
  },
  [UPDATE_CASE_SUGGESTION] (state, payload) {
    const index = state.cases.findIndex(c => c._id === payload._id)
    if (index !== -1) {
      Vue.set(state.cases, index, payload)
    }
  },
  [DELETE_CASE_WORKBENCH] (state, { _id, workbenchId }) {
    const index = state.cases.findIndex(c => c._id === _id)
    if (index !== -1) {
      const workbenchIndex = state.cases[index].workbenches.findIndex(f => f._id === workbenchId)
      Vue.delete(state.cases[index].workbenches, workbenchIndex)
    }
  },
  [DELETE_COMMENT] (state, payload) {
    const index = state.cases.findIndex(c => c._id === payload._id)
    Vue.set(state.cases, index, payload)
  },
  [DELETE_CASE] (state, _id) {
    const index = state.cases.findIndex(c => c._id === _id)
    if (index !== -1) state.cases.splice(index, 1)
  },
  [SET_TEMPLATE] (state, template) {
    state.template = template
  },
  [SET_TAB_STATE] (state, value) {
    state.actualTabCases = value
  },
  [SET_COMMENTS] (state, value) {
    state.actualCommentsEditorStorage = value
  },
  // [SET_SUGGESTIONS] (state, value) {
  //   state.actualSuggestionsEditorStorage = value
  // },
  [RESET_CASES] (state) {
    state.cases = []
  },
  updateQuestionairreFrontEnd (state, payload) {
    const actualCaseIndex = state.cases.findIndex(c => c._id === payload._id)
    if (actualCaseIndex !== -1) {
      const wbIndex = state.cases[actualCaseIndex].workbenches.findIndex(wb => wb._id === payload.workbenchId)
      if (wbIndex !== -1) {
        const tempIndex = state.cases[actualCaseIndex].workbenches[wbIndex].templates.findIndex(template => template._id === payload.templateId)
        if (tempIndex !== -1) {
          state.cases[actualCaseIndex].workbenches[wbIndex].templates[tempIndex].questionairre = payload.data
        }
      }
    }
  },
  setLoading (state, value) {
    state.loading = value
  }
}

const actions = {
  fetchCases ({ commit }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get('/getCases')
        if (data.success) {
          commit(SET_CASES, data.data)
          commit(SET_ERROR, { key: 'casesError', error: null })
          resolve()
        } else {
          commit(SET_ERROR, { key: 'casesError', error: data })
          reject(data)
        }
      } catch (e) {
        commit(SET_ERROR, { key: 'casesError', error: e })
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  fetchSharedWithMeCases ({ commit }, { _id }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getSharedCases/${_id}`)
        if (data.success) {
          commit(SET_SHARED_WITH_ME_CASES, data.data)
          commit(SET_ERROR, { key: 'casesError', error: null })
          resolve()
        } else {
          commit(SET_ERROR, { key: 'casesError', error: data })
          reject(data)
        }
      } catch (e) {
        commit(SET_ERROR, { key: 'casesError', error: e })
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  fetchCase ({ commit }, _id) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getCase/${_id}`)
        if (data.success) {
          commit(ADD_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  setCaseId ({ commit }, _id) {
    commit(SET_CASE_ID, _id)
  },
  addCase ({ commit }, payload) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/createCase`, payload)
        if (data.success) {
          commit(ADD_CASE, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  addCaseFromCaseModel ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/createCaseFromCaseForm/${_id}`, payload)
        if (data.success) {
          commit(ADD_CASE, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  // addMyComment ({ commit }, { _id, workbenchId, templateId, payload } = {}) {
  //   commit(SET_LOADING, true)
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const { data } = await axios.post(`/updateCase/${_id}/${workbenchId}/${templateId}/addComment`, payload)
  //       if (data.success) {
  //         commit(UPDATE_CASE, data.data)
  //         resolve()
  //       } else {
  //         reject(data)
  //       }
  //     } catch (e) {
  //       reject(e)
  //     } finally {
  //       commit(SET_LOADING, false)
  //     }
  //   })
  // },
  updateCase ({ commit }, { _id, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}`, payload)
        // if (data.success && data.updatedDoc) {
        //   commit(UPDATE_CASE, data.data)
        //   store.dispatch('documents/getDocument', { _id: data.updatedDoc })
        //   resolve()
        // }
        // if (data.success && data.newAssignedDocs) {
        //   commit(UPDATE_CASE, data.data)
        //   data.newAssignedDocs.forEach(doc => {
        //     store.dispatch('documents/getDocument', { _id: doc })
        //   })
        //   resolve()
        // }
        if (data.success) {
          commit(UPDATE_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateCaseClient ({ commit }, { _id, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCaseClient/${_id}`, payload)
        if (data.success) {
          commit(UPDATE_CASE_CLIENT, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteCase ({ commit }, { _id } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        await axios.post(`/deleteCase/${_id}`)
        commit(DELETE_CASE, _id)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  addCaseWorkbench ({ commit }, { _id, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/addWorkbench`, payload)
        if (data.success) {
          commit(UPDATE_CASE, data.data)
          if (vuetify && vuetify.framework.breakpoint.mobile) {
            EventBus.$emit('refresh-mobile-case')
          } else EventBus.$emit('go-to-last-wb-tab', data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateCaseWorkbench ({ commit }, { _id, workbenchId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/${workbenchId}/`, payload)
        if (data.success) {
          commit(UPDATE_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteCaseWorkbenchDocument ({ commit }, { _id, workbenchId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/remove/${workbenchId}/`, payload)
        if (data.success) {
          commit(UPDATE_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteCaseWorkbench ({ commit }, { _id, workbenchId } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/delete/${workbenchId}`)
        if (data.success) {
          commit(DELETE_CASE_WORKBENCH, { _id, workbenchId })
          EventBus.$emit('go-to-last-wb-tab', data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  addCaseWorkbenchTemplate ({ commit }, { _id, workbenchId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/${workbenchId}/addTemplate`, payload)
        if (data.success) {
          commit(UPDATE_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateCaseWorkbenchTemplate ({ commit }, { _id, workbenchId, templateId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/${workbenchId}/${templateId}`, payload)
        if (data.success) {
          commit(UPDATE_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  saveAsTemplateInCase ({ commit }, { _id, workbenchId, templateId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/copyTemplateInCase/${_id}/${workbenchId}/${templateId}`, payload)
        if (data.success) {
          commit(ADD_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateCaseWorkbenchTemplateSuggestion ({ commit }, { _id, workbenchId, templateId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateSuggestion/${_id}/${workbenchId}/${templateId}`, payload)
        if (data.success) {
          commit(UPDATE_CASE_SUGGESTION, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteMyComment ({ commit }, { _id, workbenchId, templateId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/${workbenchId}/${templateId}/deleteComment`, payload)
        if (data.success) {
          commit(UPDATE_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteCaseWorkbenchTemplate ({ commit }, { _id, workbenchId, templateId } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateCase/${_id}/${workbenchId}/delete/${templateId}`)
        if (data.success) {
          commit(UPDATE_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  commentCaseTimeline ({ commit }, { _id, timelineItemId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/setCaseTimelineComment/${_id}/${timelineItemId}`, payload)
        if (data.success) {
          commit(UPDATE_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteCommentTimeline ({ commit }, { _id, timelineItemId, payload } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/deleteCaseTimelineComment/${_id}/${timelineItemId}`, payload)
        if (data.success) {
          commit(UPDATE_CASE, data.data)
          resolve()
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  casesUpdatesSubscription ({ commit }, { _id }) {
    try {
      const connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })

      connection.onopen = function (session) {
        // call a remote procedure
        session.subscribe(`lavvira.updates.${_id}`, async (...args) => {
          const caseTopic = args[0].includes('Case')
          const createTask = args[0].includes('create')
          const updateTask = args[0].includes('update')
          const deleteTask = args[0].includes('delete')
          const removeTask = args[0].includes('remove')
          if (caseTopic && createTask) {
            commit(ADD_CASE, args[0][0])
          } else if (caseTopic && updateTask) {
            const _case = store.state.cases.cases.find(c => c._id === args[0][0]._id)
            if (_case) {
              commit(UPDATE_CASE, args[0][0])
            } else {
              // implemet 'share task'!!! to be clear
              commit(ADD_SHARED_CASE, args[0][0])
            }
          } else if (caseTopic && deleteTask) {
            commit(DELETE_CASE, args[0][0])
          } else if (caseTopic && removeTask) {
            if (args[0][4] && args[0][4].removeCaseFromStore && args[0][4].account && args[0][4].account === store.state.account.account._id) {
              commit(DELETE_CASE, args[0][0]._id)
            } else {
              commit(UPDATE_CASE, args[0][0])
            }
            // const isShared = args[0][0].sharedWith.accounts.find(c => c._id === store.state.account.account._id)
            // if (isShared) {
            //   commit(UPDATE_CASE, args[0][0])
            // } else {
            //   commit(DELETE_CASE, args[0][0])
            // }
          }
        })
      }

      connection.onclose = function (reason, details) {
        // handle connection lost
        console.log(reason, details, 'connection.onclose')
      }
      connection.open()
    } catch (e) {
      console.error(e, 'ERR in autobahn connection')
    }
  },
  setTemplate ({ commit }, template) {
    commit(SET_TEMPLATE, template)
  },
  // setComments ({ commit }, comments) {
  //   commit(SET_COMMENTS, comments)
  // },
  // setSuggestions ({ commit }, suggestions) {
  //   commit(SET_SUGGESTIONS, suggestions)
  // },
  updateActualTabCases ({ commit }, tab) {
    commit(SET_TAB_STATE, tab)
  },
  resetCases ({ commit }) {
    commit(RESET_CASES)
  }
}

const getters = {
  menuCases: (state) => {
    const temp = {}
    state.cases.forEach((c) => {
      if (c.createdBy._id in temp) {
        temp[c.createdBy._id].cases.push(c)
      } else {
        temp[c.createdBy._id] = {
          createdBy: c.createdBy,
          cases: [c]
        }
      }
    })
    return temp
  },
  getCurrentCase: (state) => {
    if (state.caseId) {
      return state.cases.find(c => c._id === state.caseId)
    }
    return null
  }
}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions,
  getters
}
