import Vue from 'vue'
import axios from '@/plugins/axios'
import store from '../index'
import { uniqBy } from '@/utils/utils'
import autobahn from 'autobahn'
import { i18n } from '@/i18n/index'

const SET_LOADING = 'SET_LOADING'
const SET_GROUP_DOCUMENTS = 'SET_GROUP_DOCUMENTS'
const ADD_GROUP_DOCUMENT = 'ADD_GROUP_DOCUMENT'
const UPDATE_GROUP_DOCUMENT = 'UPDATE_GROUP_DOCUMENT'
const DELETE_GROUP_DOCUMENT = 'DELETE_GROUP_DOCUMENT'
const RESET_GROUP_DOCUMENTS = 'RESET_GROUP_DOCUMENTS'
const SET_GROUP_FOLDERS = 'SET_GROUP_FOLDERS'
const ADD_GROUP_FOLDER = 'ADD_GROUP_FOLDER'
const UPDATE_GROUP_FOLDER = 'UPDATE_GROUP_FOLDER'
const DELETE_GROUP_FOLDER = 'DELETE_GROUP_FOLDER'

const state = () => {
  return {
    loading: false,
    groupDocuments: [],
    groupFolders: [],
    groupDocumentsSubscriptions: []
  }
}

const mutations = {
  [SET_LOADING] (state, value) {
    state.loading = value
  },
  [SET_GROUP_DOCUMENTS] (state, payload) {
    state.groupDocuments.push(payload)
    // state group documents array must be filtered for unique identification ids
    // because the appwrapper calls for each group the documents shared with it.
    // As documents can of course be repeated, they must be filtered by _id
    state.groupDocuments = uniqBy(state.groupDocuments, '_id')
  },
  [ADD_GROUP_DOCUMENT] (state, payload) {
    // We check if the update comes from an express or a crossbar.
    // If it comes from crossbar we need to do an additional check,
    // because if the user has made the request
    // the document has already been returned by express and is available in the documents
    // for all other users the document is obvously not available
    if (payload.message && payload.message === 'watchmanUpdater') {
      const index = state.groupDocuments.findIndex(doc => doc._id === payload.doc._id)
      if (index === -1) {
        state.groupDocuments.push(payload.doc)
      }
    } else if (payload.message && payload.message === 'watchmanUpdateOnShare') {
      state.groupDocuments.push(payload.doc)
    } else {
      state.groupDocuments.push(payload)
    }
  },
  [UPDATE_GROUP_DOCUMENT] (state, payload) {
    const index = state.groupDocuments.findIndex(doc => doc._id === payload._id)
    Vue.set(state.groupDocuments, index, payload)
  },
  [DELETE_GROUP_DOCUMENT] (state, payload) {
    // the index must be checked, because the friction update comes from two places:
    // crossbar and express and if there is no check,
    // an error occurs (all documents from the store temporarily disappear until refresh)
    const index = state.groupDocuments.findIndex(doc => doc._id === payload._id)
    if (index !== -1) {
      state.groupDocuments.splice(index, 1)
    }
  },
  [RESET_GROUP_DOCUMENTS] (state) {
    state.groupDocuments = []
    state.groupDocumentsSubscriptions = []
  },
  [SET_GROUP_FOLDERS] (state, payload) {
    state.groupFolders.push(...payload)
  },
  [ADD_GROUP_FOLDER] (state, payload) {
    const idx = state.groupFolders.findIndex(f => f._id === payload._id)
    if (idx === -1) state.groupFolders.push(payload)
  },
  [UPDATE_GROUP_FOLDER] (state, payload) {
    const idx = state.groupFolders.findIndex(f => f._id === payload._id)
    if (idx !== -1) Vue.set(state.groupFolders, idx, payload)
  },
  [DELETE_GROUP_FOLDER] (state, payload) {
    const idx = state.groupFolders.findIndex(f => f._id === payload._id)
    if (idx !== -1) Vue.delete(state.groupFolders, idx)
  }
}

const actions = {
  getGroupDocuments ({ commit }, { _id }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getSharedDocuments/${_id}`) // has to be group._id
        for (let i = 0; i < data.data.length; i++) {
          const doc = data.data[i]
          commit(SET_GROUP_DOCUMENTS, doc)
        }
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  getGroupDocument ({ commit }, payload = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getDocument/${payload._id}`)
        commit(UPDATE_GROUP_DOCUMENT, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  uploadGroupDocument ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const formData = new FormData()
        Object.keys(payload).forEach((key) => {
          formData.append(key, payload[key])
        })
        const { data } = await axios.post(`/uploadDocument/${_id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        if (data.success) {
          commit(ADD_GROUP_DOCUMENT, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  updateGroupDocument ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/updateDocument/${_id}`, {
          payload: payload
        })
        if (data.success && data.onNewShareId && store.state.company.company && data.onNewShareId !== store.state.company.company._id) {
          const index = store.state.groupDocuments.groupDocuments.findIndex(doc => doc._id === data.data._id)
          if (index !== -1) {
            store.state.groupDocuments.groupDocuments.splice(index, 1, data.data)
          } else {
            store.state.groupDocuments.groupDocuments.push(data.data)
          }
          resolve(data)
        }
        if (data.success && data.onNewShareId && store.state.company.company && data.onNewShareId === store.state.company.company._id) {
          store.state.companyDocuments.companyDocuments.push(data.data)
          resolve(data)
        }
        // if (data.success && data.newShareSuccess) {
        //   const sharedWithOtherGroupIndex = store.state.company.groups.findIndex(g => g._id === data.newShareSuccess)
        //   if (sharedWithOtherGroupIndex !== -1) {
        //     commit(UPDATE_GROUP_DOCUMENT, data.data)
        //     resolve(data)
        //   } else if (data.newShareSuccess === store.state.company.company._id) {
        //     store.state.company.companyDocuments.push(data.data)
        //   } else {
        //     resolve(data)
        //   }
        // }
        if (data.success && !data.onNewShareId) {
          commit(UPDATE_GROUP_DOCUMENT, data.data)
          resolve(data)
        } else if (!data.success && data.data.msg === 'account not found') {
          this.addToast({
            title: i18n.t('error|lavvira_account_not_found'),
            color: 'white',
            snackbarColor: 'error'
          })
        } else if (!data.success) {
          reject(data)
        }
      } catch (e) {
        if (e && e.response.data && e.response.data === 'Acc Not Found') {
          store.dispatch('toasts/addToast', {
            title: i18n.t('error|lavvira_account_not_found'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
        if (e && e.response.data && e.response.data === 'Already shared') {
          store.dispatch('toasts/addToast', {
            title: i18n.t('error|already_shared'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  removeGroupDocument ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/removeDocument/${_id}`, {
          payload: payload
        })
        if (data.success) {
          commit(DELETE_GROUP_DOCUMENT, data.data)
          resolve(data)
        } else {
          reject(data)
        }
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  deleteGroupDocument ({ commit }, { document } = {}) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        await axios.post(`/deleteDocument/${document._id}`)
        const _id = document._id
        commit(DELETE_GROUP_DOCUMENT, { _id })
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  resetGroupDocuments ({ commit }) {
    commit(RESET_GROUP_DOCUMENTS)
  },
  getGroupFolders ({ commit }, { _id }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`/getSharedWithMeFolders/${_id}`) // has to be group._id
        commit(SET_GROUP_FOLDERS, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  createNewGroupFolder ({ commit }, { _id, payload }) {
    commit(SET_LOADING, true)
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`/createNewFolder/${_id}`, {
          payload: payload
        })
        commit(ADD_GROUP_FOLDER, data.data)
        resolve()
      } catch (e) {
        reject(e)
      } finally {
        commit(SET_LOADING, false)
      }
    })
  },
  groupDocumentsUpdatesSubscription ({ commit }, { _id }) {
    try {
      const connection = new autobahn.Connection({
        url: process.env.VUE_APP_CROSSBAR_PATH,
        realm: process.env.VUE_APP_CROSSBAR_REALM
      })

      connection.onopen = function (session) {
        // call a remote procedure
        session.subscribe(`lavvira.updates.${_id}`, async (...args) => {
          const groupDocsTopic = args[0].includes('Documents')
          const folderTopic = args[0].includes('Folders')
          const createTask = args[0].includes('create')
          const updateTask = args[0].includes('update')
          const deleteTask = args[0].includes('delete')
          const removeTask = args[0].includes('remove')
          if (groupDocsTopic && createTask) {
            const message = 'watchmanUpdater'
            const doc = args[0][0]
            commit({ type: 'ADD_GROUP_DOCUMENT', doc, message })
          } else if (groupDocsTopic && updateTask) {
            const doc = store.state.groupDocuments.groupDocuments.find(d => d._id === args[0][0]._id)
            if (doc) {
              commit(UPDATE_GROUP_DOCUMENT, args[0][0])
            } else {
              // implemet 'share task'!!! to be clear
              const message = 'watchmanUpdateOnShare'
              const doc = args[0][0]
              commit({ type: 'ADD_GROUP_DOCUMENT', doc, message })
            }
          } else if (groupDocsTopic && deleteTask) {
            commit(DELETE_GROUP_DOCUMENT, args[0][0])
          } else if (groupDocsTopic && removeTask) {
            // the removе task is used when a group is removed from sharedWith.groups
            // since all group documents are in one array
            // it is necessary to check with which group the document is still shared
            let groupIds = []
            let sharedArray = []
            for (let i = 0; i < store.state.company.company.groups.length; i++) {
              const group = store.state.company.company.groups[i]
              groupIds.push(group._id)
            }
            groupIds.forEach(groupId => {
              const stillShared = args[0][0].sharedWith.groups.findIndex(g => g._id === groupId)
              sharedArray.push(stillShared)
            })
            if (sharedArray.some((index) => index >= 0)) {
              commit(UPDATE_GROUP_DOCUMENT, args[0][0])
            } else {
              commit(DELETE_GROUP_DOCUMENT, args[0][0])
            }
          } else if (folderTopic && createTask) {
            commit(ADD_GROUP_FOLDER, args[0][0])
          } else if (folderTopic && updateTask) {
            if (
              args[0][4] &&
              args[0][4].folderWasSharedWithGroup &&
              args[0][4].groupId === _id
            ) {
              commit(ADD_GROUP_FOLDER, args[0][0])
            } else {
              commit(UPDATE_GROUP_FOLDER, args[0][0])
            }
          } else if (folderTopic && deleteTask) {
            commit(DELETE_GROUP_FOLDER, args[0][0])
          } else if (folderTopic && removeTask) {
            if (
              args[0][4] &&
              args[0][4].removedFolder &&
              args[0][4].userType &&
              args[0][4].userType === 'groups' &&
              args[0][4].userId === _id
            ) {
              commit(DELETE_GROUP_FOLDER, args[0][0].returnFolder)
            }
          }
        }).then(
          function (subscription) {
            store.state.groupDocuments.groupDocumentsSubscriptions.push(subscription)
          }
        )
      }

      connection.onclose = function (reason, details) {
      // handle connection lost
        console.error(reason, details, 'connection.onclose')
      }
      connection.open()
    } catch (e) {
      console.error(e, 'ERROR in autobahn connection')
    }
  },
  usnsubscribeGroupDocumentsUpdates ({ commit }, sub) {
    sub.unsubscribe().then(
      function (gone) {
        if (gone) commit(RESET_GROUP_DOCUMENTS)
        // successfully unsubscribed
      },
      function (error) {
        // unsubscribe failed
        console.error(error)
      }
    )
  }
}

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions
}
