import {
  mapState,
  mapActions,
  mapMutations
} from 'vuex'
import {
  mdiFileImage,
  mdiFilePdfBox,
  mdiFileWord,
  mdiMicrosoftExcel,
  mdiCog,
  mdiCheckCircle,
  mdiChevronDown,
  mdiDrag
} from '@mdi/js'
import axios from '@/plugins/axios'

export default {
  data () {
    return {
      selectedLanguage: null,
      icons: {
        mdiCog,
        mdiCheckCircle,
        mdiChevronDown,
        drag: mdiDrag
      }
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings
    }),
    fileInputFields () {
      const docSuggestions = Object.keys(this.settings.fields).reduce(
        (acc, key) => {
          const item = this.settings.fields[key]
          if (item.type === 'file-input') {
            acc[key] = item
          }
          return acc
        },
        {}
      )
      return docSuggestions
    },
    iconType () {
      if (this.document) return this.getIconType(this.document.raw.mimetype)
      else return null
    }
  },
  mounted () {
    this.selectedLanguage = localStorage.getItem('preferedLanguage')
    window.addEventListener('pref-language-changed', (event) => {
      this.selectedLanguage = event.detail.lang
    })
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      updateDocument: 'documents/updateDocument'
    }),
    ...mapMutations({
      setLoading: 'documents/setLoading',
      setSingleDocumentLoading: 'documents/setSingleDocumentLoading'
    }),
    verifyUserRole (company, account) {
      if (company) {
        const regularUser = company.userRoles.basic.some(u => u === account._id)
        const advancedUser = company.userRoles.advanced.some(u => u === account._id)
        const adminUser = company.userRoles.admin.some(u => u === account._id)
        const adminOwnerUser = company.userRoles.adminOwner.some(u => u === account._id)
        if (regularUser) {
          return 'regular'
        } else if (advancedUser) {
          return 'advanced'
        } else if (adminUser) {
          return 'admin'
        } else if (adminOwnerUser) {
          return 'adminOwner'
        }
      } else {
        return null
      }
    },
    getBodyFontFam () {
      const body = document.querySelector('body')
      const bodyStyle = window.getComputedStyle(body)
      const bodyFontFamily = bodyStyle.fontFamily
      return bodyFontFamily
    },
    getFieldValue (docTypeKey, selectedLanguage) {
      let result = null
      for (const key in this.settings.fields) {
        if (key === docTypeKey) {
          result = Array.isArray(this.settings.fields[key].label[selectedLanguage]) ? this.settings.fields[key].label[selectedLanguage].join('/') : this.settings.fields[key].label[selectedLanguage]
          break
        } else result = null
      }
      if (docTypeKey === 'unknown') result = this.$t('common|undefined')
      if (docTypeKey && !result) result = docTypeKey.split('_').join(' ')
      return result
    },
    setInputValue (doc, itemText, index, input, source) {
      this.setSingleDocumentLoading(doc._id)
      const el = document.getElementById(`searchTxt-${index}`)
      if (input && el) {
        el.value = input.name
        this.inputFocus = null
        this.query = null
      }
      this.updateDocumentType(doc, itemText, index, source, input)
    },
    async openDocument (document, location) {
      let _doc = null
      if (!this.$refs.fileView || !document) return
      const inputType = typeof document
      if (inputType === 'string') {
        try {
          const { data } = await axios.get(`/getDocument/${document}`)
          if (data.success && data.data) {
            _doc = data.data
          }
        } catch (error) {
          console.error(error, 'Error while downloading document')
        }
      } else if (location && location === 'from-general-search') {
        try {
          const { data } = await axios.get(`/getDocument/${document._id}`)
          if (data.success && data.data) {
            _doc = data.data
          }
        } catch (error) {
          console.error(error, 'Error while downloading document')
        }
      } else {
        _doc = document
      }
      if (_doc) {
        if (location && location === 'file-input-preview') {
          this.$refs.fileView.openFile({
            type: document.file.type,
            filename: document.file.name,
            parsed: false,
            file: document.file,
            onlyFilePreviewOnUpload: true
          })
        } else if (_doc.parsed && _doc.info.content && _doc.info.content[0].lavvira_labels) {
          let path = `/downloadDocument/${_doc.raw.pdf_path.replace('files/', '')}`
          this.$refs.fileView.openFile({
            path,
            mimetype: _doc.raw.mimetype,
            filename: _doc.raw.originalname,
            json: _doc.parsed.text_json,
            extractedData: _doc.info.content[0].lavvira_labels,
            _id: _doc._id,
            doc: _doc,
            parsed: true
          })
        } else {
          let path = `/downloadDocument/${_doc.raw.pdf_path.replace('files/', '')}`
          this.$refs.fileView.openFile({
            path,
            mimetype: _doc.raw.mimetype,
            filename: _doc.raw.originalname,
            parsed: false,
            doc: _doc
          })
        }
      } else {
        this.addToast({
          title: this.$t('documents|no_longer_available'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async updateDocumentType (doc, itemText, index, source, input) {
      let el
      let newDocType
      if (source && source === 'custom') {
        const res = await this.$dialog.prompt({
          title: this.$t('actions|select_document_type'),
          text: this.$t('actions|enter_text'),
          value: itemText || 'unknown',
          showClose: false,
          textField: {
            // Any addtional props/attrs that will be binded to v-text-field component
            outlined: true,
            dense: true,
            type: 'text',
            class: 'mt-5'
          }
        })
        if (res) {
          el = document.getElementById(`searchTxt-${index}`)
          el.value = res
          newDocType = res.split(' ').join('_')
        } else {
          return
        }
      } else if (source && source === 'from-list') {
        for (const key in this.settings.fields) {
          if (input.fieldKey === key) {
            newDocType = input.fieldKey
          }
        }
      }
      await this.updateDocument({
        _id: doc._id,
        payload: {
          info: {
            ...doc.info,
            document_type: newDocType
          }
        }
      })
    },
    translateFileInputField (field, section) {
      let translation = null
      for (const key in this.fileInputFields) {
        if (Object.hasOwnProperty.call(this.fileInputFields, key)) {
          const singleFileInputField = this.fileInputFields[key]
          if (field.fieldKey.toLowerCase() === key.toLowerCase()) {
            translation = singleFileInputField.label[this.selectedLanguage]
            break
          }
        }
      }
      if (translation === null) translation = field.fieldKey.split('_').join(' ')
      if (section && section === 'questionnaire') {
        field.name = translation
        return field
      } else return translation
    },
    translateQuestionnaireField (field) {
      let translation = null
      for (const key in this.settings.fields) {
        if (Object.hasOwnProperty.call(this.settings.fields, key)) {
          const singleField = this.settings.fields[key]
          if (field.fieldKey.toLowerCase() === key.toLowerCase()) {
            translation = singleField.label[this.selectedLanguage]
            break
          }
        }
      }
      if (translation === null) translation = field.fieldKey.split('_').join(' ')
      field.name = translation
      return field
    },
    isEllipsisActive (elName) {
      let result = false
      const el = document.getElementById(elName)
      if (el && (el.offsetWidth < el.scrollWidth)) result = true
      return result
    },
    getName (input) {
      let _name
      if (input.accountData && input.accountData.firstName && input.accountData.lastName) {
        _name = input.accountData.firstName + ' ' + input.accountData.lastName
      } else {
        _name = input.email
      }
      return _name
    },
    getIconType (fileType) {
      switch (fileType) {
      case 'application/pdf':
        return {
          icon: mdiFilePdfBox,
          color: '#c11e07'
        }
      case 'image/jpeg':
        return {
          icon: mdiFileImage,
          color: '#ffbd2e'
        }
      case 'image/png':
        return {
          icon: mdiFileImage,
          color: 'blue'
        }
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/msword':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/rtf':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/vnd.ms-word.document.macroenabled.12':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/msword-template':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/vnd.oasis.opendocument.text-template':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/vnd.oasis.opendocument.text-flat-xml':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/vnd.oasis.opendocument.text':
        return {
          icon: mdiFileWord,
          color: '#2372ba'
        }
      case 'application/vnd.ms-excel':
        return {
          icon: mdiMicrosoftExcel,
          color: 'green darken-3'
        }
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return {
          icon: mdiMicrosoftExcel,
          color: 'green darken-3'
        }
      case 'application/vnd.oasis.opendocument.spreadsheet':
        return {
          icon: mdiMicrosoftExcel,
          color: 'green darken-3'
        }
      case 'application/octet-stream':
        return {
          icon: 'mdi-file',
          color: 'grey lighten-2'
        }
      default:
        return {
          icon: 'mdi-file',
          color: 'grey'
        }
      }
    },
    getSvg (fileType, docName) {
      if (docName && docName.includes('.pages')) return require(`@/assets/pages.svg`)
      if (docName && docName.includes('.numbers')) return require(`@/assets/numbers.png`)
      if (fileType && fileType === 'application/vnd.apple.pages') return require(`@/assets/pages.svg`)
      if (fileType && fileType === 'application/vnd.apple.numbers') return require(`@/assets/numbers.png`)
    },
    getOctetValue (docName) {
      if (docName && docName.includes('.pages')) return require(`@/assets/pages.svg`)
      if (docName && docName.includes('.numbers')) return require(`@/assets/numbers.png`)
    },
    isIcon (fileType, docName) {
      let rule = true
      if (fileType === 'application/vnd.apple.pages' || fileType === 'application/vnd.apple.numbers') {
        rule = false
      } else if (fileType === 'application/octet-stream') {
        if (docName && docName.includes('.pages')) rule = false
        if (docName && docName.includes('.numbers')) rule = false
      }
      return rule
    },
    getExtractedValues (node, documents, caseData, account, company) {
      let arr = []
      if (node.attrs && node.attrs.fieldKey) {
        // first we check if a company exists and user has company account
        // if this is the case we apply the company profile info if available
        if (company) {
          switch (node.attrs.fieldKey) {
          case 'My_company_name':
            arr.push({
              value: this.company.companyData.companyName ||
                node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
            })
            break
          case 'My_company_email':
            arr.push({
              value: this.company.companyData.email ||
                node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
            })
            break
          case 'My_company_phone':
            arr.push({
              value: this.company.companyData.phone ||
                node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
            })
            break
          case 'My_company_fax':
            arr.push({
              value: this.company.companyData.fax ||
                node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
            })
            break
          case 'My_company_registration_number':
            arr.push({
              value: this.company.companyData.registrationNumber ||
                node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
            })
            break
          case 'My_company_address_street':
            arr.push({
              value: this.company.companyData.address ||
                node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
            })
            break
          case 'My_company_address_city':
            arr.push({
              value: this.company.companyData.city ||
                node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
            })
            break
          case 'My_company_address_country':
            arr.push({
              value: this.company.companyData.nationality ||
                node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
            })
            break
          case 'My_company_address_postal_code':
            arr.push({
              value: this.company.companyData.postal_code ||
                node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
            })
            break
          }
        }

        // then we check if the node(suggestion) is related to profile(personal data)
        // and we apply the info if available
        switch (node.attrs.fieldKey) {
        case 'My_first_name':
          arr.push({
            value: account.accountData.firstName ||
            node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'My_last_name':
          arr.push({
            value: account.accountData.lastName ||
            node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'My_address_street':
          arr.push({
            value: account.accountData.address ||
            node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'My_address_city':
          arr.push({
            value: account.accountData.city ||
            node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'My_address_postal_code':
          arr.push({
            value: account.accountData.postal_code ||
            node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'My_address_country':
          arr.push({
            value: account.accountData.nationality ||
            node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Client_first_name':
          arr.push({
            value: caseData.client.clientData.given_names ||
            node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Client_last_name':
          arr.push({
            value: caseData.client.clientData.surname ||
            node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Client_date_of_birth':
          arr.push({
            value: caseData.client.clientData.date_of_birth ||
            node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Client_place_of_birth':
          arr.push({
            value: caseData.client.clientData.place_of_birth ||
            node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Client_address_street':
          arr.push({
            value: caseData.client.clientData.address ||
            node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Client_address_city':
          arr.push({
            value: caseData.client.clientData.city ||
            node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Client_address_postal_code':
          arr.push({
            value: caseData.client.clientData.postal_code ||
            node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Client_address_country':
          arr.push({
            value: caseData.client.clientData.nationality ||
            node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Company_client_name':
          arr.push({
            value: caseData.client.clientData.company_name ||
            node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Company_client_type':
          arr.push({
            value: caseData.client.clientData.company_type ||
            node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Company_client_shared_capital':
          arr.push({
            value: caseData.client.clientData.shared_capital ||
            node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Company_client_selected_currency':
          arr.push({
            value: caseData.client.clientData.selected_currency
              ? caseData.client.clientData.selected_currency.symbol
              : node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          arr.push({
            value: caseData.client.clientData.selected_currency
              ? caseData.client.clientData.selected_currency.name
              : node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          arr.push({
            value: caseData.client.clientData.selected_currency
              ? caseData.client.clientData.selected_currency.code
              : node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Company_client_rcs_number':
          arr.push({
            value: caseData.client.clientData.rcs_number ||
            node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        case 'Company_client_place_of_registration':
          arr.push({
            value: caseData.client.clientData.place_of_registration ||
            node.attrs.label + ' - ' + this.$t('common|not_available').toLowerCase()
          })
          break
        }
      }

      // then we get the data from the assigned documents
      documents.forEach((doc) => {
        if (doc.info && doc.info.content && Array.isArray(doc.info.content)) {
          doc.info.content.forEach(content => {
            if (content.lavvira_labels) {
              Object.keys(content.lavvira_labels).forEach((key) => {
                if ((node.attrs.fieldKey.toLowerCase() === key.toLowerCase()) || node.attrs.fieldClass?.toLowerCase() === key.toLowerCase()) {
                  content.lavvira_labels[key].forEach((val) => {
                    arr.push({
                      value: val,
                      doc
                    })
                  })
                }
              })
            }
          })
        }
      })
      return arr
    }
  }
}
