<template>
  <v-menu
    v-model="menuActiveValue"
    offset-y
    offset-overflow
    min-width="400px"
    max-width="400px"
    max-height="300px"
    min-height="300px"
    :open-on-click="false"
    :close-on-click="false"
  >
    <template v-slot:activator="{ on }">
      <div
        style="position: relative"
      >
        <v-icon
          v-if="inputFocus === index.toString()"
          small
          :style="`position: absolute; padding-top: ${prependInnerIconPaddingTop}; padding-left: ${prependInnerIconPaddingLeft};`"
        >
          mdi-magnify
        </v-icon>
        <v-icon
          class="pt-1"
          :style="`position: ${appendIconRightPosition}; right: ${appendIconRight}; bottom: ${appendIconBottom};`"
          @click="focusField()"
        >
          mdi-menu-down
        </v-icon>
        <label
          v-show="inputFocus === index.toString()"
          :for="`searchTxt-${componentIsUsedIn + index}`"
          :class="usedInDocumentsTable ? 'input-label-doc-table' : 'input-label'"
        >
          {{ itemText }}
        </label>
        <v-tooltip
          v-model="item.tooltip"
          top
          :value="inputFocus && inputFocus === index.toString()"
        >
          <template v-slot:activator="{ on: tooltip }">
            <input
              :id="`searchTxt-${componentIsUsedIn + index}`"
              ref="inputRef"
              type="text"
              style="text-overflow: ellipsis;"
              :style="inputFocus && inputFocus === index.toString() ? _inputFocus : _input"
              :placeholder="inputFocus && inputFocus === index.toString() ? '' : itemText"
              v-on="on &&inputFocus ? '' : tooltip"
              @focus="inputFocus = index.toString(); menuActiveValue = true; item.tooltip = false"
              @blur="closeMenu()"
              @input="searchValue(index)"
            >
          </template>
          <span>{{ itemText }}</span>
        </v-tooltip>
      </div>
    </template>
    <v-list
      v-if="filteredFields && filteredFields.length"
      style="overflow-y: auto; cursor: pointer;"
    >
      <v-list-item
        v-if="!query"
        @click="$emit('set-input-value', item, itemText, index, null, 'custom')"
      >
        <v-list-item-icon>
          <v-icon>
            {{ icons.cog }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('common|custom') }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-for="_f in filteredFields"
        :key="_f.fieldKey"
        @click="$emit('set-input-value', item, itemText, index, _f, 'from-list')"
      >
        <v-list-item-title>{{ _f.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <div
      v-else
      class="pa-4"
      style="background-color: white;"
    >
      {{ $t('common|no_results_found') }}
    </div>
  </v-menu>
</template>

<script>
import { mdiChevronDown, mdiCog } from '@mdi/js'

export default {
  props: {
    item: {
      type: Object,
      default: null
    },
    itemText: {
      type: String,
      default: null
    },
    index: {
      type: Number,
      default: null
    },
    componentIsUsedIn: {
      type: String,
      default: 'default'
    },
    fields: {
      type: Array,
      default: () => ([])
    },
    usedInDocumentsTable: {
      type: Boolean,
      default: false
    },
    prependInnerIconPaddingTop: {
      type: String,
      default: '4px'
    },
    prependInnerIconPaddingLeft: {
      type: String,
      default: '5px'
    },
    appendIconRight: {
      type: String,
      default: '6px'
    },
    appendIconRightPosition: {
      type: String,
      default: 'absolute'
    },
    appendIconBottom: {
      type: String,
      default: '-1px'
    },
    inputColor: {
      type: String,
      default: '#234e79'
    },
    inputFontSize: {
      type: String,
      default: '12px'
    },
    inputPaddingRight: {
      type: String,
      default: '30px'
    },
    inputPaddingLeft: {
      type: String,
      default: '8px'
    },
    inputPaddingTop: {
      type: String,
      default: '4px'
    },
    inputPaddingBottom: {
      type: String,
      default: '4px'
    },
    inputTextAlign: {
      type: String,
      default: 'start'
    },
    inputWidth: {
      type: String,
      default: '180px'
    },
    inputOutlineWidth: {
      type: String,
      default: '1px'
    },
    inputOutlineStyle: {
      type: String,
      default: 'solid'
    },
    inputOutlineColor: {
      type: String,
      default: '#234e79'
    },
    inputBorderRadius: {
      type: String,
      default: '25px'
    },
    inputColorOnFocus: {
      type: String,
      default: '#234e79'
    },
    inputFontSizeOnFocus: {
      type: String,
      default: '12px'
    },
    inputPaddingRightOnFocus: {
      type: String,
      default: '30px'
    },
    inputPaddingLeftOnFocus: {
      type: String,
      default: '25px'
    },
    inputPaddingTopOnFocus: {
      type: String,
      default: '4px'
    },
    inputPaddingBottomOnFocus: {
      type: String,
      default: '4px'
    },
    inputTextAlignOnFocus: {
      type: String,
      default: 'start'
    },
    inputWidthOnFocus: {
      type: String,
      default: '180px'
    },
    inputOutlineWidthOnFocus: {
      type: String,
      default: '1px'
    },
    inputOutlineStyleOnFocus: {
      type: String,
      default: 'solid'
    },
    inputOutlineColorOnFocus: {
      type: String,
      default: '#234e79'
    },
    inputBorderRadiusOnFocus: {
      type: String,
      default: '25px'
    }
  },
  data () {
    return {
      inputFocus: null,
      query: null,
      icons: {
        arrowDown: mdiChevronDown,
        cog: mdiCog
      },
      menuActiveValue: null
    }
  },
  computed: {
    _input () {
      return {
        'color': this.inputColor,
        'font-size': this.inputFontSize,
        paddingRight: this.inputPaddingRight,
        paddingLeft: this.inputPaddingLeft,
        paddingTop: this.inputPaddingTop,
        paddingBottom: this.inputPaddingBottom,
        'text-align': this.inputTextAlign,
        'width': this.inputWidth,
        'outline-width': this.inputOutlineWidth,
        'outline-style': this.inputOutlineStyle,
        'outline-color': this.inputOutlineColor,
        'border-radius': this.inputBorderRadius
      }
    },
    _inputFocus () {
      return {
        color: this.inputColorOnFocus,
        fontSize: this.inputFontSizeOnFocus,
        paddingRight: this.inputPaddingRightOnFocus,
        paddingLeft: this.inputPaddingLeftOnFocus,
        paddingTop: this.inputPaddingTopOnFocus,
        paddingBottom: this.inputPaddingBottomOnFocus,
        width: this.inputWidthOnFocus,
        'text-align': this.inputTextAlignOnFocus,
        'outline-width': this.inputOutlineWidthOnFocus,
        'outline-style': this.inputOutlineStyleOnFocus,
        'outline-color': this.inputOutlineColorOnFocus,
        'border-radius': this.inputBorderRadiusOnFocus
      }
    },
    filteredFields () {
      if (!this.query) return this.fields
      return this.fields.filter(field => {
        if (field.name.toLowerCase().includes(this.query.toLowerCase())) return field
      })
    }
  },
  methods: {
    closeMenu () {
      setTimeout(() => {
        this.menuActiveValue = false
        this.inputFocus = null
        this.query = null
      }, 200)
    },
    searchValue (index) {
      const el = document.getElementById(`searchTxt-${this.componentIsUsedIn + index}`)
      if (el) this.query = el.value
    },
    focusField () {
      this.$refs.inputRef.focus()
      this.item.docTypeMenuActive = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .input-label {
    position: absolute;
    left: 20px;
    top: -8px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    white-space: nowrap;
    color: #234e79;
    background-color: inherit !important;
    font-size: 9px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
  }

  // comment this out to fix the placeholder opacity in Firefox
  ::-moz-placeholder {
    opacity: 1;
  }
</style>
